import {IOption} from 'src/@types/app';

export const languagesList: IOption[] = [
	{
		value: '',
		label: 'Selecione...',
	},
	{
		value: 'Abkhaz',
		label: 'Abkhaz',
	},
	{
		value: 'Afar',
		label: 'Afar',
	},
	{
		value: 'Afrikaans',
		label: 'Afrikaans',
	},
	{
		value: 'Akan',
		label: 'Akan',
	},
	{
		value: 'Albanian',
		label: 'Albanian',
	},
	{
		value: 'Amharic',
		label: 'Amharic',
	},
	{
		value: 'Arabic',
		label: 'Arabic',
	},
	{
		value: 'Aragonese',
		label: 'Aragonese',
	},
	{
		value: 'Armenian',
		label: 'Armenian',
	},
	{
		value: 'Assamese',
		label: 'Assamese',
	},
	{
		value: 'Avaric',
		label: 'Avaric',
	},
	{
		value: 'Avestan',
		label: 'Avestan',
	},
	{
		value: 'Aymara',
		label: 'Aymara',
	},
	{
		value: 'Azerbaijani',
		label: 'Azerbaijani',
	},
	{
		value: 'Bambara',
		label: 'Bambara',
	},
	{
		value: 'Bashkir',
		label: 'Bashkir',
	},
	{
		value: 'Basque',
		label: 'Basque',
	},
	{
		value: 'Belarusian',
		label: 'Belarusian',
	},
	{
		value: 'Bengali',
		label: 'Bengali',
	},
	{
		value: 'Bihari',
		label: 'Bihari',
	},
	{
		value: 'Bislama',
		label: 'Bislama',
	},
	{
		value: 'Bosnian',
		label: 'Bosnian',
	},
	{
		value: 'Breton',
		label: 'Breton',
	},
	{
		value: 'Bulgarian',
		label: 'Bulgarian',
	},
	{
		value: 'Burmese',
		label: 'Burmese',
	},
	{
		value: 'Catalan; Valencian',
		label: 'Catalan',
	},
	{
		value: 'Chamorro',
		label: 'Chamorro',
	},
	{
		value: 'Chechen',
		label: 'Chechen',
	},
	{
		value: 'Chichewa; Chewa; Nyanja',
		label: 'Chichewa',
	},
	{
		value: 'Chinese',
		label: 'Chinese',
	},
	{
		value: 'Chuvash',
		label: 'Chuvash',
	},
	{
		value: 'Cornish',
		label: 'Cornish',
	},
	{
		value: 'Corsican',
		label: 'Corsican',
	},
	{
		value: 'Cree',
		label: 'Cree',
	},
	{
		value: 'Croatian',
		label: 'Croatian',
	},
	{
		value: 'Czech',
		label: 'Czech',
	},
	{
		value: 'Danish',
		label: 'Danish',
	},
	{
		value: 'Divehi; Dhivehi; Maldivian;',
		label: 'Divehi',
	},
	{
		value: 'Dutch',
		label: 'Dutch',
	},
	{
		value: 'English',
		label: 'English',
	},
	{
		value: 'Esperanto',
		label: 'Esperanto',
	},
	{
		value: 'Estonian',
		label: 'Estonian',
	},
	{
		value: 'Ewe',
		label: 'Ewe',
	},
	{
		value: 'Faroese',
		label: 'Faroese',
	},
	{
		value: 'Fijian',
		label: 'Fijian',
	},
	{
		value: 'Finnish',
		label: 'Finnish',
	},
	{
		value: 'French',
		label: 'French',
	},
	{
		value: 'Fula; Fulah; Pulaar; Pular',
		label: 'Fula',
	},
	{
		value: 'Galician',
		label: 'Galician',
	},
	{
		value: 'Georgian',
		label: 'Georgian',
	},
	{
		value: 'German',
		label: 'German',
	},
	{
		value: 'Greek, Modern',
		label: 'Greek',
	},
	{
		value: 'Guaraní',
		label: 'Guaraní',
	},
	{
		value: 'Gujarati',
		label: 'Gujarati',
	},
	{
		value: 'Haitian; Haitian Creole',
		label: 'Haitian',
	},
	{
		value: 'Hausa',
		label: 'Hausa',
	},
	{
		value: 'Hebrew (modern)',
		label: 'Hebrew',
	},
	{
		value: 'Herero',
		label: 'Herero',
	},
	{
		value: 'Hindi',
		label: 'Hindi',
	},
	{
		value: 'Hiri Motu',
		label: 'Hiri',
	},
	{
		value: 'Hungarian',
		label: 'Hungarian',
	},
	{
		value: 'Interlingua',
		label: 'Interlingua',
	},
	{
		value: 'Indonesian',
		label: 'Indonesian',
	},
	{
		value: 'Interlingue',
		label: 'Interlingue',
	},
	{
		value: 'Irish',
		label: 'Irish',
	},
	{
		value: 'Igbo',
		label: 'Igbo',
	},
	{
		value: 'Inupiaq',
		label: 'Inupiaq',
	},
	{
		value: 'Ido',
		label: 'Ido',
	},
	{
		value: 'Icelandic',
		label: 'Icelandic',
	},
	{
		value: 'Italian',
		label: 'Italian',
	},
	{
		value: 'Inuktitut',
		label: 'Inuktitut',
	},
	{
		value: 'Japanese',
		label: 'Japanese',
	},
	{
		value: 'Javanese',
		label: 'Javanese',
	},
	{
		value: 'Kalaallisut, Greenlandic',
		label: 'Kalaallisut',
	},
	{
		value: 'Kannada',
		label: 'Kannada',
	},
	{
		value: 'Kanuri',
		label: 'Kanuri',
	},
	{
		value: 'Kashmiri',
		label: 'Kashmiri',
	},
	{
		value: 'Kazakh',
		label: 'Kazakh',
	},
	{
		value: 'Khmer',
		label: 'Khmer',
	},
	{
		value: 'Kikuyu, Gikuyu',
		label: 'Kikuyu',
	},
	{
		value: 'Kinyarwanda',
		label: 'Kinyarwanda',
	},
	{
		value: 'Kirghiz, Kyrgyz',
		label: 'Kirghiz',
	},
	{
		value: 'Komi',
		label: 'Komi',
	},
	{
		value: 'Kongo',
		label: 'Kongo',
	},
	{
		value: 'Korean',
		label: 'Korean',
	},
	{
		value: 'Kurdish',
		label: 'Kurdish',
	},
	{
		value: 'Kwanyama, Kuanyama',
		label: 'Kwanyama',
	},
	{
		value: 'Latin',
		label: 'Latin',
	},
	{
		value: 'Luxembourgish, Letzeburgesch',
		label: 'Luxembourgish',
	},
	{
		value: 'Luganda',
		label: 'Luganda',
	},
	{
		value: 'Limburgish, Limburgan, Limburger',
		label: 'Limburgish',
	},
	{
		value: 'Lingala',
		label: 'Lingala',
	},
	{
		value: 'Lao',
		label: 'Lao',
	},
	{
		value: 'Lithuanian',
		label: 'Lithuanian',
	},
	{
		value: 'Luba-Katanga',
		label: 'Luba',
	},
	{
		value: 'Latvian',
		label: 'Latvian',
	},
	{
		value: 'Manx',
		label: 'Manx',
	},
	{
		value: 'Macedonian',
		label: 'Macedonian',
	},
	{
		value: 'Malagasy',
		label: 'Malagasy',
	},
	{
		value: 'Malay',
		label: 'Malay',
	},
	{
		value: 'Malayalam',
		label: 'Malayalam',
	},
	{
		value: 'Maltese',
		label: 'Maltese',
	},
	{
		value: 'Māori',
		label: 'Māori',
	},
	{
		value: 'Marathi (Marāṭhī)',
		label: 'Marathi',
	},
	{
		value: 'Marshallese',
		label: 'Marshallese',
	},
	{
		value: 'Mongolian',
		label: 'Mongolian',
	},
	{
		value: 'Nauru',
		label: 'Nauru',
	},
	{
		value: 'Navajo, Navaho',
		label: 'Navajo',
	},
	{
		value: 'Norwegian Bokmål',
		label: 'Norwegian',
	},
	{
		value: 'North Ndebele',
		label: 'North',
	},
	{
		value: 'Nepali',
		label: 'Nepali',
	},
	{
		value: 'Ndonga',
		label: 'Ndonga',
	},
	{
		value: 'Norwegian Nynorsk',
		label: 'Norwegian',
	},
	{
		value: 'Norwegian',
		label: 'Norwegian',
	},
	{
		value: 'Nuosu',
		label: 'Nuosu',
	},
	{
		value: 'South Ndebele',
		label: 'South',
	},
	{
		value: 'Occitan',
		label: 'Occitan',
	},
	{
		value: 'Ojibwe, Ojibwa',
		label: 'Ojibwe',
	},
	{
		value: 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
		label: 'Old',
	},
	{
		value: 'Oromo',
		label: 'Oromo',
	},
	{
		value: 'Oriya',
		label: 'Oriya',
	},
	{
		value: 'Ossetian, Ossetic',
		label: 'Ossetian',
	},
	{
		value: 'Panjabi, Punjabi',
		label: 'Panjabi',
	},
	{
		value: 'Pāli',
		label: 'Pāli',
	},
	{
		value: 'Persian',
		label: 'Persian',
	},
	{
		value: 'Polish',
		label: 'Polish',
	},
	{
		value: 'Pashto, Pushto',
		label: 'Pashto',
	},
	{
		value: 'Portuguese',
		label: 'Portuguese',
	},
	{
		value: 'Quechua',
		label: 'Quechua',
	},
	{
		value: 'Romansh',
		label: 'Romansh',
	},
	{
		value: 'Kirundi',
		label: 'Kirundi',
	},
	{
		value: 'Romanian, Moldavian, Moldovan',
		label: 'Romanian',
	},
	{
		value: 'Russian',
		label: 'Russian',
	},
	{
		value: 'Sanskrit (Saṁskṛta)',
		label: 'Sanskrit',
	},
	{
		value: 'Sardinian',
		label: 'Sardinian',
	},
	{
		value: 'Sindhi',
		label: 'Sindhi',
	},
	{
		value: 'Northern Sami',
		label: 'Northern',
	},
	{
		value: 'Samoan',
		label: 'Samoan',
	},
	{
		value: 'Sango',
		label: 'Sango',
	},
	{
		value: 'Serbian',
		label: 'Serbian',
	},
	{
		value: 'Scottish Gaelic; Gaelic',
		label: 'Scottish',
	},
	{
		value: 'Shona',
		label: 'Shona',
	},
	{
		value: 'Sinhala, Sinhalese',
		label: 'Sinhala',
	},
	{
		value: 'Slovak',
		label: 'Slovak',
	},
	{
		value: 'Slovene',
		label: 'Slovene',
	},
	{
		value: 'Somali',
		label: 'Somali',
	},
	{
		value: 'Southern Sotho',
		label: 'Southern',
	},
	{
		value: 'Spanish; Castilian',
		label: 'Spanish',
	},
	{
		value: 'Sundanese',
		label: 'Sundanese',
	},
	{
		value: 'Swahili',
		label: 'Swahili',
	},
	{
		value: 'Swati',
		label: 'Swati',
	},
	{
		value: 'Swedish',
		label: 'Swedish',
	},
	{
		value: 'Tamil',
		label: 'Tamil',
	},
	{
		value: 'Telugu',
		label: 'Telugu',
	},
	{
		value: 'Tajik',
		label: 'Tajik',
	},
	{
		value: 'Thai',
		label: 'Thai',
	},
	{
		value: 'Tigrinya',
		label: 'Tigrinya',
	},
	{
		value: 'Tibetan Standard, Tibetan, Central',
		label: 'Tibetan',
	},
	{
		value: 'Turkmen',
		label: 'Turkmen',
	},
	{
		value: 'Tagalog',
		label: 'Tagalog',
	},
	{
		value: 'Tswana',
		label: 'Tswana',
	},
	{
		value: 'Tonga (Tonga Islands)',
		label: 'Tonga',
	},
	{
		value: 'Turkish',
		label: 'Turkish',
	},
	{
		value: 'Tsonga',
		label: 'Tsonga',
	},
	{
		value: 'Tatar',
		label: 'Tatar',
	},
	{
		value: 'Twi',
		label: 'Twi',
	},
	{
		value: 'Tahitian',
		label: 'Tahitian',
	},
	{
		value: 'Uighur, Uyghur',
		label: 'Uighur',
	},
	{
		value: 'Ukrainian',
		label: 'Ukrainian',
	},
	{
		value: 'Urdu',
		label: 'Urdu',
	},
	{
		value: 'Uzbek',
		label: 'Uzbek',
	},
	{
		value: 'Venda',
		label: 'Venda',
	},
	{
		value: 'Vietnamese',
		label: 'Vietnamese',
	},
	{
		value: 'Volapük',
		label: 'Volapük',
	},
	{
		value: 'Walloon',
		label: 'Walloon',
	},
	{
		value: 'Welsh',
		label: 'Welsh',
	},
	{
		value: 'Wolof',
		label: 'Wolof',
	},
	{
		value: 'Western Frisian',
		label: 'Western',
	},
	{
		value: 'Xhosa',
		label: 'Xhosa',
	},
	{
		value: 'Yiddish',
		label: 'Yiddish',
	},
	{
		value: 'Yoruba',
		label: 'Yoruba',
	},
	{
		value: 'Zhuang, Chuang',
		label: 'Zhuang',
	},
];
