/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import {IComponent} from 'src/@types/app';
import {ComponentComplexEnum} from 'src/@types/enums';
import Components from 'src/shared/components';
import {IComponentView} from 'src/shared/components/types';
import ComponentContent from 'src/pages/builder/Builder/components/ComponentContent';

export const transformToJsx = (
	widgets: IComponent[],
	showEditComplexBtn: boolean,
	toggleSpecifyModal: (type: ComponentComplexEnum) => void,
	selectedItem: any,
	onSelect?: (val: string) => void,
	enableHelpLines?: boolean,
) => {
	return transformToView(widgets)?.map((item, index) => (
		<ComponentContent
			key={index}
			widget={item}
			onSelect={onSelect}
			enableHelpLines={enableHelpLines}
			widgets={widgets}
			showEditComplexBtn={showEditComplexBtn}
			toggleSpecifyModal={toggleSpecifyModal}
			selectedItem={selectedItem}
		/>
	));
};

const transformToView = (widgets: IComponent[]): IComponentView[] => {
	const view: IComponentView[] = [];

	widgets
		.filter(item => !item.parentRef)
		.forEach(item => {
			if (!item.component) {
				item.component = Components.find(x => x.name === item.name)!.component;
			}

			view.push({
				component: item,
				components: [],
			});
		});

	view.forEach(item => {
		item.components = addSubItem(item.component.ref as string, widgets);
	});

	return view;
};

const addSubItem = (ref: string, widgets: IComponent[]): IComponentView[] => {
	const subItens: IComponentView[] = [];
	widgets
		.filter(item => !!item.parentRef)
		.forEach(item => {
			if (item.parentRef === ref) {
				if (!item.component) {
					item.component = Components.find(x => x.name === item.name)!.component;
				}

				subItens.push({
					component: item,
					components: addSubItem(item.ref as string, widgets),
				});
			}
		});

	return subItens;
};
