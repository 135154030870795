/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import {Input, SizedBox} from 'src/shared/components/UI';
import * as S from '../styles';
import ExpressionItem from 'src/pages/builder/Builder/components/Expression/ExpressionItem';
import {IExpression, IWorkFlowItem} from 'src/@types/app';
import {setWorkFlowValues} from '../helper';

type Props = {
	item: IWorkFlowItem;
	workFlowItem: IWorkFlowItem[];
	setWorkFlowItem: (value: IWorkFlowItem[]) => void;
};

export const VariableComp = ({item, workFlowItem, setWorkFlowItem}: Props) => {
	const saveExp = (data: IExpression) => {
		setWorkFlowValues(workFlowItem, setWorkFlowItem, item.ref!, 'expression', data);
	};

	return (
		<S.ContentBasic>
			<Input
				value={item.values?.variableName}
				onChange={val =>
					setWorkFlowValues(workFlowItem, setWorkFlowItem, item.ref!, 'variableName', val)
				}
				inputType="minimal"
				label="Nome"
				mask="Sem espaço"
			/>
			<SizedBox height="5px" />

			<ExpressionItem
				currentItem={item?.values?.expression ?? {}}
				customSave={saveExp}
				inputType="minimal"
			/>
		</S.ContentBasic>
	);
};
