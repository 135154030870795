import React from 'react';
import {AddBarItem} from 'src/shared/components/System/Layout/ui/AddBarItem';
import {applicationTheme} from 'src/shared/theme';

type Props = {
	activeItem: string;
	setActiveItem: (value: string) => void;
};

export const LeftBarRender = ({activeItem, setActiveItem}: Props) => {
	return (
		<>
			<AddBarItem
				icon="airplay"
				text="Ambientes"
				onClick={() => setActiveItem('environment')}
				color={applicationTheme.gray[400]}
				textColor={applicationTheme.gray[900]}
				iconSize={20}
				selected={activeItem == 'environment'}
			/>
			<AddBarItem
				icon="database"
				text="Banco de dados"
				onClick={() => setActiveItem('database')}
				color={applicationTheme.gray[400]}
				textColor={applicationTheme.gray[900]}
				iconSize={20}
				selected={activeItem == 'database'}
			/>
			<AddBarItem
				icon="at-sign"
				text="Variáveis"
				onClick={() => setActiveItem('variables')}
				color={applicationTheme.gray[400]}
				textColor={applicationTheme.gray[900]}
				iconSize={20}
				selected={activeItem == 'variables'}
			/>
			<AddBarItem
				icon="globe"
				text="API's"
				onClick={() => setActiveItem('api')}
				color={applicationTheme.gray[400]}
				textColor={applicationTheme.gray[900]}
				iconSize={20}
				selected={activeItem == 'api'}
			/>
			<AddBarItem
				icon="mail"
				text="E-mail"
				onClick={() => setActiveItem('email')}
				color={applicationTheme.gray[400]}
				textColor={applicationTheme.gray[900]}
				iconSize={20}
				selected={activeItem == 'email'}
			/>
			<AddBarItem
				icon="copy"
				text="FTP"
				onClick={() => setActiveItem('ftp')}
				color={applicationTheme.gray[400]}
				textColor={applicationTheme.gray[900]}
				iconSize={20}
				selected={activeItem == 'ftp'}
			/>
			<AddBarItem
				icon="message-square"
				text="Mensageria"
				onClick={() => setActiveItem('messager')}
				color={applicationTheme.gray[400]}
				textColor={applicationTheme.gray[900]}
				iconSize={20}
				selected={activeItem == 'messager'}
			/>
			<AddBarItem
				icon="hard-drive"
				text="Cache"
				onClick={() => setActiveItem('cache')}
				color={applicationTheme.gray[400]}
				textColor={applicationTheme.gray[900]}
				iconSize={20}
				selected={activeItem == 'cache'}
			/>
			<AddBarItem
				icon="settings"
				text="Traduções"
				onClick={() => setActiveItem('translation')}
				color={applicationTheme.gray[400]}
				textColor={applicationTheme.gray[900]}
				iconSize={20}
				selected={activeItem == 'translation'}
			/>
			<AddBarItem
				icon="git-merge"
				text="Versionamento"
				onClick={() => setActiveItem('versioning')}
				color={applicationTheme.gray[400]}
				textColor={applicationTheme.gray[900]}
				iconSize={20}
				selected={activeItem == 'versioning'}
			/>
			<AddBarItem
				icon="pen-tool"
				text="Layout & Personalização"
				onClick={() => setActiveItem('layout')}
				color={applicationTheme.gray[400]}
				textColor={applicationTheme.gray[900]}
				iconSize={20}
				selected={activeItem == 'layout'}
			/>
			<AddBarItem
				icon="camera"
				text="Snapshot"
				onClick={() => setActiveItem('snapshot')}
				color={applicationTheme.gray[400]}
				textColor={applicationTheme.gray[900]}
				iconSize={20}
				selected={activeItem == 'snapshot'}
			/>
		</>
	);
};
