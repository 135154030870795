/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {IApplication, IBackDotnetCqrsProject, IResourceApi} from 'src/@types/app';
import {v4 as uuidV4} from 'uuid';

export const generateSln = (
	app: IApplication,
	projectIds: IBackDotnetCqrsProject,
	projName: string,
): IResourceApi => {
	return {
		name: `${projName}.sln`,
		path: 'src/',
		code: getCode(projName, projectIds),
	};
};

const getCode = (name: string, projectIds: IBackDotnetCqrsProject): string => {
	const defaultUuid = uuidV4().toString();
	return `
Microsoft Visual Studio Solution File, Format Version 12.00
# Visual Studio Version 17
VisualStudioVersion = 17.0.31903.59
MinimumVisualStudioVersion = 10.0.40219.1
Project("{${defaultUuid}}") = "${name}.API", "${name}.API\\${name}.API.csproj", "{${projectIds.Api}}"
EndProject
Project("{${defaultUuid}}") = "${name}.Application", "${name}.Application\\${name}.Application.csproj", "{${projectIds.Application}}"
EndProject
Project("{${defaultUuid}}") = "${name}.Domain", "${name}.Domain\\${name}.Domain.csproj", "{${projectIds.Domain}}"
EndProject
Project("{${defaultUuid}}") = "${name}.Infra.CrossCutting.Identity", "${name}.Infra.CrossCutting.Identity\\${name}.Infra.CrossCutting.Identity.csproj", "{${projectIds.Identity}}"
EndProject
Project("{${defaultUuid}}") = "${name}.Infra.Data", "${name}.Infra.Data\\${name}.Infra.Data.csproj", "{${projectIds.Data}}"
EndProject
Project("{${defaultUuid}}") = "${name}.CrossCutting.IoC", "${name}.CrossCutting.IoC\\${name}.CrossCutting.IoC.csproj", "{${projectIds.IoC}}"
EndProject
Global
  GlobalSection(SolutionConfigurationPlatforms) = preSolution
    Debug|Any CPU = Debug|Any CPU
    Release|Any CPU = Release|Any CPU
  EndGlobalSection
  GlobalSection(SolutionProperties) = preSolution
    HideSolutionNode = FALSE
  EndGlobalSection
  GlobalSection(ProjectConfigurationPlatforms) = postSolution
    {${projectIds.Api}}.Debug|Any CPU.ActiveCfg = Debug|Any CPU
    {${projectIds.Api}}.Debug|Any CPU.Build.0 = Debug|Any CPU
    {${projectIds.Api}}.Release|Any CPU.ActiveCfg = Release|Any CPU
    {${projectIds.Api}}.Release|Any CPU.Build.0 = Release|Any CPU
    {${projectIds.Application}}.Debug|Any CPU.ActiveCfg = Debug|Any CPU
    {${projectIds.Application}}.Debug|Any CPU.Build.0 = Debug|Any CPU
    {${projectIds.Application}}.Release|Any CPU.ActiveCfg = Release|Any CPU
    {${projectIds.Application}}.Release|Any CPU.Build.0 = Release|Any CPU
    {${projectIds.Domain}}.Debug|Any CPU.ActiveCfg = Debug|Any CPU
    {${projectIds.Domain}}.Debug|Any CPU.Build.0 = Debug|Any CPU
    {${projectIds.Domain}}.Release|Any CPU.ActiveCfg = Release|Any CPU
    {${projectIds.Domain}}.Release|Any CPU.Build.0 = Release|Any CPU
    {${projectIds.Identity}}.Debug|Any CPU.ActiveCfg = Debug|Any CPU
    {${projectIds.Identity}}.Debug|Any CPU.Build.0 = Debug|Any CPU
    {${projectIds.Identity}}.Release|Any CPU.ActiveCfg = Release|Any CPU
    {${projectIds.Identity}}.Release|Any CPU.Build.0 = Release|Any CPU
    {${projectIds.Data}}.Debug|Any CPU.ActiveCfg = Debug|Any CPU
    {${projectIds.Data}}.Debug|Any CPU.Build.0 = Debug|Any CPU
    {${projectIds.Data}}.Release|Any CPU.ActiveCfg = Release|Any CPU
    {${projectIds.Data}}.Release|Any CPU.Build.0 = Release|Any CPU
    {${projectIds.IoC}}.Debug|Any CPU.ActiveCfg = Debug|Any CPU
    {${projectIds.IoC}}.Debug|Any CPU.Build.0 = Debug|Any CPU
    {${projectIds.IoC}}.Release|Any CPU.ActiveCfg = Release|Any CPU
    {${projectIds.IoC}}.Release|Any CPU.Build.0 = Release|Any CPU
  EndGlobalSection
EndGlobal`;
};
