import {IOption} from 'src/@types/app';

export const BackendLanguages: IOption[] = [
	{
		label: '.Net',
		value: '.Net',
	},
];

export const BackendLanguagesVersion: IOption[] = [
	{
		label: '6.0',
		value: '6.0',
	},
	{
		label: '7.0',
		value: '7.0',
	},
	{
		label: '8.0',
		value: '8.0',
	},
];
