/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {useContext} from 'react';
import {IOption, IWorkFlowItem} from 'src/@types/app';
import {setWorkFlowValues} from '../helper';
import * as S from '../styles';
import {ApplicationContext} from 'src/context';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';

type Props = {
	item: IWorkFlowItem;
	workFlowItem: IWorkFlowItem[];
	setWorkFlowItem: (value: IWorkFlowItem[]) => void;
};

export const EmailComp = ({item, workFlowItem, setWorkFlowItem}: Props) => {
	const [application] = useContext(ApplicationContext);

	const getEmailData = (): IOption[] =>
		(application.emails ?? []).map(item => ({
			label: `${item.host}`,
			value: item.ref,
		}));

	return (
		<S.ContentBasic>
			<SystemSelect
				value={getEmailData().find(x => x.value === item.values?.email)}
				options={getEmailData()}
				onChange={value =>
					setWorkFlowValues(
						workFlowItem,
						setWorkFlowItem,
						item.ref!,
						'email',
						value.value,
					)
				}
				label="E-mail"
				inputType="minimal"
			/>
		</S.ContentBasic>
	);
};
