import React from 'react';
import * as S from './styles';
import FeatherIcon from 'feather-icons-react';
import {Button, Wrapper} from 'src/shared/components/UI';
import {applicationTheme} from 'src/shared/theme';

type Props = {
	id: number;
	name?: string;
	description?: string;
	benefit?: string;
	icon: string;
	iconColor?: string;
	price?: string;
	term: number;
	category?: string;
};

export const PlanCard = ({
	id,
	name,
	description,
	benefit,
	icon,
	iconColor,
	price,
	term,
	category,
}: Props) => {
	return (
		<S.Content>
			<div className="top">
				<div className="icon">
					<S.CircleAction textColor={applicationTheme.gray[25]} background={iconColor}>
						<FeatherIcon icon={icon} size={16} />
					</S.CircleAction>
				</div>

				<div className="name">
					<span>Plano {name}</span>
				</div>

				<div className="category">
					<span>{category}</span>
				</div>

				<div className="price">
					<span>R$ {price}</span>
				</div>

				<div className="term">
					<span>Por {term} meses</span>
				</div>
			</div>

			<div className="footer">
				<Wrapper justifyContent="center" flexDirection="row" gap="15px">
					<Button
						text="Adquirir plano"
						onClick={() => {
							const key: number = id;
							alert(key);
						}}
						background={applicationTheme.brand[600]}
						textColor={applicationTheme.gray[50]}
						fill="auto"
						height="36px"
					/>

					<Button
						text="Saiba mais"
						onClick={() => {
							const key: number = id;
							alert(key);
						}}
						background={applicationTheme.gray[50]}
						textColor={applicationTheme.brand[600]}
						fill="auto"
						height="36px"
					/>
				</Wrapper>
			</div>
		</S.Content>
	);
};
