import {IOption} from 'src/@types/app';
import {ResourceTypeEnum} from 'src/@types/enums';

export const ResourceTypeData: IOption[] = [
	{
		value: undefined,
		label: 'Selecione...',
	},
	{
		value: ResourceTypeEnum.Page,
		label: 'Página',
	},
	{
		value: ResourceTypeEnum.Modal,
		label: 'Modal',
	},
];
