import {ITheme} from 'src/shared/theme';
import {modifiersTheme} from 'src/shared/theme/modifiers';
import styled, {css} from 'styled-components';

type ContentProps = {
	theme: ITheme;
	themeStyle?: 'success' | 'danger' | 'info' | 'alert' | 'black' | 'primary' | 'light';
	fontSize?: string;
};

export const Content = styled.div<ContentProps>`
	${({theme, themeStyle, fontSize}) => css`
		display: flex;
		align-items: center;
		gap: 8px;
		width: auto;
		color: ${modifiersTheme(theme, themeStyle as string)?.main ?? 'black'};
		font-size: ${fontSize ?? '13px'};
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	`}
`;
