import {IComponent} from 'src/@types/app';
import {ComponentComplexEnum, ComponentSimpleEnum, ComponentTypeEnum} from 'src/@types/enums';
import {DataGrid, Tab, LabelValue} from './Complex';
import {labelValueProperties} from './Complex/LabelValue';
import {
	CheckBox,
	Input,
	Switch,
	WidthBox,
	Wrapper,
	Button,
	InputDate,
	InputTag,
	SizedBox,
	IconButton,
	Box,
	Collapse,
	Select,
	Autocomplete,
	Text,
	Address,
	TextArea,
	Section,
	IconContent,
} from './UI';
import {addressProperties} from './UI/Address';
import {autocompleteProperties} from './UI/Autocomplete';
import {boxProperties} from './UI/Box';
import {buttonProperties} from './UI/Button';
import {checkboxProperties} from './UI/Checkbox';
import {collapseProperties} from './UI/Collapse';
import {iconButtonProperties} from './UI/IconButton';
import {iconContentProperties} from './UI/IconContent';
import {inputProperties} from './UI/Input';
import {inputDateProperties} from './UI/InputDate';
import {inputTagProperties} from './UI/InputTag';
import Line, {lineProperties} from './UI/Line';
import {sectionProperties} from './UI/Section';
import {selectProperties} from './UI/Select';
import {sizedBoxProperties} from './UI/SizedBox';
import {switchProperties} from './UI/Switch';
import {textProperties} from './UI/Text';
import {textAreaProperties} from './UI/TextArea';
import {widthBoxProperties} from './UI/WidthBox';
import {wrapperProperties} from './UI/Wrapper';

export const DataInputComponent = [
	'Input',
	'InputDate',
	'InputTag',
	'Checkbox',
	'Switch',
	ComponentSimpleEnum.Autocomplete,
	ComponentSimpleEnum.Select,
];
export const DataComplexComponent = [
	ComponentComplexEnum.DataGrid,
	ComponentComplexEnum.Modal,
	ComponentComplexEnum.Tab,
	ComponentComplexEnum.UploadFile,
	ComponentComplexEnum.LabelValue,
	ComponentComplexEnum.Chart,
	ComponentComplexEnum.Timeline,
	'',
];

const Components: IComponent[] = [
	// UI
	{
		name: ComponentSimpleEnum.Text,
		type: ComponentTypeEnum.UI,
		icon: 'type',
		component: Text,
		options: textProperties,
	},
	{
		name: ComponentSimpleEnum.Input,
		type: ComponentTypeEnum.UI,
		icon: 'message-square',
		component: Input,
		options: inputProperties,
	},
	{
		name: ComponentSimpleEnum.InputDate,
		type: ComponentTypeEnum.UI,
		icon: 'calendar',
		component: InputDate,
		options: inputDateProperties,
	},
	{
		name: ComponentSimpleEnum.InputTag,
		type: ComponentTypeEnum.UI,
		icon: 'tag',
		component: InputTag,
		options: inputTagProperties,
	},
	{
		name: ComponentSimpleEnum.Select,
		type: ComponentTypeEnum.UI,
		icon: 'arrow-down',
		component: Select,
		options: selectProperties,
	},
	{
		name: ComponentSimpleEnum.Autocomplete,
		type: ComponentTypeEnum.UI,
		icon: 'list',
		component: Autocomplete,
		options: autocompleteProperties,
	},
	{
		name: ComponentSimpleEnum.TextArea,
		type: ComponentTypeEnum.UI,
		icon: 'align-justify',
		component: TextArea,
		options: textAreaProperties,
	},
	{
		name: ComponentSimpleEnum.Checkbox,
		type: ComponentTypeEnum.UI,
		icon: 'check-circle',
		component: CheckBox,
		options: checkboxProperties,
	},
	{
		name: ComponentSimpleEnum.Switch,
		type: ComponentTypeEnum.UI,
		icon: 'toggle-left',
		component: Switch,
		options: switchProperties,
	},
	{
		name: ComponentSimpleEnum.Button,
		type: ComponentTypeEnum.UI,
		icon: 'square',
		component: Button,
		options: buttonProperties,
	},
	{
		name: ComponentSimpleEnum.IconButton,
		type: ComponentTypeEnum.UI,
		icon: 'paperclip',
		component: IconButton,
		options: iconButtonProperties,
	},
	{
		name: ComponentSimpleEnum.Address,
		type: ComponentTypeEnum.UI,
		icon: 'map-pin',
		component: Address,
		options: addressProperties,
	},
	{
		name: ComponentSimpleEnum.IconContent,
		type: ComponentTypeEnum.UI,
		icon: 'feather',
		component: IconContent,
		options: iconContentProperties,
	},

	// CONTAINER
	{
		name: ComponentSimpleEnum.Line,
		type: ComponentTypeEnum.Container,
		icon: 'minus',
		component: Line,
		options: lineProperties,
	},
	{
		name: ComponentSimpleEnum.Box,
		type: ComponentTypeEnum.Container,
		icon: 'clipboard',
		component: Box,
		options: boxProperties,
	},
	{
		name: ComponentSimpleEnum.SizedBox,
		type: ComponentTypeEnum.Container,
		icon: 'more-vertical',
		component: SizedBox,
		options: sizedBoxProperties,
	},
	{
		name: ComponentSimpleEnum.WidthBox,
		type: ComponentTypeEnum.Container,
		icon: 'more-horizontal',
		component: WidthBox,
		options: widthBoxProperties,
	},
	{
		name: ComponentSimpleEnum.Wrapper,
		type: ComponentTypeEnum.Container,
		icon: 'columns',
		component: Wrapper,
		options: wrapperProperties,
	},
	{
		name: ComponentSimpleEnum.Collapse,
		type: ComponentTypeEnum.Container,
		icon: 'credit-card',
		component: Collapse,
		options: collapseProperties,
	},
	{
		name: ComponentSimpleEnum.Section,
		type: ComponentTypeEnum.Container,
		icon: 'layers',
		component: Section,
		options: sectionProperties,
	},

	// COMPLEX
	{
		name: ComponentComplexEnum.DataGrid,
		type: ComponentTypeEnum.Complex,
		icon: 'grid',
		component: DataGrid,
	},
	{
		name: ComponentComplexEnum.Tab,
		type: ComponentTypeEnum.Complex,
		icon: 'table',
		component: Tab,
	},
	// {
	// 	name: ComponentComplexEnum.UploadFile,
	// 	type: ComponentTypeEnum.Complex,
	// 	icon: 'type',
	// 	component: UploadFile,
	// },
	// {
	// 	name: ComponentComplexEnum.Timeline,
	// 	type: ComponentTypeEnum.Complex,
	// 	icon: 'type',
	// 	component: Timeline,
	// },
	{
		name: ComponentComplexEnum.LabelValue,
		type: ComponentTypeEnum.Complex,
		icon: 'crosshair',
		component: LabelValue,
		options: labelValueProperties,
	},
	// {
	// 	name: ComponentComplexEnum.Chart,
	// 	type: ComponentTypeEnum.Complex,
	// 	icon: 'type',
	// 	component: Charts,
	// },
];

export default Components;
