import React from 'react';
import * as S from './styles';

type Props = {
	title: string;
	subtitle?: string;
	leftContent?: JSX.Element;
	icon?: JSX.Element; // Novo: Ícone opcional
	centerTitle?: boolean; // Novo: Centralizar título
};

const ModalTitle = ({title, subtitle, leftContent, icon, centerTitle}: Props) => {
	return (
		<S.ContentModal>
			<div className={`container ${centerTitle ? 'centered' : ''}`}>
				{icon && <div className="icon">{icon}</div>}
				<div className="text-container">
					<div className="title">{title}</div>
					{subtitle && <div className="subtitle">{subtitle}</div>}
				</div>
				{leftContent}
			</div>
		</S.ContentModal>
	);
};

export default ModalTitle;
