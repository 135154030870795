import {applicationTheme} from 'src/shared/theme';
import styled from 'styled-components';

interface PopBtnProps {
	isRemove?: boolean; // A prop isRemove é opcional
}

export const Content = styled.div`
	border-radius: 4px;
	background: #fff;
	height: 60px;
	width: 250px;
	display: flex;
	justify-content: space-between;
	cursor: pointer;
	transition: 0.4s;

	&:hover {
		box-shadow: 2px 2px 5px ${applicationTheme.gray[300]};
		transition: 0.4s;
	}
`;

export const Principal = styled.div`
	margin-left: 16px;
	margin-bottom: 21px;
	margin-top: 21px;
	display: flex;
	align-items: center;
	justify-content: center;

	.img {
		color: ${applicationTheme.gray[500]};
		margin-right: 21px;
	}

	.name {
		font-family: 'Inter';
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: ${applicationTheme.gray[600]};
	}
`;

export const Second = styled.div`
	display: flex;
	justify-content: end;
	align-items: start;
	width: auto;

	.icon {
		color: ${applicationTheme.gray[400]};
		margin-right: 2px;
		margin-top: 5px;

		&:hover {
			color: ${applicationTheme.gray[600]};
		}
	}
`;

export const PopUpContent = styled.div`
	border-radius: 4px;
	background: #fff;
	box-shadow: 2px 2px 5px ${applicationTheme.gray[300]};
	transition: 0.4s;
`;

export const PopBtn = styled.div<PopBtnProps>`
	font-family: 'Inter';
	font-weight: 400;
	font-size: 13px;
	display: flex;
	align-items: center;
	justify-content: start;
	padding: 12px 32px 12px 20px;
	color: ${({isRemove}) => (isRemove ? applicationTheme.error[900] : applicationTheme.gray[600])};
	cursor: pointer;
	gap: 10px;

	&:hover {
		background-color: ${applicationTheme.gray[100]};
	}
`;
