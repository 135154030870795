/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	IApplicationApiComplexUpdate,
	IApplicationApiCreate,
	IApplicationApiUpdate,
	IApplicationApiUpdateFolder,
} from 'src/@types/api-request';
import {
	IApplicationApiResult,
	IApplicationApiResourceResult,
	IApplicationBuildResult,
} from 'src/@types/api-result';
import {IApplication, ILayoutData, IProject} from 'src/@types/app';
import {Api} from './base';
import {getTemplateComponent} from 'src/templates/template-config';
import {TemplateEnum} from 'src/templates/template-enum';

export class ApplicationApi {
	async getById(id: number): Promise<IApplication | null> {
		const result = await Api.get(`/application/${id}`);

		if (result.status == 200) {
			const resultData: IApplicationApiResult = result.data;
			const layout: ILayoutData = resultData?.layout
				? JSON.parse(resultData?.layout)
				: undefined;

			const app: IApplication = {
				id: resultData.id,
				ref: resultData.reference,
				name: resultData.name,
				apis: JSON.parse(resultData.apis),
				database: resultData.database ? JSON.parse(resultData.database) : undefined,
				databaseRules: resultData.databaseRules
					? JSON.parse(resultData.databaseRules)
					: undefined,
				template: getTemplateComponent(layout?.templateName as TemplateEnum),
				account: resultData.account ? JSON.parse(resultData.account) : undefined,
				companyName: resultData.companyName,
				git: resultData.git ? JSON.parse(resultData.git) : undefined,
				layout: layout,
				security: resultData.security ? JSON.parse(resultData.security) : undefined,
				translation: resultData.translation
					? JSON.parse(resultData.translation)
					: undefined,
				buildStatus: resultData.buildStatus,
				projectLink: resultData.projectLink,
				userConfiguration: resultData.contextEnvironment
					? JSON.parse(resultData.contextEnvironment)
					: undefined,
				environments: resultData.environments
					? JSON.parse(resultData.environments)
					: undefined,
				emails: resultData.emails ? JSON.parse(resultData.emails) : undefined,
				amqps: resultData.amqps ? JSON.parse(resultData.amqps) : undefined,
				ftps: resultData.ftps ? JSON.parse(resultData.ftps) : undefined,
				caches: resultData.caches ? JSON.parse(resultData.caches) : undefined,
				projectConfiguration: resultData.projectConfiguration
					? JSON.parse(resultData.projectConfiguration)
					: undefined,
			};

			return app;
		}

		return null;
	}

	async getAll(): Promise<IProject | null> {
		const result = await Api.get('/application');

		if (result.status == 200) {
			const applications: IApplication[] = [];
			const resultData: IApplicationApiResult[] = result.data;

			resultData.forEach(item => {
				const app: IApplication = {
					id: item.id,
					ref: item.reference,
					name: item.name,
				};

				applications.push(app);
			});

			return {
				applications,
			};
		}

		return null;
	}

	async getResources(application: IApplication, setFolder = true): Promise<IApplication | null> {
		const folderSelected = application.folders?.find(x => x.selected);

		const result = await Api.get(`/application/resources/${application.id}`);

		if (result.status == 200) {
			const resultData: IApplicationApiResourceResult = result.data;

			const resources: any = resultData.resources.map(resource => ({
				id: resource.id,
				name: resource.name,
				type: resource.type,
				ref: resource.reference,
				width: resource.width,
				path: resource.path,
				menu: JSON.parse(resource.menu),
				isRoot: resource.isRoot,
				useDefaultLayout: resource.useDefaultLayout,
				isLogin: resource.isLogin,
				databaseTableRef: resource.databaseTableRef,
			}));

			const folders = JSON.parse(resultData.folders);

			if (folderSelected && setFolder) {
				folders.forEach((item: any) => {
					if (item.ref === folderSelected.ref) item.selected = true;
				});
			}
			if (!setFolder) {
				folders?.forEach((item: any) => {
					item.selected = false;
				});
			}

			const app: IApplication = {
				...application,
				folders,
				resources: resources,
			};

			return app;
		}

		return null;
	}

	async getBuildingStatus(projectId: number): Promise<IApplicationBuildResult | null> {
		const result = await Api.get(`/application/build/${projectId}`);

		if (result.status == 200) {
			return result.data;
		}

		return null;
	}

	async create(data: IApplicationApiCreate): Promise<number | null> {
		const result = await Api.post('/application', data);

		if (result.status == 200) {
			return result.data;
		}

		return null;
	}

	async update(data: IApplicationApiUpdate): Promise<boolean> {
		const result = await Api.put('/application', {...data});

		if (result.status == 200) return true;
		return false;
	}

	async updateComplex(data: IApplicationApiComplexUpdate): Promise<boolean> {
		const result = await Api.put('/application/complex', {...data});

		if (result.status == 200) return true;
		return false;
	}

	async updateFolder(data: IApplicationApiUpdateFolder): Promise<boolean> {
		const result = await Api.put('/application/folder', {...data});

		if (result.status == 200) return true;
		return false;
	}

	async delete(id: number): Promise<boolean> {
		const result = await Api.delete(`/application/${id}`);

		if (result.status == 200) return true;
		return false;
	}
}
