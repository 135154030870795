import {ComponentTypeEnum} from 'src/@types/enums';
import Components from 'src/shared/components';

export const clearEmptyContainer = (code: string) => {
	const components = Components.filter(x => x.type === ComponentTypeEnum.Container).map(
		x => x.name,
	);
	components.forEach(comp => {
		code = code.replaceAll(createTag(comp), '');
	});

	code = code.replaceAll('<Wrapper></Wrapper>', '');

	return code;
};

const createTag = (name: string) => `<${name}></${name}>`;
