import styled from 'styled-components';

export const Content = styled.div`
	margin: 25px;
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const ContentDiagram = styled.div`
	overflow-x: hidden;
	overflow-y: hidden;
`;
