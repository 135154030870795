import styled from 'styled-components';
import {applicationTheme} from 'src/shared/theme';

export const Container = styled.div`
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow-y: hidden;
`;

export const Content = styled.div`
	width: 100%;
	background: #fff;
	display: flex;
`;

export const TitleLogin = styled.div`
	color: ${applicationTheme.brand[600]};
	font-size: 20px;
	padding-top: 10px;
	font-weight: 600;
`;

export const Title = styled.div`
	color: 'black';
	font-size: 17px;
	padding-top: 10px;
`;

export const Header = styled.div`
	height: 80px;
	position: absolute;
	background-color: ${applicationTheme.brand[800]};
	left: 0;
	top: 0;
	width: 100%;
	color: white;
	text-align: center;

	.LogoHeader {
		float: none;
		display: block;
		text-align: left;
		padding: 20px;
		height: 40px;
	}
`;

export const FormLogin = styled.div`
	height: 330px;
	width: 320px;
	background: #fff;
	margin-top: -5%;
`;

export const ContentLogin = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const Footer = styled.div`
	height: 80px;
	position: fixed;
	background-color: ${applicationTheme.brand[800]};
	left: 0;
	bottom: 0;
	width: 100%;
	color: white;
	text-align: center;
`;
