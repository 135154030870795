import {applicationTheme} from 'src/shared/theme';
import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
`;

export const Content = styled.div`
	width: 280px;
	padding: 40px;
	background-color: #fff;
	border-radius: 22px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-family: 'Inter';
	height: auto;

	.text1 {
		font-weight: 500;
		font-size: 20px;
		line-height: 28px;
		color: ${applicationTheme.gray[700]};
		text-align: center;
		margin-top: 25px;
		width: 70%;
	}

	.text2 {
		font-weight: 400;
		font-size: 16px;
		line-height: 22px;
		color: ${applicationTheme.gray[500]};
		text-align: center;
		margin-top: 25px;
		margin-bottom: 25px;
		width: 70%;
	}

	.text3 {
		font-weight: 400;
		font-size: 16px;
		line-height: 22px;
		color: ${applicationTheme.gray[500]};
		text-align: center;
		margin-top: 20px;
		margin-bottom: 20px;
		width: 70%;
	}
`;
