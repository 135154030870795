/* eslint-disable @typescript-eslint/no-explicit-any */
import {faAdd, faTrash} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useContext, useEffect, useState} from 'react';
import {IComponent, IOption} from 'src/@types/app';
import {ApiParamTypeEnum, ComponentComplexEnum} from 'src/@types/enums';
import {TimelineIconType, TimelineProps} from 'src/shared/components/Complex/Timeline';
import {
	Button,
	Select,
	WidthBox,
	Wrapper,
	Text,
	Input,
	Switch,
	ModalTitle,
} from 'src/shared/components/UI';
import LocalDataSelect from 'src/shared/components/UI/LocalDataSelect';
import {ApplicationContext} from 'src/context';
import {
	getSubComponentList,
	mergeComponent,
	removeComponent,
} from 'src/helpers/methods/app-methods';
import IconRender from './components/IconRender';

type Props = {
	onClose: () => void;
	selectedWidget: IComponent | undefined;
};

const TimelineSpecify = ({onClose, selectedWidget}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);

	const [timeline, setTimeline] = useState<TimelineProps>();
	const [statusIcon, setStatusIcon] = useState<TimelineIconType>();
	const [listSubComponents, setListSubComponents] = useState<IOption[]>([]);

	useEffect(() => {
		setListSubComponents(getSubComponentList(application));
	}, [application]);

	useEffect(() => {
		if (selectedWidget) {
			setTimeline({...selectedWidget?.properties});
		} else {
			clear();
		}
	}, [selectedWidget]);

	const save = () => {
		const data: any[] = [];

		if (timeline?.useHeader) {
			data.push({
				[timeline?.subProp ?? '']: [],
			});
		}

		timeline?.dataIcon?.forEach(item => {
			if (timeline?.useHeader) {
				data[0][timeline?.subProp ?? ''].push({
					[timeline?.propStatus ?? '']: item.status,
				});
			} else {
				data.push({
					[timeline?.propStatus ?? '']: item.status,
				});
			}
		});

		if (timeline?.useHeader) {
			data.push({
				[timeline?.subProp ?? '']: [],
			});
		}

		timeline?.dataIcon?.forEach(item => {
			if (timeline?.useHeader) {
				data[1][timeline?.subProp ?? ''].push({
					[timeline?.propStatus ?? '']: item.status,
				});
			} else {
				data.push({
					[timeline?.propStatus ?? '']: item.status,
				});
			}
		});

		const props = {
			...timeline,
			data: data,
			orientation: 'Horizontal',
			leftContent:
				application?.resources
					?.find(x => x.selected)
					?.subComponents?.find(x => x.ref === timeline?.subComponentRef)?.widgets ?? [],
		};

		const newApplication = mergeComponent(
			application,
			selectedWidget,
			ComponentComplexEnum.Timeline,
			props,
		);
		setApplication(newApplication);
		close();
	};

	const remove = (ref: string) => {
		setApplication(removeComponent(application, ref));
		close();
	};

	const close = () => {
		onClose();
		clear();
	};

	const clear = () => {
		setTimeline(undefined);
	};

	const validAddStatus = () =>
		statusIcon &&
		statusIcon.status != '' &&
		statusIcon.icon &&
		!timeline?.dataIcon
			?.map(x => x.status?.toLocaleLowerCase())
			?.includes(statusIcon.status?.toLocaleLowerCase());

	return (
		<div>
			<Wrapper margin="0 0 10px 0">
				<LocalDataSelect
					localData={{
						data: timeline?.dataRef as any,
						prop: timeline?.propRef,
					}}
					setLocalData={val =>
						setTimeline({...timeline, dataRef: val?.data, propRef: val?.prop})
					}
					acceptTypes={[ApiParamTypeEnum.ARRAY]}
				/>
			</Wrapper>

			<Wrapper margin="12px 0">
				<WidthBox width="30%">
					<Switch
						isOn={timeline?.useHeader ?? false}
						handleToggle={() =>
							setTimeline({...timeline, useHeader: !timeline?.useHeader ?? false})
						}
						text="Utiliza cabeçalho"
						textColor={'#000'}
					/>
				</WidthBox>
				{timeline?.useHeader ? (
					<>
						<WidthBox width="40%">
							<Select
								value={listSubComponents.find(
									x => x.value === timeline?.subComponentRef,
								)}
								options={listSubComponents}
								onChange={val =>
									setTimeline({...timeline, subComponentRef: val.value})
								}
								background="transparent"
								border="1px solid #333"
								textColor={'#000'}
							/>
						</WidthBox>
						<WidthBox width="30%">
							<Input
								label="Sub propriedade"
								inputType="light"
								value={timeline?.subProp}
								onChange={val => setTimeline({...timeline, subProp: val})}
								border="1px solid #444"
								background="transparent"
								textColor={'#000'}
							/>
						</WidthBox>
					</>
				) : (
					<></>
				)}
			</Wrapper>

			<Wrapper>
				<WidthBox width="25%" margin="0 0 12px 0">
					<Input
						label="Tamanho"
						inputType="light"
						value={timeline?.iconWidth}
						onChange={val => setTimeline({...timeline, iconWidth: val})}
						border="1px solid #444"
						background="transparent"
						textColor={'#000'}
						mask="Numero"
					/>
				</WidthBox>
				<WidthBox width="30%" margin="0 0 12px 0">
					<Input
						label="Propriedade com o status"
						inputType="light"
						value={timeline?.propStatus}
						onChange={val => setTimeline({...timeline, propStatus: val})}
						border="1px solid #444"
						background="transparent"
						textColor={'#000'}
						mask="Sem espaço"
					/>
				</WidthBox>
			</Wrapper>

			<Wrapper>
				<Wrapper width="50%" flexDirection="column" alignItems="start" gap="0">
					<IconRender
						value={timeline?.firstIcon}
						setValue={val => setTimeline({...timeline, firstIcon: val})}
						text="Primeiro ícone"
					/>
				</Wrapper>
				<Wrapper width="50%" flexDirection="column" alignItems="start" gap="0">
					<IconRender
						value={timeline?.lastIcon}
						setValue={val => setTimeline({...timeline, lastIcon: val})}
						text="Último ícone"
					/>
				</Wrapper>
			</Wrapper>

			<IconRender
				value={statusIcon}
				setValue={setStatusIcon}
				text="Adicionar status"
				onClick={
					validAddStatus()
						? () => {
								const data = [...(timeline?.dataIcon ?? [])];
								data.push({
									color: statusIcon?.color,
									icon: statusIcon?.icon,
									status: statusIcon?.status,
								});
								setTimeline({...timeline, dataIcon: data});
								setStatusIcon(undefined);
						  }
						: undefined
				}
				hasAddBtn={true}
				useStatus={true}
			/>

			<Wrapper margin="0 15px">
				<WidthBox width="30%">
					<Text text="Status" color={'#000'} fontWeight="bold" />
				</WidthBox>
				<WidthBox width="30%">
					<Text text="Icone" color={'#000'} fontWeight="bold" />
				</WidthBox>
				<WidthBox width="30%">
					<Text text="Cor" color={'#000'} fontWeight="bold" />
				</WidthBox>
				<WidthBox width="10%">
					<></>
				</WidthBox>
			</Wrapper>
			{timeline?.dataIcon?.map(x => {
				return (
					<>
						<Wrapper margin="0 15px">
							<WidthBox width="30%">
								<Text text={x.status} color={'#000'} />
							</WidthBox>
							<WidthBox width="30%">
								<FontAwesomeIcon icon={x.icon?.icon ?? faAdd} />
							</WidthBox>
							<WidthBox width="30%">
								<Text text={x.color} color={'#000'} />
							</WidthBox>
							<WidthBox width="10%">
								<div
									onClick={() =>
										setTimeline({
											...timeline,
											dataIcon: [...(timeline?.dataIcon ?? [])].filter(
												i => i.status != x.status,
											),
										})
									}>
									<FontAwesomeIcon icon={faTrash} color="#FF584C" />
								</div>
							</WidthBox>
						</Wrapper>
					</>
				);
			})}

			<Wrapper margin="20px 0 0 0" justifyContent="end">
				<Button
					text="Fechar"
					fill="auto"
					themeStyle="primary"
					type="ghost"
					onClick={close}
				/>
				{selectedWidget ? (
					<Button
						text="Remover"
						fill="auto"
						leftIcon={faTrash}
						themeStyle="primary"
						type="ghost"
						onClick={() => remove(selectedWidget.ref as string)}
					/>
				) : (
					<></>
				)}
				<Button
					onClick={save}
					text={selectedWidget ? 'Alterar' : 'Criar'}
					fill="auto"
					themeStyle="primary"
				/>
			</Wrapper>
		</div>
	);
};

export default TimelineSpecify;
