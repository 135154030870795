import {IApplication, IResourceApi} from 'src/@types/app';
import {
	createMainComponent,
	createMenuBarComponent,
	createTopBarComponent,
} from './createComponent';
import {createInterfaces} from './createInterface';
import {createMainStyle, createMenuBarStyle, createTopBarStyle} from './createStyle';

export const generateNiftyLayout = (application: IApplication): IResourceApi[] => {
	const resources: IResourceApi[] = [];
	const layoutProps = application.layout?.templateConfig['Layout'];

	resources.push({
		code: createMainComponent(application),
		name: 'index.tsx',
		path: 'src/template/',
	});
	resources.push({
		code: createMainStyle(
			layoutProps?.backTop ?? '#1bb355',
			layoutProps?.backBottom ?? '#edf1f6',
		),
		name: 'styles.ts',
		path: 'src/template/',
	});

	resources.push({
		code: createTopBarComponent(application?.layout?.logo ?? ''),
		name: 'index.tsx',
		path: 'src/template/components/TopBar/',
	});
	resources.push({
		code: createTopBarStyle(layoutProps?.backTop ?? '#1bb355'),
		name: 'styles.ts',
		path: 'src/template/components/TopBar/',
	});

	resources.push({
		code: createMenuBarComponent(),
		name: 'index.tsx',
		path: 'src/template/components/MenuBar/',
	});
	resources.push({
		code: createMenuBarStyle(),
		name: 'styles.ts',
		path: 'src/template/components/MenuBar/',
	});

	resources.push({
		code: createInterfaces(),
		name: 'models.ts',
		path: 'src/template/',
	});

	return resources;
};
