/* eslint-disable @typescript-eslint/no-explicit-any */
import {faClose} from '@fortawesome/free-solid-svg-icons';
import React, {useContext, useEffect, useState} from 'react';
import {Modal} from 'src/shared/components/Complex';
import {
	Button,
	IconButton,
	WidthBox,
	Wrapper,
	ModalTitle,
	Text,
	Line,
	Switch,
	SizedBox,
} from 'src/shared/components/UI';
import {ApplicationContext} from 'src/context';
import {getSelectedWidget, getSubcomponentSelected} from 'src/helpers/methods/app-methods';
import {applicationTheme} from 'src/shared/theme';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import {IOption} from 'src/@types/app';
import {SystemInput} from 'src/shared/components/System/SystemInput';

type Props = {
	isOpen: boolean;
	onClose: () => void;
};

enum OptionEnum {
	option = 1,
	finder = 2,
}

const selecteData: IOption[] = [
	{value: undefined, label: 'Selecione...'},
	{value: OptionEnum.option, label: 'Valores estáticos'},
	{value: OptionEnum.finder, label: 'Valores dinâmicos'},
];

const AddOption = ({isOpen, onClose}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);

	const [optItem, setOptItem] = useState<IOption>();
	const [validItem, setValidItem] = useState(false);
	const [optionType, setOptionType] = useState<OptionEnum>();
	const [tableRef, setTableRef] = useState();
	const [idRef, setIdRef] = useState();
	const [valueRef, setValueRef] = useState();
	const [useParentDependency, setUseParentDependency] = useState(false);
	const [aditionalLabels, setAditionalLabels] = useState<any[]>([]);
	const [customProperties, setCustomProperties] = useState<any[]>([]);

	const [dataOptions, setDataOptions] = useState<IOption[]>([]);
	const [tableOptions, setTableOptions] = useState<IOption[]>([]);
	const [columnOptions, setColumnOptions] = useState<IOption[]>([]);
	const [dependencyList, setDependencyList] = useState<IOption[]>([]);

	useEffect(() => {
		const selectedSubComponent = getSubcomponentSelected(application);
		const selectWidget = getSelectedWidget(application, selectedSubComponent);
		const propperties = selectWidget?.properties;

		setTableOptions([
			{value: undefined, label: 'Selecione...'},
			...(application.database?.tables?.map(table => {
				return {
					value: table.ref,
					label: table.name ?? '',
				};
			}) ?? []),
		]);

		if (propperties?.optionType) setOptionType(propperties?.optionType);
		if (propperties?.options && propperties?.options?.length > 0)
			setDataOptions(propperties.options);
		if (propperties?.dependencyList) setDependencyList(propperties?.dependencyList ?? []);

		const finderData = propperties?.finderData;
		if (finderData?.tableRef) setTableRef(finderData?.tableRef);
		if (finderData?.propData) {
			setIdRef(finderData?.propData?.id);
			setValueRef(finderData?.propData?.value);
			setUseParentDependency(finderData?.propData?.useParentDependency);
			setAditionalLabels(finderData?.propData?.aditionalLabels ?? []);
			setCustomProperties(finderData?.propData?.customProperties ?? []);
		}
	}, [application]);

	useEffect(() => {
		if (
			optItem &&
			optItem.label != '' &&
			optItem.value != '' &&
			!dataOptions
				.map(x => x.value?.toLocaleLowerCase())
				.includes(optItem.value?.toLocaleLowerCase())
		)
			setValidItem(true);
		else setValidItem(false);
	}, [optItem]);

	useEffect(() => {
		const column = application.database?.tables?.find(x => x.ref === tableRef)?.columns;
		setColumnOptions([
			{value: undefined, label: 'Selecione...'},
			...(column?.map(x => {
				return {
					value: x.ref,
					label: x.name,
				} as any;
			}) ?? []),
		]);

		if (idRef) setIdRef(idRef);
		if (valueRef) setValueRef(valueRef);
	}, [tableRef]);

	const close = () => {
		setTimeout(() => {
			setOptItem(undefined);
			setValidItem(false);
			setOptionType(undefined);
			setTableRef(undefined);
			setIdRef(undefined);
			setValueRef(undefined);
			setAditionalLabels([]);
			setCustomProperties([]);
		}, 200);

		onClose();
	};

	const save = () => {
		const newApplication = {
			...application,
			resources: [...(application.resources ?? [])].map(resource => {
				if (resource.selected) {
					resource.widgets = resource.widgets?.map(widget => {
						if (widget.selected) {
							widget.properties.options = dataOptions;
							widget.properties.optionType = optionType;
							widget.properties.dependencyList = dependencyList;

							if (!widget.properties.finderData) widget.properties.finderData = {};
							widget.properties.finderData.tableRef = tableRef;

							if (!widget.properties.finderData.propData)
								widget.properties.finderData.propData = {};
							widget.properties.finderData.propData.id = idRef;
							widget.properties.finderData.propData.value = valueRef;
							widget.properties.finderData.propData.aditionalLabels = aditionalLabels;
							widget.properties.finderData.propData.customProperties =
								customProperties;
							widget.properties.finderData.propData.useParentDependency =
								useParentDependency;
						}
						return widget;
					});
				}
				return resource;
			}),
		};

		setApplication(newApplication);
		setDataOptions([]);
		close();
	};

	const render = (item: IOption, index: number) => {
		return (
			<Wrapper key={index} width="100%">
				<WidthBox width="45%">
					<Text text={item.value} />
				</WidthBox>
				<WidthBox width="45%">
					<Text text={item.label} />
				</WidthBox>
				<IconButton
					icon={faClose}
					color="red"
					onClick={() => {
						const data = [...dataOptions].filter(x => x.value !== item.value);
						setDataOptions(data);
					}}
				/>
			</Wrapper>
		);
	};

	const addItem = () => {
		const data = [...dataOptions];
		data.push({
			value: optItem?.value,
			label: optItem?.label ?? '',
		});

		setDataOptions(data);
		setOptItem(undefined);
	};

	const renderDependency = (dependencyItem: IOption, index: number) => {
		return (
			<Wrapper key={index} margin="10px 0 0 0">
				<WidthBox width="30%">
					<Text text={`Item ${index + 1}`} />
				</WidthBox>
				<WidthBox width="65%">
					<SystemSelect
						value={dependencyItem}
						options={columnOptions as any}
						onChange={val =>
							setDependencyList(
								[...dependencyList].map((x, idx) => {
									if (index === idx) return val;
									return x;
								}),
							)
						}
						disabled={!columnOptions || columnOptions?.length == 0}
					/>
				</WidthBox>
				<IconButton
					onClick={() =>
						setDependencyList([...dependencyList].filter((_, idx) => index != idx))
					}
					icon={faClose}
					color="red"
				/>
			</Wrapper>
		);
	};

	return (
		<Modal isOpen={isOpen} onClose={close} maxWidth="700px">
			<>
				<ModalTitle title="Realizar customizações" />
				<Wrapper margin="0 0 10px 0">
					<SystemSelect
						value={selecteData.find(x => x.value === optionType)}
						options={selecteData as any}
						onChange={val => setOptionType(val.value)}
						label="Tipo"
					/>
				</Wrapper>
				{optionType === OptionEnum.option ? (
					<>
						<Wrapper margin="0 0 16px 0">
							<WidthBox width="45%">
								<SystemInput
									value={optItem?.value}
									onChange={val => setOptItem({...optItem, value: val} as any)}
									onPressEnter={addItem}
									label="Valor interno"
								/>
							</WidthBox>
							<WidthBox width="45%">
								<SystemInput
									value={optItem?.label}
									onChange={val => setOptItem({...optItem, label: val} as any)}
									onPressEnter={addItem}
									label="Valor exibido"
								/>
							</WidthBox>
							<WidthBox width="auto" margin="21px 0 0 0">
								<Button
									icon2="plus"
									onClick={addItem}
									fill="auto"
									textColor={applicationTheme.brand[600]}
									background={applicationTheme.brand[100]}
									disabled={!validItem}
									height="41px"
								/>
							</WidthBox>
						</Wrapper>

						{dataOptions?.map((item, index) => render(item, index))}
					</>
				) : (
					<></>
				)}
				{optionType === OptionEnum.finder ? (
					<>
						<Wrapper margin="0 0 10px 0">
							<SystemSelect
								value={tableOptions.find(x => x.value === tableRef)}
								options={tableOptions as any}
								onChange={val => setTableRef(val.value)}
								label="Tabela"
							/>
						</Wrapper>
						<Wrapper margin="0 0 10px 0">
							<WidthBox width="30%" margin="0 0 0 20px">
								<Text text="Valor interno" />
							</WidthBox>
							<WidthBox width="70%">
								<SystemSelect
									value={columnOptions.find(x => x.value === idRef)}
									options={columnOptions as any}
									onChange={val => setIdRef(val.value)}
									disabled={!columnOptions || columnOptions?.length == 0}
								/>
							</WidthBox>
						</Wrapper>
						<Wrapper>
							<WidthBox width="30%" margin="0 0 0 20px">
								<Text text="Valor exibido" />
							</WidthBox>
							<WidthBox width="70%">
								<SystemSelect
									value={columnOptions.find(x => x.value === valueRef)}
									options={columnOptions as any}
									onChange={val => setValueRef(val.value)}
									disabled={!columnOptions || columnOptions?.length == 0}
								/>
							</WidthBox>
						</Wrapper>
						{aditionalLabels.map((aditionalLabel: any, index) => (
							<Wrapper justifyContent="end" key={index} margin="7px 0 0 0">
								<WidthBox width="30%" margin="0 0 0 20px">
									<></>
								</WidthBox>
								<WidthBox width="62%">
									<SystemSelect
										value={columnOptions.find(
											x => x.value === aditionalLabel.value,
										)}
										options={columnOptions as any}
										onChange={val => {
											setAditionalLabels(
												[...(aditionalLabels ?? [])].map(mItem => {
													if (mItem.ref == aditionalLabel.ref)
														mItem.value = val.value;
													return mItem;
												}),
											);
										}}
										disabled={!columnOptions || columnOptions?.length == 0}
									/>
								</WidthBox>
								<WidthBox width="5%">
									<IconButton
										icon={faClose}
										color="red"
										onClick={() => {
											setAditionalLabels(
												[...(aditionalLabels ?? [])].filter(
													mItem => mItem.ref != aditionalLabel.ref,
												),
											);
										}}
									/>
								</WidthBox>
							</Wrapper>
						))}
						<Wrapper justifyContent="end" margin="10px 0 0 0">
							<Button
								text="Adicionar campo"
								leftIcon2="plus-square"
								onClick={() => {
									const mValue = [...aditionalLabels];
									mValue.push({ref: crypto.randomUUID()});
									setAditionalLabels(mValue);
								}}
								background={applicationTheme.brand[50]}
								textColor={applicationTheme.brand[600]}
								type="ghost"
								height="41px"
								borderRadius="3px"
								fill="auto"
							/>
						</Wrapper>
					</>
				) : (
					<></>
				)}

				<WidthBox margin="15px 0">
					<Line height="0.5px" background="#555" />
				</WidthBox>

				{customProperties.map((customProperty: any, index) => (
					<Wrapper justifyContent="end" key={index} margin="7px 0 0 0">
						<WidthBox width="95%">
							<SystemSelect
								value={columnOptions.find(x => x.value === customProperty.value)}
								options={columnOptions as any}
								onChange={val => {
									setCustomProperties(
										[...(customProperties ?? [])].map(mItem => {
											if (mItem.ref == customProperty.ref)
												mItem.value = val.value;
											return mItem;
										}),
									);
								}}
								disabled={!columnOptions || columnOptions?.length == 0}
							/>
						</WidthBox>
						<WidthBox width="5%">
							<IconButton
								icon={faClose}
								color="red"
								onClick={() => {
									setCustomProperties(
										[...(customProperties ?? [])].filter(
											mItem => mItem.ref != customProperty.ref,
										),
									);
								}}
							/>
						</WidthBox>
					</Wrapper>
				))}

				<Wrapper justifyContent="center" margin="10px 0 0 0">
					<Button
						text="Adicionar propriedades customizadas"
						leftIcon2="plus-square"
						onClick={() => {
							const mValue = [...customProperties];
							mValue.push({ref: crypto.randomUUID()});
							setCustomProperties(mValue);
						}}
						background={applicationTheme.brand[50]}
						textColor={applicationTheme.brand[600]}
						height="41px"
						borderRadius="3px"
						fill="auto"
					/>
				</Wrapper>

				<WidthBox margin="15px 0">
					<Line height="0.5px" background="#555" />
				</WidthBox>

				<Wrapper justifyContent="center">
					<Button
						text="Adicionar dependência"
						leftIcon2="plus-square"
						onClick={() => {
							const data = [...dependencyList];
							data.push({
								value: undefined,
								label: 'Selecione...',
							});
							setDependencyList(data);
						}}
						background={applicationTheme.brand[50]}
						textColor={applicationTheme.brand[600]}
						height="41px"
						borderRadius="3px"
						fill="auto"
					/>
				</Wrapper>

				{dependencyList.map((lItem, index) => renderDependency(lItem, index))}

				{dependencyList && dependencyList.length > 0 ? (
					<>
						<SizedBox height="15px" />
						<Switch
							isOn={useParentDependency}
							handleToggle={() => setUseParentDependency(!useParentDependency)}
							text="Usar dependência para relacionamento?"
						/>
					</>
				) : (
					<></>
				)}

				<Wrapper margin="28px 0 0 0" justifyContent="end">
					<Button
						text="Fechar"
						onClick={close}
						fill="auto"
						background={applicationTheme.gray[300]}
						textColor={applicationTheme.gray[700]}
						type="ghost"
					/>
					<Button
						text="Salvar"
						onClick={save}
						fill="auto"
						background={applicationTheme.brand[600]}
					/>
				</Wrapper>
			</>
		</Modal>
	);
};

export default AddOption;
