/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import AccordionItem from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import FeatherIcon from 'feather-icons-react';

type Props = {
	title: string;
	body: any;
};

export const Accordion = ({title, body}: Props) => {
	return (
		<AccordionItem>
			<AccordionSummary
				aria-controls="panel1-content"
				id="panel1-header"
				expandIcon={<FeatherIcon icon="chevron-down" />}>
				{title}
			</AccordionSummary>
			<AccordionDetails>{body}</AccordionDetails>
		</AccordionItem>
	);
};
