import {IOption} from 'src/@types/app';

export const arithmeticList: IOption[] = [
	{
		value: '+',
		label: '+',
	},
	{
		value: '-',
		label: '-',
	},
	{
		value: '*',
		label: '*',
	},
	{
		value: '/',
		label: '/',
	},
	{
		value: '%',
		label: '%',
	},
];
