import styled from 'styled-components';
import {applicationTheme} from 'src/shared/theme';

export const Container = styled.div`
	width: calc(30%);
	background: #fff;
	z-index: 100;
	max-height: calc(100vh - 72px);
	max-width: 360px;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	transition: 0.2s;
	overflow-x: hidden;
	border-right: 1px solid ${applicationTheme.gray[300]};
`;

export const ContentBody = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: start;
	background: #fff;
`;

export const Collapse = styled.div`
	margin-left: 15px;
	margin-right: 15px;
`;

export const CollapseHeader = styled.div`
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;

	.cont {
		display: flex;
		align-items: center;
	}

	.flag {
		width: 4px;
		height: 40px;
		background: ${applicationTheme.brand[600]};
		border-radius: 3px;
	}

	.icon {
		margin-left: 10px;
		color: ${applicationTheme.gray[500]};
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.text {
		font-family: 'Inter';
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		text-align: left;
		color: ${applicationTheme.gray[800]};
		margin-left: 20px;
	}

	.leftIcon {
		color: ${applicationTheme.gray[500]};
		display: flex;
		align-items: center;
		justify-content: center;
	}

	transition: 0.3s;
	&:hover {
		background: ${applicationTheme.gray[100]};
		border-radius: 4px;
		transition: 0.3s;
	}
`;

export const CollapseBody = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 7px;
	margin: 12px 8px;
`;

export const CollapseItem = styled.div`
	width: 71px;
	height: 62px;
	padding: 16px 0px 0px 0px;
	gap: 4px;
	border-radius: 12px;
	box-shadow: 0px 4px 8px -2px #ccc;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	cursor: pointer;
	border: 1px solid #fff;

	.icon {
		color: ${applicationTheme.gray[500]};
	}
	.text {
		font-family: 'Inter';
		font-size: 10px;
		font-weight: 600;
		line-height: 18px;
		text-align: center;
		color: ${applicationTheme.gray[800]};
	}

	transition: 0.3s;
	&:hover {
		box-shadow: none;
		border: 1px solid ${applicationTheme.gray[200]};
		transition: 0.3s;
	}
`;

export const ActionItem = styled.div`
	width: 80px;
	height: 80px;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	color: ${applicationTheme.gray[900]};
	box-shadow: 0px 4px 8px -2px #ccc;
	border: 1px solid #fff;
	gap: 7px;
	font-size: 14px;

	transition: 0.3s;
	&:hover {
		box-shadow: none;
		border: 1px solid ${applicationTheme.gray[200]};
		transition: 0.3s;
		cursor: pointer;
	}
	&:active {
		border: 1px solid ${applicationTheme.gray[500]};
	}
`;
