import {IComponent} from 'src/@types/app';
import {lowerFirstWord, upperFirstWord} from 'src/helpers/methods/text-methods';
import {STATE_OBJECTS} from '../engine-enum';
import {ComponentComplexEnum} from 'src/@types/enums';

export const addState = (
	widget: IComponent,
	documentsState: string[],
	documentType: string[],
	documentErrorType: string[],
) => {
	const objectItem = STATE_OBJECTS.find(x => x.name === widget.name);
	if (objectItem) {
		const objName = widget.properties['name'];
		const documentTypeInclude = `${objName}?: ${objectItem.type};`;
		const documentErrorTypeInclude = `${objName}?: string;`;

		if (!documentType.includes(documentTypeInclude)) documentType.push(documentTypeInclude);
		if (!documentErrorType.includes(documentErrorTypeInclude))
			documentErrorType.push(documentErrorTypeInclude);

		if (objectItem.name === ComponentComplexEnum.DataGrid) {
			documentsState.push(
				`const [${lowerFirstWord(objName)}Edit, set${upperFirstWord(
					objName,
				)}Edit] = useState<any>();`,
			);
		}
	}
};
