import React from 'react';
import * as S from './styles';

type Props = {
	height?: string;
	width?: string;
};

const SizedBox = ({height, width}: Props) => {
	return <S.Size height={height ?? '0px'} width={width ?? '0px'}></S.Size>;
};

export default SizedBox;

export const sizedBoxProperties = {
	height: 'string',
};
