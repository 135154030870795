import styled from 'styled-components';

export const BuilderContent = styled.div`
	padding: 10px 15px;
	background: #ddd;
	border-radius: 5px;
`;

type ColorButtonProps = {
	background?: string;
};
export const ColorButton = styled.div<ColorButtonProps>`
	width: 100%;
	height: 34px;
	background: ${props =>
		props.background && props.background != '' ? props.background : 'black'};
	border: 0.5px solid white;
	cursor: pointer;
	position: relative;
	border-radius: 4px;
`;

export const PickerContent = styled.div`
	position: absolute;
	z-index: 1000;
`;

export const Line = styled.div`
	height: 1px;
	width: 100%;
	background: #fff;
`;
