import {IOption} from 'src/@types/app';
import {CacheEnum} from 'src/@types/enums';

export const CacheList: IOption[] = [
	{
		value: undefined,
		label: 'Selecione...',
	},
	{
		value: CacheEnum.Memory,
		label: 'Memory',
	},
	{
		value: CacheEnum.Redis,
		label: 'Redis',
	},
];
