import prettier from 'prettier/standalone';
import {PrettierConfig} from 'src/shared/engine/common/prettier-config';

export const createMainStyle = (topColor: string, bottomColor: string) => {
	const code =
		"import styled, {css} from 'styled-components';\n" +
		'' +
		'export const Main = styled.div`\n' +
		'	position: relative;\n' +
		'	height: calc(100% - 180px);\n' +
		'`;' +
		'\n' +
		'export const Container = styled.div`\n' +
		'	margin: 0;\n' +
		'	padding: 0;\n' +
		'	height: calc(100% + 130px);\n' +
		'' +
		'	position: relative;\n' +
		'`;' +
		'\n' +
		'export const BackGround = styled.div`\n' +
		'	position: absolute;\n' +
		'	width: 100%;\n' +
		'	height: 100vh;\n' +
		'`;' +
		'\n' +
		'export const BackGroundTop = styled.div`\n' +
		'	width: 100%;\n' +
		'	height: 180px;\n' +
		` background: ${topColor};\n` +
		'`;' +
		'\n' +
		'export const BackGroundBottom = styled.div`\n' +
		'	width: 100%;\n' +
		'	height: calc(100% - 180px);\n' +
		`	background: ${bottomColor};\n` +
		'`;' +
		'\n' +
		'export const Body = styled.div`\n' +
		'	display: flex;\n' +
		'	height: 100%;\n' +
		'`;' +
		'\n' +
		'type FormAreaProps = {\n' +
		'	isOpen: boolean;\n' +
		'};' +
		'export const FormArea = styled.div<FormAreaProps>`\n' +
		'	${({isOpen}) => css`\n' +
		'		display: flex;\n' +
		'		flex-direction: column;\n' +
		'		margin: 18px 2px 10px 18px;\n' +
		"		width: ${isOpen ? 'calc(100% - 200px)' : 'calc(100% - 50px)'};\n" +
		'		transition: 0.1s;\n' +
		'		padding: 0 5px;\n' +
		'		box-sizing: border-box;\n' +
		'	`}\n' +
		'`;' +
		'\n' +
		'export const ContentTop = styled.div`\n' +
		'	height: 120px;\n' +
		'`;' +
		'\n' +
		'export const Title = styled.div`\n' +
		"	font-family: 'Ubunto';\n" +
		'	color: white;\n' +
		'	font-size: 32px;\n' +
		'	font-weight: 400;\n' +
		'`;';

	return prettier.format(code, PrettierConfig);
};

export const createTopBarStyle = (topColor: string) => {
	const code =
		"import styled, {css} from 'styled-components';" +
		"import {shade} from 'polished';" +
		'' +
		'export const Container = styled.div`' +
		'	width: 100%;' +
		'	height: 50px;' +
		'	display: flex;' +
		'	color: white;' +
		'	align-items: center;' +
		'`;' +
		'' +
		'type LogoProps = {' +
		'	isOpen: boolean;' +
		'};' +
		'export const Logo = styled.div<LogoProps>`' +
		'	${({isOpen}) => css`' +
		"		width: ${isOpen ? '200px' : '70px'};" +
		'		transition: 0.1s;' +
		'	`}' +
		'`;' +
		'' +
		'type MenuActionProps = {' +
		'	isOpen: boolean;' +
		'};' +
		'export const MenuAction = styled.div<MenuActionProps>`' +
		'	${({isOpen}) => css`' +
		"		width: ${isOpen ? 'calc(85% - 200px)' : 'calc(85% - 70px)'};" +
		'		transition: 0.1s;' +
		'	`}' +
		'`;' +
		'' +
		'export const IconsContent = styled.div`' +
		'	width: calc(15%);' +
		'	display: flex;' +
		'	align-items: center;' +
		'	justify-content: space-around;' +
		'`;' +
		'' +
		'export const MenuActionBtn = styled.div`' +
		'	border-radius: 10px;' +
		'	width: 30px;' +
		'	height: 30px;' +
		'	display: flex;' +
		'	align-items: center;' +
		'	justify-content: center;' +
		'' +
		'	&:hover {' +
		`		background-color: \${shade(0.3, '${topColor}')};` +
		'		cursor: pointer;' +
		'	}' +
		'	&:active {' +
		`		background-color: \${shade(0.5, '${topColor}')};` +
		'	}' +
		'`;';

	return prettier.format(code, PrettierConfig);
};

export const createMenuBarStyle = () => {
	const code =
		"import styled, {css} from 'styled-components';" +
		'' +
		'type ContainerProps = {' +
		'	isOpen: boolean;' +
		'};' +
		'export const Container = styled.div<ContainerProps>`' +
		'	${({isOpen}) => css`' +
		"		width: ${isOpen ? '250px' : '50px'};" +
		'		background-color: white;' +
		'		min-height: calc(100vh - 50px);' +
		'		border-radius: 0 17px 0 0;' +
		'		transition: 0.1s;' +
		'		box-sizing: border-box;' +
		'		display: flex;' +
		'		flex-direction: column;' +
		'		justify-content: space-between;' +
		'		box-shadow: 2px 0px 6px #bbb;' +
		'	`}' +
		'`;' +
		'' +
		'export const ContainerMenu = styled.div`' +
		'	padding: 20px 12px;' +
		'`;' +
		'export const ContainerMenuLogout = styled.div`' +
		'	padding: 6px 12px;' +
		'	border-top: 1px solid #e1e2e3;' +
		'`;' +
		'' +
		'export const ItemContent = styled.div`' +
		'	width: 100%;' +
		'	display: flex;' +
		'	padding: 10px 6px;' +
		'	box-sizing: border-box;' +
		'	cursor: pointer;' +
		'`;' +
		'' +
		'export const ItemContentLogout = styled.div`' +
		'	width: 100%;' +
		'	display: flex;' +
		'	padding: 10px 6px;' +
		'	box-sizing: border-box;' +
		'	cursor: pointer;' +
		'`;' +
		'' +
		'type IconProps = {' +
		'	isOpen: boolean;' +
		'};' +
		'export const Icon = styled.div<IconProps>`' +
		'	${({isOpen}) => css`' +
		"		width: ${isOpen ? '18%' : '100%'};" +
		'		color: #75868f;' +
		'		display: flex;' +
		'		align-items: center;' +
		"		justify-content: ${isOpen ? 'start' : 'center'};" +
		'		transition: 0.1s;' +
		'	`}' +
		'`;' +
		'' +
		'export const Item = styled.div`' +
		'	width: 67%;' +
		"	font-family: 'Ubunto';" +
		'	font-size: 13px;' +
		'	color: #75868f;' +
		'	display: flex;' +
		'	align-items: center;' +
		'`;' +
		'' +
		'export const Arrow = styled.div`' +
		'	width: 15%;' +
		'	color: #75868f;' +
		'	display: flex;' +
		'	align-items: center;' +
		'	justify-content: center;' +
		'	font-size: 13px;' +
		'`;' +
		'' +
		'export const SubItemContent = styled.div`' +
		'	display: flex;' +
		'	align-items: center;' +
		'	cursor: pointer;' +
		'`;' +
		'' +
		'export const SubItem = styled.div`' +
		'	width: calc(85% - 19px);' +
		'	margin-left: 9%;' +
		'	border-left: 1px dashed #75868f;' +
		'	color: #75868f;' +
		'	box-sizing: border-box;' +
		'	padding: 5px 0 5px 19px;' +
		'	display: flex;' +
		'	font-size: 13px;' +
		'`;';

	return prettier.format(code, PrettierConfig);
};
