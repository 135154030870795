/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {IDatabase, IColumn, ITable, IDataFile, IDataFileColumn} from 'src/@types/app';
import {v4 as uuidV4} from 'uuid';
import {mapTypeDatabase} from './map-type-database';

export const convertDataFileToDatabase = (data: IDataFile[], databaseRef: string): IDatabase => {
	const databaseTables: ITable[] = [];
	const foreignKeysMap = new Map();

	data.forEach(item => {
		const itemRef = uuidV4().toString();
		databaseTables.push({
			ref: itemRef,
			schema: item.Schema.SchemaName,
			name: item.TableName,
			description: item.TableDescription,
			columns: parseColumns(item.Columns ?? []),
			selected: true,
			databaseRef: databaseRef,
		});
		item.ForeignKeys?.forEach(key =>
			foreignKeysMap.set(key.TableName + key.Column.ColumnName, itemRef),
		);
	});

	databaseTables.forEach(table => {
		table.columns?.forEach(
			column => (column.constraint = foreignKeysMap.get(table.name! + column.name!)),
		);
	});

	return {
		tables: databaseTables,
	};
};

const parseColumns = (fileColumns: IDataFileColumn[]): IColumn[] => {
	const columns: IColumn[] = [];

	fileColumns.forEach(item => {
		columns.push({
			ref: uuidV4().toString(),
			isPrimaryKey: item.IsPrimaryKey,
			name: item.ColumnName,
			suggestName: item.Translate?.Portuguese,
			description: item.ColumnDescription,
			nullable: item.IsNullable,
			type: mapTypeDatabase(item.DataType as any),
			maxLength: item.MaxLength,
			precision: item.Precision,
			autoIncremente: item.IdIdentity,
		});
	});

	return columns;
};
