/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {ICommunicationApiCommunicationResult} from 'src/@types/api-result';
import {ICommunication} from 'src/@types/app';
import {Api} from './base';

export class CommunicationApi {
	async getAll(): Promise<ICommunication | null> {
		const result = await Api.get('/communication');

		if (result.status == 200) {
			const resultData: ICommunicationApiCommunicationResult = result.data;
			const communications: ICommunication = {};
			communications.news = [];
			communications.articles = [];
			communications.videos = [];

			resultData?.news?.forEach(item => {
				communications?.news?.push({
					publicationDate: item.publicationDate,
					classification: item.classification,
					icon: item.icon,
					title: item.title,
					description: item.description,
					url: item.url,
					iconColor: item.iconColor,
				});
			});

			resultData?.articles?.forEach(item => {
				communications?.articles?.push({
					publicationDate: item.publicationDate,
					classification: item.classification,
					icon: item.icon,
					title: item.title,
					description: item.description,
					url: item.url,
					iconColor: item.iconColor,
				});
			});

			resultData?.videos?.forEach(item => {
				communications?.videos?.push({
					publicationDate: item.publicationDate,
					classification: item.classification,
					icon: item.icon,
					title: item.title,
					description: item.description,
					url: item.url,
					iconColor: item.iconColor,
				});
			});

			return communications;
		}

		return null;
	}
}
