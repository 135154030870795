/* eslint-disable @typescript-eslint/no-explicit-any */
import {faAdd, faRemove} from '@fortawesome/free-solid-svg-icons';
import React, {useState} from 'react';
import {PhotoshopPicker} from 'react-color';
import {ChartOptions, ChartProps} from 'src/shared/components/Complex/Charts';
import {Button, Input, Switch, WidthBox, Wrapper} from 'src/shared/components/UI';
import * as S from '../../styles';

type Props = {
	chart: ChartProps | undefined;
	setChart: (val: ChartProps | undefined) => void;
};

const AreaChartSpecify = ({chart, setChart}: Props) => {
	const [chartOptions, setChartOptions] = useState<ChartOptions>();
	const [showPicker, setShowPicker] = useState(false);
	const [fillColor, setFillColor] = useState('');

	const addGrid = () => {
		const optionData = {...chartOptions};
		optionData.fillColor = fillColor;
		const data: ChartOptions[] = [...(chart?.dataOptions ?? [])];
		data.push(optionData);
		setChart({...chart, dataOptions: data});

		setChartOptions(undefined);
	};

	return (
		<>
			{showPicker ? (
				<S.PickerContent>
					<PhotoshopPicker
						color={fillColor ?? '#000'}
						onChangeComplete={color => setFillColor(color.hex)}
						onAccept={() => setShowPicker(false)}
						onCancel={() => setShowPicker(false)}
					/>
				</S.PickerContent>
			) : (
				<></>
			)}
			<Wrapper margin="12px 0">
				<WidthBox width="25%" margin="20px 0 0 0">
					<Switch
						isOn={chart?.useOriginData ?? true}
						handleToggle={() =>
							setChart({...chart, useOriginData: !chart?.useOriginData ?? true})
						}
						text="Dados custmizados?"
						textColor={'#000'}
					/>
				</WidthBox>
				<WidthBox width="25%">
					<Input
						value={chart?.axisLabel}
						onChange={val => setChart({...chart, axisLabel: val})}
						label="Prop eixo X"
						inputType="light"
						border="1px solid #333"
						background="transparent"
						textColor={'#000'}
					/>
				</WidthBox>
				{(function () {
					if (!chart?.useOriginData) {
						return (
							<>
								<WidthBox width="25%">
									<Input
										value={chart?.datasetProp}
										onChange={val => setChart({...chart, datasetProp: val})}
										label="Prop Dataset"
										inputType="light"
										border="1px solid #333"
										background="transparent"
										textColor={'#000'}
									/>
								</WidthBox>
								<WidthBox width="25%">
									<Input
										value={chart?.valueProp}
										onChange={val => setChart({...chart, valueProp: val})}
										label="Prop value"
										inputType="light"
										border="1px solid #333"
										background="transparent"
										textColor={'#000'}
									/>
								</WidthBox>
							</>
						);
					}
				})()}
			</Wrapper>

			<Wrapper margin="12px 0">
				<WidthBox width="30%">
					<Input
						value={chartOptions?.dataKey}
						onChange={val => setChartOptions({...(chartOptions ?? {}), dataKey: val})}
						label="Propriedade"
						inputType="light"
						border="1px solid #333"
						background="transparent"
						textColor={'#000'}
						onPressEnter={addGrid}
						mask="Sem espaço"
					/>
				</WidthBox>
				<WidthBox width="20%" margin="20px 0 0 0">
					<S.ColorButton
						background={chartOptions?.fillColor}
						onClick={() => setShowPicker(true)}
					/>
				</WidthBox>

				<WidthBox width="auto" margin="20px 0 0 0">
					<Button
						icon={faAdd}
						themeStyle="success"
						onClick={addGrid}
						fill="auto"
						disabled={
							!chartOptions?.dataKey ||
							chartOptions?.dataKey == '' ||
							chart?.dataOptions?.map(x => x.dataKey).includes(chartOptions?.dataKey)
						}
					/>
				</WidthBox>
			</Wrapper>

			{/* <Grid
				template="1fr 1fr 1fr"
				header={['Propriedade', 'Cor']}
				data={chart?.dataOptions}
				properties={['dataKey', 'fillColor', 'innerRadius', 'outerRadius']}
				headerTextColor={'#000'}
				dataActions={[
					{
						icon: faRemove,
						color: 'red',
						onClick: (_, index) => {
							const data: ChartOptions[] = [...(chart?.dataOptions ?? [])].filter(
								(_, i) => i != index,
							);
							setChart({...chart, dataOptions: data});
						},
					},
				]}
			/> */}
		</>
	);
};

export default AreaChartSpecify;
