import {removeDiacritics} from 'src/helpers/methods/removeDiacritics';
import {lowerFirstWord, upperFirstWord} from 'src/helpers/methods/text-methods';

export const dotnetFormatScreenName = (name: string): string => {
	const removePrefix = ['TB_', 'TABLE_', 'TABELA_'];
	let nameFormat = removeDiacritics(name).toUpperCase();

	removePrefix.forEach(item => {
		nameFormat = nameFormat.replace(item, '');
	});

	const nameSplited = nameFormat.split('_').map(item => {
		return upperFirstWord(item.toLocaleLowerCase());
	});

	return nameSplited.join('');
};

export const dotnetSchemaName = (name: string): string => {
	if (/(\d{1})/.test(name)) {
		name = name.substring(1);
	}
	name = name.toLocaleLowerCase();
	name = upperFirstWord(name);

	return name;
};

export const dotnetFormatRepoName = (classNameLow: string): string => `_${classNameLow}Repository`;

export const dotnetFormatFullRepoName = (tableName: string): string =>
	dotnetFormatRepoName(lowerFirstWord(dotnetFormatScreenName(tableName)));
