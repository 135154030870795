/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {IApplication, ITable, IResourceApi} from 'src/@types/app';
import {dotnetFormatScreenName, dotnetSchemaName} from '../core/dotnet-formatter';
import {lowerFirstWord, substringText, upperFirstWord} from 'src/helpers/methods/text-methods';
import {backFormatPropertyName} from 'src/shared/engine-back/common/common-formatters';

export const generateApi = (app: IApplication, environmentRef: string): IResourceApi[] => {
	const resources: IResourceApi[] = [];
	const projName = app.name!.replaceAll(' ', '');
	const tables = app.database!.tables!;
	const hasSchemas = true;

	resources.push(getAuthorizationConfig(projName));
	resources.push(getAutoMapperConfig(projName, tables));
	resources.push(getMigrationConfig(projName));
	resources.push(getCorsConfig(projName));
	resources.push(getMediatrConfig(projName));
	resources.push(getConfigOpenTelemetry(projName));
	resources.push(getODataConfig(projName, tables, hasSchemas));
	resources.push(getProgram(projName));
	resources.push(getAppSettings(projName));
	resources.push(getLauchSettings(projName));
	resources.push(...getController(projName, tables, hasSchemas));
	resources.push(getJaegerConfig());
	resources.push(getOtelCollector());
	resources.push(getPrometheus());
	resources.push(getGrafanaDash());
	resources.push(getGrafanaConn());
	resources.push(getGrafanaDashss());
	resources.push(getDockerFile(projName));
	resources.push(getDockerCompose());

	return resources;
};

const getDockerFile = (projName: string) => {
	const code = `FROM mcr.microsoft.com/dotnet/sdk:7.0 AS build
WORKDIR /App
COPY . ./
RUN dotnet restore
RUN dotnet publish -c Release -o out

FROM mcr.microsoft.com/dotnet/aspnet:7.0
WORKDIR /App
COPY --from=build /App/out .
EXPOSE 80
ENTRYPOINT ["dotnet", "${projName}.API.dll"]`;

	return {
		path: './src',
		name: 'Dockerfile',
		code: code,
	};
};

const getAuthorizationConfig = (projName: string): IResourceApi => {
	return {
		path: `src/${projName}.API/Configuration/`,
		name: 'AuthorizationConfiguration.cs',
		code: `using System.Text;
using Microsoft.AspNetCore.Authentication.JwtBearer;
using Microsoft.AspNetCore.Authorization;
using Microsoft.IdentityModel.Tokens;

namespace ${projName}.API.Configuration;

public static class AuthorizationConfiguration
{
    /// <summary>
    /// Responsável por parametrizar a autenticação dos serviços através do JWT
    /// </summary>
    /// <param name="services">Contêm uma instância da interface <see cref="IServiceCollection"/></param>
    /// <param name="configuration">Contêm uma instância da interface <see cref="IConfiguration"/></param>
    /// <returns>Retorna uma instância da interface <see cref="IServiceCollection"/></returns>
    public static IServiceCollection ConfigureAuthorization(this IServiceCollection services, IConfiguration configuration)
    {
        var secret = configuration.GetValue<string>("JWT:Secret")!;
        var issuer = configuration.GetValue<string>("JWT:Issuer")!;
        var audience = configuration.GetValue<string>("JWT:Audience")!;
        var expiration = configuration.GetValue<double>("JWT:Expiration")!;

        var key = Encoding.ASCII.GetBytes(secret);

        services.AddAuthentication(x =>
        {
            x.DefaultAuthenticateScheme = JwtBearerDefaults.AuthenticationScheme;
            x.DefaultChallengeScheme = JwtBearerDefaults.AuthenticationScheme;
        }).AddJwtBearer(x =>
        {
            x.RequireHttpsMetadata = false;
            x.SaveToken = true;
            x.TokenValidationParameters = new TokenValidationParameters
            {
                ValidateIssuerSigningKey = true,
                IssuerSigningKey = new SymmetricSecurityKey(key),
                ValidateIssuer = true,
                ValidateAudience = true,
                ValidIssuer = issuer,
                ValidAudience = audience
            };
        });

        services.AddAuthorization(options =>
        {
            var defaultAuthorizationPolicyBuilder = new AuthorizationPolicyBuilder(JwtBearerDefaults.AuthenticationScheme);
            defaultAuthorizationPolicyBuilder = defaultAuthorizationPolicyBuilder.RequireAuthenticatedUser();
            options.DefaultPolicy = defaultAuthorizationPolicyBuilder.Build();
        });

        return services;
    }
}`,
	};
};

const getAutoMapperConfig = (projName: string, tables: ITable[]): IResourceApi => {
	let mappers = '';

	tables.forEach(table => {
		const className = dotnetFormatScreenName(table.name!);
		mappers += `              config.AddProfile(new ${className}Mapping());\n`;
	});

	const code = `using ${projName}.Application.Mapping;

namespace ${projName}.API.Configuration;

public static class AutoMapperConfiguration
{
    /// <summary>
    /// Responsável por realizar o mapeamento automático das entidades
    /// </summary>
    /// <param name="services">Contêm uma instância da interface <see cref="IServiceCollection"/></param>
    /// <returns>Retorna uma instância da interface <see cref="IServiceCollection"/></returns>
    public static IServiceCollection ConfigureAutoMapper(this IServiceCollection services)
    {
        var autoMapperConfig = new AutoMapper.MapperConfiguration(config =>
            {
${mappers}
            });

        var mapper = autoMapperConfig.CreateMapper();
        services.AddSingleton(mapper);

        return services;
    }
}`;

	return {
		path: `src/${projName}.API/Configuration/`,
		name: 'AutoMapperConfig.cs',
		code: code,
	};
};

const getMigrationConfig = (projName: string): IResourceApi => {
	const code = `using Microsoft.EntityFrameworkCore;
using ${projName}.Infra.Data.Context;

namespace ${projName}.API.Configuration;

public static class MigrationConfiguration
{
    public static void ExecuteMigration(this IApplicationBuilder app)
    {
        using (var scope = app.ApplicationServices.CreateScope())
        {
            var services = scope.ServiceProvider;

            using (var context = services.GetRequiredService<DataContext>())
            {
                if (context.Database.GetPendingMigrations().Any())
                {
                    context.Database.Migrate();
                }
            }
        }
    }
}`;

	return {
		path: `src/${projName}.API/Configuration/`,
		name: 'MigrationConfig.cs',
		code: code,
	};
};

const getCorsConfig = (projName: string): IResourceApi => {
	const code = `namespace ${projName}.API.Configuration;

public static class CorsConfiguration
{
    /// <summary>
    /// Responsável por realizar a parametrização do Cors (Cross-origin Resource Sharing)
    /// </summary>
    /// <param name="services">Contêm uma instância da interface <see cref="IServiceCollection"/></param>
    /// <returns>Retorna uma instância da interface <see cref="IServiceCollection"/></returns>
    public static IServiceCollection ConfigureCors(this IServiceCollection services)
    {
        services.AddCors(config =>
        {
            config.AddPolicy("CorsPolicy", option =>
            {
                option
                    .AllowAnyMethod()
                    .AllowAnyHeader()
                    .SetIsOriginAllowed(_ => true)
                    .AllowCredentials();
            });
        });

        return services;
    }
}`;

	return {
		path: `src/${projName}.API/Configuration/`,
		name: 'CorsConfig.cs',
		code: code,
	};
};

const getMediatrConfig = (projName: string): IResourceApi => {
	const code = `namespace ${projName}.API.Configuration;

public static class MediatrConfiguration
{
    /// <summary>
    /// Responsável por adicionar recursos aos serviços através do MediatR
    /// </summary>
    /// <param name="services">Contêm uma instância da interface <see cref="IServiceCollection"/></param>
    /// <returns>Retorna uma instância da interface <see cref="IServiceCollection"/></returns>
    public static IServiceCollection ConfigureMediatr(this IServiceCollection services)
    {
        var assembly = AppDomain.CurrentDomain.Load("${projName}.Application");
        services.AddMediatR(cfg => cfg.RegisterServicesFromAssemblies(assembly));

        return services;
    }
}`;

	return {
		path: `src/${projName}.API/Configuration/`,
		name: 'MediatrConfig.cs',
		code: code,
	};
};

const getODataConfig = (projName: string, tables: ITable[], hasSchemas: boolean): IResourceApi => {
	let builders = '';
	let modelImports = '';

	tables.forEach(table => {
		const className = dotnetFormatScreenName(table.name!);
		builders += `        modelBuilder.EntitySet<${className}>("${className}");\n`;
	});

	if (hasSchemas) {
		const schemas = Array.from(new Set(tables?.map(x => x.schema) ?? []));

		schemas.forEach(schema => {
			modelImports += `using ${projName}.Domain.Models.${dotnetSchemaName(schema!)};\n`;
		});
	} else modelImports = `using ${projName}.Domain.Models;`;

	const code = `using Microsoft.AspNetCore.OData;
using Microsoft.OData.ModelBuilder;
${modelImports}

namespace ${projName}.API.Configuration;

public static class ODataConfiguration
{
    /// <summary>
    /// Responsável por realizar a parametrização do OData (Open Data Protocol)
    /// </summary>
    /// <param name="mvcBuilder">Contêm uma instância da interface <see cref="IMvcBuilder"/></param>
    /// <returns>Retorna uma instância da interface <see cref="IMvcBuilder"/></returns>
    public static IMvcBuilder ConfigureOData(this IMvcBuilder mvcBuilder)
    {
        var modelBuilder = new ODataConventionModelBuilder();

${builders}

        mvcBuilder.AddOData(
            options => options
                .Select()
                .Filter()
                .OrderBy()
                .Expand()
                .Count()
                .SetMaxTop(null)
                .EnableQueryFeatures()
                .AddRouteComponents(
                    "odata",
                    modelBuilder.GetEdmModel())
                );

        return mvcBuilder;
    }
}`;

	return {
		path: `src/${projName}.API/Configuration/`,
		name: 'ODataConfig.cs',
		code: code,
	};
};

const getProgram = (projName: string): IResourceApi => {
	const code = `using ${projName}.API.Configuration;
using ${projName}.CrossCutting.IoC.ConfigureData;
using ${projName}.CrossCutting.IoC.DependencyInjection;

var builder = WebApplication.CreateBuilder(args);

builder.Services
    .AddControllers()
    .ConfigureOData();

builder.Services
    .AddEndpointsApiExplorer()
    .AddSwaggerGen()
    .ConfigureMediatr()
    .ConfigureCors()
    .ConfigureAuthorization(builder.Configuration)
    .ConfigureDependencies()
    .ConfigContext(builder.Configuration)
    .ConfigureAutoMapper()
    .ConfigureOpenTelemetry(builder.Configuration, builder.Host);

var app = builder.Build();

app.UseStatusCodePages();

app.UseDeveloperExceptionPage();

app.UseSwagger();
app.UseSwaggerUI();

app.UseCors("CorsPolicy");

app.UseHttpsRedirection();

app.UseAuthorization();

app.ExecuteMigration();

app.MapControllers();

app.Run();
`;

	return {
		path: `src/${projName}.API/`,
		name: 'Program.cs',
		code: code,
	};
};

const getAppSettings = (projName: string): IResourceApi => {
	const code = `{
  "Logging": {
    "LogLevel": {
      "Default": "Information",
      "Microsoft.AspNetCore": "Warning"
    }
  },
  "AllowedHosts": "*",
  "JWT": {
    "Secret": "${projName}_e31f3379-cbe8-4d26-b951-0a7b17c7183b-0a7b17c7183b",
    "Issuer": "${projName}_Issuer",
    "Audience": "${projName}_Audience",
    "Expiration": 8
  },
  "ConnectionStrings": {
    "DefaultConnection": "Server=localhost;Database=${projName}DB;User Id=sa;Password=1q2w3e4r@#$;TrustServerCertificate=True;"
  },
  "Otlp": {
    "Endpoint": "http://localhost:4317"
  },
  "Jaeger": {
    "Url": "localhost",
    "Port": 6831
  }
}`;

	return {
		path: `src/${projName}.API/`,
		name: 'appsettings.json',
		code: code,
	};
};

const getLauchSettings = (projName: string): IResourceApi => {
	const code = `{
  "$schema": "https://json.schemastore.org/launchsettings.json",
  "iisSettings": {
    "windowsAuthentication": false,
    "anonymousAuthentication": true,
    "iisExpress": {
      "applicationUrl": "http://localhost:47958",
      "sslPort": 44317
    }
  },
  "profiles": {
    "http": {
      "commandName": "Project",
      "dotnetRunMessages": true,
      "launchBrowser": true,
      "launchUrl": "swagger",
      "applicationUrl": "http://localhost:5049",
      "environmentVariables": {
        "ASPNETCORE_ENVIRONMENT": "Development"
      }
    },
    "https": {
      "commandName": "Project",
      "dotnetRunMessages": true,
      "launchBrowser": true,
      "launchUrl": "swagger",
      "applicationUrl": "https://localhost:7146;http://localhost:5049",
      "environmentVariables": {
        "ASPNETCORE_ENVIRONMENT": "Development"
      }
    },
    "IIS Express": {
      "commandName": "IISExpress",
      "launchBrowser": true,
      "launchUrl": "swagger",
      "environmentVariables": {
        "ASPNETCORE_ENVIRONMENT": "Development"
      }
    }
  }
}
`;

	return {
		path: `src/${projName}.API/`,
		name: 'launchSettings.json',
		code: code,
	};
};

const getController = (projName: string, tables: ITable[], hasSchemas: boolean): IResourceApi[] => {
	const resources: IResourceApi[] = [];

	if (hasSchemas) {
		const schemas = Array.from(new Set(tables?.map(x => x.schema) ?? []));
		schemas.forEach(schema => {
			const name = `${dotnetSchemaName(schema!)}Controller`;
			resources.push({
				path: `src/${projName}.API/Controllers`,
				name: `${name}.cs`,
				code: createController(
					projName,
					name,
					tables.filter(x => x.schema === schema),
				),
			});
		});
	} else {
		tables.forEach(table => {
			const name = `${upperFirstWord(table.name!)}Controller.cs`;
			resources.push({
				path: `src/${projName}.API/Controllers`,
				name: `${name}.cs`,
				code: createController(projName, name, [table]),
			});
		});
	}

	return resources;
};

const createController = (projName: string, name: string, tables: ITable[]): string => {
	let imports = '';
	let instances = '';
	let injection = '';
	let attr = '';
	let routes = '';

	tables.forEach((table, index) => {
		const className = dotnetFormatScreenName(table.name!);
		const classNameLow = lowerFirstWord(className);
		imports += `using ${projName}.Application.Commands.${className};
using ${projName}.Application.Queries.${className}Query;\n`;

		instances += `    private readonly I${className}Query _${classNameLow}Query;\n`;

		injection += `, I${className}Query ${classNameLow}Query`;

		if (tables.length === index)
			attr += `        _${classNameLow}Query = ${classNameLow}Query;`;
		else attr += `        _${classNameLow}Query = ${classNameLow}Query;\n`;

		const propIdName = backFormatPropertyName(
			table.columns?.find(x => x.autoIncremente)?.name ?? '',
		);

		routes += `
    /// <summary>
    /// Listar registros do(a) ${className.toLocaleLowerCase()} utilizando OData
    /// </summary>
    /// <param name="requestId">Identificador exclusivo para cada solicitação HTTP envolvida no processamento da operação</param>
    /// <returns>Resultado do processamento da operação através do tipo <see cref="IActionResult"/></returns>
    /// <response code="200">Processamento realizado com êxito</response>
    /// <response code="400">Requisição não está dentro do padrão esperado</response>
    /// <response code="401">Usuário não possui permissão para acessar a URL informada ou o TOKEN não for informado</response>
    [SwaggerResponse((int)HttpStatusCode.OK, Description = "Processamento realizado com êxito", Type = typeof(ResponseService))]
    [SwaggerResponse((int)HttpStatusCode.BadRequest, Description = "Requisição não está dentro do padrão esperado", Type = typeof(ResponseBadRequestService))]
    [SwaggerResponse((int)HttpStatusCode.Unauthorized, Description = "Usuário não possui permissão para acessar a URL informada ou o TOKEN não for informado", Type = typeof(ResponseUnauthorizedService))]
    [SwaggerResponse((int)HttpStatusCode.NotFound, Description = "Nenhum registro foi localizado", Type = typeof(ResponseNotFoundService))]
    [SwaggerResponse((int)HttpStatusCode.MethodNotAllowed, Description = "Requisição não está dentro do padrão esperado", Type = typeof(ResponseMethodNotAllowedService))]
    [SwaggerResponse((int)HttpStatusCode.InternalServerError, Description = "Requisição gerou uma dificuldade de processamento do servidor", Type = typeof(ResponseInternalServerErrorService))]
    [EnableQuery(MaxExpansionDepth = 25)]
    [HttpGet]
    [Route("${classNameLow}")]
    public IActionResult GetOData${className}([FromHeader(Name = "x-requestid")] string? requestId)
    {
        try
        {
            return Ok(_${classNameLow}Query.GetOData());
        }
        catch (Exception ex)
        {
            return BadRequest(new ResponseBadRequestService(ex.Message));
        }
    }

    /// <summary>
    /// Cria registro do(a) ${className.toLocaleLowerCase()}
    /// </summary>
    /// <param name="command">Estrutura de dados necessária para execução das operações</param>
    /// <param name="requestId">Identificador exclusivo para cada solicitação HTTP envolvida no processamento da operação</param>
    /// <returns>Resultado do processamento da operação através do tipo <see cref="IActionResult"/></returns>
    /// <response code="200">Processamento realizado com êxito</response>
    /// <response code="400">Requisição não está dentro do padrão esperado</response>
    /// <response code="401">Usuário não possui permissão para acessar a URL informada ou o TOKEN não for informado</response>
    [SwaggerResponse((int)HttpStatusCode.OK, Description = "Processamento realizado com êxito", Type = typeof(ResponseService))]
    [SwaggerResponse((int)HttpStatusCode.BadRequest, Description = "Requisição não está dentro do padrão esperado", Type = typeof(ResponseBadRequestService))]
    [SwaggerResponse((int)HttpStatusCode.Unauthorized, Description = "Usuário não possui permissão para acessar a URL informada ou o TOKEN não for informado", Type = typeof(ResponseUnauthorizedService))]
    [SwaggerResponse((int)HttpStatusCode.NotFound, Description = "Nenhum registro foi localizado", Type = typeof(ResponseNotFoundService))]
    [SwaggerResponse((int)HttpStatusCode.MethodNotAllowed, Description = "Requisição não está dentro do padrão esperado", Type = typeof(ResponseMethodNotAllowedService))]
    [SwaggerResponse((int)HttpStatusCode.InternalServerError, Description = "Requisição gerou uma dificuldade de processamento do servidor", Type = typeof(ResponseInternalServerErrorService))]
    [HttpPost]
    [Route("${classNameLow}")]
    public async Task<IActionResult> Criar${className}(Criar${className}Command command, [FromHeader(Name = "x-requestid")] string? requestId)
    {
        try
        {
            var result = await _mediator.Send(command);
            if (!result.Status)
                throw new Exception(result.Message);

            return Ok(result);
        }
        catch (Exception ex)
        {
            return BadRequest(new ResponseBadRequestService(ex.Message));
        }
    }

    /// <summary>
    /// Atualiza registro do(a) ${className.toLocaleLowerCase()}
    /// </summary>
    /// <param name="command">Estrutura de dados necessária para execução das operações</param>
    /// <param name="requestId">Identificador exclusivo para cada solicitação HTTP envolvida no processamento da operação</param>
    /// <returns>Resultado do processamento da operação através do tipo <see cref="IActionResult"/></returns>
    /// <response code="200">Processamento realizado com êxito</response>
    /// <response code="400">Requisição não está dentro do padrão esperado</response>
    /// <response code="401">Usuário não possui permissão para acessar a URL informada ou o TOKEN não for informado</response>
    [SwaggerResponse((int)HttpStatusCode.OK, Description = "Processamento realizado com êxito", Type = typeof(ResponseService))]
    [SwaggerResponse((int)HttpStatusCode.BadRequest, Description = "Requisição não está dentro do padrão esperado", Type = typeof(ResponseBadRequestService))]
    [SwaggerResponse((int)HttpStatusCode.Unauthorized, Description = "Usuário não possui permissão para acessar a URL informada ou o TOKEN não for informado", Type = typeof(ResponseUnauthorizedService))]
    [SwaggerResponse((int)HttpStatusCode.NotFound, Description = "Nenhum registro foi localizado", Type = typeof(ResponseNotFoundService))]
    [SwaggerResponse((int)HttpStatusCode.MethodNotAllowed, Description = "Requisição não está dentro do padrão esperado", Type = typeof(ResponseMethodNotAllowedService))]
    [SwaggerResponse((int)HttpStatusCode.InternalServerError, Description = "Requisição gerou uma dificuldade de processamento do servidor", Type = typeof(ResponseInternalServerErrorService))]
    [HttpPut]
    [Route("${classNameLow}")]
    public async Task<IActionResult> Alterar${className}(Alterar${className}Command command, [FromHeader(Name = "x-requestid")] string? requestId)
    {
        try
        {
            var result = await _mediator.Send(command);
            if (!result.Status)
                throw new Exception(result.Message);

            return Ok(result);
        }
        catch (KeyNotFoundException)
        {
            return NotFound(new ResponseNotFoundService());
        }
        catch (Exception ex)
        {
            return BadRequest(new ResponseBadRequestService(ex.Message));
        }
    }

    /// <summary>
    /// Remove registro do(a) ${className.toLocaleLowerCase()}
    /// </summary>
    /// <param name="command">Estrutura de dados necessária para execução das operações</param>
    /// <param name="requestId">Identificador exclusivo para cada solicitação HTTP envolvida no processamento da operação</param>
    /// <returns>Resultado do processamento da operação através do tipo <see cref="IActionResult"/></returns>
    /// <response code="200">Processamento realizado com êxito</response>
    /// <response code="400">Requisição não está dentro do padrão esperado</response>
    /// <response code="401">Usuário não possui permissão para acessar a URL informada ou o TOKEN não for informado</response>
    [SwaggerResponse((int)HttpStatusCode.OK, Description = "Processamento realizado com êxito", Type = typeof(ResponseService))]
    [SwaggerResponse((int)HttpStatusCode.BadRequest, Description = "Requisição não está dentro do padrão esperado", Type = typeof(ResponseBadRequestService))]
    [SwaggerResponse((int)HttpStatusCode.Unauthorized, Description = "Usuário não possui permissão para acessar a URL informada ou o TOKEN não for informado", Type = typeof(ResponseUnauthorizedService))]
    [SwaggerResponse((int)HttpStatusCode.NotFound, Description = "Nenhum registro foi localizado", Type = typeof(ResponseNotFoundService))]
    [SwaggerResponse((int)HttpStatusCode.MethodNotAllowed, Description = "Requisição não está dentro do padrão esperado", Type = typeof(ResponseMethodNotAllowedService))]
    [SwaggerResponse((int)HttpStatusCode.InternalServerError, Description = "Requisição gerou uma dificuldade de processamento do servidor", Type = typeof(ResponseInternalServerErrorService))]
    [HttpDelete]
    [Route("${classNameLow}/{id}")]
    public async Task<IActionResult> Remover${className}(int id, [FromHeader(Name = "x-requestid")] string? requestId)
    {
        try
        {
            var command = new Remover${className}Command { ${propIdName} = id };
            var result = await _mediator.Send(command);
            if (!result.Status)
                throw new Exception(result.Message);

            return Ok(result);
        }
        catch (Exception ex)
        {
            return BadRequest(new ResponseBadRequestService(ex.Message));
        }
    }
    `;
	});

	attr = substringText(attr);
	instances = substringText(instances);

	return `using MediatR;
using Microsoft.AspNetCore.Authorization;
using Microsoft.AspNetCore.Mvc;
using Microsoft.AspNetCore.OData.Query;
using Microsoft.AspNetCore.OData.Routing.Controllers;
using Swashbuckle.AspNetCore.Annotations;
using System.Net;
using ${projName}.Application.Utils;
${imports}

namespace ${projName}.API.Controllers;

/// <summary>
/// Disponibiliza informações referentes ao módulo ${name
		.toLocaleLowerCase()
		.replace('controller', '')}
/// </summary>
// [Authorize]
[ApiController]
[Route("v1/[controller]")]
[Produces("application/json")]
public class ${name} : ODataController
{
    private readonly IMediator _mediator;
${instances}

    /// <summary>
    /// Construtor
    /// </summary>
    /// <param name="mediator">Orquestrador de requisições</param>
    public ${name}(IMediator mediator${injection})
    {
        _mediator = mediator;
${attr}
    }
    ${routes}
}`;
};

const getConfigOpenTelemetry = (projName: string): IResourceApi => {
	const code = `using OpenTelemetry.Logs;
using OpenTelemetry.Metrics;
using OpenTelemetry.Resources;
using OpenTelemetry.Trace;

namespace ${projName}.API.Configuration;

public static class ConfigOpenTelemetry
{
  /// <summary>
  /// Responsável por configurar a telemetria
  /// </summary>
  /// <param name="services">Contêm uma instância da interface <see cref="IServiceCollection"/></param>
  /// <returns>Retorna uma instância da interface <see cref="IServiceCollection"/></returns>
  public static void ConfigureOpenTelemetry(this IServiceCollection services, IConfiguration configuration, IHostBuilder hostBuilder)
  {
      services.AddOpenTelemetry()
          .WithTracing(options =>
          {
              options
                  .SetResourceBuilder(ResourceBuilder.CreateDefault().AddService("Dotnet.Telemetry.Jaeger.Tracing"))
                  .AddAspNetCoreInstrumentation()
                  .AddHttpClientInstrumentation()

                  .SetSampler(new AlwaysOnSampler())
                  .AddJaegerExporter(jaegerOptions =>
                  {
                      jaegerOptions.AgentHost = configuration["Jaeger:Url"] ?? throw new InvalidOperationException();
                      jaegerOptions.AgentPort = int.Parse(configuration["Jaeger:Port"] ?? throw new InvalidOperationException());
                  })
                  .AddOtlpExporter(o => o.Endpoint = new Uri(configuration["Otlp:Endpoint"] ?? throw new InvalidOperationException()));
          })
          .WithMetrics(options =>
          {
              options
                  .SetResourceBuilder(ResourceBuilder.CreateDefault().AddService("Dotnet.Telemetry.Jaeger.Metrics"))
                  .AddAspNetCoreInstrumentation()
                  .AddHttpClientInstrumentation()
                  .AddRuntimeInstrumentation()
                  .AddProcessInstrumentation()
                  .AddOtlpExporter(o => o.Endpoint = new Uri(configuration["Otlp:Endpoint"] ?? throw new InvalidOperationException()));
          });

      services.AddLogging(logging => logging.AddOpenTelemetry(openTelemetryLoggerOptions =>
      {
          openTelemetryLoggerOptions.IncludeScopes = true;
          openTelemetryLoggerOptions.IncludeFormattedMessage = true;
          openTelemetryLoggerOptions.ParseStateValues = true;

          openTelemetryLoggerOptions.AddOtlpExporter(exporter =>
          {
              exporter.Endpoint = new Uri(configuration["Otlp:Endpoint"] ?? throw new InvalidOperationException());
          });
      })
      .SetMinimumLevel(LogLevel.Debug));
  }
}`;

	return {
		path: `src/${projName}.API/Configuration/`,
		name: 'ConfigOpenTelemetry.cs',
		code: code,
	};
};

const getJaegerConfig = () => {
	const code = `{
    "monitor": {
        "menuEnabled": true
    },
    "dependencies": {
        "menuEnabled": false
    }
}`;

	return {
		path: './jaeger-config',
		name: 'jaeger-ui.json',
		code: code,
	};
};

const getOtelCollector = () => {
	const code = `receivers:
  otlp:
    protocols:
      grpc:
      http:

exporters:
  prometheus:
    endpoint: 0.0.0.0:8889

  logging:

  otlphttp/jaeger:
    endpoint: "http://jaeger:4318"
    tls:
      insecure: true

  otlphttp/seq:
    endpoint: "http://seq:5341/ingest/otlp"
    tls:
      insecure: true

# Configure processors (batch, sampling, filtering, hashing sensitive data, etc.)
# https://opentelemetry.io/docs/collector/configuration/#processors
processors:
  batch:

extensions:
  health_check:

service:
  extensions: [health_check]
  pipelines:
    traces:
      receivers: [otlp]
      processors: [batch]
      exporters: [logging, otlphttp/jaeger]

    metrics:
      receivers: [otlp]
      processors: [batch]
      exporters: [logging, prometheus]

    logs:
      receivers: [otlp]
      processors: [batch]
      exporters: [logging, otlphttp/seq]`;

	return {
		path: './otel-collector',
		name: 'otel-collector-config.yml',
		code: code,
	};
};

const getPrometheus = () => {
	const code = `global:
  scrape_interval: 10s
  evaluation_interval: 10s

scrape_configs:
  - job_name: 'otel-collector'
    static_configs:
      - targets: ['otel-collector:8889']
      - targets: ['otel-collector:8888']`;

	return {
		path: './prometheus',
		name: 'prometheus.yml',
		code: code,
	};
};

const getGrafanaDash = () => {
	const code = `apiVersion: 1

providers:
  - name: 'prometheus-net'
    orgId: 1
    type: file
    disableDeletion: true
    updateIntervalSeconds: 10
    allowUiUpdates: true
    options:
      path:  /var/lib/grafana/dashboards
      foldersFromFilesStructure: true`;

	return {
		path: './grafana/provisioning/dashboards',
		name: 'prometheus-dashboards.yml',
		code: code,
	};
};

const getGrafanaConn = () => {
	const code = `apiVersion: 1

deleteDatasources:
  - name: Prometheus
    orgId: 1

datasources:
  - name: Prometheus
    type: prometheus
    access: proxy
    orgId: 1
    url: http://prometheus:9090`;

	return {
		path: './grafana/provisioning/datasources',
		name: 'prometheus-connector.yml',
		code: code,
	};
};

const getGrafanaDashss = () => {
	const code = `{
    "annotations": {
        "list": [
            {
                "builtIn": 1,
                "datasource": {
                    "type": "grafana",
                    "uid": "-- Grafana --"
                },
                "enable": true,
                "hide": true,
                "iconColor": "rgba(0, 211, 255, 1)",
                "name": "Annotations & Alerts",
                "target": {
                    "limit": 100,
                    "matchAny": false,
                    "tags": [],
                    "type": "dashboard"
                },
                "type": "dashboard"
            }
        ]
    },
    "editable": true,
    "fiscalYearStartMonth": 0,
    "graphTooltip": 0,
    "id": 2,
    "links": [],
    "liveNow": false,
    "panels": [
        {
            "collapsed": false,
            "gridPos": {
                "h": 1,
                "w": 24,
                "x": 0,
                "y": 0
            },
            "id": 32,
            "panels": [],
            "title": "System.Runtime performance Counters",
            "type": "row"
        },
        {
            "datasource": {
                "type": "prometheus",
                "uid": "PBFA97CFB590B2093"
            },
            "fieldConfig": {
                "defaults": {
                    "color": {
                        "mode": "palette-classic"
                    },
                    "custom": {
                        "axisCenteredZero": false,
                        "axisColorMode": "text",
                        "axisLabel": "",
                        "axisPlacement": "auto",
                        "barAlignment": 0,
                        "drawStyle": "line",
                        "fillOpacity": 0,
                        "gradientMode": "none",
                        "hideFrom": {
                            "legend": false,
                            "tooltip": false,
                            "viz": false
                        },
                        "lineInterpolation": "linear",
                        "lineWidth": 1,
                        "pointSize": 5,
                        "scaleDistribution": {
                            "type": "linear"
                        },
                        "showPoints": "auto",
                        "spanNulls": false,
                        "stacking": {
                            "group": "A",
                            "mode": "none"
                        },
                        "thresholdsStyle": {
                            "mode": "off"
                        }
                    },
                    "mappings": [],
                    "thresholds": {
                        "mode": "absolute",
                        "steps": [
                            {
                                "color": "green",
                                "value": null
                            }
                        ]
                    },
                    "unit": "decbytes"
                },
                "overrides": []
            },
            "gridPos": {
                "h": 8,
                "w": 7,
                "x": 0,
                "y": 1
            },
            "id": 24,
            "options": {
                "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                },
                "tooltip": {
                    "mode": "single",
                    "sort": "none"
                }
            },
            "targets": [
                {
                    "datasource": {
                        "type": "prometheus",
                        "uid": "PBFA97CFB590B2093"
                    },
                    "editorMode": "code",
                    "expr": "process_runtime_dotnet_gc_allocations_size",
                    "legendFormat": "{{__name__}}",
                    "range": true,
                    "refId": "A"
                }
            ],
            "title": "GC Allocation Size",
            "type": "timeseries"
        },
        {
            "datasource": {
                "type": "prometheus",
                "uid": "PBFA97CFB590B2093"
            },
            "fieldConfig": {
                "defaults": {
                    "color": {
                        "mode": "thresholds"
                    },
                    "mappings": [],
                    "thresholds": {
                        "mode": "absolute",
                        "steps": [
                            {
                                "color": "green",
                                "value": null
                            }
                        ]
                    },
                    "unit": "decbytes"
                },
                "overrides": []
            },
            "gridPos": {
                "h": 8,
                "w": 7,
                "x": 7,
                "y": 1
            },
            "id": 12,
            "options": {
                "colorMode": "value",
                "graphMode": "area",
                "justifyMode": "auto",
                "orientation": "auto",
                "reduceOptions": {
                    "calcs": [
                        "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                },
                "textMode": "auto"
            },
            "pluginVersion": "9.4.3",
            "targets": [
                {
                    "datasource": {
                        "type": "prometheus",
                        "uid": "PBFA97CFB590B2093"
                    },
                    "editorMode": "code",
                    "expr": "process_memory_usage",
                    "legendFormat": "{{__name__}}",
                    "range": true,
                    "refId": "A"
                }
            ],
            "title": "Memory Usage",
            "type": "stat"
        },
        {
            "datasource": {
                "type": "prometheus",
                "uid": "PBFA97CFB590B2093"
            },
            "fieldConfig": {
                "defaults": {
                    "color": {
                        "mode": "palette-classic"
                    },
                    "custom": {
                        "axisCenteredZero": false,
                        "axisColorMode": "text",
                        "axisLabel": "",
                        "axisPlacement": "auto",
                        "barAlignment": 0,
                        "drawStyle": "line",
                        "fillOpacity": 0,
                        "gradientMode": "none",
                        "hideFrom": {
                            "legend": false,
                            "tooltip": false,
                            "viz": false
                        },
                        "lineInterpolation": "linear",
                        "lineWidth": 1,
                        "pointSize": 5,
                        "scaleDistribution": {
                            "type": "linear"
                        },
                        "showPoints": "auto",
                        "spanNulls": false,
                        "stacking": {
                            "group": "A",
                            "mode": "none"
                        },
                        "thresholdsStyle": {
                            "mode": "off"
                        }
                    },
                    "mappings": [],
                    "thresholds": {
                        "mode": "absolute",
                        "steps": [
                            {
                                "color": "green",
                                "value": null
                            }
                        ]
                    },
                    "unit": "percentunit"
                },
                "overrides": []
            },
            "gridPos": {
                "h": 8,
                "w": 7,
                "x": 14,
                "y": 1
            },
            "id": 14,
            "options": {
                "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                },
                "tooltip": {
                    "mode": "single",
                    "sort": "none"
                }
            },
            "pluginVersion": "9.4.3",
            "targets": [
                {
                    "datasource": {
                        "type": "prometheus",
                        "uid": "PBFA97CFB590B2093"
                    },
                    "editorMode": "code",
                    "expr": "100 * (delta(process_cpu_time{state='user'}[$__rate_interval])/ignoring(state)(process_cpu_count* 30))",
                    "hide": false,
                    "legendFormat": "cpu_utilization",
                    "range": true,
                    "refId": "B"
                }
            ],
            "title": "CPU Utilization",
            "type": "timeseries"
        },
        {
            "datasource": {
                "type": "prometheus",
                "uid": "PBFA97CFB590B2093"
            },
            "fieldConfig": {
                "defaults": {
                    "color": {
                        "mode": "palette-classic"
                    },
                    "custom": {
                        "axisCenteredZero": false,
                        "axisColorMode": "text",
                        "axisLabel": "",
                        "axisPlacement": "auto",
                        "barAlignment": 0,
                        "drawStyle": "line",
                        "fillOpacity": 0,
                        "gradientMode": "none",
                        "hideFrom": {
                            "legend": false,
                            "tooltip": false,
                            "viz": false
                        },
                        "lineInterpolation": "linear",
                        "lineWidth": 1,
                        "pointSize": 5,
                        "scaleDistribution": {
                            "type": "linear"
                        },
                        "showPoints": "auto",
                        "spanNulls": false,
                        "stacking": {
                            "group": "A",
                            "mode": "none"
                        },
                        "thresholdsStyle": {
                            "mode": "off"
                        }
                    },
                    "mappings": [],
                    "thresholds": {
                        "mode": "absolute",
                        "steps": [
                            {
                                "color": "green",
                                "value": null
                            },
                            {
                                "color": "red",
                                "value": 80
                            }
                        ]
                    }
                },
                "overrides": []
            },
            "gridPos": {
                "h": 8,
                "w": 7,
                "x": 0,
                "y": 9
            },
            "id": 10,
            "options": {
                "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                },
                "tooltip": {
                    "mode": "single",
                    "sort": "none"
                }
            },
            "targets": [
                {
                    "datasource": {
                        "type": "prometheus",
                        "uid": "PBFA97CFB590B2093"
                    },
                    "editorMode": "code",
                    "expr": "process_runtime_dotnet_gc_collections_count",
                    "legendFormat": "{{generation}}",
                    "range": true,
                    "refId": "A"
                }
            ],
            "title": "Number of GC Generations over time",
            "type": "timeseries"
        },
        {
            "datasource": {
                "type": "prometheus",
                "uid": "PBFA97CFB590B2093"
            },
            "fieldConfig": {
                "defaults": {
                    "color": {
                        "mode": "palette-classic"
                    },
                    "custom": {
                        "axisCenteredZero": false,
                        "axisColorMode": "text",
                        "axisLabel": "",
                        "axisPlacement": "auto",
                        "barAlignment": 0,
                        "drawStyle": "line",
                        "fillOpacity": 0,
                        "gradientMode": "none",
                        "hideFrom": {
                            "legend": false,
                            "tooltip": false,
                            "viz": false
                        },
                        "lineInterpolation": "linear",
                        "lineWidth": 1,
                        "pointSize": 5,
                        "scaleDistribution": {
                            "type": "linear"
                        },
                        "showPoints": "auto",
                        "spanNulls": false,
                        "stacking": {
                            "group": "A",
                            "mode": "none"
                        },
                        "thresholdsStyle": {
                            "mode": "off"
                        }
                    },
                    "mappings": [],
                    "thresholds": {
                        "mode": "absolute",
                        "steps": [
                            {
                                "color": "green",
                                "value": null
                            },
                            {
                                "color": "red",
                                "value": 80
                            }
                        ]
                    }
                },
                "overrides": []
            },
            "gridPos": {
                "h": 8,
                "w": 7,
                "x": 7,
                "y": 9
            },
            "id": 18,
            "options": {
                "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                },
                "tooltip": {
                    "mode": "single",
                    "sort": "none"
                }
            },
            "targets": [
                {
                    "datasource": {
                        "type": "prometheus",
                        "uid": "PBFA97CFB590B2093"
                    },
                    "editorMode": "builder",
                    "expr": "process_runtime_dotnet_thread_pool_completed_items_count",
                    "legendFormat": "{{__name__}}",
                    "range": true,
                    "refId": "A"
                }
            ],
            "title": "TheadPool Completed Items Count",
            "type": "timeseries"
        },
        {
            "datasource": {
                "type": "prometheus",
                "uid": "PBFA97CFB590B2093"
            },
            "fieldConfig": {
                "defaults": {
                    "color": {
                        "mode": "palette-classic"
                    },
                    "custom": {
                        "axisCenteredZero": false,
                        "axisColorMode": "text",
                        "axisLabel": "",
                        "axisPlacement": "auto",
                        "barAlignment": 0,
                        "drawStyle": "line",
                        "fillOpacity": 0,
                        "gradientMode": "none",
                        "hideFrom": {
                            "legend": false,
                            "tooltip": false,
                            "viz": false
                        },
                        "lineInterpolation": "linear",
                        "lineWidth": 1,
                        "pointSize": 5,
                        "scaleDistribution": {
                            "type": "linear"
                        },
                        "showPoints": "auto",
                        "spanNulls": false,
                        "stacking": {
                            "group": "A",
                            "mode": "none"
                        },
                        "thresholdsStyle": {
                            "mode": "off"
                        }
                    },
                    "mappings": [],
                    "thresholds": {
                        "mode": "absolute",
                        "steps": [
                            {
                                "color": "green",
                                "value": null
                            },
                            {
                                "color": "red",
                                "value": 80
                            }
                        ]
                    }
                },
                "overrides": []
            },
            "gridPos": {
                "h": 8,
                "w": 7,
                "x": 14,
                "y": 9
            },
            "id": 20,
            "options": {
                "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                },
                "tooltip": {
                    "mode": "single",
                    "sort": "none"
                }
            },
            "targets": [
                {
                    "datasource": {
                        "type": "prometheus",
                        "uid": "PBFA97CFB590B2093"
                    },
                    "editorMode": "builder",
                    "expr": "process_runtime_dotnet_thread_pool_threads_count",
                    "legendFormat": "{{__name__}}",
                    "range": true,
                    "refId": "A"
                }
            ],
            "title": "ThreadPool Threads Count",
            "type": "timeseries"
        },
        {
            "datasource": {
                "type": "prometheus",
                "uid": "PBFA97CFB590B2093"
            },
            "fieldConfig": {
                "defaults": {
                    "color": {
                        "mode": "palette-classic"
                    },
                    "custom": {
                        "axisCenteredZero": false,
                        "axisColorMode": "text",
                        "axisLabel": "",
                        "axisPlacement": "auto",
                        "barAlignment": 0,
                        "drawStyle": "line",
                        "fillOpacity": 0,
                        "gradientMode": "none",
                        "hideFrom": {
                            "legend": false,
                            "tooltip": false,
                            "viz": false
                        },
                        "lineInterpolation": "linear",
                        "lineWidth": 1,
                        "pointSize": 5,
                        "scaleDistribution": {
                            "type": "linear"
                        },
                        "showPoints": "auto",
                        "spanNulls": false,
                        "stacking": {
                            "group": "A",
                            "mode": "none"
                        },
                        "thresholdsStyle": {
                            "mode": "off"
                        }
                    },
                    "mappings": [],
                    "thresholds": {
                        "mode": "absolute",
                        "steps": [
                            {
                                "color": "green",
                                "value": null
                            },
                            {
                                "color": "red",
                                "value": 80
                            }
                        ]
                    }
                },
                "overrides": []
            },
            "gridPos": {
                "h": 7,
                "w": 7,
                "x": 0,
                "y": 17
            },
            "id": 16,
            "options": {
                "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                },
                "tooltip": {
                    "mode": "single",
                    "sort": "none"
                }
            },
            "targets": [
                {
                    "datasource": {
                        "type": "prometheus",
                        "uid": "PBFA97CFB590B2093"
                    },
                    "editorMode": "code",
                    "expr": "process_runtime_dotnet_thread_pool_queue_length",
                    "legendFormat": "{{__name__}}",
                    "range": true,
                    "refId": "A"
                }
            ],
            "title": "Thread Pool Queue Length",
            "type": "timeseries"
        },
        {
            "datasource": {
                "type": "prometheus",
                "uid": "PBFA97CFB590B2093"
            },
            "fieldConfig": {
                "defaults": {
                    "color": {
                        "mode": "palette-classic"
                    },
                    "custom": {
                        "axisCenteredZero": false,
                        "axisColorMode": "text",
                        "axisLabel": "",
                        "axisPlacement": "auto",
                        "barAlignment": 0,
                        "drawStyle": "line",
                        "fillOpacity": 0,
                        "gradientMode": "none",
                        "hideFrom": {
                            "legend": false,
                            "tooltip": false,
                            "viz": false
                        },
                        "lineInterpolation": "linear",
                        "lineWidth": 1,
                        "pointSize": 5,
                        "scaleDistribution": {
                            "type": "linear"
                        },
                        "showPoints": "auto",
                        "spanNulls": false,
                        "stacking": {
                            "group": "A",
                            "mode": "none"
                        },
                        "thresholdsStyle": {
                            "mode": "off"
                        }
                    },
                    "mappings": [],
                    "thresholds": {
                        "mode": "absolute",
                        "steps": [
                            {
                                "color": "green",
                                "value": null
                            },
                            {
                                "color": "red",
                                "value": 80
                            }
                        ]
                    },
                    "unit": "ns"
                },
                "overrides": []
            },
            "gridPos": {
                "h": 7,
                "w": 7,
                "x": 7,
                "y": 17
            },
            "id": 30,
            "options": {
                "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                },
                "tooltip": {
                    "mode": "single",
                    "sort": "none"
                }
            },
            "targets": [
                {
                    "datasource": {
                        "type": "prometheus",
                        "uid": "PBFA97CFB590B2093"
                    },
                    "editorMode": "code",
                    "expr": "process_runtime_dotnet_jit_compilation_time",
                    "interval": "",
                    "legendFormat": "{{__name__}}",
                    "range": true,
                    "refId": "A"
                }
            ],
            "title": "JIT Compilation Time",
            "type": "timeseries"
        },
        {
            "datasource": {
                "type": "prometheus",
                "uid": "PBFA97CFB590B2093"
            },
            "fieldConfig": {
                "defaults": {
                    "color": {
                        "mode": "palette-classic"
                    },
                    "custom": {
                        "axisCenteredZero": false,
                        "axisColorMode": "text",
                        "axisLabel": "",
                        "axisPlacement": "auto",
                        "barAlignment": 0,
                        "drawStyle": "line",
                        "fillOpacity": 0,
                        "gradientMode": "none",
                        "hideFrom": {
                            "legend": false,
                            "tooltip": false,
                            "viz": false
                        },
                        "lineInterpolation": "linear",
                        "lineWidth": 1,
                        "pointSize": 5,
                        "scaleDistribution": {
                            "type": "linear"
                        },
                        "showPoints": "auto",
                        "spanNulls": false,
                        "stacking": {
                            "group": "A",
                            "mode": "none"
                        },
                        "thresholdsStyle": {
                            "mode": "off"
                        }
                    },
                    "mappings": [],
                    "thresholds": {
                        "mode": "absolute",
                        "steps": [
                            {
                                "color": "green",
                                "value": null
                            }
                        ]
                    },
                    "unit": "decbytes"
                },
                "overrides": []
            },
            "gridPos": {
                "h": 7,
                "w": 7,
                "x": 14,
                "y": 17
            },
            "id": 28,
            "options": {
                "legend": {
                    "calcs": [],
                    "displayMode": "list",
                    "placement": "bottom",
                    "showLegend": true
                },
                "tooltip": {
                    "mode": "single",
                    "sort": "none"
                }
            },
            "pluginVersion": "9.1.3",
            "targets": [
                {
                    "datasource": {
                        "type": "prometheus",
                        "uid": "PBFA97CFB590B2093"
                    },
                    "editorMode": "code",
                    "expr": "process_runtime_dotnet_jit_il_compiled_size",
                    "legendFormat": "{{__name__}}",
                    "range": true,
                    "refId": "A"
                }
            ],
            "title": "JIT IL Compiled Size Bytes",
            "type": "timeseries"
        },
        {
            "datasource": {
                "type": "prometheus",
                "uid": "PBFA97CFB590B2093"
            },
            "fieldConfig": {
                "defaults": {
                    "color": {
                        "mode": "thresholds"
                    },
                    "mappings": [],
                    "thresholds": {
                        "mode": "absolute",
                        "steps": [
                            {
                                "color": "green",
                                "value": null
                            },
                            {
                                "color": "red",
                                "value": 80
                            }
                        ]
                    }
                },
                "overrides": []
            },
            "gridPos": {
                "h": 6,
                "w": 3,
                "x": 0,
                "y": 24
            },
            "id": 26,
            "options": {
                "colorMode": "value",
                "graphMode": "area",
                "justifyMode": "auto",
                "orientation": "auto",
                "reduceOptions": {
                    "calcs": [
                        "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                },
                "textMode": "auto"
            },
            "pluginVersion": "9.4.3",
            "targets": [
                {
                    "datasource": {
                        "type": "prometheus",
                        "uid": "PBFA97CFB590B2093"
                    },
                    "editorMode": "builder",
                    "expr": "process_runtime_dotnet_timer_count",
                    "legendFormat": "__auto",
                    "range": true,
                    "refId": "A"
                }
            ],
            "title": ".NET Timer",
            "type": "stat"
        },
        {
            "datasource": {
                "type": "prometheus",
                "uid": "PBFA97CFB590B2093"
            },
            "fieldConfig": {
                "defaults": {
                    "color": {
                        "mode": "thresholds"
                    },
                    "mappings": [],
                    "thresholds": {
                        "mode": "absolute",
                        "steps": [
                            {
                                "color": "green",
                                "value": null
                            },
                            {
                                "color": "red",
                                "value": 30000
                            }
                        ]
                    }
                },
                "overrides": []
            },
            "gridPos": {
                "h": 6,
                "w": 3,
                "x": 3,
                "y": 24
            },
            "id": 22,
            "options": {
                "colorMode": "value",
                "graphMode": "area",
                "justifyMode": "auto",
                "orientation": "auto",
                "reduceOptions": {
                    "calcs": [
                        "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                },
                "textMode": "auto"
            },
            "pluginVersion": "9.4.3",
            "targets": [
                {
                    "datasource": {
                        "type": "prometheus",
                        "uid": "PBFA97CFB590B2093"
                    },
                    "editorMode": "builder",
                    "expr": "process_runtime_dotnet_jit_methods_compiled_count",
                    "legendFormat": "__auto",
                    "range": true,
                    "refId": "A"
                }
            ],
            "title": "JIT Methods Compiled",
            "type": "stat"
        },
        {
            "datasource": {
                "type": "prometheus",
                "uid": "PBFA97CFB590B2093"
            },
            "fieldConfig": {
                "defaults": {
                    "color": {
                        "mode": "thresholds"
                    },
                    "mappings": [],
                    "thresholds": {
                        "mode": "absolute",
                        "steps": [
                            {
                                "color": "green",
                                "value": null
                            }
                        ]
                    }
                },
                "overrides": []
            },
            "gridPos": {
                "h": 6,
                "w": 3,
                "x": 6,
                "y": 24
            },
            "id": 2,
            "options": {
                "colorMode": "value",
                "graphMode": "area",
                "justifyMode": "auto",
                "orientation": "auto",
                "reduceOptions": {
                    "calcs": [
                        "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                },
                "textMode": "auto"
            },
            "pluginVersion": "9.4.3",
            "targets": [
                {
                    "datasource": {
                        "type": "prometheus",
                        "uid": "PBFA97CFB590B2093"
                    },
                    "editorMode": "builder",
                    "expr": "process_runtime_dotnet_assemblies_count",
                    "legendFormat": "__auto",
                    "range": true,
                    "refId": "A"
                }
            ],
            "title": ".NET Assemblies",
            "type": "stat"
        },
        {
            "datasource": {
                "type": "prometheus",
                "uid": "PBFA97CFB590B2093"
            },
            "fieldConfig": {
                "defaults": {
                    "color": {
                        "mode": "thresholds"
                    },
                    "mappings": [],
                    "thresholds": {
                        "mode": "absolute",
                        "steps": [
                            {
                                "color": "green",
                                "value": null
                            },
                            {
                                "color": "red",
                                "value": 80
                            }
                        ]
                    }
                },
                "overrides": []
            },
            "gridPos": {
                "h": 6,
                "w": 7,
                "x": 9,
                "y": 24
            },
            "id": 8,
            "options": {
                "colorMode": "value",
                "graphMode": "none",
                "justifyMode": "auto",
                "orientation": "auto",
                "reduceOptions": {
                    "calcs": [
                        "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                },
                "textMode": "auto"
            },
            "pluginVersion": "9.4.3",
            "targets": [
                {
                    "datasource": {
                        "type": "prometheus",
                        "uid": "PBFA97CFB590B2093"
                    },
                    "editorMode": "code",
                    "expr": "process_runtime_dotnet_gc_collections_count",
                    "legendFormat": "{{generation}}",
                    "range": true,
                    "refId": "A"
                }
            ],
            "title": "GC Generations Total",
            "type": "stat"
        },
        {
            "datasource": {
                "type": "prometheus",
                "uid": "PBFA97CFB590B2093"
            },
            "fieldConfig": {
                "defaults": {
                    "color": {
                        "mode": "thresholds"
                    },
                    "mappings": [],
                    "thresholds": {
                        "mode": "absolute",
                        "steps": [
                            {
                                "color": "green",
                                "value": null
                            },
                            {
                                "color": "red",
                                "value": 0
                            }
                        ]
                    }
                },
                "overrides": []
            },
            "gridPos": {
                "h": 6,
                "w": 3,
                "x": 16,
                "y": 24
            },
            "id": 4,
            "options": {
                "colorMode": "value",
                "graphMode": "area",
                "justifyMode": "auto",
                "orientation": "auto",
                "reduceOptions": {
                    "calcs": [
                        "lastNotNull"
                    ],
                    "fields": "",
                    "values": false
                },
                "textMode": "auto"
            },
            "pluginVersion": "9.4.3",
            "targets": [
                {
                    "datasource": {
                        "type": "prometheus",
                        "uid": "PBFA97CFB590B2093"
                    },
                    "editorMode": "builder",
                    "expr": "process_runtime_dotnet_exceptions_count",
                    "legendFormat": "__auto",
                    "range": true,
                    "refId": "A"
                }
            ],
            "title": ".NET Exceptions",
            "type": "stat"
        }
    ],
    "refresh": "5s",
    "revision": 1,
    "schemaVersion": 38,
    "style": "dark",
    "tags": [],
    "templating": {
        "list": []
    },
    "time": {
        "from": "now-5m",
        "to": "now"
    },
    "timepicker": {},
    "timezone": "",
    "title": ".NET Performance Counters",
    "uid": "qL76cuMVk",
    "version": 4,
    "weekStart": ""
}`;

	return {
		path: './grafana/dashboards',
		name: 'dotnet-performance-counters-dashboard.json',
		code: code,
	};
};

const getDockerCompose = () => {
	const code = `version: "3.4"
name: dotnet-app

services:
  backend:
    build:
      context: ./src
      dockerfile: Dockerfile
    container_name: application
    ports:
      - "5000:80"
    environment:
      Otlp__Endpoint: http://otel-collector:4317
      Jaeger__Url: jaeger
      Jaeger__Port: 6831
    depends_on:
      - otel-collector
    networks:
      - app_network

  jaeger:
    image: jaegertracing/all-in-one:1.35
    container_name: jaeger
    volumes:
      - "./jaeger-config/jaeger-ui.json:/etc/jaeger/jaeger-ui.json"
    command: --query.ui-config /etc/jaeger/jaeger-ui.json
    ports:
      - "5775:5775/udp"
      - "6831:6831/udp"
      - "6832:6832/udp"
      - "5778:5778"
      - "16686:16686"
      - "14268:14268"
      - "9411:9411"
    environment:
      - COLLECTOR_OTLP_ENABLED=true
      - METRICS_STORAGE_TYPE=prometheus
      - PROMETHEUS_SERVER_URL=http://prometheus:9090
      - LOG_LEVEL=debug
      - PROMETHEUS_QUERY_SUPPORT_SPANMETRICS_CONNECTOR=true
      - PROMETHEUS_QUERY_NAMESPACE=span_metrics
      - PROMETHEUS_QUERY_DURATION_UNIT=s
    networks:
      - app_network

  prometheus:
    image: prom/prometheus:v2.29.2
    volumes:
      - ./prometheus/prometheus.yml:/etc/prometheus/prometheus.yml
    ports:
      - "9090:9090"
    depends_on:
      - backend
    networks:
      - app_network

  otel-collector:
    image: otel/opentelemetry-collector-contrib:0.61.0
    container_name: otel-collector
    volumes:
      - ./otel-collector/otel-collector-config.yml:/etc/otel/config.yaml
    command: --config /etc/otel/config.yaml
    ports:
      - "8888:8888" # Prometheus metrics exposed by the collector
      - "8889:8889" # Prometheus exporter metrics
      - "13133:13133"
      - "4317:4317" # OTLP gRPC receiver
    networks:
      - app_network

  grafana:
    image: grafana/grafana:9.4.3
    depends_on:
      - prometheus
    ports:
      - 3000:3000
    networks:
      - app_network
    volumes:
      - ./grafana/provisioning/:/etc/grafana/provisioning/
      - ./grafana/dashboards/:/var/lib/grafana/dashboards

  seq:
    container_name: seq
    image: datalust/seq:preview
    ports:
      - "5341:80"
      - "5342:5342"
      - "45341:45341"
    environment:
      - ACCEPT_EULA=Y
    networks:
      - app_network

networks:
  app_network:
`;

	return {
		path: '',
		name: 'docker-compose.yaml',
		code: code,
	};
};
