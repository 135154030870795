/* eslint-disable @typescript-eslint/no-explicit-any */
export const getListIcons = (): any[] => {
	const data: any[] = [
		{
			label: 'nenhum',
			value: undefined,
		},
		{
			label: 'activity',
			value: 'activity',
		},
		{
			label: 'airplay',
			value: 'airplay',
		},
		{
			label: 'alert-circle',
			value: 'alert-circle',
		},
		{
			label: 'alert-octagon',
			value: 'alert-octagon',
		},
		{
			label: 'alert-triangle',
			value: 'alert-triangle',
		},
		{
			label: 'align-center',
			value: 'align-center',
		},
		{
			label: 'align-justify',
			value: 'align-justify',
		},
		{
			label: 'align-left',
			value: 'align-left',
		},
		{
			label: 'align-right',
			value: 'align-right',
		},
		{
			label: 'anchor',
			value: 'anchor',
		},
		{
			label: 'aperture',
			value: 'aperture',
		},
		{
			label: 'archive',
			value: 'archive',
		},
		{
			label: 'arrow-down-circle',
			value: 'arrow-down-circle',
		},
		{
			label: 'arrow-down-left',
			value: 'arrow-down-left',
		},
		{
			label: 'arrow-down-right',
			value: 'arrow-down-right',
		},
		{
			label: 'arrow-down',
			value: 'arrow-down',
		},
		{
			label: 'arrow-left-circle',
			value: 'arrow-left-circle',
		},
		{
			label: 'arrow-left',
			value: 'arrow-left',
		},
		{
			label: 'arrow-right-circle',
			value: 'arrow-right-circle',
		},
		{
			label: 'arrow-right',
			value: 'arrow-right',
		},
		{
			label: 'arrow-up-circle',
			value: 'arrow-up-circle',
		},
		{
			label: 'arrow-up-left',
			value: 'arrow-up-left',
		},
		{
			label: 'arrow-up-right',
			value: 'arrow-up-right',
		},
		{
			label: 'arrow-up',
			value: 'arrow-up',
		},
		{
			label: 'at-sign',
			value: 'at-sign',
		},
		{
			label: 'award',
			value: 'award',
		},
		{
			label: 'bar-chart-2',
			value: 'bar-chart-2',
		},
		{
			label: 'bar-chart',
			value: 'bar-chart',
		},
		{
			label: 'battery-charging',
			value: 'battery-charging',
		},
		{
			label: 'battery',
			value: 'battery',
		},
		{
			label: 'bell-off',
			value: 'bell-off',
		},
		{
			label: 'bell',
			value: 'bell',
		},
		{
			label: 'bluetooth',
			value: 'bluetooth',
		},
		{
			label: 'bold',
			value: 'bold',
		},
		{
			label: 'book-open',
			value: 'book-open',
		},
		{
			label: 'book',
			value: 'book',
		},
		{
			label: 'bookmark',
			value: 'bookmark',
		},
		{
			label: 'box',
			value: 'box',
		},
		{
			label: 'briefcase',
			value: 'briefcase',
		},
		{
			label: 'calendar',
			value: 'calendar',
		},
		{
			label: 'camera-off',
			value: 'camera-off',
		},
		{
			label: 'camera',
			value: 'camera',
		},
		{
			label: 'cast',
			value: 'cast',
		},
		{
			label: 'check-circle',
			value: 'check-circle',
		},
		{
			label: 'check-square',
			value: 'check-square',
		},
		{
			label: 'check',
			value: 'check',
		},
		{
			label: 'chevron-down',
			value: 'chevron-down',
		},
		{
			label: 'chevron-left',
			value: 'chevron-left',
		},
		{
			label: 'chevron-right',
			value: 'chevron-right',
		},
		{
			label: 'chevron-up',
			value: 'chevron-up',
		},
		{
			label: 'chevrons-down',
			value: 'chevrons-down',
		},
		{
			label: 'chevrons-left',
			value: 'chevrons-left',
		},
		{
			label: 'chevrons-right',
			value: 'chevrons-right',
		},
		{
			label: 'chevrons-up',
			value: 'chevrons-up',
		},
		{
			label: 'chrome',
			value: 'chrome',
		},
		{
			label: 'circle',
			value: 'circle',
		},
		{
			label: 'clipboard',
			value: 'clipboard',
		},
		{
			label: 'clock',
			value: 'clock',
		},
		{
			label: 'cloud-drizzle',
			value: 'cloud-drizzle',
		},
		{
			label: 'cloud-lightning',
			value: 'cloud-lightning',
		},
		{
			label: 'cloud-off',
			value: 'cloud-off',
		},
		{
			label: 'cloud-rain',
			value: 'cloud-rain',
		},
		{
			label: 'cloud-snow',
			value: 'cloud-snow',
		},
		{
			label: 'cloud',
			value: 'cloud',
		},
		{
			label: 'code',
			value: 'code',
		},
		{
			label: 'codepen',
			value: 'codepen',
		},
		{
			label: 'codesandbox',
			value: 'codesandbox',
		},
		{
			label: 'coffee',
			value: 'coffee',
		},
		{
			label: 'columns',
			value: 'columns',
		},
		{
			label: 'command',
			value: 'command',
		},
		{
			label: 'compass',
			value: 'compass',
		},
		{
			label: 'copy',
			value: 'copy',
		},
		{
			label: 'corner-down-left',
			value: 'corner-down-left',
		},
		{
			label: 'corner-down-right',
			value: 'corner-down-right',
		},
		{
			label: 'corner-left-down',
			value: 'corner-left-down',
		},
		{
			label: 'corner-left-up',
			value: 'corner-left-up',
		},
		{
			label: 'corner-right-down',
			value: 'corner-right-down',
		},
		{
			label: 'corner-right-up',
			value: 'corner-right-up',
		},
		{
			label: 'corner-up-left',
			value: 'corner-up-left',
		},
		{
			label: 'corner-up-right',
			value: 'corner-up-right',
		},
		{
			label: 'cpu',
			value: 'cpu',
		},
		{
			label: 'credit-card',
			value: 'credit-card',
		},
		{
			label: 'crop',
			value: 'crop',
		},
		{
			label: 'crosshair',
			value: 'crosshair',
		},
		{
			label: 'database',
			value: 'database',
		},
		{
			label: 'delete',
			value: 'delete',
		},
		{
			label: 'disc',
			value: 'disc',
		},
		{
			label: 'divide-circle',
			value: 'divide-circle',
		},
		{
			label: 'divide-square',
			value: 'divide-square',
		},
		{
			label: 'divide',
			value: 'divide',
		},
		{
			label: 'dollar-sign',
			value: 'dollar-sign',
		},
		{
			label: 'download-cloud',
			value: 'download-cloud',
		},
		{
			label: 'download',
			value: 'download',
		},
		{
			label: 'dribbble',
			value: 'dribbble',
		},
		{
			label: 'droplet',
			value: 'droplet',
		},
		{
			label: 'edit-2',
			value: 'edit-2',
		},
		{
			label: 'edit-3',
			value: 'edit-3',
		},
		{
			label: 'edit',
			value: 'edit',
		},
		{
			label: 'external-link',
			value: 'external-link',
		},
		{
			label: 'eye-off',
			value: 'eye-off',
		},
		{
			label: 'eye',
			value: 'eye',
		},
		{
			label: 'facebook',
			value: 'facebook',
		},
		{
			label: 'fast-forward',
			value: 'fast-forward',
		},
		{
			label: 'feather',
			value: 'feather',
		},
		{
			label: 'figma',
			value: 'figma',
		},
		{
			label: 'file-minus',
			value: 'file-minus',
		},
		{
			label: 'file-plus',
			value: 'file-plus',
		},
		{
			label: 'file-text',
			value: 'file-text',
		},
		{
			label: 'file',
			value: 'file',
		},
		{
			label: 'film',
			value: 'film',
		},
		{
			label: 'filter',
			value: 'filter',
		},
		{
			label: 'flag',
			value: 'flag',
		},
		{
			label: 'folder-minus',
			value: 'folder-minus',
		},
		{
			label: 'folder-plus',
			value: 'folder-plus',
		},
		{
			label: 'folder',
			value: 'folder',
		},
		{
			label: 'framer',
			value: 'framer',
		},
		{
			label: 'frown',
			value: 'frown',
		},
		{
			label: 'gift',
			value: 'gift',
		},
		{
			label: 'git-branch',
			value: 'git-branch',
		},
		{
			label: 'git-commit',
			value: 'git-commit',
		},
		{
			label: 'git-merge',
			value: 'git-merge',
		},
		{
			label: 'git-pull-request',
			value: 'git-pull-request',
		},
		{
			label: 'github',
			value: 'github',
		},
		{
			label: 'gitlab',
			value: 'gitlab',
		},
		{
			label: 'globe',
			value: 'globe',
		},
		{
			label: 'grid',
			value: 'grid',
		},
		{
			label: 'hard-drive',
			value: 'hard-drive',
		},
		{
			label: 'hash',
			value: 'hash',
		},
		{
			label: 'headphones',
			value: 'headphones',
		},
		{
			label: 'heart',
			value: 'heart',
		},
		{
			label: 'help-circle',
			value: 'help-circle',
		},
		{
			label: 'hexagon',
			value: 'hexagon',
		},
		{
			label: 'home',
			value: 'home',
		},
		{
			label: 'image',
			value: 'image',
		},
		{
			label: 'inbox',
			value: 'inbox',
		},
		{
			label: 'info',
			value: 'info',
		},
		{
			label: 'instagram',
			value: 'instagram',
		},
		{
			label: 'italic',
			value: 'italic',
		},
		{
			label: 'key',
			value: 'key',
		},
		{
			label: 'layers',
			value: 'layers',
		},
		{
			label: 'layout',
			value: 'layout',
		},
		{
			label: 'life-buoy',
			value: 'life-buoy',
		},
		{
			label: 'link-2',
			value: 'link-2',
		},
		{
			label: 'link',
			value: 'link',
		},
		{
			label: 'linkedin',
			value: 'linkedin',
		},
		{
			label: 'list',
			value: 'list',
		},
		{
			label: 'loader',
			value: 'loader',
		},
		{
			label: 'lock',
			value: 'lock',
		},
		{
			label: 'log-in',
			value: 'log-in',
		},
		{
			label: 'log-out',
			value: 'log-out',
		},
		{
			label: 'mail',
			value: 'mail',
		},
		{
			label: 'map-pin',
			value: 'map-pin',
		},
		{
			label: 'map',
			value: 'map',
		},
		{
			label: 'maximize-2',
			value: 'maximize-2',
		},
		{
			label: 'maximize',
			value: 'maximize',
		},
		{
			label: 'meh',
			value: 'meh',
		},
		{
			label: 'menu',
			value: 'menu',
		},
		{
			label: 'message-circle',
			value: 'message-circle',
		},
		{
			label: 'message-square',
			value: 'message-square',
		},
		{
			label: 'mic-off',
			value: 'mic-off',
		},
		{
			label: 'mic',
			value: 'mic',
		},
		{
			label: 'minimize-2',
			value: 'minimize-2',
		},
		{
			label: 'minimize',
			value: 'minimize',
		},
		{
			label: 'minus-circle',
			value: 'minus-circle',
		},
		{
			label: 'minus-square',
			value: 'minus-square',
		},
		{
			label: 'minus',
			value: 'minus',
		},
		{
			label: 'monitor',
			value: 'monitor',
		},
		{
			label: 'moon',
			value: 'moon',
		},
		{
			label: 'more-horizontal',
			value: 'more-horizontal',
		},
		{
			label: 'more-vertical',
			value: 'more-vertical',
		},
		{
			label: 'mouse-pointer',
			value: 'mouse-pointer',
		},
		{
			label: 'move',
			value: 'move',
		},
		{
			label: 'music',
			value: 'music',
		},
		{
			label: 'navigation-2',
			value: 'navigation-2',
		},
		{
			label: 'navigation',
			value: 'navigation',
		},
		{
			label: 'octagon',
			value: 'octagon',
		},
		{
			label: 'package',
			value: 'package',
		},
		{
			label: 'paperclip',
			value: 'paperclip',
		},
		{
			label: 'pause-circle',
			value: 'pause-circle',
		},
		{
			label: 'pause',
			value: 'pause',
		},
		{
			label: 'pen-tool',
			value: 'pen-tool',
		},
		{
			label: 'percent',
			value: 'percent',
		},
		{
			label: 'phone-call',
			value: 'phone-call',
		},
		{
			label: 'phone-forwarded',
			value: 'phone-forwarded',
		},
		{
			label: 'phone-incoming',
			value: 'phone-incoming',
		},
		{
			label: 'phone-missed',
			value: 'phone-missed',
		},
		{
			label: 'phone-off',
			value: 'phone-off',
		},
		{
			label: 'phone-outgoing',
			value: 'phone-outgoing',
		},
		{
			label: 'phone',
			value: 'phone',
		},
		{
			label: 'pie-chart',
			value: 'pie-chart',
		},
		{
			label: 'play-circle',
			value: 'play-circle',
		},
		{
			label: 'play',
			value: 'play',
		},
		{
			label: 'plus-circle',
			value: 'plus-circle',
		},
		{
			label: 'plus-square',
			value: 'plus-square',
		},
		{
			label: 'plus',
			value: 'plus',
		},
		{
			label: 'pocket',
			value: 'pocket',
		},
		{
			label: 'power',
			value: 'power',
		},
		{
			label: 'printer',
			value: 'printer',
		},
		{
			label: 'radio',
			value: 'radio',
		},
		{
			label: 'refresh-ccw',
			value: 'refresh-ccw',
		},
		{
			label: 'refresh-cw',
			value: 'refresh-cw',
		},
		{
			label: 'repeat',
			value: 'repeat',
		},
		{
			label: 'rewind',
			value: 'rewind',
		},
		{
			label: 'rotate-ccw',
			value: 'rotate-ccw',
		},
		{
			label: 'rotate-cw',
			value: 'rotate-cw',
		},
		{
			label: 'rss',
			value: 'rss',
		},
		{
			label: 'save',
			value: 'save',
		},
		{
			label: 'scissors',
			value: 'scissors',
		},
		{
			label: 'search',
			value: 'search',
		},
		{
			label: 'send',
			value: 'send',
		},
		{
			label: 'server',
			value: 'server',
		},
		{
			label: 'settings',
			value: 'settings',
		},
		{
			label: 'share-2',
			value: 'share-2',
		},
		{
			label: 'share',
			value: 'share',
		},
		{
			label: 'shield-off',
			value: 'shield-off',
		},
		{
			label: 'shield',
			value: 'shield',
		},
		{
			label: 'shopping-bag',
			value: 'shopping-bag',
		},
		{
			label: 'shopping-cart',
			value: 'shopping-cart',
		},
		{
			label: 'shuffle',
			value: 'shuffle',
		},
		{
			label: 'sidebar',
			value: 'sidebar',
		},
		{
			label: 'skip-back',
			value: 'skip-back',
		},
		{
			label: 'skip-forward',
			value: 'skip-forward',
		},
		{
			label: 'slack',
			value: 'slack',
		},
		{
			label: 'slash',
			value: 'slash',
		},
		{
			label: 'sliders',
			value: 'sliders',
		},
		{
			label: 'smartphone',
			value: 'smartphone',
		},
		{
			label: 'smile',
			value: 'smile',
		},
		{
			label: 'speaker',
			value: 'speaker',
		},
		{
			label: 'square',
			value: 'square',
		},
		{
			label: 'star',
			value: 'star',
		},
		{
			label: 'stop-circle',
			value: 'stop-circle',
		},
		{
			label: 'sun',
			value: 'sun',
		},
		{
			label: 'sunrise',
			value: 'sunrise',
		},
		{
			label: 'sunset',
			value: 'sunset',
		},
		{
			label: 'table',
			value: 'table',
		},
		{
			label: 'tablet',
			value: 'tablet',
		},
		{
			label: 'tag',
			value: 'tag',
		},
		{
			label: 'target',
			value: 'target',
		},
		{
			label: 'terminal',
			value: 'terminal',
		},
		{
			label: 'thermometer',
			value: 'thermometer',
		},
		{
			label: 'thumbs-down',
			value: 'thumbs-down',
		},
		{
			label: 'thumbs-up',
			value: 'thumbs-up',
		},
		{
			label: 'toggle-left',
			value: 'toggle-left',
		},
		{
			label: 'toggle-right',
			value: 'toggle-right',
		},
		{
			label: 'tool',
			value: 'tool',
		},
		{
			label: 'trash-2',
			value: 'trash-2',
		},
		{
			label: 'trash',
			value: 'trash',
		},
		{
			label: 'trello',
			value: 'trello',
		},
		{
			label: 'trending-down',
			value: 'trending-down',
		},
		{
			label: 'trending-up',
			value: 'trending-up',
		},
		{
			label: 'triangle',
			value: 'triangle',
		},
		{
			label: 'truck',
			value: 'truck',
		},
		{
			label: 'tv',
			value: 'tv',
		},
		{
			label: 'twitch',
			value: 'twitch',
		},
		{
			label: 'twitter',
			value: 'twitter',
		},
		{
			label: 'type',
			value: 'type',
		},
		{
			label: 'umbrella',
			value: 'umbrella',
		},
		{
			label: 'underline',
			value: 'underline',
		},
		{
			label: 'unlock',
			value: 'unlock',
		},
		{
			label: 'upload-cloud',
			value: 'upload-cloud',
		},
		{
			label: 'upload',
			value: 'upload',
		},
		{
			label: 'user-check',
			value: 'user-check',
		},
		{
			label: 'user-minus',
			value: 'user-minus',
		},
		{
			label: 'user-plus',
			value: 'user-plus',
		},
		{
			label: 'user-x',
			value: 'user-x',
		},
		{
			label: 'user',
			value: 'user',
		},
		{
			label: 'users',
			value: 'users',
		},
		{
			label: 'video-off',
			value: 'video-off',
		},
		{
			label: 'video',
			value: 'video',
		},
		{
			label: 'voicemail',
			value: 'voicemail',
		},
		{
			label: 'volume-1',
			value: 'volume-1',
		},
		{
			label: 'volume-2',
			value: 'volume-2',
		},
		{
			label: 'volume-x',
			value: 'volume-x',
		},
		{
			label: 'volume',
			value: 'volume',
		},
		{
			label: 'watch',
			value: 'watch',
		},
		{
			label: 'wifi-off',
			value: 'wifi-off',
		},
		{
			label: 'wifi',
			value: 'wifi',
		},
		{
			label: 'wind',
			value: 'wind',
		},
		{
			label: 'x-circle',
			value: 'x-circle',
		},
		{
			label: 'x-octagon',
			value: 'x-octagon',
		},
		{
			label: 'x-square',
			value: 'x-square',
		},
		{
			label: 'x',
			value: 'x',
		},
		{
			label: 'youtube',
			value: 'youtube',
		},
		{
			label: 'zap-off',
			value: 'zap-off',
		},
		{
			label: 'zap',
			value: 'zap',
		},
		{
			label: 'zoom-in',
			value: 'zoom-in',
		},
		{
			label: 'zoom-out',
			value: 'zoom-out',
		},
	];

	return data;
};
