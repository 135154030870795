/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect} from 'react';
import {IApplication, IComponent} from 'src/@types/app';
import {ComponentComplexEnum, ComponentTypeEnum, ResourceTypeEnum} from 'src/@types/enums';
import {Input, WidthBox, Wrapper} from 'src/shared/components/UI';
import {transformToJsx} from 'src/helpers/methods/transformers';
import SubComponent from '../SubComponent';
import * as S from './styles';
import {MenuRoute} from 'src/shared/components/System/MenuRoute';
import {useNavigate} from 'react-router-dom';
import {ROUTER_PATHS} from 'src/router/router.path';

type Props = {
	application: IApplication;
	widgets: IComponent[];
	setWidgets: (value: IComponent[]) => void;
	pageType?: ResourceTypeEnum;
	resourceWidth: string;
	setResourceWidth: (value: string) => void;
	isMaximazed: boolean;
	showSubcomponents: boolean;
	setShowSubcomponents: (value: boolean) => void;
	setHasSelected: (value: boolean) => void;
	enableHelpLines: boolean;
	setSelectedItem: (value: any) => void;
	setShowEditComplexBtn: (value: boolean) => void;
	showEditComplexBtn: boolean;
	toggleSpecifyModal: (type: ComponentComplexEnum) => void;
	selectedItem: any;
};

const WorkScreen = ({
	application,
	widgets,
	setWidgets,
	pageType = ResourceTypeEnum.Page,
	resourceWidth,
	setResourceWidth,
	isMaximazed,
	showSubcomponents,
	setShowSubcomponents,
	setHasSelected,
	enableHelpLines,
	setSelectedItem,
	setShowEditComplexBtn,
	showEditComplexBtn,
	toggleSpecifyModal,
	selectedItem,
}: Props) => {
	const navigate = useNavigate();

	useEffect(() => {
		setHasSelected(!!widgets.find(x => x.selected));
	}, [widgets]);

	const onSelect = (ref: string) => {
		const newWidgets = [...widgets].map(item => {
			if (item.ref === ref && !item.selected) {
				if (item.type === ComponentTypeEnum.Complex) {
					setShowEditComplexBtn(true);
					setSelectedItem(item.name);
				} else {
					setShowEditComplexBtn(false);
				}
				item.selected = !item.selected;
			} else item.selected = false;
			return item;
		});

		setWidgets(newWidgets);
	};

	if (pageType === ResourceTypeEnum.Page) {
		return (
			<S.Container>
				<Wrapper margin="10px 0 20px 20px">
					<MenuRoute
						useMargin={false}
						pathRoute={[
							{
								name: 'Início',
								action: () => navigate(ROUTER_PATHS.HOME),
							},
							{
								name: 'Projetos',
								action: () => navigate(ROUTER_PATHS.PROJECTS),
							},
							{
								name: application.name ?? '',
								action: () => navigate(ROUTER_PATHS.PROJECT),
							},
							{
								name: application.folders?.find(x => x.selected)?.name ?? undefined,
								action: () => navigate(ROUTER_PATHS.PROJECT),
							},
							{
								name: 'Builder',
							},
						]}
					/>
				</Wrapper>
				<S.Screen expanded={isMaximazed}>
					{(function () {
						if (
							application?.resources?.find(x => x.selected)?.useDefaultLayout ??
							true
						) {
							return (
								<application.template
									application={application}
									pageName={
										application?.resources?.find(x => x.selected)?.name ?? ''
									}>
									{transformToJsx(
										widgets,
										showEditComplexBtn,
										toggleSpecifyModal,
										selectedItem,
										onSelect,
										enableHelpLines,
									)}
								</application.template>
							);
						}

						return (
							<>
								{transformToJsx(
									widgets,
									showEditComplexBtn,
									toggleSpecifyModal,
									selectedItem,
									onSelect,
									enableHelpLines,
								)}
							</>
						);
					})()}
				</S.Screen>
				<SubComponent
					isOpen={showSubcomponents}
					onClose={() => setShowSubcomponents(false)}
				/>
			</S.Container>
		);
	}

	if (pageType === ResourceTypeEnum.Modal) {
		return (
			<S.Container>
				<S.BarTools expanded={isMaximazed}>
					<WidthBox width="30%">
						<Input
							placeholder="Largura do modal"
							value={resourceWidth}
							onChange={setResourceWidth}
							border="1px solid #333"
							background="transparent"
							textColor="#fff"
						/>
					</WidthBox>
				</S.BarTools>
				<S.ModalScreen width={resourceWidth}>
					{transformToJsx(
						widgets,
						showEditComplexBtn,
						toggleSpecifyModal,
						selectedItem,
						onSelect,
						enableHelpLines,
					)}
				</S.ModalScreen>
			</S.Container>
		);
	}

	if (pageType === ResourceTypeEnum.SubComponent) {
		return (
			<S.Container>
				<S.Screen expanded={isMaximazed}>
					<S.Test>
						{transformToJsx(
							widgets,
							showEditComplexBtn,
							toggleSpecifyModal,
							selectedItem,
							onSelect,
							enableHelpLines,
						)}
					</S.Test>
				</S.Screen>

				<SubComponent
					isOpen={showSubcomponents}
					onClose={() => setShowSubcomponents(false)}
				/>
			</S.Container>
		);
	}

	return <></>;
};

export default WorkScreen;
