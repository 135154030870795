import React from 'react';
import * as S from './styles';
import FeatherIcon from 'feather-icons-react';
import {Button, Wrapper} from 'src/shared/components/UI';
import {applicationTheme} from 'src/shared/theme';

type Props = {
	id: number;
	name?: string;
	description?: string;
	benefit?: string;
	icon: string;
	price?: string;
	term: number;
	category?: string;
};

export const MyPlanCard = ({
	id,
	name,
	description,
	benefit,
	icon,
	price,
	term,
	category,
}: Props) => {
	return (
		<S.Content>
			<div className="top">
				<div className="icon">
					<S.CircleAction
						textColor={applicationTheme.gray[25]}
						background={applicationTheme.success[500]}>
						<FeatherIcon icon={icon} size={16} />
					</S.CircleAction>
				</div>

				<div className="plan">
					<span className="category">Ativo</span>
					<span className="name">{name}</span>
				</div>
			</div>

			<div className="footer">
				<Wrapper
					alignItems="center"
					justifyContent="center"
					flexDirection="column"
					gap="0px">
					<Button
						text="Ver detalhes do plano"
						onClick={() => {
							const key: number = id;
							alert(key);
						}}
						background={applicationTheme.brand[700]}
						textColor={applicationTheme.gray[50]}
						fill="auto"
						height="32px"
						leftIcon2="list"
					/>
				</Wrapper>
			</div>
		</S.Content>
	);
};
