import {IOption} from 'src/@types/app';
import {ApiParamTypeEnum} from 'src/@types/enums';

export const ParamTypeList: IOption[] = [
	{
		value: undefined,
		label: 'Selecione...',
	},
	{
		value: ApiParamTypeEnum.STRING,
		label: 'STRING',
	},
	{
		value: ApiParamTypeEnum.NUMBER,
		label: 'NUMBER',
	},
	{
		value: ApiParamTypeEnum.BOOLEAN,
		label: 'BOOLEAN',
	},
	{
		value: ApiParamTypeEnum.OBJECT,
		label: 'OBJECT',
	},
	{
		value: ApiParamTypeEnum.ARRAY,
		label: 'ARRAY',
	},
	{
		value: ApiParamTypeEnum.FILE,
		label: 'FILE',
	},
];
