/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {Api} from './base';
import {ILogin, IToken} from 'src/@types/app';

export class AuthApi {
	async login(data: ILogin): Promise<IToken | null> {
		const result = await Api.post('/auth/login', data);

		if (result.status == 200) {
			return result.data;
		}

		return null;
	}
}
