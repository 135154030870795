/* eslint-disable @typescript-eslint/no-explicit-any */
import {faAdd, faTrash} from '@fortawesome/free-solid-svg-icons';
import React, {useEffect, useState} from 'react';
import {IAction, IApplication, IOption} from 'src/@types/app';
import {ActionTypeEnum} from 'src/@types/enums';
import {Wrapper, Text, WidthBox, Select, Input, Button} from 'src/shared/components/UI';
import {getSelectedEffect, getWidgetsInputAndComplex} from 'src/helpers/methods/app-methods';

type Props = {
	application: IApplication;
	btnAction: IAction | undefined;
	setBtnAction: React.Dispatch<React.SetStateAction<IAction | undefined>>;
};

const SetValue = ({application, btnAction, setBtnAction}: Props) => {
	const [listComponents, setListComponents] = useState<IOption[]>([]);
	const [component, setComponent] = useState<any>();
	const [property, setProperty] = useState('');

	useEffect(() => {
		setListComponents(getWidgetsInputAndComplex(application));
	}, [application]);

	const selectedEffect = () => getSelectedEffect(application);

	if (!selectedEffect()) {
		return (
			<Wrapper>
				<Text text="Nenhuma efeito selecionado" color="#fff" />
			</Wrapper>
		);
	}

	const addItem = () => {
		const data = btnAction?.data ?? [];
		data.push({
			targetRef: component.value,
			componentName: component.label,
			property: property,
		});

		setBtnAction({
			...(btnAction ?? {}),
			actionType: ActionTypeEnum.SetValue,
			data: data,
		});

		setComponent(listComponents.find(x => x.value === undefined));
		setProperty('');
	};

	return (
		<>
			<Wrapper margin="10px 0 0 0">
				<WidthBox width="50%">
					<Select
						value={component}
						options={listComponents}
						onChange={setComponent}
						placeholder="Selecione o componente"
						background="transparent"
						border="1px solid #333"
						textColor="#fff"
					/>
				</WidthBox>
				<WidthBox width="50%">
					<Input
						value={property}
						onChange={setProperty}
						background="transparent"
						border="1px solid #333"
						textColor="#fff"
						placeholder="Propriedade"
						mask="Sem espaço"
					/>
				</WidthBox>
				<Button
					fill="auto"
					icon={faAdd}
					themeStyle="success"
					onClick={addItem}
					disabled={
						!property ||
						property === '' ||
						!component ||
						!component.value ||
						btnAction?.data?.map((x: any) => x.targetRef).includes(component.value)
					}
				/>
			</Wrapper>

			{/* <Grid
				template="1fr 1fr 0.2fr"
				header={['Componente', 'Propriedade']}
				data={btnAction?.data ?? []}
				properties={['componentName', 'property']}
				headerTextColor="#fff"
				dataActions={[
					{
						icon: faTrash,
						color: 'red',
						onClick: item => {
							setBtnAction({
								...(btnAction ?? {}),
								actionType: ActionTypeEnum.SetValue,
								data: [...(btnAction?.data ?? [])].filter(
									x => x.targetRef !== item.targetRef,
								),
							});
						},
					},
				]}
			/> */}
		</>
	);
};

export default SetValue;
