import {IExpression, IExpressionItem, IOption} from 'src/@types/app';
import {ExpressionFunctionEnum} from 'src/@types/enums';
import prettier from 'prettier/standalone';
import {PrettierConfig} from 'src/shared/engine/common/prettier-config';

const CODE_ERROR = 'CODE_ERROR';

export const buildJsCode = (
	expressions: IExpressionItem[],
	fieldOptions: IOption[],
	currentItem?: IExpression,
): string => {
	const data = [...expressions];
	const attr = fieldOptions.find(x => x.value == currentItem?.refField)?.label;
	let code = ``;
	let changedFields = '';

	data.forEach((expression, index) => {
		let isNumber = false;
		if (
			(index > 0 && data[index - 1]?.type === ExpressionFunctionEnum.Arithmetic) ||
			data[index + 1]?.type === ExpressionFunctionEnum.Arithmetic
		)
			isNumber = true;

		if (expression.type === ExpressionFunctionEnum.Field) {
			const item = expression.propName;
			if (isNumber) code += `Number(${item})`;
			else code += `${item}`;
			changedFields += `${item},`;
		} else if (expression.type === ExpressionFunctionEnum.FreeText) {
			if (isNumber) code += `Number('${expression.value}')`;
			else code += `'${expression.value}'`;
		} else if (expression.type === ExpressionFunctionEnum.UpperCase) code += `.toUpperCase()`;
		else if (expression.type === ExpressionFunctionEnum.LowerCase) code += `.toLowerCase()`;
		else if (expression.type === ExpressionFunctionEnum.Arithmetic)
			code += ` ${expression.value} `;
		else if (expression.type === ExpressionFunctionEnum.Concat) code += ` + `;
	});

	const finalCode = `
		useEffect(() => {
			const expData = ${code};
			set${attr}(expData);
		}, [${attr},${changedFields.substring(0, changedFields.length - 1)}]);
			`;

	let prettierResult = '';

	try {
		prettierResult = prettier.format(finalCode, PrettierConfig);
	} catch {
		prettierResult = CODE_ERROR;
	}

	return prettierResult;
};
