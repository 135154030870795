import {applicationTheme} from 'src/shared/theme';
import styled from 'styled-components';

export const ContentLine = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;
`;

export const Line = styled.div`
	height: 0.5px;
	background: ${applicationTheme.gray[300]};
	width: 209px;
`;

export const ContentBtn = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 12px;
	flex-direction: column;
`;
