import {applicationTheme} from 'src/shared/theme';
import styled from 'styled-components';

export const Content = styled.div`
	width: 70%;
`;

export const Tag = styled.div`
	color: ${applicationTheme.gray[900]};
	display: flex;
	font-size: 14px;
	font-weight: 600;
	gap: 10px;
	margin-bottom: 15px;

	.margin {
		margin-top: 12px;
	}
`;

export const Card = styled.div`
	background-color: #fff;
	border-radius: 8px;
	padding: 22px 16px;
`;
