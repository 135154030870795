import React from 'react';
import * as S from './styles';

type PathRouteType = {
	name?: string;
	action?: () => void;
};

type Props = {
	pathRoute: PathRouteType[];
	useMargin?: boolean;
};

export const MenuRoute = ({pathRoute, useMargin = true}: Props) => {
	const pathFilter = pathRoute.filter(pathItem => !!pathItem.name);
	return (
		<S.Content useMargin={useMargin}>
			Você está em:{' '}
			{pathFilter.map((pathItem, index) => {
				if (index === pathFilter.length - 1)
					return (
						<span
							key={pathItem.name}
							onClick={pathItem.action}
							className={pathItem.action ? 'action weight' : 'weight'}>
							{pathItem.name}
						</span>
					);
				else
					return (
						<span
							key={pathItem.name}
							onClick={pathItem.action}
							className={pathItem.action ? 'action' : ''}>
							{pathItem.name} /{' '}
						</span>
					);
			})}
		</S.Content>
	);
};
