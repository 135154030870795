/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {IApplication, IBackDotnetCqrsProject, IResourceApi} from 'src/@types/app';
import {v4 as uuidV4} from 'uuid';
import {generateSln} from '../core/generateSln';
import {generateProject} from '../core/generateProject';
import {packagesConstants, referenceConstants} from './constants';
import {generateApi} from './generateApi';
import {generateApplication} from './generateApplication';
import {generateData} from './generateData';
import {generateDomain} from './generateDomain';
import {generateIoC} from './generateIoC';
import {generateIdentity} from './generateIdentity';
import {generateReadme} from './generateReadme';
import {generateAuth} from './generateAuth';

export const cqrsdddGenerator = (app: IApplication, environmentRef: string): IResourceApi[] => {
	const resourceApi: IResourceApi[] = [];
	const projName = app.name!.replaceAll(' ', '');

	const projectIds = getProjectIds();
	resourceApi.push(generateSln(app, projectIds, projName));
	resourceApi.push(...generateProjs(projName));
	resourceApi.push(...generateApi(app, environmentRef));
	resourceApi.push(...generateApplication(app));
	resourceApi.push(...generateData(app));
	resourceApi.push(...generateDomain(app));
	resourceApi.push(...generateIoC(app));
	resourceApi.push(...generateIdentity(app));
	resourceApi.push(generateReadme(projName));
	if (app.database?.tables?.find(x => x.isUser) && app.userConfiguration?.mapping)
		resourceApi.push(...generateAuth(app, projName));

	return resourceApi;
};

const getProjectIds = (): IBackDotnetCqrsProject => {
	return {
		Api: uuidV4().toString(),
		Application: uuidV4().toString(),
		Data: uuidV4().toString(),
		Domain: uuidV4().toString(),
		Identity: uuidV4().toString(),
		IoC: uuidV4().toString(),
	};
};

const generateProjs = (projName: string): IResourceApi[] => {
	const resource: IResourceApi[] = [];
	resource.push(
		generateProject(
			`${projName}.API`,
			referenceConstants(projName).Api,
			packagesConstants.Api,
			true,
		),
	);

	resource.push(
		generateProject(
			`${projName}.Application`,
			referenceConstants(projName).Application,
			packagesConstants.Application,
		),
	);

	resource.push(
		generateProject(
			`${projName}.CrossCutting.IoC`,
			referenceConstants(projName).IoC,
			packagesConstants.IoC,
		),
	);

	resource.push(generateProject(`${projName}.Domain`, '', ''));

	resource.push(
		generateProject(
			`${projName}.Infra.Data`,
			referenceConstants(projName).Data,
			packagesConstants.Data,
		),
	);

	resource.push(
		generateProject(
			`${projName}.Infra.CrossCutting.Identity`,
			referenceConstants(projName).Identity,
			packagesConstants.Identity,
		),
	);

	return resource;
};
