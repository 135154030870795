import {applicationTheme} from 'src/shared/theme';
import styled from 'styled-components';

export const Content = styled.div`
	margin: 25px;
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;
`;

export const ContentNotice = styled.div`
	display: flex;
	gap: 25px;
	flex-wrap: wrap;
	flex-direction: row;
	margin-bottom: 25px;
`;

export const Title = styled.div`
	font-family: Inter;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
	color: ${applicationTheme.gray[700]};
	margin-bottom: 16px;
`;
