import React, {useContext, useState} from 'react';
import {SizedBox, Switch, Wrapper} from 'src/shared/components/UI';
import {DiagramConfigType} from '..';
import {ApplicationContext} from 'src/context';
import * as S from './styles';
import {TreeView} from '@mui/x-tree-view/TreeView';
import {styled} from '@mui/material/styles';
import {TreeItem, TreeItemProps, treeItemClasses} from '@mui/x-tree-view/TreeItem';
import {Box, Typography} from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import {IDatabaseConfig, ITable} from 'src/@types/app';

type Props = {
	configs: DiagramConfigType;
	setConfigs: (value: DiagramConfigType) => void;
};

type StyledTreeItemProps = TreeItemProps & {
	labelIcon?: JSX.Element;
};

const StyledTreeItemLabel = styled(Typography)({
	fontSize: '14px',
	fontFamily: 'Inter',
}) as unknown as typeof Typography;

export const AddBarRender = ({configs, setConfigs}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);
	const [showAll, setShowAll] = useState(false);

	const StyledTreeItemRoot = styled(TreeItem)(({}) => ({
		[`& .${treeItemClasses.iconContainer}`]: {
			'& .close': {
				opacity: 0.3,
			},
		},
		[`& .${treeItemClasses.group}`]: {
			marginLeft: 1,
			paddingLeft: 15,
		},
	}));

	const StyledTreeItem = React.forwardRef(function StyledTreeItem(
		props: StyledTreeItemProps,
		ref: React.Ref<HTMLLIElement>,
	) {
		const {labelIcon, label, ...other} = props;

		return (
			<StyledTreeItemRoot
				label={
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
						}}>
						<StyledTreeItemLabel variant="body2">{label}</StyledTreeItemLabel>
						<Wrapper justifyContent="end">{labelIcon}</Wrapper>
					</Box>
				}
				{...other}
				ref={ref}
			/>
		);
	});

	const selectTable = (table: ITable) => {
		setApplication({
			...application,
			database: {
				...application.database,
				tables: [...(application.database?.tables ?? [])].map(tb => {
					if (tb.ref === table.ref) tb.selected = true;
					else tb.selected = false;
					return tb;
				}),
			},
		});
	};

	const selectDatabase = (db: IDatabaseConfig) => {
		setApplication({
			...application,
			database: {
				...application.database,
				databases: [...(application.database?.databases ?? [])].map(x => {
					if (x.ref == db.ref) x.selected = true;
					else x.selected = false;
					return x;
				}),
				tables: [...(application.database?.tables ?? [])].map(tb => {
					tb.selected = false;
					return tb;
				}),
			},
		});
	};

	const getName = (name?: string) => {
		if (!name) return '';

		if (name.length > 20) {
			return name.substring(0, 20) + '...';
		}
		return name;
	};

	const custonIconView = (table: ITable, handleClick: () => void) => {
		const icon = configs.columnsEnable.find(x => x.ref == table.ref)?.show ? 'eye' : 'eye-off';
		return (
			<div
				className="cont"
				onClick={e => {
					e.stopPropagation();
					handleClick();
				}}>
				<FeatherIcon icon={icon} className="labelIcon" size={20} />
			</div>
		);
	};

	const handleViewTable = (table: ITable) => {
		setConfigs({
			...configs,
			columnsEnable: configs.columnsEnable.map(x => {
				if (x.ref == table.ref) x.show = !x.show;
				return x;
			}),
		});
	};

	const custonIconViewTable = () => {
		const icon = showAll ? 'eye' : 'eye-off';
		return (
			<div
				className="cont"
				onClick={e => {
					e.stopPropagation();
					setConfigs({
						...configs,
						columnsEnable: configs.columnsEnable.map(x => {
							x.show = !showAll;
							return x;
						}),
					});
					setShowAll(!showAll);
				}}>
				<FeatherIcon icon={icon} className="labelIcon" size={20} />
			</div>
		);
	};

	return (
		<S.Content>
			<Switch
				isOn={configs?.showColumns}
				handleToggle={() => setConfigs({...configs, showColumns: !configs?.showColumns})}
				text="Exibir colunas"
				textPosition="right"
			/>
			<SizedBox height="15px" />
			<TreeView defaultExpanded={['1']} sx={{overflowX: 'hidden'}}>
				<StyledTreeItem
					nodeId="0"
					label="Banco"
					icon={<FeatherIcon icon="hard-drive" className="icon" />}>
					{application.database?.databases?.map((database, index) => (
						<>
							<StyledTreeItem
								nodeId={`db-${index}`}
								label={database.name}
								icon={<FeatherIcon icon="database" className="icon" />}
								onClick={() => selectDatabase(database)}>
								<StyledTreeItem
									nodeId={`folder-table-${index}`}
									label="Tabelas"
									icon={<FeatherIcon icon="folder" className="icon" />}
									labelIcon={custonIconViewTable()}>
									{application.database?.tables
										?.filter(table => table.databaseRef === database.ref)
										.map((table, tbIndex) => (
											<>
												<StyledTreeItem
													nodeId={`table-${index}-${tbIndex}`}
													label={getName(`${table.schema}.${table.name}`)}
													onClick={() => selectTable(table)}
													icon={
														<FeatherIcon
															icon="table"
															className="icon"
														/>
													}
													labelIcon={custonIconView(table, () =>
														handleViewTable(table),
													)}
												/>
											</>
										))}
								</StyledTreeItem>
							</StyledTreeItem>
						</>
					))}
				</StyledTreeItem>
			</TreeView>
		</S.Content>
	);
};
