import styled from 'styled-components';

export const NoContent = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px 0 0 0;
	color: black;
`;

export const LineContent = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 10px;
`;

export const Line = styled.div`
	width: 1px;
	background: #fff;
	height: 30px;
	margin: 0 10px;
`;

type LabelProps = {
	color: string;
};
export const Label = styled.div<LabelProps>`
	width: 100%;
	padding-left: 5%;
	color: ${props => props.color};
	font-weight: bold;
`;
