import {IOption} from 'src/@types/app';
import {WorkFlowTargetEnum} from 'src/@types/enums';

export const WorkFlowTargetList: IOption[] = [
	{
		label: 'Selecione...',
		value: undefined,
	},
	{
		label: 'Inserir',
		value: WorkFlowTargetEnum.Insert,
	},
	{
		label: 'Alterar',
		value: WorkFlowTargetEnum.Update,
	},
	{
		label: 'Excluir',
		value: WorkFlowTargetEnum.Delete,
	},
];
