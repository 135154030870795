import React, {useContext} from 'react';
import * as S from './styles';
import {Wrapper} from 'src/shared/components/UI';
import ApplicationContext from 'src/context/ApplicationContext';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import {SystemInput} from 'src/shared/components/System/SystemInput';
import {RepositoryTypeList} from 'src/helpers/select-data/repositoryTypeList';

export const RenderVersioning = () => {
	const [data, setData] = useContext(ApplicationContext);

	return (
		<S.Content>
			<S.Tag>
				<div className="text">Dados referentes ao repositório</div>
			</S.Tag>
			<S.Card>
				<Wrapper>
					<SystemSelect
						width="35%"
						value={RepositoryTypeList.find(x => x.value === data?.git?.type)}
						options={RepositoryTypeList}
						onChange={val =>
							setData({
								...data,
								git: {...(data?.git ?? {}), type: val.value},
							})
						}
						label="Tipo"
					/>

					<SystemInput
						width="65%"
						value={data?.git?.url}
						onChange={val =>
							setData({
								...data,
								git: {...(data?.git ?? {}), url: val},
							})
						}
						label="URL"
					/>
				</Wrapper>

				<Wrapper margin="15px 0 0 0">
					<SystemInput
						value={data?.git?.repositoryKey}
						onChange={val =>
							setData({
								...data,
								git: {...(data?.git ?? {}), repositoryKey: val},
							})
						}
						label="Chave"
						disabled={!!data?.git?.user || !!data?.git?.password}
					/>
				</Wrapper>

				<Wrapper margin="15px 0 0 0">
					<SystemInput
						value={data?.git?.user}
						onChange={val =>
							setData({
								...data,
								git: {...(data?.git ?? {}), user: val},
							})
						}
						label={data?.git?.url ? 'Usuário*' : 'Usuário'}
						disabled={!!data?.git?.repositoryKey}
					/>
					<SystemInput
						value={data?.git?.password}
						onChange={val =>
							setData({
								...data,
								git: {...(data?.git ?? {}), password: val},
							})
						}
						label={data?.git?.url ? 'Senha*' : 'Senha'}
						type="password"
						disabled={!!data?.git?.repositoryKey}
					/>
				</Wrapper>
			</S.Card>
		</S.Content>
	);
};
