import {removeDiacritics} from './removeDiacritics';

export const upperFirstWord = (word: string): string => {
	if (!word) return '';
	return word[0].toUpperCase() + word.substring(1);
};

export const lowerFirstWord = (word: string): string => {
	if (!word) return '';
	return word[0].toLocaleLowerCase() + word.substring(1);
};

export const replaceAccents = (word: string): string => {
	let r = word;
	r = r.replace(new RegExp(/\s/g), '');
	r = r.replace(new RegExp(/[àáâãäå]/g), 'a');
	r = r.replace(new RegExp(/æ/g), 'ae');
	r = r.replace(new RegExp(/ç/g), 'c');
	r = r.replace(new RegExp(/[èéêë]/g), 'e');
	r = r.replace(new RegExp(/[ìíîï]/g), 'i');
	r = r.replace(new RegExp(/ñ/g), 'n');
	r = r.replace(new RegExp(/[òóôõö]/g), 'o');
	r = r.replace(new RegExp(/œ/g), 'oe');
	r = r.replace(new RegExp(/[ùúûü]/g), 'u');
	r = r.replace(new RegExp(/[ýÿ]/g), 'y');
	r = r.replace(new RegExp(/\W/g), '');
	return r;
};

export const substringText = (value: string, cut = 1): string => {
	return value.substring(0, value.length - cut);
};

const formatPropertyName = (name: string): string => {
	let nameFormat = '';

	removeDiacritics(name)
		.split('')
		.forEach((letter, idx) => {
			if (idx == 0) nameFormat += letter;
			else {
				if (letter == letter.toUpperCase()) nameFormat += `_${letter}`;
				else nameFormat += letter;
			}
		});

	const nameSplited = nameFormat.split('_').map(item => {
		return upperFirstWord(item.toLocaleLowerCase());
	});

	return nameSplited.join(' ');
};

export const formatColumnLabelName = (name: string): string => {
	name = formatPropertyName(name);
	let newName = name.replace('Cod', 'Código');
	newName = newName.replace('Dt', 'Data');
	newName = newName.replace('Nm', 'Nome');
	newName = newName.replace('Ident', 'Identificação');
	newName = newName.replace('Obs', 'Observação');
	newName = newName.replace('Hr', 'Hora');
	newName = newName.replace('Nr', 'Número');
	newName = newName.substring(0, 2).toUpperCase() == 'ID' ? newName.substring(2) : newName;

	return newName;
};
