import {IOption} from 'src/@types/app';
import {ValidationTypeEnum} from 'src/@types/enums';

export const ValidationList: IOption[] = [
	{
		value: ValidationTypeEnum.Required,
		label: 'Required',
		custom: {
			value: 'Este campo é obrigatório',
		},
	},
	{
		value: ValidationTypeEnum.MinLength,
		label: 'MinLength',
		custom: {
			value: 'Mínimo de {0} caracteres',
		},
	},
	{
		value: ValidationTypeEnum.MaxLength,
		label: 'MaxLength',
		custom: {
			value: 'Máximo de {0} caracteres',
		},
	},
	{
		value: ValidationTypeEnum.MinValue,
		label: 'MinValue',
		custom: {
			value: 'O valor mínimo é {0}',
		},
	},
	{
		value: ValidationTypeEnum.MaxValue,
		label: 'MaxValue',
		custom: {
			value: 'O valor máximo é {0}',
		},
	},
	{
		value: ValidationTypeEnum.GreatThan,
		label: 'GreatThan',
		custom: {
			value: 'Maior que {0}',
		},
	},
	{
		value: ValidationTypeEnum.LessThan,
		label: 'LessThan',
		custom: {
			value: 'Menor que {0}',
		},
	},
	{
		value: ValidationTypeEnum.Email,
		label: 'Email',
		custom: {
			value: 'E-mail inválido',
		},
	},
	{
		value: ValidationTypeEnum.CnpjCpf,
		label: 'CnpjCpf',
		custom: {
			value: 'Cpf/Cnpj inválido',
		},
	},
	{
		value: ValidationTypeEnum.Between,
		label: 'Entre',
		custom: {
			value: 'A data deve estar entre {0} e {1}',
		},
	},
	{
		value: ValidationTypeEnum.Url,
		label: 'Url',
		custom: {
			value: 'URL inválida',
		},
	},
];
