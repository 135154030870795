import React from 'react';
import {IWorkFlowItem} from 'src/@types/app';
import {Button, SizedBox, Wrapper} from 'src/shared/components/UI';
import {v4 as uuidV4} from 'uuid';
import {WorkFlowDataTypeEnum, WorkFlowTypeEnum} from 'src/@types/enums';
import {applicationTheme} from 'src/shared/theme';
import {buildWorkflowSub} from '../helper';

type Props = {
	item: IWorkFlowItem;
	workFlowItem: IWorkFlowItem[];
	setWorkFlowItem: (value: IWorkFlowItem[]) => void;
};

export const IfElseComp = ({item, workFlowItem, setWorkFlowItem}: Props) => {
	const addItemPosition = () => {
		const items = [...workFlowItem];
		items.push({
			ref: uuidV4().toString(),
			text: 'Titulo',
			refItem: item.ref,
			type: WorkFlowTypeEnum.Variable,
		});

		setWorkFlowItem(items);
	};

	return (
		<Wrapper alignItems="center" justifyContent="center" flexDirection="column" gap="0px">
			{buildWorkflowSub(
				workFlowItem,
				setWorkFlowItem,
				addItemPosition,
				item.ref ?? '',
				WorkFlowDataTypeEnum.Data,
			)}

			<SizedBox height="12px" />
			<Button
				text="Adicionar recurso"
				leftIcon2="plus-square"
				onClick={() => {
					const data = [...workFlowItem];

					data.push({
						ref: uuidV4().toString(),
						text: 'Titulo',
						type: WorkFlowTypeEnum.Variable,
						refItem: item.ref,
					});

					setWorkFlowItem(data);
				}}
				background={applicationTheme.brand[50]}
				textColor={applicationTheme.brand[600]}
				fill="auto"
				height="35px"
			/>
		</Wrapper>
	);
};
