import {IOption} from 'src/@types/app';
import {ApiMethods} from 'src/@types/enums';

export const ApiMethodsList: IOption[] = [
	{
		value: undefined,
		label: 'Selecione...',
	},
	{
		value: ApiMethods.GET,
		label: 'GET',
	},
	{
		value: ApiMethods.POST,
		label: 'POST',
	},
	{
		value: ApiMethods.PUT,
		label: 'PUT',
	},
	{
		value: ApiMethods.DELETE,
		label: 'DELETE',
	},
];
