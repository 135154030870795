/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {useContext} from 'react';
import {IOption, IWorkFlowItem} from 'src/@types/app';
import {Button, Line, SizedBox, WidthBox, Wrapper} from 'src/shared/components/UI';
import {buildWorkflowSub, setWorkFlowValues} from '../helper';
import * as S from '../styles';
import {v4 as uuidV4} from 'uuid';
import {WorkFlowDataTypeEnum, WorkFlowTypeEnum} from 'src/@types/enums';
import {applicationTheme} from 'src/shared/theme';
import {ApplicationContext} from 'src/context';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import {SystemInput} from 'src/shared/components/System/SystemInput';

type Props = {
	item: IWorkFlowItem;
	workFlowItem: IWorkFlowItem[];
	setWorkFlowItem: (value: IWorkFlowItem[]) => void;
};

export const AmqpComp = ({item, workFlowItem, setWorkFlowItem}: Props) => {
	const [application] = useContext(ApplicationContext);

	const getAmqpData = (): IOption[] =>
		(application.amqps ?? []).map(item => ({
			label: `${item.host}`,
			value: item.ref,
		}));

	const addItemPosition = () => {
		const items = [...workFlowItem];
		items.push({
			ref: uuidV4().toString(),
			text: 'Titulo',
			refItem: item.ref,
			type: WorkFlowTypeEnum.Variable,
		});

		setWorkFlowItem(items);
	};

	return (
		<S.ContentBasic>
			<SystemSelect
				value={getAmqpData().find(x => x.value === item.values?.amqp)}
				options={getAmqpData()}
				onChange={value =>
					setWorkFlowValues(workFlowItem, setWorkFlowItem, item.ref!, 'amqp', value.value)
				}
				label="AMQP"
				inputType="minimal"
			/>
			<Wrapper>
				<SystemInput
					width="50%"
					value={item.values?.exchange}
					onChange={val =>
						setWorkFlowValues(workFlowItem, setWorkFlowItem, item.ref!, 'exchange', val)
					}
					inputType="minimal"
					label="Exchange"
				/>
				<SystemInput
					width="50%"
					value={item.values?.queue}
					onChange={val =>
						setWorkFlowValues(workFlowItem, setWorkFlowItem, item.ref!, 'queue', val)
					}
					inputType="minimal"
					label="Queue"
				/>
			</Wrapper>

			<WidthBox margin="10px 0">
				<Line height="0.5px" />
			</WidthBox>

			<Wrapper alignItems="center" justifyContent="center" flexDirection="column" gap="0px">
				{buildWorkflowSub(
					workFlowItem,
					setWorkFlowItem,
					addItemPosition,
					item.ref ?? '',
					WorkFlowDataTypeEnum.Data,
				)}

				<SizedBox height="12px" />
				<Button
					text="Adicionar recurso"
					leftIcon2="plus-square"
					onClick={() => {
						const data = [...workFlowItem];

						data.push({
							ref: uuidV4().toString(),
							text: 'Titulo',
							type: WorkFlowTypeEnum.Variable,
							refItem: item.ref,
						});

						setWorkFlowItem(data);
					}}
					background={applicationTheme.brand[50]}
					textColor={applicationTheme.brand[600]}
					fill="auto"
					height="35px"
				/>
			</Wrapper>
		</S.ContentBasic>
	);
};
