import {IOption} from 'src/@types/app';

export const RepositoryTypeList: IOption[] = [
	{
		label: 'Nenhum',
		value: 'Nenhum',
	},
	{
		label: 'Azure devops',
		value: 'Azure devops',
	},
	{
		label: 'Bitbucket',
		value: 'Bitbucket',
	},
	{
		label: 'GitHub',
		value: 'GitHub',
	},
];
