import {removeDiacritics} from 'src/helpers/methods/removeDiacritics';
import {upperFirstWord} from 'src/helpers/methods/text-methods';

export const backFormatPropertyName = (name: string, isTableKey = false): string => {
	if (isTableKey) return 'Id';
	const nameFormat = removeDiacritics(name).toUpperCase();
	const nameSplited = nameFormat.split('_').map(item => {
		return upperFirstWord(item.toLocaleLowerCase());
	});

	return nameSplited.join('');
};
