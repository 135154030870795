/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {IAction, IActionItem, IApplication, IComponent, IOption} from 'src/@types/app';
import {ActionTypeEnum, ComponentComplexEnum} from 'src/@types/enums';
import {DataInputComponent} from 'src/shared/components';
import {Input, Select, SizedBox, Switch, WidthBox} from 'src/shared/components/UI';
import LocalDataSelect from 'src/shared/components/UI/LocalDataSelect';
import {getWidthByRef} from 'src/helpers/methods/app-methods';
import * as S from './styles';
import {CreateDataGridColumnType} from 'src/pages/builder/Specify/subpages/DataGrid';

type Props = {
	application: IApplication;
	btnAction: IAction | undefined;
	setBtnAction: React.Dispatch<React.SetStateAction<IAction | undefined>>;
};

const TypeGrid = ({application, btnAction, setBtnAction}: Props) => {
	const [grids, setGrids] = useState<IOption[]>();
	const [inputs, setInputs] = useState<IOption[]>();
	const [selectedGrid, setSelectedGrid] = useState<IComponent | undefined>();

	useEffect(() => {
		const gridsM = application?.resources
			?.find((x: any) => x.selected)
			?.widgets?.filter((x: any) => x.name === ComponentComplexEnum.DataGrid);

		const inputsM = application?.resources
			?.find((x: any) => x.selected)
			?.widgets?.filter((x: any) => DataInputComponent.includes(x.name));

		setGrids(
			gridsM?.map(x => {
				return {
					value: x,
					label: x.properties?.name,
				};
			}),
		);

		setInputs(
			inputsM?.map(x => {
				return {
					value: x,
					label: x.properties?.name,
				};
			}),
		);
	}, []);

	useEffect(() => {
		autoSet();
	}, [inputs, selectedGrid]);

	useEffect(() => {
		if (btnAction && !selectedGrid) {
			const selectedGridM = getWidthByRef(application, btnAction.targetRef);
			setSelectedGrid(selectedGridM);
		}
	}, [grids]);

	const autoSet = () => {
		if (!selectedGrid) return;
		const item = {...btnAction} as IAction;

		item.items = item.items?.map((x: any) => {
			inputs?.forEach(input => {
				if (x.propertyName?.toUpperCase() === input.label?.toUpperCase())
					x.componentRef = input.value.ref;
			});

			return x;
		});

		setBtnAction(item);
	};

	const render = (property: string, index: number) => {
		const action = btnAction?.items?.find(x => x.propertyName == property);

		return (
			<S.LineContent key={index}>
				<WidthBox width="50%">
					<S.Label color={'#000'}>{property}</S.Label>
				</WidthBox>
				<S.Line />
				<WidthBox width="50%">
					{btnAction?.data?.useData ? (
						<Input
							value={
								btnAction?.data?.props
									? btnAction?.data?.props[property]
									: undefined
							}
							onChange={val => {
								setBtnAction({
									...btnAction,
									actionType: ActionTypeEnum.DataGrid,
									data: {
										...(btnAction?.data ?? {}),
										props: {
											...(btnAction?.data?.props ?? {}),
											[property]: val,
										},
									},
								});
							}}
							placeholder="Propriedade"
							background="transparent"
							border="1px solid #333"
							textColor={'#000'}
							mask="Sem espaço"
						/>
					) : (
						<Select
							value={inputs?.find(x => x.value.ref === action?.componentRef)}
							options={inputs as any}
							onChange={value => {
								const item = {...btnAction} as IAction;
								item.items = item.items?.map(x => {
									if (x.propertyName === property)
										x.componentRef = value.value.ref;
									return x;
								});
								setBtnAction(item);
							}}
							background="transparent"
							border="1px solid #333"
							textColor={'#000'}
						/>
					)}
				</WidthBox>
			</S.LineContent>
		);
	};

	const save = (value: any) => {
		setSelectedGrid(value.value);
		const properties: string[] = value.value.properties.dataColumns.map(
			(x: CreateDataGridColumnType) => x.field,
		);
		const actionItems = properties.map(item => {
			return {
				propertyName: item,
			} as IActionItem;
		});

		setBtnAction({
			...btnAction,
			targetRef: value.value.ref,
			actionType: ActionTypeEnum.DataGrid,
			items: actionItems,
		} as IAction);
	};

	if (!grids || grids.length === 0) {
		return <S.NoContent>Nenhuma Grid foi encontrada na página</S.NoContent>;
	}
	if (!inputs || inputs.length === 0) {
		return <S.NoContent>Nenhum input de dados foi encontrado</S.NoContent>;
	}

	return (
		<>
			<SizedBox height="12px" />
			<Select
				value={grids.find(x => x.value.ref === selectedGrid?.ref)}
				options={grids}
				onChange={save}
				placeholder="Selecione a grid"
				background="transparent"
				border="1px solid #333"
				textColor={'#000'}
			/>
			<SizedBox height="12px" />

			{(function () {
				if (selectedGrid) {
					const properties: string[] = selectedGrid.properties.dataColumns
						.filter((x: CreateDataGridColumnType) => x.columnType === 1)
						.map((x: CreateDataGridColumnType) => x.field);

					return (
						<>
							<Switch
								text="Usar dados"
								textColor={'#000'}
								isOn={btnAction?.data?.useData ?? false}
								handleToggle={() => {
									setBtnAction({
										...btnAction,
										actionType: ActionTypeEnum.DataGrid,
										data: {
											...(btnAction?.data ?? {}),
											useData: !btnAction?.data?.useData ?? false,
										},
									});
								}}
							/>
							<SizedBox height="10px" />

							{btnAction?.data?.useData ? (
								<>
									<LocalDataSelect
										localData={btnAction?.data?.apiData}
										setLocalData={val => {
											setBtnAction({
												...btnAction,
												actionType: ActionTypeEnum.DataGrid,
												data: {
													...(btnAction?.data ?? {}),
													apiData: val,
												},
											});
										}}
									/>
									<SizedBox height="12px" />
								</>
							) : (
								<></>
							)}

							{properties?.map((item, index) => render(item, index))}
						</>
					);
				}
			})()}
		</>
	);
};

export default TypeGrid;
