import React, {useContext, useEffect, useState} from 'react';
import {Modal} from 'src/shared/components/Complex';
import {Button, IconButton, SizedBox, Wrapper} from 'src/shared/components/UI';
import {ApplicationContext} from 'src/context';
import {faClose, faFloppyDisk} from '@fortawesome/free-solid-svg-icons';
import {IWorkFlowItem} from 'src/@types/app';
import {v4 as uuidV4} from 'uuid';
import {WorkFlowDataTypeEnum, WorkFlowTypeEnum} from 'src/@types/enums';
import {applicationTheme} from 'src/shared/theme';
import {buildWorkFlow} from 'src/helpers/workflow/helper';

type Props = {
	isOpen: boolean;
	onClose: () => void;
	actionRef: string;
};

export const InputWorkFlowModal = ({isOpen, onClose, actionRef}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);

	const [workflowData, setWorkflowData] = useState<IWorkFlowItem[]>([]);

	useEffect(() => {
		setWorkflowData(
			getSelectedWidget()?.inputActions?.find(x => x.ref == actionRef)?.workflow ?? [],
		);
	}, [isOpen]);

	const defaultItem: IWorkFlowItem = {
		ref: uuidV4().toString(),
		text: 'Titulo',
		type: WorkFlowTypeEnum.Default,
	};

	const getSelectedWidget = () => {
		return application.resources?.find(x => x.selected)?.widgets?.find(x => x.selected);
	};

	const addItem = () => {
		const items = [...workflowData];
		items.push(defaultItem);
		setWorkflowData(items);
	};

	const addItemPosition = (position: number) => {
		const items = [...workflowData];
		items.splice(position + 1, 0, defaultItem);
		setWorkflowData(items);
	};

	const close = () => {
		onClose();
	};

	const save = () => {
		setApplication({
			...application,
			resources: [...(application.resources ?? [])].map(resource => {
				resource.widgets = [...(resource.widgets ?? [])].map(widget => {
					if (widget.ref === getSelectedWidget()?.ref) {
						widget.inputActions = [...(widget.inputActions ?? [])].map(x => {
							if (x.ref === actionRef) {
								x.workflow = workflowData;
							}
							return x;
						});
					}
					return widget;
				});
				return resource;
			}),
		});
		close();
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={close}
			width="90%"
			maxWidth="1800px"
			maxHeight="100vh"
			height="87vh">
			<Wrapper width="100%" flexDirection="column">
				<Wrapper alignItems="center" justifyContent="space-between">
					<Wrapper justifyContent="end">
						<IconButton
							icon={faFloppyDisk}
							onClick={save}
							color={applicationTheme.brand[700]}
						/>
						<IconButton icon={faClose} onClick={close} />
					</Wrapper>
				</Wrapper>

				<Wrapper justifyContent="center" flexDirection="column" gap="0px">
					{buildWorkFlow(
						workflowData,
						setWorkflowData,
						addItemPosition,
						WorkFlowDataTypeEnum.Input,
					)}
					<SizedBox height="20px" />
					<Button
						text="Adicionar nova regra"
						leftIcon2="plus-square"
						onClick={addItem}
						background={applicationTheme.brand[50]}
						textColor={applicationTheme.brand[600]}
						fill="auto"
					/>
				</Wrapper>
				<SizedBox height="120px" />
			</Wrapper>
		</Modal>
	);
};
