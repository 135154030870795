/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {IApplication, ICustomMapVars, IResourceApi} from 'src/@types/app';
import {dotnetFormatScreenName, dotnetSchemaName} from '../core/dotnet-formatter';
import {mapToDotnet} from 'src/helpers/methods/map-type-database';
import {upperFirstWord} from 'src/helpers/methods/text-methods';

export const generateIdentity = (app: IApplication): IResourceApi[] => {
	const resources: IResourceApi[] = [];
	const projName = app.name!.replaceAll(' ', '');

	resources.push(...getConstants(app, projName));
	resources.push(...getInterfaces(app, projName));
	resources.push(...getModels(app, projName));
	resources.push(...getServices(app, projName));

	return resources;
};

const getPropNameFromCustom = (customMap: ICustomMapVars[]): string => {
	const lastItem = customMap[customMap.length - 1];
	const columnName = dotnetFormatScreenName(lastItem.column?.label ?? '');
	const tableName = dotnetFormatScreenName(lastItem.table?.label ?? '');
	return tableName + columnName;
};

const getPropNameFromCustomSub = (app: IApplication, customMap: ICustomMapVars[]): string => {
	let code = '';
	customMap.forEach((item, index) => {
		if (customMap.length === index + 1) {
			const isPrimaryKey = app.database?.tables
				?.find(y => y.ref === item.table?.value)
				?.columns?.find(y => y.ref === item.column?.value)?.isPrimaryKey;

			code +=
				dotnetFormatScreenName(item.table?.label ?? '') +
				'.' +
				(isPrimaryKey ? 'Id' : dotnetFormatScreenName(item.column?.label ?? ''));
		} else code += dotnetFormatScreenName(item.table?.label ?? '') + '.';
	});
	return code;
};

const getConstants = (app: IApplication, projName: string): IResourceApi[] => {
	const resources: IResourceApi[] = [];
	const defualtCode = `/// <summary>
  /// Armazena o ID do usuário
  /// </summary>
  public const string USER_ID = "application_user_id";`;

	let customCode = '';

	const mapData = app.userConfiguration?.mapping?.filter(x => x.isEnvironment);

	mapData?.forEach((mapItem, index) => {
		const breakLine = mapData.length - 1 != index ? '\n\n' : '';

		customCode += `    /// <summary>
    /// Armazena o ${mapItem.columnName} do usuário
    /// </summary>
    public const string ${mapItem.columnName} = "application_${mapItem.columnName}";${breakLine}`;
	});

	app.userConfiguration?.mapCustom?.forEach(x => {
		const propName = getPropNameFromCustom(x.customMapVars);

		customCode += `\n\n    /// <summary>
    /// Armazena o ${propName} do usuário
    /// </summary>
    public const string ${propName} = "application_${propName}";`;
	});

	const code = `namespace ${projName}.Infra.CrossCutting.Identity.Constants;

  /// <summary>
  /// Constantes das chaves dos valores do token
  /// </summary>
  public static class ClaimsConstant
  {
${customCode ?? defualtCode}
  }`;

	resources.push({
		path: `src/${projName}.Infra.CrossCutting.Identity/Constants/`,
		name: 'ClaimsConstant.cs',
		code,
	});

	return resources;
};

const getInterfaces = (app: IApplication, projName: string): IResourceApi[] => {
	const resources: IResourceApi[] = [];
	const table = app.database?.tables?.find(x => x.isUser);
	const className = dotnetFormatScreenName(table?.name ?? '');
	const tokenParams = table ? `${className} user` : `int userId`;

	const defaultIdentityCode = `/// <summary>
  /// Obtém o id do usuário
  /// </summary>
  int GetUserId();`;

	let customIdentityCode = '';
	const mapData = app.userConfiguration?.mapping?.filter(x => x.isEnvironment);

	mapData?.forEach((mapItem, index) => {
		const breakLine = mapData.length - 1 != index ? '\n\n' : '';
		const columnType = table?.columns?.find(x => x.ref === mapItem.columnRef)?.type;
		const type = mapToDotnet(columnType!);

		customIdentityCode += `    /// <summary>
    /// Obtém o id do usuário
    /// </summary>
    ${type} GetUser${mapItem.columnName}();${breakLine}`;
	});

	app.userConfiguration?.mapCustom?.forEach(x => {
		const propName = getPropNameFromCustom(x.customMapVars);
		const lastItem = x.customMapVars[x.customMapVars.length - 1];
		const mTable = app.database?.tables?.find(table => table.ref === lastItem.table?.value);
		const columnType = mTable?.columns?.find(x => x.ref === lastItem.column?.value)?.type;
		const type = mapToDotnet(columnType!);

		customIdentityCode += `\n\n    /// <summary>
    /// Obtém o/a ${propName}
    /// </summary>
    ${type} GetUser${propName}();`;
	});

	const code3 = `namespace ${projName}.Infra.CrossCutting.Identity.Interfaces;

  /// <summary>
  /// Interface responsável por acessar os dados do token
  /// </summary>
  public interface IIdentityService
  {
${customIdentityCode ?? defaultIdentityCode}
  }`;

	resources.push({
		path: `src/${projName}.Infra.CrossCutting.Identity/Interfaces/`,
		name: 'IIdentityService.cs',
		code: code3,
	});

	const mUser = app.database?.tables?.find(table => table.isUser);
	const mSchema = dotnetSchemaName(mUser?.schema ?? '');

	resources.push({
		path: `src/${projName}.Infra.CrossCutting.Identity/Interfaces/`,
		name: 'IUserService.cs',
		code: `using ${projName}.Infra.CrossCutting.Identity.Models;
using ${projName}.Domain.Models.${mUser?.schema ? mSchema : 'Base'};

namespace ${projName}.Infra.CrossCutting.Identity.Interfaces;

/// <summary>
/// Interface responsável por gerenciar o usuário
/// </summary>
public interface IUserService
{
    /// <summary>
    /// Gera o token do usuário
    /// </summary>
    JwtToken GenerateJwtToken(${tokenParams});

    /// <summary>
    /// Criptografa a senha do usuário
    /// </summary>
    string HashPassword(string password);

    /// <summary>
    /// Verifica se a senha do usuário é válida
    /// </summary>
    bool VerifyHashedPassword(string hashedPassword, string password);
}`,
	});

	return resources;
};

const getModels = (app: IApplication, projName: string): IResourceApi[] => {
	const resources: IResourceApi[] = [];
	const table = app.database?.tables?.find(x => x.isUser);
	const defaultComplementarCode = ` public required string UserLogin { get; set; }`;
	let customComplementarCode = '';

	if (table && app.userConfiguration?.mapping) {
		app.userConfiguration?.mapping
			.filter(x => x.isEnvironment)
			.forEach(mapItem => {
				const columnType = table.columns?.find(x => x.ref === mapItem.columnRef)?.type;
				const type = mapToDotnet(columnType!);
				if (mapItem.isEnvironment)
					customComplementarCode += `  public ${type} ${mapItem.columnName} { get; set; }\n`;
			});
	}

	app.userConfiguration?.mapCustom?.forEach(x => {
		const propName = getPropNameFromCustom(x.customMapVars);
		const lastItem = x.customMapVars[x.customMapVars.length - 1];
		const mTable = app.database?.tables?.find(table => table.ref === lastItem.table?.value);
		const columnType = mTable?.columns?.find(x => x.ref === lastItem.column?.value)?.type;
		const type = mapToDotnet(columnType!);

		customComplementarCode += `  public ${type} ${propName} { get; set; }\n`;
	});

	resources.push({
		path: `src/${projName}.Infra.CrossCutting.Identity/Models/`,
		name: 'JwtToken.cs',
		code: `namespace ${projName}.Infra.CrossCutting.Identity.Models;

/// <summary>
/// Informações referente ao token
/// </summary>
public class JwtToken
{
  public required string Token { get; set; }
  public required DateTime ExpireDate { get; set; }
${customComplementarCode ?? defaultComplementarCode}
}`,
	});

	return resources;
};

const getServices = (app: IApplication, projName: string): IResourceApi[] => {
	const resources: IResourceApi[] = [];
	const table = app.database?.tables?.find(x => x.isUser);
	const className = dotnetFormatScreenName(table?.name ?? '');
	const tokenParams = table ? `${className} user` : `int userId`;
	const tokenParamsSend = table ? `user` : `userId`;

	const defaultIdentityCode = `/// <summary>
  /// Obtém o id do usuário
  /// </summary>
  public int GetUserId()
  {
      var userId = _context.HttpContext?.User.FindFirst(ClaimsConstant.USER_ID)?.Value;

      if (userId == null)
          throw new KeyNotFoundException("UserId not found!");

      return int.Parse(userId);
  }`;

	let customIdentityCode = '';
	const mapData = app.userConfiguration?.mapping?.filter(x => x.isEnvironment);

	mapData?.forEach((mapItem, index) => {
		const breakLine = mapData.length - 1 != index ? '\n\n' : '';
		const columnType = table?.columns?.find(x => x.ref === mapItem.columnRef)?.type;
		const type = mapToDotnet(columnType!);
		let converterCode = '';
		if (type == 'bool') converterCode = 'Boolean';
		else if (type == 'int') converterCode = 'Int32';
		else converterCode = upperFirstWord(type);

		customIdentityCode += `    /// <summary>
    /// Obtém o ${mapItem.columnName} do usuário
    /// </summary>
    public ${type} GetUser${mapItem.columnName}()
    {
        var claimValue = _context.HttpContext?.User.FindFirst(ClaimsConstant.${mapItem.columnName})?.Value;

        if (claimValue == null)
            throw new KeyNotFoundException("${mapItem.columnName} not found!");

        return Convert.To${converterCode}(claimValue);
    }${breakLine}`;
	});

	app.userConfiguration?.mapCustom?.forEach(x => {
		const propName = getPropNameFromCustom(x.customMapVars);
		const lastItem = x.customMapVars[x.customMapVars.length - 1];
		const mTable = app.database?.tables?.find(table => table.ref === lastItem.table?.value);
		const columnType = mTable?.columns?.find(x => x.ref === lastItem.column?.value)?.type;
		const type = mapToDotnet(columnType!);
		let converterCode = '';
		if (type == 'bool') converterCode = 'Boolean';
		else if (type == 'int') converterCode = 'Int32';
		else converterCode = upperFirstWord(type);

		customIdentityCode += `\n\n    /// <summary>
    /// Obtém o ${propName} do usuário
    /// </summary>
    public ${type} GetUser${propName}()
    {
        var claimValue = _context.HttpContext?.User.FindFirst(ClaimsConstant.${propName})?.Value;

        if (claimValue == null)
            throw new KeyNotFoundException("${propName} not found!");

        return Convert.To${converterCode}(claimValue);
    }`;
	});

	const code2 = `using System.Security.Claims;
using Microsoft.AspNetCore.Http;
using ${projName}.Infra.CrossCutting.Identity.Constants;
using ${projName}.Infra.CrossCutting.Identity.Interfaces;

namespace ${projName}.Infra.CrossCutting.Identity.Services;

/// <summary>
/// Responsável por gerar acessar os dados do usuário logado
/// </summary>
public class IdentityService : IIdentityService
{
    private readonly IHttpContextAccessor _context;

    /// <summary>
    /// Construtor
    /// </summary>
    public IdentityService(IHttpContextAccessor context)
    {
        _context = context;
    }

${customIdentityCode ?? defaultIdentityCode}
}`;

	resources.push({
		path: `src/${projName}.Infra.CrossCutting.Identity/Services/`,
		name: 'IdentityService.cs',
		code: code2,
	});

	const defaultClaim = 'claims.Add(new Claim(ClaimsConstant.USER_ID, userId.ToString()));\n';
	let tokenData = '';
	let customClaim = '';
	app.userConfiguration?.mapping
		?.filter(x => x.isEnvironment)
		.forEach(mapItem => {
			const isAutoIncrement = app.database?.tables
				?.find(t => t.columns?.find(c => c.ref == mapItem.columnRef))
				?.columns?.find(c => c.ref == mapItem.columnRef)?.autoIncremente;
			const cName = isAutoIncrement ? 'Id' : mapItem.columnName;

			tokenData += `              ${mapItem.columnName} = user.${cName},\n`;
			customClaim += `          claims.Add(new Claim(ClaimsConstant.${mapItem.columnName}, user.${cName}.ToString()));\n`;
		});

	app.userConfiguration?.mapCustom?.forEach(x => {
		const propName = getPropNameFromCustom(x.customMapVars);
		const propName2 = getPropNameFromCustomSub(app, x.customMapVars);

		tokenData += `              ${propName} = user.${propName2},\n`;
		customClaim += `          claims.Add(new Claim(ClaimsConstant.${propName}, user.${propName2}.ToString()));\n`;
	});

	const mUser = app.database?.tables?.find(table => table.isUser);
	const mSchema = dotnetSchemaName(mUser?.schema ?? '');

	const code = `using System.IdentityModel.Tokens.Jwt;
  using System.Security.Claims;
  using System.Text;
  using Microsoft.Extensions.Configuration;
  using Microsoft.IdentityModel.Tokens;
  using ${projName}.Domain.Models.${mUser?.schema ? mSchema : 'Base'};
  using ${projName}.Infra.CrossCutting.Identity.Constants;
  using ${projName}.Infra.CrossCutting.Identity.Models;

  namespace ${projName}.Infra.CrossCutting.Identity.Services;

  /// <summary>
  /// Responsável por gerar o token
  /// </summary>
  public class JwtFactory
  {
      /// <summary>
      /// Gera o token do usuário
      /// </summary>
      public JwtToken GenerateJwtToken(${tokenParams}, IConfiguration configuration)
      {
          var claims = new List<Claim>();
          var secret = configuration.GetValue<string>("JWT:Secret")!;
          var issuer = configuration.GetValue<string>("JWT:Issuer")!;
          var audience = configuration.GetValue<string>("JWT:Audience")!;
          var expiration = configuration.GetValue<double>("JWT:Expiration")!;

${customClaim ?? defaultClaim}
          var identityClaims = new ClaimsIdentity();
          identityClaims.AddClaims(claims);

          var tokenHandler = new JwtSecurityTokenHandler();
          var key = Encoding.ASCII.GetBytes(secret);
          var expireDate = DateTime.UtcNow.AddHours(expiration);

          var tokenDescriptor = new SecurityTokenDescriptor
          {
              Subject = identityClaims,
              Issuer = issuer,
              Audience = audience,
              Expires = expireDate,
              SigningCredentials = new SigningCredentials(new SymmetricSecurityKey(key), SecurityAlgorithms.HmacSha256Signature)
          };

          return new JwtToken
          {
              Token = tokenHandler.WriteToken(tokenHandler.CreateToken(tokenDescriptor)),
              ExpireDate = expireDate,
${tokenData}
          };
      }
  }`;

	resources.push({
		path: `src/${projName}.Infra.CrossCutting.Identity/Services/`,
		name: 'JwtFactory.cs',
		code,
	});

	resources.push({
		path: `src/${projName}.Infra.CrossCutting.Identity/Services/`,
		name: 'UserService.cs',
		code: `using Microsoft.Extensions.Configuration;
using ${projName}.Infra.CrossCutting.Identity.Interfaces;
using ${projName}.Infra.CrossCutting.Identity.Models;
using System.Security.Cryptography;
using ${projName}.Domain.Models.${mUser?.schema ? mSchema : 'Base'};

namespace ${projName}.Infra.CrossCutting.Identity.Services;

/// <summary>
/// Responsável por gerenciar o usuário
/// </summary>
public class UserService : IUserService
{
    private readonly IConfiguration _configuration;

    public UserService(IConfiguration configuration)
    {
        _configuration = configuration;
    }

    /// <summary>
    /// Gera o token do usuário
    /// </summary>
    public JwtToken GenerateJwtToken(${tokenParams})
    {
        return new JwtFactory().GenerateJwtToken(${tokenParamsSend}, _configuration);
    }

    /// <summary>
    /// Criptografa a senha do usuário
    /// </summary>
    public string HashPassword(string password)
    {
      byte[] salt;
      byte[] buffer2;
      if (password == null)
          throw new ArgumentNullException("password");
      using (var bytes = new Rfc2898DeriveBytes(password, 0x10, 0x3e8))
      {
          salt = bytes.Salt;
          buffer2 = bytes.GetBytes(0x20);
      }

      byte[] dst = new byte[0x31];
      Buffer.BlockCopy(salt, 0, dst, 1, 0x10);
      Buffer.BlockCopy(buffer2, 0, dst, 0x11, 0x20);

      return Convert.ToBase64String(dst);
    }

    /// <summary>
    /// Verifica se a senha do usuário é válida
    /// </summary>
    public bool VerifyHashedPassword(string hashedPassword, string password)
    {
      byte[] buffer4;
      if (hashedPassword == null)
          return false;
      if (password == null)
          throw new ArgumentNullException("password");
      byte[] src = Convert.FromBase64String(hashedPassword);
      if ((src.Length != 0x31) || (src[0] != 0))
          return false;
      byte[] dst = new byte[0x10];
      Buffer.BlockCopy(src, 1, dst, 0, 0x10);
      byte[] buffer3 = new byte[0x20];
      Buffer.BlockCopy(src, 0x11, buffer3, 0, 0x20);
      using (var bytes = new Rfc2898DeriveBytes(password, dst, 0x3e8))
      {
          buffer4 = bytes.GetBytes(0x20);
      }

      return buffer3.SequenceEqual(buffer4);
    }
}`,
	});

	return resources;
};
