/* eslint-disable @typescript-eslint/no-explicit-any */
import {IComponent} from 'src/@types/app';
import {ComponentComplexEnum} from 'src/@types/enums';
import {GET_REF} from '../engine-enum';

export const removeNotUsedRefs = (widget: IComponent, bodyString: string): string => {
	if (widget.name === ComponentComplexEnum.Tab) {
		widget.properties?.tabs?.forEach((tab: any) => {
			bodyString = bodyString.replace(GET_REF(tab.ref ?? ''), '');
		});
	}

	return bodyString;
};
