/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useContext, useState} from 'react';
import {IApplication, IComponent} from 'src/@types/app';
import {ComponentComplexEnum, ComponentSimpleEnum, ComponentTypeEnum} from 'src/@types/enums';
import {IComponentView} from 'src/shared/components/types';
import {transformToJsx} from 'src/helpers/methods/transformers';
import * as S from './styles';
import {ApplicationContext} from 'src/context';

type Props = {
	widget: IComponentView;
	onSelect?: (ref: string) => void;
	enableHelpLines?: boolean;
	widgets: IComponent[];
	showEditComplexBtn: boolean;
	toggleSpecifyModal: (type: ComponentComplexEnum) => void;
	selectedItem: any;
};

const ComponentContent = ({
	widget,
	onSelect,
	enableHelpLines,
	widgets,
	showEditComplexBtn,
	toggleSpecifyModal,
	selectedItem,
}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);
	const [internalState, setInternalState] = useState<any>({});
	const booleanList = ['isOn', 'checked'];

	const getWidth = (component: IComponent) => {
		if (component.type === ComponentTypeEnum.Container) {
			return component?.properties?.width && component?.properties?.width != ''
				? component?.properties?.width
				: '100%';
		}
		if (component.type === ComponentTypeEnum.UI) {
			if (component.name === ComponentSimpleEnum.Text) return 'auto';
			if (
				component?.properties?.hasOwnProperty('fill') &&
				component?.properties?.fill === 'auto'
			)
				return 'fit-content';
		}
	};

	const getterSetter = (component: IComponent) => {
		if (!component.options) return undefined;

		const valueProp = getPropHasValue(component.options, 'valueProp');
		const changeProp = getPropHasValue(component.options, 'changeProp');

		return {
			[valueProp]: internalState[component.ref as string],
			[changeProp]: (e: any) => {
				const newState = {...internalState};
				if (booleanList.includes(valueProp))
					newState[component.ref as string] = !newState[component.ref as string];
				else newState[component.ref as string] = e;
				setInternalState(newState);
			},
		};
	};

	const getPropHasValue = (entity: any, value: string): string => {
		const keys = Object.keys(entity);
		for (const key of keys) {
			if (entity[key] === value) return key;
		}
		return '';
	};

	const transformTimeLine = (name: string, propsTimeLine: any) => {
		if (name === ComponentComplexEnum.Timeline) {
			return {
				leftContent: transformToJsx(
					propsTimeLine.leftContent ?? [],
					showEditComplexBtn,
					toggleSpecifyModal,
					selectedItem,
				),
			};
		}
		return {};
	};

	const removeItem = () => {
		const newWidgets = [...widgets].filter(x => !x.selected);
		setApplication({
			...application,
			resources: [...(application.resources ?? [])].map(item => {
				if (item.selected) {
					item.widgets = newWidgets;
				}

				return item;
			}),
		});
	};

	const render = (widgetData: IComponentView, index?: number) => {
		return (
			<S.Content width={getWidth(widgetData.component)}>
				{widgetData.component.selected ? (
					<S.ContainerActionContent>
						<S.ContainerAction title="Remover" onClick={removeItem}>
							<i className="fa fa-close"></i>
						</S.ContainerAction>
						{showEditComplexBtn ? (
							<S.ContainerAction
								title="Editar"
								onClick={() => {
									toggleSpecifyModal(selectedItem);
									setApplication({
										...application,
										resources: [...(application.resources ?? [])].map(
											resource => {
												if (resource.selected) {
													resource.widgets = resource.widgets?.map(w => {
														w.selected = false;
														if (w.ref == widgetData.component.ref)
															w.selected = true;
														return w;
													});
												}
												return resource;
											},
										),
									});
								}}>
								<i className="fa fa-edit"></i>
							</S.ContainerAction>
						) : (
							<></>
						)}
						{widgets.find(x => x.selected)?.tabRef ? (
							<S.ContainerAction
								title="Remover da tabela"
								onClick={() => {
									const data: IApplication = {
										...application,
										resources: [...(application.resources ?? [])].map(
											resource => {
												if (resource.selected) {
													resource.widgets = resource.widgets?.map(
														widget => {
															if (widget.selected) {
																widget.parentRef = undefined;
																widget.tabRef = undefined;
															}

															return widget;
														},
													);
												}
												return resource;
											},
										),
									};

									setApplication(data);
								}}>
								<i className="fa fa-table"></i>
							</S.ContainerAction>
						) : (
							<></>
						)}
					</S.ContainerActionContent>
				) : (
					<></>
				)}
				<S.Container
					key={index}
					nameComponent={widgetData.component.name}
					width={getWidth(widgetData.component)}
					isSelected={widgetData.component.selected ?? false}
					typeComponent={widgetData.component.type}
					onClick={e => {
						if (!widgetData.component.properties.mask)
							widgetData.component.properties.mask = 'Nenhuma';
						if (onSelect) onSelect(widgetData.component.ref as string);
						e.stopPropagation();
					}}
					enableHelpLines={enableHelpLines ?? false}>
					{(function () {
						if (
							widgetData.components &&
							widgetData.components.length > 0 &&
							widgetData.component.name !== ComponentComplexEnum.Tab
						) {
							return (
								<widgetData.component.component
									{...widgetData.component.properties}
									width="100%">
									{(function () {
										return widgetData.components.map((x, i) => render(x, i));
									})()}
								</widgetData.component.component>
							);
						}

						const textData =
							widgetData.component.name === ComponentSimpleEnum.Text
								? {
										text: widgetData.component.properties.text
											? widgetData.component.properties.text
											: 'Texto',
								  }
								: undefined;

						if (widgetData.component.name === ComponentComplexEnum.Tab) {
							widgetData.component.properties.tabs = [
								...(widgetData.component.properties.tabs ?? []),
							].map((item: any) => {
								item.children = (
									<>
										{(function () {
											const data =
												widgetData.components?.filter(
													x => x.component.tabRef === item.ref,
												) ?? [];

											return data.map((x, i) => render(x, i));
										})()}
									</>
								);
								return item;
							});
						}

						return (
							<widgetData.component.component
								{...widgetData.component.properties}
								{...getterSetter(widgetData.component)}
								{...textData}
								{...transformTimeLine(
									widgetData.component.name,
									widgetData.component.properties,
								)}
								width={
									widgetData.component.name === ComponentSimpleEnum.Text
										? '0px'
										: '100%'
								}
							/>
						);
					})()}
				</S.Container>
			</S.Content>
		);
	};

	return <>{render(widget)}</>;
};

export default ComponentContent;
