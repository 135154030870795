import React from 'react';
import {IProjConfig} from 'src/@types/app';
import {Switch, Wrapper} from 'src/shared/components/UI';

type Props = {
	projConfig: IProjConfig | undefined;
	setProjConfig: (value: IProjConfig) => void;
};

export const RenderConfigureGeneration = ({projConfig, setProjConfig}: Props) => {
	return (
		<>
			<Wrapper flexDirection="column">
				<Wrapper>
					<Switch
						isOn={projConfig?.createScreen ?? false}
						handleToggle={() =>
							setProjConfig({
								...projConfig,
								createScreen: !projConfig?.createScreen ?? false,
								updateScreen: false,
								checkRelations: false,
							})
						}
						text="Criar telas"
						textColor={'#000'}
					/>
					<Switch
						isOn={projConfig?.removeAllResources ?? false}
						handleToggle={() =>
							setProjConfig({
								...projConfig,
								removeAllResources: !projConfig?.removeAllResources ?? false,
								updateScreen: false,
								checkRelations: false,
							})
						}
						text="Remover todas as telas antes de criar"
						textColor={'#000'}
					/>
					<Switch
						isOn={projConfig?.updateScreen ?? false}
						handleToggle={() =>
							setProjConfig({
								...projConfig,
								updateScreen: !projConfig?.updateScreen ?? false,
								createScreen: false,
								removeAllResources: false,
							})
						}
						text="Criar campos complementares"
						textColor={'#000'}
					/>
					<Switch
						isOn={projConfig?.checkRelations ?? false}
						handleToggle={() =>
							setProjConfig({
								...projConfig,
								checkRelations: !projConfig?.checkRelations ?? false,
								createScreen: false,
								removeAllResources: false,
							})
						}
						text="Verificar relacionamentos"
						textColor={'#000'}
					/>
				</Wrapper>
			</Wrapper>
		</>
	);
};
