/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {IApplication, ITable, IResourceApi} from 'src/@types/app';
import {dotnetFormatScreenName} from '../core/dotnet-formatter';
import {backFormatPropertyName} from 'src/shared/engine-back/common/common-formatters';

export const generateIoC = (app: IApplication): IResourceApi[] => {
	const resources: IResourceApi[] = [];
	const projName = app.name!.replaceAll(' ', '');

	resources.push(getConfigureData(projName));
	resources.push(getDependencyInjection(projName, app.database!.tables!));

	return resources;
};

const getConfigureData = (projName: string): IResourceApi => {
	const code = `using Microsoft.AspNetCore.Identity;
using Microsoft.EntityFrameworkCore;
using Microsoft.Extensions.Configuration;
using Microsoft.Extensions.DependencyInjection;
using ${projName}.Domain.Interfaces;
using ${projName}.Infra.CrossCutting.Identity.Models;
using ${projName}.Infra.Data.Context;
using ${projName}.Infra.Data.UoW;

namespace ${projName}.CrossCutting.IoC.ConfigureData;

/// <summary>
/// Responsável por configurar os dados de acesso ao banco de dados
/// </summary>
public static class ConfigureData
{
    /// <summary>
    /// Responsável por parametrizar o acesso ao banco de dados
    /// </summary>
    /// <param name="services">Contêm uma instância da interface <see cref="IServiceCollection"/></param>
    /// <param name="configuration">Contêm uma instância da interface <see cref="IConfiguration"/></param>
    /// <returns>Retorna uma instância da interface <see cref="IServiceCollection"/></returns>
    public static IServiceCollection ConfigContext(this IServiceCollection services, IConfiguration configuration)
    {
        var connectionString = configuration.GetConnectionString("DefaultConnection");

        services.AddDbContext<DataContext>(
            option =>
            {
                option.UseSqlServer(connectionString);
            }
        );

        services.Configure<IdentityOptions>(options =>
        {
            options.Password.RequireNonAlphanumeric = false;
            options.Password.RequireUppercase = false;
            options.Password.RequiredLength = 8;
        });

        services.AddScoped<IUnitOfWork, UnitOfWork>();

        return services;
    }
}`;
	return {
		path: `src/${projName}.CrossCutting.IoC/ConfigureData/`,
		name: 'ConfigureData.cs',
		code: code,
	};
};

const getDependencyInjection = (projName: string, tables: ITable[]): IResourceApi => {
	let queries = '';
	let imports = '';

	tables.forEach(table => {
		const className = dotnetFormatScreenName(table.name!);
		queries += `        services.AddScoped<I${className}Query, ${className}Query>();\n`;
		imports += `using ${projName}.Application.Queries.${className}Query;\n`;
	});

	const userTable = tables.find(x => x.isUser);
	const propUserName = backFormatPropertyName(dotnetFormatScreenName(userTable?.name ?? ''));
	const injectStr = propUserName
		? `services.AddScoped<I${propUserName}Repository, ${propUserName}Repository>();`
		: '';

	const code = `using Microsoft.Extensions.DependencyInjection;
using ${projName}.Domain.Interfaces.Repositories.Base;
using ${projName}.Domain.Interfaces.Repositories;
using ${projName}.Infra.CrossCutting.Identity.Interfaces;
using ${projName}.Infra.CrossCutting.Identity.Services;
using ${projName}.Infra.Data.Repositories.Base;
using ${projName}.Infra.Data.Repositories;
${imports}
namespace ${projName}.CrossCutting.IoC.DependencyInjection;

/// <summary>
/// Responsável por realizar as injeções de dependência
/// </summary>
public static class DependencyInjection
{
    /// <summary>
    /// Responsável por parametrizar a injeção de dependência
    /// </summary>
    /// <param name="services">Contêm uma instância da interface <see cref="IServiceCollection"/></param>
    /// <returns>Retorna uma instância da interface <see cref="IServiceCollection"/></returns>
    public static IServiceCollection ConfigureDependencies(this IServiceCollection services)
    {
        services.AddScoped(typeof(IRepository<>), typeof(Repository<>));

        services.AddScoped<IIdentityService, IdentityService>();
        services.AddScoped<IUserService, UserService>();
        ${injectStr}

        services.AddHttpContextAccessor();

${queries}
        return services;
    }
}`;

	return {
		path: `src/${projName}.CrossCutting.IoC/DependencyInjection/`,
		name: 'DependencyInjection.cs',
		code: code,
	};
};
