/* eslint-disable @typescript-eslint/no-explicit-any */
import {IComponent, ISubComponent} from 'src/@types/app';

const removeCompFromWidget = (internalWidget: IComponent[]) => {
	return internalWidget.map(x => {
		const newWidget: any = {...x};
		Reflect.deleteProperty(newWidget, 'component');
		if (
			newWidget.name === 'Tab' &&
			newWidget?.properties?.tabs &&
			newWidget?.properties?.tabs?.length > 0
		) {
			newWidget.properties.tabs.forEach((tabItem: any) =>
				Reflect.deleteProperty(tabItem, 'children'),
			);
		}

		return newWidget;
	});
};

export const extractWidgets = (widgets: IComponent[]): string | undefined => {
	return widgets ? JSON.stringify(removeCompFromWidget(widgets)) : undefined;
};

export const extractSubComponents = (subComponents: ISubComponent[]): string | undefined => {
	return subComponents
		? JSON.stringify(
				subComponents.map(x => {
					x.widgets = removeCompFromWidget(x.widgets ?? []);
					return x;
				}),
		  )
		: undefined;
};
