import {IOption} from 'src/@types/app';

export const TabsTypeList: IOption[] = [
	{
		value: undefined,
		label: 'Selecione...',
	},
	{
		value: 'default',
		label: 'Padrão',
	},
	{
		value: 'inline',
		label: 'Linha',
	},
];
