import {applicationTheme} from 'src/shared/theme';
import styled from 'styled-components';

export const Content = styled.div`
	width: 100%;
	color: ${applicationTheme.brand[600]};
`;

export const ContentGrid = styled.div`
	width: calc(100% - 350px);
`;

export const UploadContent = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 15px;
`;
