/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {useEffect, useState} from 'react';
import {IOption, IWorkFlowItem} from 'src/@types/app';
import {WorkFlowDataTypeEnum, WorkFlowTypeEnum} from 'src/@types/enums';
import {Button, Select, SizedBox, Wrapper} from 'src/shared/components/UI';
import {CrudOptions} from './crudComp';
import {buildWorkflowSub, setWorkFlowValues} from '../helper';
import * as S from '../styles';
import {v4 as uuidV4} from 'uuid';
import {applicationTheme} from 'src/shared/theme';

type Props = {
	item: IWorkFlowItem;
	workFlowItem: IWorkFlowItem[];
	setWorkFlowItem: (value: IWorkFlowItem[]) => void;
};

export const ForComp = ({item, workFlowItem, setWorkFlowItem}: Props) => {
	const [listOptions, setListOptions] = useState<IOption[]>([]);

	useEffect(() => {
		const data: IOption[] = [{value: undefined, label: 'Selecione...'}];

		workFlowItem.forEach(flowItem => {
			if (
				flowItem.type === WorkFlowTypeEnum.CRUD &&
				flowItem.values &&
				flowItem.values.operation === CrudOptions.List &&
				flowItem.values.variableName
			) {
				data.push({
					value: flowItem.ref,
					label: `var - ${flowItem.values?.variableName}`,
				});
			}
		});

		setListOptions(data);
	}, [workFlowItem]);

	const addItemPosition = () => {
		const items = [...workFlowItem];
		items.push({
			ref: uuidV4().toString(),
			text: 'Titulo',
			refItem: item.ref,
			type: WorkFlowTypeEnum.Variable,
		});

		setWorkFlowItem(items);
	};

	return (
		<>
			<S.ContentBasic>
				<Select
					value={listOptions.find(y => y.value == item.values?.property?.value)}
					options={listOptions}
					onChange={val => {
						setWorkFlowValues(
							workFlowItem,
							setWorkFlowItem,
							item.ref!,
							'property',
							val,
						);
					}}
					label="Propriedade"
					background="transparent"
					inputType="minimal"
				/>

				{item.values && item.values.property ? (
					<Wrapper
						alignItems="center"
						justifyContent="center"
						flexDirection="column"
						gap="0px">
						<SizedBox height="15px" />

						{buildWorkflowSub(
							workFlowItem,
							setWorkFlowItem,
							addItemPosition,
							item.ref ?? '',
							WorkFlowDataTypeEnum.Data,
						)}

						<SizedBox height="15px" />

						<Button
							text="Adicionar recurso"
							leftIcon2="plus-square"
							onClick={() => {
								const data = [...workFlowItem];

								data.push({
									ref: uuidV4().toString(),
									text: 'Titulo',
									type: WorkFlowTypeEnum.Variable,
									refItem: item.ref,
								});

								setWorkFlowItem(data);
							}}
							background={applicationTheme.brand[50]}
							textColor={applicationTheme.brand[600]}
							fill="auto"
							height="35px"
						/>
					</Wrapper>
				) : (
					<></>
				)}
			</S.ContentBasic>
		</>
	);
};
