import React, {ReactNode} from 'react';
import * as S from './styles';
import {LeftBar} from './ui/LeftBar';
import {TopBarMenu} from './ui/TopBarMenu';
import {Wrapper} from 'src/shared/components/UI';

export type Props = {
	children: ReactNode;
	onClick?: () => void;
	expanded?: boolean;
	search?: (value: string) => void;
	additionalBarContent?: JSX.Element;
	middleContent?: JSX.Element;
	save?: () => void;
	messageWarning?: string;
};

const Layout = ({
	children,
	onClick,
	expanded = true,
	search,
	additionalBarContent,
	save,
	messageWarning,
	middleContent,
}: Props) => {
	return (
		<Wrapper alignItems="start" gap="0px">
			<LeftBar expanded={expanded} />
			<Wrapper flexDirection="column" gap="0px">
				<TopBarMenu
					search={search}
					save={save}
					messageWarning={messageWarning}
					middleContent={middleContent}
				/>
				<S.Content>
					{additionalBarContent ? (
						<S.AdditionalBar>{additionalBarContent}</S.AdditionalBar>
					) : (
						<></>
					)}
					<S.Container onClick={onClick} hasPanel={!!additionalBarContent}>
						{children}
					</S.Container>
				</S.Content>
			</Wrapper>
		</Wrapper>
	);
};

export default Layout;
