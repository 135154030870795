import {IResource, IResourceApi} from 'src/@types/app';
import {ResourceTypeEnum} from 'src/@types/enums';
import prettier from 'prettier/standalone';
import {PrettierConfig} from './common/prettier-config';

export const routeGenerator = (resources: IResource[]): IResourceApi[] => {
	const data: IResourceApi[] = [];
	data.push(genetareIndex());
	data.push(generateRouterPath(resources));
	data.push(generateRouterConfig(resources));

	return data;
};

export const resourceRouteFormatted = (resource?: IResource): string => {
	if (!resource) return '';
	const resourcePath = resource.path?.substring(1).toUpperCase().replaceAll('/', '_');
	const resourceName = resource.name?.toUpperCase();

	return `${resourcePath}_${resourceName}`;
};

const getResourceName = (resource: IResource): string => {
	const path = resource.path?.substring(1);
	const formattedPath = path?.replaceAll('/', '');
	return `${formattedPath}${resource.name}`;
};

const generateRouterPath = (resources: IResource[]): IResourceApi => {
	const paths = resources?.map(resource => {
		let path = '';
		if (resource.isRoot) path = '/';
		else if (resource.path) path = `/${resource.path.substring(1)}/${resource.name ?? ''}`;
		else path = `/${resource.name}` ?? '';

		return `${resourceRouteFormatted(resource)} = '${path}',`;
	});

	let code = `export enum ROUTER_PATHS {${paths.join('')}}`;

	code = prettier.format(code, PrettierConfig);

	return {
		code,
		name: 'router.path.ts',
		path: 'src/router/',
	};
};

const generateRouterConfig = (resources: IResource[]): IResourceApi => {
	const imports = resources
		?.filter(x => x.type === ResourceTypeEnum.Page)
		?.map(resource => {
			const name = getResourceName(resource);
			if (!resource.path) return `import ${name} from 'src/modules/${resource.name}';`;
			return `import ${name} from 'src/modules${resource.path}/${resource.name}';`;
		});

	const routes = resources
		?.filter(x => x.type === ResourceTypeEnum.Page)
		?.filter(x => !x.isLogin)
		?.map(resource => {
			const name = getResourceName(resource);
			return `<Route path={ROUTER_PATHS.${resourceRouteFormatted(
				resource,
			)}} element={<${name} />}></Route>`;
		});

	const hasLogin = resources.find(x => x.isLogin) ? true : false;

	let code = `
		import React from 'react';
		import {Routes, Route, useLocation, Navigate} from 'react-router-dom';
		import {ROUTER_PATHS} from './router.path';
		${imports.join('')}

		export const RouterConfig = () => {
			const location = useLocation();

			${hasLogin ? getAuthMethod(resources.find(x => x.isLogin)?.name ?? '') : ''}

			return (
				<Routes location={location} key={location.pathname}>
					${routes.join('')}
				</Routes>
			);
		};
  `;

	code = prettier.format(code, PrettierConfig);

	return {
		code,
		name: 'router-config.tsx',
		path: 'src/router/',
	};
};

const genetareIndex = (): IResourceApi => {
	let code = `export {RouterConfig as default} from './router-config';`;

	code = prettier.format(code, PrettierConfig);

	return {
		code,
		name: 'index.tsx',
		path: 'src/router/',
	};
};

const getAuthMethod = (resourceName: string): string => {
	return `
		const isAuthenticated = () => {
			const token = localStorage.getItem('jwt_token');
			if (!token || token == '') return false;
			const tokenSplit = token.split('.');
			if (tokenSplit.length != 3) return false;
			const tokenData = JSON.parse(atob(tokenSplit[1]));
			if (typeof tokenData.exp != 'number') return false;
			return tokenData.exp > Date.now() / 1000;
		};

		if (!isAuthenticated()) {
			return (
				<Routes location={location} key={location.pathname}>
					<Route path="*" element={<Navigate to="/" replace />}></Route>
					<Route path="/" element={<${resourceName} />}></Route>
				</Routes>
			);
		}
	`;
};
