import React, {useContext, useEffect, useState} from 'react';
import {IComponent, IOption, IValidation} from 'src/@types/app';
import {ValidationTypeEnum} from 'src/@types/enums';
import {Modal} from 'src/shared/components/Complex';
import {
	Button,
	InputDate,
	Text,
	ModalTitle,
	SizedBox,
	WidthBox,
	Wrapper,
	Line,
} from 'src/shared/components/UI';
import {ApplicationContext} from 'src/context';
import {
	DATE_INPUT_TYPE,
	getValidationList,
	NUMBER_INPUT_TYPE,
} from 'src/shared/validation/component-validation';
import {v4 as uuidV4} from 'uuid';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import {applicationTheme} from 'src/shared/theme';
import {SystemInput} from 'src/shared/components/System/SystemInput';
import {MenuItem} from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import {SystemDataGrid} from 'src/shared/components/System/SystemDataGrid';
import {SystemRowOptions} from 'src/shared/components/System/SystemRowOptions';
import {IconWrapper} from 'src/shared/components/UI/ModalTitle/styles';

type Props = {
	isOpen: boolean;
	selectedWidget: IComponent;
	onClose: () => void;
};

interface CellType {
	row: IValidation;
}

const AddValidation = ({isOpen, onClose, selectedWidget}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);

	const [editRef, setEditRef] = useState('');
	const [optionsData, setOptionsData] = useState<IOption[]>([]);
	const [validationType, setValidationType] = useState<IOption>();
	const [inputValue, setInputValue] = useState<any>();
	const [inputValue2, setInputValue2] = useState<any>();
	const [validationData, setValidationData] = useState<IValidation[]>([]);
	const [isValid, setIsValid] = useState(false);
	const [message, setMessage] = useState('');
	const [isOpenDelete, showIsOpenDelete] = useState(false);
	const [rowToDelete, setRowToDelete] = useState<IValidation | null>(null);

	useEffect(() => {
		if (selectedWidget && selectedWidget.name) {
			setOptionsData(getValidationList(selectedWidget));
			setValidationData(selectedWidget.valition ?? []);
		}
	}, [selectedWidget, application]);

	useEffect(() => {
		if (validationType && validationType.value) {
			if (
				[
					ValidationTypeEnum.Required,
					ValidationTypeEnum.CnpjCpf,
					ValidationTypeEnum.Email,
				].includes(validationType.value)
			)
				setIsValid(true);
			else if (
				validationType.value !== ValidationTypeEnum.Required &&
				inputValue &&
				!Number.isNaN(Number(inputValue)) &&
				Number(inputValue) > 0
			)
				setIsValid(true);
			else setIsValid(false);
		} else setIsValid(false);
	}, [validationType, inputValue]);

	const renderSubProps = () => {
		if (NUMBER_INPUT_TYPE.includes(validationType?.value as ValidationTypeEnum)) {
			return (
				<Wrapper margin="12px 0 0 0" width="50%">
					<SystemInput
						label="Quantidade"
						mask="Numero"
						value={inputValue}
						onChange={setInputValue}
					/>
				</Wrapper>
			);
		} else if (DATE_INPUT_TYPE.includes(validationType?.value as ValidationTypeEnum)) {
			return (
				<Wrapper margin="12px 0 0 0" width="100%">
					<WidthBox width="50%">
						<InputDate
							label="Data"
							value={inputValue}
							onChange={setInputValue}
							border="1px solid #333"
							inputType="light"
							placeholder="dd/mm/aaaa"
						/>
					</WidthBox>
					{validationType?.value === ValidationTypeEnum.Between ? (
						<WidthBox width="50%">
							<InputDate
								label="Data 2"
								value={inputValue2}
								onChange={setInputValue2}
								border="1px solid #333"
								inputType="light"
								placeholder="dd/mm/aaaa"
							/>
						</WidthBox>
					) : (
						<></>
					)}
				</Wrapper>
			);
		}
	};

	const addLine = () => {
		let data = [...validationData];

		if (editRef) {
			data = [...data].map(item => {
				if (item.ref === editRef) {
					item.type = validationType?.value as ValidationTypeEnum;
					item.value = inputValue;
					item.label = validationType?.label;
					item.value2 = inputValue2;
					item.message = message;
				}
				return item;
			});
		} else {
			data.push({
				ref: uuidV4().toString(),
				type: validationType?.value as ValidationTypeEnum,
				value: inputValue,
				value2: inputValue2,
				label: validationType?.label,
				message,
			});
		}

		setInputValue('');
		setValidationType(undefined);
		setEditRef('');
		setValidationData(data);
	};

	const removeLine = (row: IValidation) => {
		const data = [...validationData].filter(x => x.ref !== row.ref);
		setValidationData(data);
		setEditRef('');
		showIsOpenDelete(false);
	};

	const handleEdit = (item: IValidation) => {
		setEditRef(item.ref);
		setMessage(item.message ?? '');
		if ([ValidationTypeEnum.GreatThan, ValidationTypeEnum.LessThan].includes(item.type)) {
			const date = new Date(Date.parse(item.value));
			setInputValue(date);
		} else if (item.type === ValidationTypeEnum.Between) {
			const date = new Date(Date.parse(item.value));
			const date2 = new Date(Date.parse(item.value2));

			setInputValue(date);
			setInputValue2(date2);
		} else {
			setInputValue(item.value);
		}
		setValidationType(optionsData.find(x => x.value === item.type));
	};

	const handleDeleteClick = (event: React.MouseEvent<HTMLElement>, item: IValidation) => {
		event.stopPropagation();
		setRowToDelete(item);
		showIsOpenDelete(true);
	};

	const save = () => {
		const newApplication = {
			...application,
			resources: [...(application.resources ?? [])].map(resource => {
				if (resource.selected) {
					resource.widgets = resource.widgets?.map(widget => {
						if (widget.ref === selectedWidget.ref) widget.valition = validationData;
						return widget;
					});
				}
				return resource;
			}),
		};

		setApplication(newApplication);
		close();
	};

	const close = () => {
		onClose();
		setInputValue('');
		setValidationType(undefined);
		setValidationData([]);
		setEditRef('');
	};

	if (!optionsData) return <></>;

	return (
		<>
			<Modal isOpen={isOpen} onClose={close} maxWidth="800px">
				<>
					<ModalTitle title="Criar validações" />
					<>
						<Wrapper>
							<WidthBox width="90%">
								<SystemSelect
									value={validationType ?? optionsData[0]}
									options={optionsData}
									onChange={(value: IOption) => {
										setValidationType(value);
										setMessage(value.custom?.value ?? '');
									}}
									label="Tipo"
								/>
							</WidthBox>
							<WidthBox width="auto" margin="22px 0 0 0">
								<Button
									icon2={editRef ? 'edit-3' : 'plus'}
									onClick={addLine}
									fill="auto"
									textColor={applicationTheme.brand[600]}
									background={applicationTheme.brand[100]}
									disabled={!isValid}
									height="41px"
								/>
							</WidthBox>
						</Wrapper>

						{validationType && validationType.value ? (
							<Wrapper margin="12px 0 0 0">
								<SystemInput
									label="Mensagem"
									value={message}
									onChange={setMessage}
								/>
							</Wrapper>
						) : (
							<></>
						)}

						{renderSubProps()}

						<SizedBox height="12px" />
						<SystemDataGrid
							rowsData={validationData}
							columns={[
								{
									flex: 0.1,
									minWidth: 90,
									type: 'actions',
									filterable: false,
									hideable: false,
									headerAlign: 'left',
									align: 'left',
									sortable: false,
									field: 'actions',
									headerName: '',
									renderCell: ({row}: CellType) => (
										<SystemRowOptions
											childrens={
												<>
													<MenuItem
														onClick={event => handleEdit(row)}
														sx={{'& svg': {mr: 2}}}>
														<FeatherIcon icon="edit-3" size={16} />
														<Text text="Editar" />
													</MenuItem>
													<Wrapper
														alignItems="center"
														justifyContent="center">
														<WidthBox width="90%">
															<Line
																height="0.5px"
																background={
																	applicationTheme.gray[300]
																}
															/>
														</WidthBox>
													</Wrapper>
													<MenuItem
														onClick={event =>
															handleDeleteClick(event, row)
														}
														sx={{'& svg': {mr: 2}}}>
														<FeatherIcon icon="trash-2" size={16} />
														<Text
															text="Excluir"
															color={applicationTheme.error[900]}
														/>
													</MenuItem>
												</>
											}
										/>
									),
								},
								{
									flex: 0.4,
									headerAlign: 'left',
									align: 'left',
									sortable: false,
									headerName: 'Tipo',
									field: 'label',
									minWidth: 180,
								},
								{
									flex: 0.4,
									headerAlign: 'left',
									align: 'left',
									sortable: false,
									headerName: 'Valor',
									field: 'value',
									minWidth: 180,
								},
								{
									flex: 0.4,
									headerAlign: 'left',
									align: 'left',
									sortable: false,
									headerName: 'Mensagem',
									field: 'message',
									minWidth: 180,
								},
							]}
						/>
					</>
				</>
			</Modal>

			{/* Modal de Exclusão */}
			<Modal isOpen={isOpenDelete} onClose={() => showIsOpenDelete(false)} width="auto">
				<>
					<ModalTitle
						title="Deletar validação?"
						icon={
							<IconWrapper>
								<FeatherIcon icon="alert-circle" size={20} />
							</IconWrapper>
						}
						centerTitle={true}
					/>
					<div
						style={{
							color: applicationTheme.gray[500],
							textAlign: 'center',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							fontSize: '14px',
							paddingBottom: '24px',
						}}>
						Tem certeza de que deseja excluir este item? <br />
						Essa ação não pode ser desfeita.
					</div>
					<Wrapper margin="20px 0 0 0" justifyContent="end">
						<Button
							text="Cancelar"
							onClick={() => showIsOpenDelete(false)}
							fill="full"
							background={applicationTheme.gray[300]}
							textColor={applicationTheme.gray[700]}
							type="ghost"
						/>
						<Button
							text="Deletar"
							onClick={() => rowToDelete && removeLine(rowToDelete)}
							fill="full"
							background={applicationTheme.error[600]}
						/>
					</Wrapper>
				</>
			</Modal>
		</>
	);
};

export default AddValidation;
