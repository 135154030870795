/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {InputTag, Wrapper} from '../../UI';

type Props = {
	values?: string[];
	onChange: (values: string[]) => void;
	options?: any[];
	label: string;
	width?: string;
	margin?: string;
	maxWidth?: string;
};

export const SystemInputTag = ({
	values,
	onChange,
	width,
	margin,
	maxWidth,
	options,
	label,
}: Props) => {
	return (
		<Wrapper width={width} margin={margin} maxWidth={maxWidth}>
			<InputTag
				values={values}
				onChange={onChange}
				options={options}
				label={label}
				inputType="light"
			/>
		</Wrapper>
	);
};
