/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useContext, useEffect, useState} from 'react';
import {
	IAction,
	IActionApiMap,
	IApiMap,
	IApplication,
	IDefineFail,
	IModalConfig,
	IOption,
} from 'src/@types/app';
import {ActionTypeEnum, ApiMapType, ComponentComplexEnum} from 'src/@types/enums';
import {Collapse, SizedBox, Switch, WidthBox, Wrapper} from 'src/shared/components/UI';
import {getApiList, getListRoute, getWidgetsInputAndComplex} from 'src/helpers/methods/app-methods';
import DefineFail from '../components/DefineFail';
import ModalConfig, {DefaultModalConfig} from '../components/ModalConfig';
import * as S from './styles';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';

type Props = {
	application: IApplication;
	btnAction: IAction | undefined;
	setBtnAction: (value: IAction) => void;
};

const API = ({application, btnAction, setBtnAction}: Props) => {
	const [listApis, setListApis] = useState<IOption[]>([]);
	const [listRoutes, setListRoutes] = useState<IOption[]>([]);
	const [listComponents, setListComponents] = useState<IOption[]>([]);
	const [selectedEndpoint, setSelectedEndpoint] = useState<IOption>();
	const [requestDic, setRequestDic] = useState<any>({});
	const [responseDic, setResponseDic] = useState<any>({});
	const [requestModalConfig, setRequestModalConfig] = useState<IModalConfig>(DefaultModalConfig);
	const [successModalConfig, setSuccessModalConfig] = useState<IModalConfig>(DefaultModalConfig);
	const [failModalConfig, setFailModalConfig] = useState<IModalConfig>(DefaultModalConfig);
	const [defineFail, setDefineFail] = useState<IDefineFail>();

	useEffect(() => {
		const apiList = getApiList(application);
		const requestObj: any = {};
		const responseObj: any = {};
		const mListComponents = getWidgetsInputAndComplex(application);

		btnAction?.api?.mapRequest?.forEach(item => {
			requestObj[item.targetApiRef] = mListComponents.find(
				x => x.value === item.targetComponentRef,
			);
		});
		btnAction?.api?.mapResponse?.forEach(item => {
			responseObj[item.targetApiRef] = mListComponents.find(
				x => x.value === item.targetComponentRef,
			);
		});

		setRequestDic(requestObj);
		setResponseDic(responseObj);
		setRequestModalConfig(btnAction?.modal ?? DefaultModalConfig);
		setSuccessModalConfig(btnAction?.api?.modalSuccess ?? DefaultModalConfig);
		setFailModalConfig(btnAction?.api?.modalFail ?? DefaultModalConfig);
		setDefineFail(btnAction?.api?.defineFail);

		setListApis(apiList);
		setListComponents(mListComponents);
	}, [application]);

	useEffect(() => {
		saveOthers();
	}, [requestModalConfig, successModalConfig, failModalConfig, defineFail]);

	useEffect(() => {
		setListRoutes(getListRoute(application, btnAction?.api?.targetApi));
	}, [btnAction]);

	useEffect(() => {
		let defaultAction: any;
		defaultAction = automaticSetProperties(ApiMapType.REQUEST, defaultAction);
		defaultAction = automaticSetProperties(ApiMapType.RESPONSE, defaultAction);
	}, [selectedEndpoint]);

	useEffect(() => {
		if (btnAction && btnAction.api && btnAction.api.targetEndpoint)
			setSelectedEndpoint(listRoutes.find(x => x.value === btnAction?.api?.targetEndpoint));
	}, [listRoutes]);

	const automaticSetProperties = (type: ApiMapType, defaultAction: any) => {
		getApiMapData(type)?.forEach(data => {
			const findedComponent = listComponents.find(x => x.label === data.name);
			if (findedComponent && !requestDic[data.ref ?? ''])
				defaultAction = saveChangeMap(data, findedComponent, type, defaultAction);
		});
		return defaultAction;
	};

	const renderRequest = (type: ApiMapType) => {
		return (
			<>
				{renderMapProperties(type)}
				<ModalConfig config={requestModalConfig} setConfig={setRequestModalConfig} />
			</>
		);
	};

	const renderResponse = (type: ApiMapType) => {
		return (
			<>
				<SizedBox height="7px" />
				<Collapse
					text="Sucesso"
					headerTextColor={'#000'}
					border="1px solid #333"
					borderRadius="4px">
					<>
						{!btnAction?.api?.isData ? renderMapProperties(type) : <></>}
						<ModalConfig
							config={successModalConfig}
							setConfig={setSuccessModalConfig}
							switchText="Exibir mensagem de sucesso?"
						/>
					</>
				</Collapse>
				<SizedBox height="7px" />
				<Collapse
					text="Falha"
					headerTextColor={'#000'}
					border="1px solid #333"
					borderRadius="4px">
					<Collapse
						text="Definir falha"
						headerTextColor={'#000'}
						border="1px solid #333"
						borderRadius="4px">
						<>
							<DefineFail
								data={defineFail}
								setData={setDefineFail}
								apiMapData={getApiMapData(type) ?? []}
							/>
						</>
					</Collapse>
					<SizedBox height="7px" />
					<ModalConfig
						config={failModalConfig}
						setConfig={setFailModalConfig}
						switchText="Exibir mensagem de falha?"
					/>
				</Collapse>
			</>
		);
	};

	const renderCollapse = (type: ApiMapType) => {
		if (getApiMapData(type)) {
			return (
				<>
					<SizedBox height="10px" />
					<Collapse
						text={`Configuração ${type}`}
						headerTextColor={'#000'}
						border={`1px solid ${type === ApiMapType.REQUEST ? '#40CF54' : '#FFC61A'}`}
						borderRadius="4px">
						{(function () {
							if (type === ApiMapType.REQUEST) return renderRequest(type);
							else return renderResponse(type);
						})()}
					</Collapse>
				</>
			);
		}
	};

	const renderMapProperties = (type: ApiMapType) => {
		return (
			<>
				<Collapse
					text="Mapear propriedades"
					headerTextColor={'#000'}
					border="1px solid #333"
					borderRadius="4px">
					{getApiMapData(type)?.map((item, index) => renderEditItem(item, index, type))}
				</Collapse>
				<SizedBox height="7px" />
			</>
		);
	};

	const renderEditItem = (data: IApiMap, index: number, type: ApiMapType) => {
		const calcParent = (parent: number, item: IApiMap) => {
			if (item.parentRef) {
				parent += 1;
				parent = calcParent(
					parent,
					getApiMapData(type)!.find(x => x.ref === item.parentRef)!,
				);
			}
			return parent;
		};

		const getListComponents = () => {
			if (data.type == 'array')
				return listComponents.filter((x: any) => x.name == ComponentComplexEnum.DataGrid);
			if (data.type == 'file')
				return listComponents.filter((x: any) => x.name == ComponentComplexEnum.UploadFile);
			return listComponents;
		};

		return (
			<Wrapper key={index} margin="0 0 5px 0">
				<WidthBox width="50%">
					<S.Item parents={calcParent(0, data)} color={'#000'}>
						{data.name}
					</S.Item>
				</WidthBox>
				<WidthBox width="50%">
					{data.type != 'object' ? (
						<SystemSelect
							value={
								type === ApiMapType.REQUEST
									? requestDic[data.ref ?? '']
									: responseDic[data.ref ?? '']
							}
							options={getListComponents()}
							onChange={value => saveChangeMap(data, value, type)}
							label="Nome do parâmetro"
						/>
					) : (
						<></>
					)}
				</WidthBox>
			</Wrapper>
		);
	};

	const getApiMapData = (type: ApiMapType): IApiMap[] | undefined => {
		if (!selectedEndpoint) return undefined;

		return application.apis
			?.find(x => x.ref === btnAction?.api?.targetApi)
			?.endpoints?.find(x => x.ref === selectedEndpoint?.value)![type];
	};

	const saveChangeMap = (
		data: IApiMap,
		value: any,
		type: ApiMapType,
		defaultAction?: any,
	): any => {
		const newDicData =
			defaultAction && defaultAction[0]
				? {...defaultAction[0], [data.ref ?? '']: value}
				: {
						...(type === ApiMapType.REQUEST ? requestDic : responseDic),
						[data.ref ?? '']: value,
				  };

		if (type === ApiMapType.REQUEST) setRequestDic(newDicData);
		else setResponseDic(newDicData);

		const keys = Object.keys(newDicData);
		const dataMap: IActionApiMap[] = [];
		keys.forEach(key => {
			dataMap.push({
				targetApiRef: key,
				targetComponentRef: newDicData[key]?.value,
			});
		});

		const newMap = {
			actionType: ActionTypeEnum.Api,
			api: {
				...(defaultAction && defaultAction[1] ? defaultAction[1]?.api : btnAction?.api),
			},
			showLoading: true,
			ref: btnAction && btnAction.ref ? btnAction.ref : undefined,
		};

		if (type === ApiMapType.REQUEST) newMap.api.mapRequest = dataMap;
		else newMap.api.mapResponse = dataMap;

		setBtnAction(newMap);
		return [newDicData, newMap];
	};

	const saveOthers = () => {
		const newMap = {...btnAction};
		if (!newMap.api) newMap.api = {};

		if (newMap) {
			newMap.modal = requestModalConfig;
			newMap.api.modalSuccess = successModalConfig;
			newMap.api.modalFail = failModalConfig;
			newMap.showLoading = true;
			newMap.api.defineFail = defineFail;
			newMap.ref = btnAction && btnAction.ref ? btnAction.ref : undefined;
			setBtnAction(newMap as IAction);
		}
	};

	if (listApis?.length === 0)
		return <S.NoContent>Nenhuma API foi configurada neste projeto</S.NoContent>;

	return (
		<>
			<SizedBox height="10px" />
			<SystemSelect
				value={listApis.find(x => x.value === btnAction?.api?.targetApi)}
				options={listApis}
				onChange={value => {
					setBtnAction({
						...btnAction,
						actionType: ActionTypeEnum.Api,
						api: {
							...btnAction?.api,
							targetApi: value.value ?? '',
						},
					});
				}}
				label="Selecione a API"
			/>

			{btnAction?.api?.targetApi ? (
				<>
					<SizedBox height="10px" />
					<SystemSelect
						value={selectedEndpoint}
						options={listRoutes}
						onChange={value => {
							setSelectedEndpoint(value);
							setBtnAction({
								...btnAction,
								actionType: ActionTypeEnum.Api,
								api: {
									...btnAction?.api,
									targetEndpoint: value.value ?? '',
								},
							});
						}}
						label="Selecione o Endpoint"
					/>
				</>
			) : (
				<></>
			)}

			<SizedBox height="10px" />
			{btnAction?.api?.targetApi ? (
				<Switch
					isOn={btnAction?.api?.isData ?? false}
					handleToggle={() =>
						setBtnAction({
							...btnAction,
							actionType: ActionTypeEnum.Api,
							api: {...btnAction?.api, isData: !(btnAction?.api?.isData ?? false)},
						})
					}
					text="São dados?"
					textColor={'#000'}
				/>
			) : (
				<></>
			)}

			{renderCollapse(ApiMapType.REQUEST)}
			{renderCollapse(ApiMapType.RESPONSE)}
		</>
	);
};

export default API;
