import {IGenerateApi} from 'src/@types/app';
import {Api} from './base';

export class GenerateApi {
	async create(generate: IGenerateApi): Promise<void> {
		const response = await Api.post('/generate', generate, {responseType: 'blob'});

		try {
			const blob = new Blob([response.data], {type: 'application/zip'});
			const href = URL.createObjectURL(blob);

			const link = document.createElement('a');
			link.href = href;
			link.setAttribute('download', 'application.zip');
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			URL.revokeObjectURL(href);
		} catch {
			console.error('Erro to download file');
		}
	}
}
