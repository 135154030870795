/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {useContext, useEffect, useState} from 'react';
import {IFolder} from 'src/@types/app';
import {ApplicationContext} from 'src/context';
import {v4 as uuidV4} from 'uuid';
import {ApplicationApi} from 'src/shared/repositories/application-api';
import {LoadingContext} from 'src/context';
import {Modal} from 'src/shared/components/Complex';
import {
	Button,
	CheckBox,
	ModalTitle,
	SelectIcon,
	SizedBox,
	Wrapper,
} from 'src/shared/components/UI';
import {SystemInput} from 'src/shared/components/System/SystemInput';
import {applicationTheme} from 'src/shared/theme';

type Props = {
	isOpen: boolean;
	onClose: () => void;
	itemSelected?: IFolder;
};

const AddFolder = ({isOpen, onClose, itemSelected}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);
	const [, setLoading] = useContext(LoadingContext);

	const [name, setName] = useState('');
	const [isValid, setIsValid] = useState(false);
	const [useMenu, setUseMenu] = useState(false);
	const [menuName, setMenuName] = useState('');
	const [menuIcon, setMenuIcon] = useState();
	const [updatedItem, setUpdatedItem] = useState<IFolder | undefined>();

	const applicationApi = new ApplicationApi();

	useEffect(() => {
		if (
			name &&
			name != '' &&
			!application.folders
				?.filter(x => x.ref != updatedItem?.ref && x.path === getPath())
				?.map(x => x.name?.toLocaleLowerCase())
				?.includes(name.toLocaleLowerCase())
		)
			setIsValid(true);
		else setIsValid(false);
	}, [name]);

	useEffect(() => {
		if (itemSelected) {
			setName(itemSelected.name as string);
			setUseMenu(itemSelected.menu?.use as boolean);
			setMenuName(itemSelected.menu?.name ?? '');
			setMenuIcon(itemSelected.menu?.icon);
			setIsValid(true);
			setUpdatedItem(itemSelected);
		}
	}, [itemSelected]);

	const clear = () => {
		setName('');
		setUseMenu(false);
		setMenuName('');
		setUpdatedItem(undefined);
		setMenuIcon(undefined);
		onClose();
	};

	const save = async () => {
		try {
			setLoading(true);
			if (updatedItem) {
				const folders = application.folders!.map(x => {
					if (x.ref === updatedItem.ref) {
						x.name = name;
						x.menu = {
							use: useMenu,
							name: useMenu
								? menuName && menuName.trim() != ''
									? menuName
									: name
								: undefined,
							icon: useMenu ? menuIcon : undefined,
						};
					}
					return x;
				});

				await applicationApi.updateFolder({
					id: application.id!,
					folders: JSON.stringify(folders),
				});
			} else {
				const folders = [...(application.folders ?? [])];
				folders.push({
					name: name,
					ref: uuidV4().toString(),
					selected: false,
					path: getPath(),
					menu: {
						use: useMenu,
						name: menuName && menuName.trim() != '' ? menuName : name,
						icon: menuIcon,
					},
				});

				await applicationApi.updateFolder({
					id: application.id!,
					folders: JSON.stringify(folders),
				});
			}
		} finally {
			const app = await applicationApi.getResources(application);
			setApplication(app!);
			setLoading(false);
			clear();
		}
	};

	const getPath = () => {
		const path = application.folders?.find(x => x.selected);
		return path ? `${path.path ? path.path : ''}/${path.name}` : undefined;
	};

	return (
		<Modal isOpen={isOpen} onClose={clear}>
			<>
				<ModalTitle title="Adicionar módulo" />
				<SystemInput
					width="100%"
					value={name}
					onChange={setName}
					label="Nome interno"
					placeholder="Nome interno"
				/>
				<SizedBox height="12px" />
				<CheckBox
					checked={useMenu}
					handleToggle={() => setUseMenu(!useMenu)}
					label="Adicionar ao menu?"
					textColor={'#000'}
					fontWeight="400"
				/>
				<SizedBox height="12px" />

				{(function () {
					if (useMenu) {
						return (
							<>
								<SystemInput
									width="100%"
									value={menuName}
									onChange={setMenuName}
									label="Nome no menu"
									placeholder="Nome que será exibido no menu"
								/>
								<SizedBox height="12px" />
								<SelectIcon
									value={menuIcon}
									onChange={(value: any) => setMenuIcon(value)}
									background="transparent"
									border="1px solid #333"
									textColor={'#000'}
								/>
							</>
						);
					}
				})()}

				<Wrapper margin="20px 0 0 0" justifyContent="end">
					<Button
						text="Cancelar"
						onClick={onClose}
						fill="auto"
						background={applicationTheme.gray[300]}
						textColor={applicationTheme.gray[700]}
						type="ghost"
					/>
					<Button
						text="Salvar"
						onClick={save}
						fill="auto"
						background={applicationTheme.brand[600]}
						disabled={!isValid}
					/>
				</Wrapper>
			</>
		</Modal>
	);
};

export default AddFolder;
