import {IResourceApi} from 'src/@types/app';

export const generateDefaultLayout = (): IResourceApi[] => {
	const resources: IResourceApi[] = [];

	const code = `
    import React, {ReactNode} from 'react';

    type Props = {
      children: ReactNode;
      pageName?: string;
    };

    export const DefaultLayout = ({children, pageName}: Props) => {
      return <div>{children}</div>;
    };

    export default DefaultLayout;
  `;

	resources.push({
		code,
		name: 'index.tsx',
		path: 'src/template/',
	});

	return resources;
};
