/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {useContext, useEffect, useState} from 'react';
import {IApplication} from 'src/@types/app';
import {Modal} from 'src/shared/components/Complex';
import {Button, ModalTitle, Wrapper} from 'src/shared/components/UI';
import {applicationTheme} from 'src/shared/theme';
import {LoadingBarContext, LoadingContext, ProjectContext} from 'src/context';
import {ApplicationApi} from 'src/shared/repositories/application-api';
import {v4 as uuidV4} from 'uuid';
import {ResourceApi} from 'src/shared/repositories/resource-api';
import {AddProjetRender} from './Render';
import {TemplateEnum} from 'src/templates/template-enum';

type Props = {
	isOpen: boolean;
	onClose: () => void;
};

export const AddProject = ({isOpen, onClose}: Props) => {
	const [, setLoading] = useContext(LoadingContext);
	const [, setLoadingBar] = useContext(LoadingBarContext);
	const [project, setProject] = useContext(ProjectContext);

	const [data, setData] = useState<IApplication>();

	const applicationApi = new ApplicationApi();
	const resourceApi = new ResourceApi();

	useEffect(() => {
		const finded = project.applications?.find(x => x.selectedEdit);

		if (finded) setData(finded);
	}, [project]);

	const clear = (clearProject = true) => {
		if (clearProject) {
			setTimeout(() => {
				setData({});
				setProject({
					...project,
					applications: [...(project.applications ?? [])].map(item => {
						item.selectedEdit = false;
						return item;
					}),
				});
			}, 500);
		}
		onClose();
	};

	const save = async () => {
		try {
			setLoading(true);
			refreshBar(true, 0, 3);

			await saveProj({
				...data,
				layout: {
					templateName: TemplateEnum.None,
				},
			});
			refreshBar(true, 1, 3);
		} finally {
			const projectResult = await applicationApi.getAll();
			refreshBar(true, 2, 3);
			if (projectResult) setProject(projectResult);

			refreshBar(true, 3, 3);
			await syncColor();
			setLoading(false);
			refreshBar(false, 0, 0);
			clear(false);
		}
	};

	const saveProj = async (app: IApplication) => {
		if (app.id) {
			await applicationApi.update({
				id: app.id,
				name: app.name ?? '',
				companyName: app.companyName,
				account: app.account ? JSON.stringify(app.account) : undefined,
				git: app.git ? JSON.stringify(app.git) : undefined,
				layout: app.layout ? JSON.stringify(app.layout) : undefined,
				security: app.security ? JSON.stringify(app.security) : undefined,
				translation: app.translation ? JSON.stringify(app.translation) : undefined,
				contextEnvironment: app.userConfiguration
					? JSON.stringify(app.userConfiguration)
					: undefined,
				projectConfiguration: app.projectConfiguration
					? JSON.stringify(app.projectConfiguration)
					: undefined,
			});
		} else {
			await applicationApi.create({
				name: app.name ?? '',
				reference: uuidV4().toString(),
				companyName: app.companyName,
				account: app.account ? JSON.stringify(app.account) : undefined,
				git: app.git ? JSON.stringify(app.git) : undefined,
				layout: app.layout ? JSON.stringify(app.layout) : undefined,
				security: app.security ? JSON.stringify(app.security) : undefined,
				translation: app.translation ? JSON.stringify(app.translation) : undefined,
				contextEnvironment: app.userConfiguration
					? JSON.stringify(app.userConfiguration)
					: undefined,
				projectConfiguration: app.projectConfiguration
					? JSON.stringify(app.projectConfiguration)
					: undefined,
			});
		}
	};

	const syncColor = async () => {
		try {
			if (!data?.id) return;

			const resources = await resourceApi.getAll(data.id!);
			if (!resources) return;

			for (const res of resources ?? []) {
				res.widgets?.forEach(widget => {
					Object.keys(data.layout?.templateConfig).forEach(key => {
						if (widget.name === key) {
							widget.properties = {
								...widget.properties,
								...data.layout?.templateConfig[key],
							};
						}
					});
				});

				await resourceApi.updateComplex(res);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const refreshBar = (show: boolean, progress: number, total: number) => {
		setLoadingBar({
			show,
			total,
			progress,
		});
	};

	return (
		<Modal isOpen={isOpen} onClose={clear} width="840px" maxWidth="840px">
			<>
				<ModalTitle title="Configure seu projeto" subtitle="Dados referentes ao projeto" />

				<AddProjetRender data={data} setData={setData} />

				<Wrapper justifyContent="end">
					<Button
						text="Cancelar"
						onClick={clear}
						fill="auto"
						background={applicationTheme.gray[300]}
						textColor={applicationTheme.gray[700]}
						type="ghost"
					/>
					<Button
						text="Salvar"
						onClick={save}
						fill="auto"
						background={applicationTheme.brand[600]}
					/>
				</Wrapper>
			</>
		</Modal>
	);
};
