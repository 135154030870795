import React, {useState} from 'react';
import Routing from 'src/router';
import {IApplication, IBuilder, IProject, IUser} from './@types/app';
import {
	ApplicationContext,
	CustomModalContext,
	ProjectContext,
	LoadingContext,
	SearchModalContext,
	LoadingBarContext,
	UserContext,
	FloatMenuContext,
	BuilderContext,
} from 'src/context';
import {ThemeProvider} from 'styled-components';
import theme from 'src/shared/theme';
import CustomModal, {ICustomModal} from 'src/shared/components/Global/CustomModal';
import Loading, {ILoadingBar} from 'src/shared/components/Global/Loading';
import SearchModal, {ISearchModal} from 'src/shared/components/Global/SearchModal';
import FloatMenu, {
	IFloatMenu,
} from 'src/pages/builder/Builder/components/Expression/ExpressionItem/component/FloatMenu';
import {SnackbarProvider} from 'notistack';

const App = () => {
	const [application, setApplication] = useState<IApplication>({});
	const [project, setProject] = useState<IProject>({});
	const [customModal, setCustomModal] = useState<ICustomModal>({});
	const [isLoading, setIsLoading] = useState(false);
	const [seachModal, setSearchModal] = useState<ISearchModal>({});
	const [loadingBar, setLoadingBar] = useState<ILoadingBar>({});
	const [user, setUser] = useState<IUser>({});
	const [floatMenu, setFloatMenu] = useState<IFloatMenu>({});
	const [builder, setBuilder] = useState<IBuilder>({});

	return (
		<SnackbarProvider maxSnack={3}>
			<UserContext.Provider value={[user, setUser]}>
				<ProjectContext.Provider value={[project, setProject]}>
					<ApplicationContext.Provider value={[application, setApplication]}>
						<BuilderContext.Provider value={[builder, setBuilder]}>
							<CustomModalContext.Provider value={[customModal, setCustomModal]}>
								<LoadingContext.Provider value={[isLoading, setIsLoading]}>
									<SearchModalContext.Provider
										value={[seachModal, setSearchModal]}>
										<LoadingBarContext.Provider
											value={[loadingBar, setLoadingBar]}>
											<FloatMenuContext.Provider
												value={[floatMenu, setFloatMenu]}>
												<ThemeProvider theme={theme}>
													<Routing />
													<CustomModal />
													<SearchModal />
													<Loading />
													<FloatMenu />
												</ThemeProvider>
											</FloatMenuContext.Provider>
										</LoadingBarContext.Provider>
									</SearchModalContext.Provider>
								</LoadingContext.Provider>
							</CustomModalContext.Provider>
						</BuilderContext.Provider>
					</ApplicationContext.Provider>
				</ProjectContext.Provider>
			</UserContext.Provider>
		</SnackbarProvider>
	);
};

export default App;
