/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useContext, useEffect, useState} from 'react';
import {IComponent, IOption} from 'src/@types/app';
import {Modal} from 'src/shared/components/Complex';
import {Button, Select, WidthBox, Wrapper} from 'src/shared/components/UI';
import {ApplicationContext, LoadingContext} from 'src/context';
import {DragDropController} from 'src/helpers/methods/drag-grop-element';
import {ApplicationApi} from 'src/shared/repositories/application-api';

type Props = {
	isOpen: boolean;
	onClose: () => void;
	data?: any;
	dragDropController: DragDropController;
};

export const TabJoin = ({isOpen, onClose, data, dragDropController}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);
	const [, setLoading] = useContext(LoadingContext);

	const [tabs, setTabs] = useState<IOption[]>([]);
	const [tab, setTab] = useState<IOption>({value: undefined, label: 'Selecione...'});

	const applicationApi = new ApplicationApi();

	useEffect(() => {
		if (
			data.resources?.widgets?.find((x: IComponent) => x.name === 'Tab')?.properties?.tabs &&
			data.resources?.widgets?.find((x: IComponent) => x.name === 'Tab')?.properties?.tabs
				?.length > 0
		) {
			const tabComponent = data.resources?.widgets?.find((x: IComponent) => x.name === 'Tab');
			const optionData: IOption[] = [{value: undefined, label: 'Selecione...'}];

			tabComponent?.properties?.tabs.forEach((item: any) => {
				optionData.push({
					value: item.ref,
					label: item.name,
					custom: {
						ident: tabComponent.ref,
					},
				});
			});

			setTabs(optionData);
		}
	}, [isOpen]);

	return (
		<Modal isOpen={isOpen} onClose={onClose} width="70%">
			<>
				<WidthBox margin="12px 0">
					<Select
						value={tab}
						options={tabs}
						onChange={setTab}
						label="Tipo"
						inputType="light"
						background="transparent"
						border="1px solid #333"
						textColor={'#000'}
					/>
				</WidthBox>

				<Wrapper margin="20px 0 0 0" justifyContent="end">
					<Button
						text="Salvar"
						fill="auto"
						themeStyle="primary"
						onClick={async () => {
							setLoading(true);
							await dragDropController.transferAndRemoveResource(
								data.originResult,
								data.originItem,
								data.destinyResult,
								data.destinyItem,
								tab.custom?.ident,
								tab.value,
							);

							const app = await applicationApi.getResources(application);
							setApplication(app!);
							setLoading(false);
							onClose();
						}}
					/>
					<Button
						text="Fechar"
						fill="auto"
						themeStyle="primary"
						onClick={onClose}
						type="ghost"
					/>
				</Wrapper>
			</>
		</Modal>
	);
};
