import {applicationTheme} from 'src/shared/theme';
import styled from 'styled-components';

export const Container = styled.div`
	width: calc(30%);
	background: #fff;
	overflow-y: auto;
	z-index: 100;
	max-height: calc(100vh - 72px);
	max-width: 360px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow-x: hidden;
	transition: 0.2s;
	border-left: 1px solid ${applicationTheme.gray[300]};
`;

export const Item = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 8px;
`;

export const Label = styled.div`
	width: 45%;
	color: ${applicationTheme.gray[800]};
	font-family: 'Inter';
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	text-align: left;
`;

export const ValueInput = styled.div`
	width: 50%;
	color: black;
`;

type ColorButtonProps = {
	background?: string;
};
export const ColorButton = styled.div<ColorButtonProps>`
	width: 45%;
	margin-left: 55%;
	height: 25px;
	border-radius: 4px;
	background: ${props =>
		props.background && props.background != '' ? props.background : '#555'};
	margin-top: 5px;
	border: 0.5px solid white;
	cursor: pointer;
	position: relative;
`;

type PickerContentProps = {
	top: number;
	left: number;
};
export const PickerContent = styled.div<PickerContentProps>`
	position: absolute;
	z-index: 1000;
	top: calc(${props => props.top} + 10) px;
	left: ${props => props.left}px;
`;

export const HideMenu = styled.div`
	display: flex;
	justify-content: end;
	border-top: 1px solid #000;
	box-sizing: border-box;
	padding: 15px 20px;
	width: 100%;
	font-size: 14px;
	color: #000;
	cursor: pointer;
`;
