import React, {ReactNode} from 'react';
import * as S from './styles';

type Props = {
	children: ReactNode;
	width?: string;
	margin?: string;
	minWidth?: string;
};

const WidthBox = ({children, width = '100%', margin = '0', minWidth = '0px'}: Props) => {
	return (
		<S.WidthBox width={width} margin={margin} minWidth={minWidth}>
			{children}
		</S.WidthBox>
	);
};

export default WidthBox;

export const widthBoxProperties = {
	children: 'ReactNode',
	width: 'string',
	margin: 'string',
};
