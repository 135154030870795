import {IOption} from 'src/@types/app';

export const comparetorList: IOption[] = [
	{
		value: undefined,
		label: 'Selecione',
	},
	{
		value: '==',
		label: 'igual',
	},
	{
		value: '>',
		label: 'maior',
	},
	{
		value: '<',
		label: 'menor',
	},
	{
		value: '!=',
		label: 'diferente',
	},
	{
		value: '!= null',
		label: 'não nulo',
	},
	{
		value: '== null',
		label: 'igual nulo',
	},
];

export const comparetorJavaScriptList: IOption[] = [
	{
		value: undefined,
		label: 'Selecione',
	},
	{
		value: '==',
		label: 'igual',
	},
	{
		value: '>',
		label: 'maior',
	},
	{
		value: '<',
		label: 'menor',
	},
	{
		value: '!=',
		label: 'diferente',
	},
	{
		value: '!= null',
		label: 'não nulo',
	},
	{
		value: '== null',
		label: 'igual nulo',
	},
	{
		value: '!',
		label: '!',
	},
	{
		value: '!!',
		label: '!!',
	},
];

export const comparetorEOuList: IOption[] = [
	{
		value: undefined,
		label: 'Selecione',
	},
	{
		value: '&&',
		label: 'E',
	},
	{
		value: '||',
		label: 'OU',
	},
];
