import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	height: calc(100vh - 62px);
	max-height: calc(100vh - 72px);
	position: relative;
`;

type ScreenProps = {
	expanded: boolean;
};
export const Screen = styled.div<ScreenProps>`
	background: white;
	width: 100%;
	height: ${props => (props.expanded ? '100%' : '70vh')};
	border-radius: 2px;
`;

type ModalScreenProps = {
	width: string;
};
export const ModalScreen = styled.div<ModalScreenProps>`
	background: white;
	width: ${props => (props.width && props.width != '' ? props.width : '95%')};
	max-width: 1000px;
	border-radius: 3px;
	padding: 1%;
`;

type BarToolsProps = {
	expanded: boolean;
};
export const BarTools = styled.div<BarToolsProps>`
	display: flex;
	align-items: flex-start;
	justify-content: start;
	width: 100%;
	background: #161b22;
	margin-bottom: ${props => (props.expanded ? '0' : '2%')};
	padding: 8px 12px;
	box-sizing: border-box;
`;

export const ContentTools = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	width: 100%;
	height: 100%;
`;

export const Test = styled.div`
	box-sizing: border-box;
	width: 99.5%;
`;
