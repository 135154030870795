import {IOption} from 'src/@types/app';
import {DatabaseColumnEnum} from 'src/@types/enums';

export const DatabaseTypeList: IOption[] = [
	{
		label: 'Selecione...',
		value: undefined,
	},
	{
		label: 'Texto',
		value: DatabaseColumnEnum.String,
	},
	{
		label: 'Número inteiro pequeno (tinyint)',
		value: DatabaseColumnEnum.Byte,
	},
	{
		label: 'Número inteiro curto (smallint)',
		value: DatabaseColumnEnum.Int16,
	},
	{
		label: 'Número inteiro (int)',
		value: DatabaseColumnEnum.Int32,
	},
	{
		label: 'Número inteiro longo (long)',
		value: DatabaseColumnEnum.Int64,
	},
	{
		label: 'Número decimal',
		value: DatabaseColumnEnum.Decimal,
	},
	{
		label: 'Verdadeiro/Falso',
		value: DatabaseColumnEnum.Boolean,
	},
	{
		label: 'Data e Hora',
		value: DatabaseColumnEnum.Date,
	},
	{
		label: 'Hora',
		value: DatabaseColumnEnum.TimeOnly,
	},
	{
		label: 'Data',
		value: DatabaseColumnEnum.DateOnly,
	},
];
