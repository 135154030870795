/* eslint-disable @typescript-eslint/no-explicit-any */
import {IResource, IWorkFlowItem} from 'src/@types/app';
import {WorkFlowTypeEnum} from 'src/@types/enums';
import {builderJavaScriptExpression} from 'src/pages/builder/Builder/components/Expression/builder/buildJavaScript';
import {IConditionFrontComp} from '../../component/enableDisableFieldsComp';
import {isDisableOperationsJavaScript, isOperationsJavaScript} from '../../helper';

export const buildWorkFlowFrontCode = (
	workFlowItem: IWorkFlowItem[],
	resource: IResource,
): string => {
	let code = '';
	let resultCode = '';

	const formDataVars: any[] = [];

	workFlowItem.forEach(flowItem => {
		if (flowItem.type === WorkFlowTypeEnum.AttrVariable) {
			const resultCode = buildAttrVariable(flowItem);
			const flowValues = flowItem.values;
			if (!includesVar(flowValues.propName)) {
				formDataVars.push(resultCode);
			} else {
				code += resultCode;
			}
		}
		if (flowItem.type === WorkFlowTypeEnum.Variable) code += buildVariable(flowItem);
		if (flowItem.type === WorkFlowTypeEnum.ShowHideFields)
			code += buildShowHideFields(flowItem, resource);
		if (flowItem.type === WorkFlowTypeEnum.EnableDisableFields)
			code += buildEnableDisableFields(flowItem, resource);
	});

	resultCode += `useEffect(() => {
		${code}
	}, [formData]);\n\n`;

	if (formDataVars.length > 0) {
		resultCode += `useEffect(() => {
			setFormData({
			...formData,
			${formDataVars.join(',')}
		});
	}, []);`;
	}

	return resultCode;
};

const buildAttrVariable = (flowItem: IWorkFlowItem): string => {
	const flowValues = flowItem.values;
	let varName = '';
	let code = '';
	if (includesVar(flowValues.propName)) {
		varName = replaceVar(flowValues.propName);
	} else {
		varName = `${flowValues.propName}`;
	}
	if (flowValues.subField) varName += `.${flowValues.subFieldName}`;

	if (includesVar(flowValues.propName)) {
		code = `${varName} = `;
		if (flowValues.expression) code += builderJavaScriptExpression(flowValues.expression);
		code = getFormatCode(code);
	} else {
		code += `${varName}: ${builderJavaScriptExpression(flowValues.expression)}`;
	}

	return code;
};

const buildVariable = (flowItem: IWorkFlowItem): string => {
	if (!flowItem?.values?.variableName) return '';

	let code = `let ${flowItem.values.variableName} = `;
	if (flowItem.values.expression) code += builderJavaScriptExpression(flowItem.values.expression);
	return getFormatCode(code);
};

const buildShowHideFields = (flowItem: IWorkFlowItem, resource: IResource): string => {
	let code = 'if (';
	flowItem.values.conditions?.forEach((cond: IConditionFrontComp, idx: number) => {
		let prop1 = '';
		let prop2 = '';

		if (!includesVar(cond.field1Name ?? '')) prop1 = `formData?.${cond.field1Name}`;
		else prop1 = replaceVar(cond.field1Name);
		if (!includesVar(cond.field2Name ?? '')) prop2 = `formData?.${cond.field2Name}`;
		else prop2 = replaceVar(cond.field2Name);

		if (isOperationsJavaScript(cond.operator ?? '')) code += `${cond.operator}${prop1}`;
		else if (!isDisableOperationsJavaScript(cond.operator ?? ''))
			code += `${prop1} ${cond.operator} ${prop2}`;
		else code += `${prop1} ${cond.operator}`;

		if (flowItem.values.conditions?.length - 1 != idx) code += ` ${cond.ext} `;
	});

	code += `)\n{{IF_YES_CODE}} else {{IF_NO_CODE}}`;
	let subCode = 'setHideFields({...hideFields,';
	let subCode2 = 'setHideFields({...hideFields,';

	flowItem.values.showHideList?.forEach((item: any) => {
		subCode += `${item.label}: ${item.value ? 'true' : 'false'},`;
		subCode2 += `${item.label}: ${item.value ? 'false' : 'true'},`;
		resource.widgets = resource.widgets?.map(widget => {
			if (widget.ref === item.ident) {
				widget.properties.hide = `hideFields['${item.label}']`;
			}
			return widget;
		});
	});

	subCode += '});';
	subCode2 += '});';
	code = code.replace('{IF_YES_CODE}', subCode);
	code = code.replace('{IF_NO_CODE}', subCode2);

	return code;
};

const buildEnableDisableFields = (flowItem: IWorkFlowItem, resource: IResource): string => {
	let code = 'if (';
	flowItem.values.conditions?.forEach((cond: IConditionFrontComp, idx: number) => {
		let prop1 = '';
		let prop2 = '';

		if (!includesVar(cond.field1Name ?? '')) prop1 = `formData?.${cond.field1Name}`;
		else prop1 = replaceVar(cond.field1Name);
		if (!includesVar(cond.field2Name ?? '')) prop2 = `formData?.${cond.field2Name}`;
		else prop2 = replaceVar(cond.field2Name);

		if (isOperationsJavaScript(cond.operator ?? '')) code += `${cond.operator}${prop1}`;
		else if (!isDisableOperationsJavaScript(cond.operator ?? ''))
			code += `${prop1} ${cond.operator} ${prop2}`;
		else code += `${prop1} ${cond.operator}`;

		if (flowItem.values.conditions?.length - 1 != idx) code += ` ${cond.ext} `;
	});

	code += `)\n{{IF_YES_CODE}} else {{IF_NO_CODE}}`;
	let subCode = 'setDisableFields({...disableFields, ';
	let subCode2 = 'setDisableFields({...disableFields,';

	flowItem.values.enableDisableList?.forEach((item: any) => {
		subCode += `${item.label}: ${item.value ? 'true' : 'false'},`;
		subCode2 += `${item.label}: ${item.value ? 'false' : 'true'},`;
		resource.widgets = resource.widgets?.map(widget => {
			if (widget.ref === item.ident) {
				widget.properties.disabled = `disableFields['${item.label}']`;
			}
			return widget;
		});
	});

	subCode += '});';
	subCode2 += '});';
	code = code.replace('{IF_YES_CODE}', subCode);
	code = code.replace('{IF_NO_CODE}', subCode2);

	return code;
};

const includesVar = (prop: string): boolean => {
	if (prop.includes('var -')) return true;
	if (prop.includes('for -')) return true;
	return false;
};

const replaceVar = (prop: string | null | undefined): string => {
	prop = prop?.replace('var - ', '');
	prop = prop?.replace('for - ', '');
	return prop ?? '';
};

const getFormatCode = (code: string) => `${code};\n`;
