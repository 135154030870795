/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useContext, useEffect, useState} from 'react';
import {IResource} from 'src/@types/app';
import {ApplicationContext} from 'src/context';
import {ResourceTypeData} from 'src/helpers/select-data/resourceTypeList';
import {v4 as uuidV4} from 'uuid';
import {IOption} from 'src/shared/components/UI/Select';
import {upperFirstWord} from 'src/helpers/methods/text-methods';
import {ResourceApi} from 'src/shared/repositories/resource-api';
import {ApplicationApi} from 'src/shared/repositories/application-api';
import {LoadingContext} from 'src/context';
import {Modal} from 'src/shared/components/Complex';
import {
	Button,
	CheckBox,
	Input,
	ModalTitle,
	SelectIcon,
	SizedBox,
	WidthBox,
	Wrapper,
} from 'src/shared/components/UI';
import {SystemInput} from 'src/shared/components/System/SystemInput';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import {applicationTheme} from 'src/shared/theme';

type Props = {
	isOpen: boolean;
	onClose: () => void;
	itemSelected?: IResource;
};

const AddResource = ({isOpen, onClose, itemSelected}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);
	const [, setLoading] = useContext(LoadingContext);

	const [name, setName] = useState('');
	const [typeRource, setTypeRource] = useState<IOption>();
	const [useMenu, setUseMenu] = useState(false);
	const [menuName, setMenuName] = useState('');
	const [isValid, setIsValid] = useState(false);
	const [menuIcon, setMenuIcon] = useState();
	const [updatedItem, setUpdatedItem] = useState<IResource | undefined>();
	const [isRoot, setIsRoot] = useState(false);
	const [useDefaultLayout, setUseDefaultLayout] = useState(true);
	const [isLogin, setIsLogin] = useState(false);

	const resourceApi = new ResourceApi();
	const applicationApi = new ApplicationApi();

	useEffect(() => {
		if (
			name &&
			name != '' &&
			typeRource &&
			typeRource.value != undefined &&
			!application.resources
				?.filter(x => x.ref != updatedItem?.ref)
				?.map(x => x.name?.toLocaleLowerCase())
				?.includes(name.toLocaleLowerCase())
		)
			setIsValid(true);
		else setIsValid(false);
	}, [name, typeRource]);

	useEffect(() => {
		if (itemSelected) {
			setName(itemSelected.name as string);
			setTypeRource(ResourceTypeData.find(x => x.value === itemSelected.type));
			setUseMenu(itemSelected.menu?.use as boolean);
			setMenuName(itemSelected.menu?.name ?? '');
			setMenuIcon(itemSelected.menu?.icon);
			setIsValid(true);
			setUpdatedItem(itemSelected);
			setIsRoot(itemSelected.isRoot ?? false);
			setUseDefaultLayout(itemSelected.useDefaultLayout ?? true);
			setIsLogin(itemSelected.isLogin ?? false);
		}
	}, [itemSelected]);

	const clear = () => {
		setName('');
		setTypeRource(ResourceTypeData.find(x => x.value === undefined));
		setUseMenu(false);
		setMenuName('');
		setUpdatedItem(undefined);
		setMenuIcon(undefined);
		onClose();
		setIsRoot(false);
		setUseDefaultLayout(true);
		setIsLogin(false);
	};

	const save = async () => {
		try {
			setLoading(true);
			if (updatedItem) {
				for (const item of application.resources!) {
					if (item.ref === updatedItem.ref) {
						await resourceApi.update({
							id: item.id!,
							name: upperFirstWord(name),
							menu: JSON.stringify({
								use: useMenu,
								name: useMenu
									? menuName && menuName.trim() != ''
										? menuName
										: name
									: undefined,
								icon: useMenu ? menuIcon : undefined,
							}),
							width: item.width ?? '',
							isLogin: item.isLogin ?? false,
							isRoot: isRoot,
							path: item.path ?? '',
							type: typeRource?.value,
							useDefaultLayout: item.useDefaultLayout ?? true,
							databaseTableRef: '',
						});
					} else if (isRoot && item.isRoot) {
						await resourceApi.update({
							id: item.id!,
							name: upperFirstWord(item.name ?? ''),
							menu: JSON.stringify({
								use: item.menu!.use,
								name: item.menu!.name,
								icon: item.menu!.icon,
							}),
							width: item.width ?? '',
							isLogin: item.isLogin ?? false,
							isRoot: false,
							path: item.path ?? '',
							type: item.type!,
							useDefaultLayout: item.useDefaultLayout ?? true,
							databaseTableRef: item.databaseTableRef ?? '',
						});
					}
				}
			} else {
				const path = application.folders?.find(x => x.selected);
				await resourceApi.create({
					databaseTableRef: '',
					name: upperFirstWord(name),
					isLogin,
					isRoot,
					menu: JSON.stringify({
						use: useMenu,
						name: menuName && menuName.trim() != '' ? menuName : name,
						icon: menuIcon,
					}),
					path: path ? `${path.path ? path.path : ''}/${path.name}` : '',
					reference: uuidV4().toString(),
					type: typeRource?.value,
					useDefaultLayout,
					width: '',
					applicationId: application.id!,
				});
			}
		} finally {
			const app = await applicationApi.getResources(application);
			setApplication(app!);
			setLoading(false);
			clear();
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={clear}>
			<>
				<ModalTitle title="Adicionar recurso" />
				<Wrapper>
					<SystemInput
						width="50%"
						value={name}
						onChange={setName}
						label="Nome interno"
						placeholder="Nome interno"
						mask="Sem espaço"
					/>
					<SystemSelect
						width="50%"
						value={typeRource}
						options={ResourceTypeData}
						onChange={setTypeRource}
						label="Tipo"
						placeholder="Tipo"
					/>
				</Wrapper>

				<SizedBox height="12px" />

				<Wrapper>
					<WidthBox width="50%">
						<CheckBox
							checked={useMenu}
							handleToggle={() => setUseMenu(!useMenu)}
							label="Adicionar ao menu?"
							textColor={'#000'}
							fontWeight="400"
						/>
					</WidthBox>
					<WidthBox width="50%">
						<CheckBox
							checked={useDefaultLayout}
							handleToggle={() => setUseDefaultLayout(!useDefaultLayout)}
							label="Utilizar layout padrão?"
							textColor={'#000'}
							fontWeight="400"
						/>
					</WidthBox>
				</Wrapper>
				<Wrapper>
					<WidthBox width="50%">
						<CheckBox
							checked={isRoot}
							handleToggle={() => setIsRoot(!isRoot)}
							label="Página inicial?"
							textColor={'#000'}
							fontWeight="400"
						/>
					</WidthBox>
					<WidthBox width="50%">
						<CheckBox
							checked={isLogin}
							handleToggle={() => setIsLogin(!isLogin)}
							label="Página de autenticação?"
							textColor={'#000'}
							fontWeight="400"
						/>
					</WidthBox>
				</Wrapper>

				<SizedBox height="12px" />

				{(function () {
					if (useMenu) {
						return (
							<>
								<Input
									value={menuName}
									onChange={setMenuName}
									label="Nome no menu"
									inputType="light"
									border="1px solid #333"
									background="transparent"
									textColor={'#000'}
								/>
								<SizedBox height="12px" />
								<SelectIcon
									value={menuIcon}
									onChange={(value: any) => setMenuIcon(value)}
									background="transparent"
									border="1px solid #333"
									textColor={'#000'}
								/>
							</>
						);
					}
				})()}

				<Wrapper margin="20px 0 0 0" justifyContent="end">
					<Button
						text="Cancelar"
						onClick={clear}
						fill="auto"
						background={applicationTheme.gray[300]}
						textColor={applicationTheme.gray[700]}
						type="ghost"
					/>
					<Button
						text={itemSelected ? 'Editar' : 'Salvar'}
						onClick={save}
						fill="auto"
						background={applicationTheme.brand[600]}
						disabled={!isValid}
					/>
				</Wrapper>
			</>
		</Modal>
	);
};

export default AddResource;
