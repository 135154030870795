import {IOption} from 'src/@types/app';
import {IndexType} from 'src/@types/enums';

export const IndexTypeList: IOption[] = [
	{
		value: undefined,
		label: 'Selecione...',
	},
	{
		value: IndexType.IndiceComum,
		label: 'Índice',
	},
	{
		value: IndexType.IndiceUnico,
		label: 'Chave única',
	},
];
