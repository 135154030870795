/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {IApplication, ITable, IResourceApi} from 'src/@types/app';
import {dotnetFormatScreenName, dotnetSchemaName} from '../core/dotnet-formatter';
import {getConstraintName} from 'src/helpers/methods/database-to-screen';
import {substringText} from 'src/helpers/methods/text-methods';
import {backFormatPropertyName} from 'src/shared/engine-back/common/common-formatters';
import {DatabaseColumnEnum} from 'src/@types/enums';

export const generateData = (app: IApplication): IResourceApi[] => {
	const resources: IResourceApi[] = [];
	const projName = app.name!.replaceAll(' ', '');
	const tables = app.database!.tables!;
	const hasSchemas = true;

	resources.push(getContext(projName, hasSchemas, tables));
	resources.push(...getMappings(projName, hasSchemas, tables, app));
	resources.push(getUnitOfWork(projName));
	resources.push(getRepository(projName));
	if (!!app.database?.tables?.find(table => table.isUser))
		resources.push(getUserRepository(app, projName));

	return resources;
};

const getContext = (projName: string, hasSchemas: boolean, tables: ITable[]): IResourceApi => {
	let imports = !hasSchemas ? `using ${projName}.Domain.Models;` : '';

	if (hasSchemas) {
		Array.from(new Set(tables.map(x => x.schema) ?? [])).forEach(schema => {
			imports += `using ${projName}.Domain.Models.${dotnetSchemaName(schema!)};\n`;
		});
	}

	let builders = '';

	tables?.forEach(table => {
		builders += `		modelBuilder.ApplyConfiguration(new ${dotnetFormatScreenName(
			table.name!,
		)}Map());\n`;
	});

	let dbSets = '';

	tables.forEach(table => {
		dbSets += `	public DbSet<${dotnetFormatScreenName(table.name!)}>? ${dotnetFormatScreenName(
			table.name!,
		)} { get; set; }\n`;
	});

	const code = `using Microsoft.EntityFrameworkCore;
using ${projName}.Infra.Data.Mappings;
${imports}
namespace ${projName}.Infra.Data.Context;

/// <summary>
/// Contexto responsável por realizar operações no banco de dados
/// </summary>
public class DataContext : DbContext
{
	/// <summary>
	/// Construtor
	/// </summary>
	/// <param name="options">Opções</param>
    public DataContext(DbContextOptions<DataContext> options) : base(options) { }

    protected override void OnModelCreating(ModelBuilder modelBuilder)
    {
${builders}
    }

${dbSets}
}`;

	return {
		path: `src/${projName}.Infra.Data/Context/`,
		name: 'DataContext.cs',
		code: code,
	};
};

const getMappings = (
	projName: string,
	hasSchemas: boolean,
	tables: ITable[],
	app: IApplication,
): IResourceApi[] => {
	const resources: IResourceApi[] = [];

	tables.forEach(table => {
		const path = hasSchemas ? `${dotnetSchemaName(table.schema!)}/` : '';
		resources.push({
			path: `src/${projName}.Infra.Data/Mappings/${path}`,
			name: `${dotnetFormatScreenName(table.name!)}Map.cs`,
			code: getMappingCode(projName, table, tables, hasSchemas, app),
		});
	});

	return resources;
};

const getMappingCode = (
	projName: string,
	table: ITable,
	tables: ITable[],
	hasSchemas: boolean,
	app: IApplication,
): string => {
	let properties = '';
	const idColumn = table.columns?.find(x => x.autoIncremente && x.isPrimaryKey);

	table.columns
		?.filter(x => !x.autoIncremente)
		.forEach(column => {
			const refTable = tables.find(x => x.ref == column.constraint);
			if (column.constraint && refTable && table.ref != refTable.ref) {
				const columnName = getConstraintName(backFormatPropertyName(column.name!));

				properties += `
		builder
			.HasOne(p => p.${columnName})
			.WithMany()
			.HasForeignKey("${column.name}")
			.OnDelete(DeleteBehavior.Restrict)`;
			} else {
				const decimalScale = column.scale ?? 2;
				const decimalCode =
					column.type === DatabaseColumnEnum.Decimal
						? column.precision
							? `\n			.HasColumnType("DECIMAL(${column.precision}, ${decimalScale})")`
							: `\n			.HasColumnType("DECIMAL(15, 2)")`
						: '';

				properties += `
		builder
			.Property(p => p.${backFormatPropertyName(column.name!)})${decimalCode}
			.HasColumnName("${column.name}")`;

				if (column.maxLength) properties += `\n			.HasMaxLength(${column.maxLength})`;
				if (!column.nullable) properties += `\n			.IsRequired()`;
			}

			properties += ';\n';
		});

	if (!table.isUser) {
		app.database?.tables
			?.filter(t => t.ref != table.ref)
			.forEach(t => {
				const constTable = t.columns?.find(column => column.constraint == table.ref);

				if (!!constTable) {
					properties += `
		builder
			.HasMany(p => p.${dotnetFormatScreenName(t.name ?? '')}s)
			.WithOne(p => p.${dotnetFormatScreenName(table.name ?? '')});\n`;
				}
			});
	}

	table.uniqueIndex?.forEach(columnIdx => {
		if (columnIdx.fieldRefs && columnIdx.fieldRefs.length === 1) {
			const columnItem = table.columns?.find(x => x.ref === (columnIdx.fieldRefs as any)[0]);
			const columnName = getConstraintName(backFormatPropertyName(columnItem?.name ?? ''));
			properties += `\n		builder.HasIndex(p => p.${columnName}).IsUnique();\n`;
		}
		if (columnIdx.fieldRefs && columnIdx.fieldRefs.length > 1) {
			let propDataIndex = '';
			columnIdx.fieldRefs.forEach(fieldRef => {
				const columnItem = table.columns?.find(x => x.ref === fieldRef);
				const columnName = getConstraintName(
					backFormatPropertyName(columnItem?.name ?? ''),
				);
				propDataIndex += `p.${columnName},`;
			});

			properties += `		builder.HasIndex(p => new { ${propDataIndex} }).IsUnique();\n`;
		}
	});

	properties = substringText(properties);

	const mDatabase = app.database?.databases?.find(x => x.ref === table.databaseRef);
	const schemaString = mDatabase?.useSchema ? `, "${dotnetSchemaName(table.schema!)}"` : '';

	return `using Microsoft.EntityFrameworkCore;
using Microsoft.EntityFrameworkCore.Metadata.Builders;
using ${projName}.Domain.Models${hasSchemas ? `.${dotnetSchemaName(table.schema!)}` : ''};

namespace ${projName}.Infra.Data.Mappings;

/// <summary>
/// Responsável por realizar o mapeamento da tabela referente a ${dotnetFormatScreenName(
		table.name!,
	)}
/// </summary>
public class ${dotnetFormatScreenName(
		table.name!,
	)}Map : IEntityTypeConfiguration<${dotnetFormatScreenName(table.name!)}>
{
	public void Configure(EntityTypeBuilder<${dotnetFormatScreenName(table.name!)}> builder)
	{
		builder
			.ToTable("${table.name}"${schemaString});

		builder
			.HasKey(p => p.Id);

		builder
			.Property(p => p.Id)
			.HasColumnName("${idColumn?.name}");
		${properties}
	}
}`;
};

const getUnitOfWork = (projName: string): IResourceApi => {
	const code = `using ${projName}.Domain.Interfaces;
using ${projName}.Infra.Data.Context;

namespace ${projName}.Infra.Data.UoW;

/// <summary>
/// Responsável por armazenar informações referentes aos processamentos no banco de dados
/// </summary>
public class UnitOfWork : IUnitOfWork
{
	private readonly DataContext _context;

	/// <summary>
	/// Construtor
	/// </summary>
	/// <param name="context">Contexto</param>
	public UnitOfWork(DataContext context)
	{
		_context = context;
	}

	/// <summary>
	/// Responsável por comitar os dados no banco
	/// </summary>
	public async Task<bool> CommitAsync()
	{
		var result = await _context.SaveChangesAsync();
		return result > 0;
	}

	/// <summary>
	/// Dispose
	/// </summary>
	public void Dispose()
	{
		_context.Dispose();
	}
}
	`;

	return {
		path: `src/${projName}.Infra.Data/UoW/`,
		name: 'UnitOfWork.cs',
		code: code,
	};
};

const getRepository = (projName: string): IResourceApi => {
	const code = `using Microsoft.EntityFrameworkCore;
using ${projName}.Domain.Interfaces.Repositories.Base;
using ${projName}.Domain.Models.Base;
using ${projName}.Infra.Data.Context;
using System.Linq.Expressions;

namespace ${projName}.Infra.Data.Repositories.Base;

/// <summary>
/// Responsável por realizar operações no banco de dados
/// </summary>
public class Repository<T> : IRepository<T> where T : BaseEntity
{
	private readonly DbSet<T> _dbSet;

	public Repository(DataContext context)
	{
		_dbSet = context.Set<T>();
	}

	/// <summary>
	/// Obtém registros pelo Id
	/// </summary>
	public async Task<T?> ObterPorIdAsync(int id)
	{
		var model = await _dbSet.FindAsync(id);
		return model;
	}

	/// <summary>
	/// Obtém registro
	/// </summary>
	public async Task<T?> Obter(Expression<Func<T, bool>> predicate)
	{
		var model = await _dbSet.FirstOrDefaultAsync(predicate);
		return model;
	}

	/// <summary>
	/// Lisa registros
	/// </summary>
	public async Task<List<T>?> Listar(Expression<Func<T, bool>> predicate)
	{
		var model = await _dbSet.Where(predicate).ToListAsync();
		return model;
	}

	/// <summary>
	/// Obtém todos os registros
	/// </summary>
	public IQueryable<T> ObterTodos()
	{
		return _dbSet.AsQueryable();
	}

	/// <summary>
	/// Cria um registro
	/// </summary>
	public T Criar(T model)
	{
		var result = _dbSet.Add(model);
		return result.Entity;
	}

	/// <summary>
	/// Atualiza um registro
	/// </summary>
	public T Alterar(T model)
	{
		_dbSet.Entry(model).State = EntityState.Modified;
		var result = _dbSet.Update(model);
		return result.Entity;
	}

	/// <summary>
	/// Remove um registro
	/// </summary>
	public void Remover(int id)
	{
		var result = _dbSet.SingleOrDefault(p => p.Id.Equals(id));
		if (result == null) return;

		_dbSet.Remove(result);
	}
}`;

	return {
		path: `src/${projName}.Infra.Data/Repositories/Base/`,
		name: 'Repository.cs',
		code: code,
	};
};

const getUserRepository = (app: IApplication, projName: string): IResourceApi => {
	const user = app.database?.tables?.find(table => table.isUser);
	const schema = dotnetSchemaName(user?.schema ?? '');
	const propName = backFormatPropertyName(dotnetFormatScreenName(user?.name ?? ''));
	let includeStr = '';
	app.userConfiguration?.mapCustom?.forEach(item => {
		item.customMapVars.forEach((customItem, index) => {
			if (index == 0)
				includeStr += `.Include(x => x.${dotnetFormatScreenName(
					customItem.table?.label ?? '',
				)})\n`;
			else
				includeStr += `.ThenInclude(x => x.${dotnetFormatScreenName(
					customItem.table?.label ?? '',
				)})\n`;
		});
	});

	const code = `using System.Linq.Expressions;
using Microsoft.EntityFrameworkCore;
using ${projName}.Domain.Interfaces.Repositories;
using ${projName}.Domain.Models.${schema ?? 'Base'};
using ${projName}.Infra.Data.Context;

namespace ${projName}.Infra.Data.Repositories
{
    public class ${propName}Repository : I${propName}Repository
    {
        private readonly DbSet<${propName}> _dbSet;

        public ${propName}Repository(DataContext context)
        {
            _dbSet = context.Set<${propName}>();
        }

        /// <summary>
        /// Obtém registro
        /// </summary>
        public async Task<${propName}?> Buscar(Expression<Func<${propName}, bool>> predicate)
        {
            var model = await _dbSet${includeStr}
                .FirstOrDefaultAsync(predicate);
            return model;
        }
    }
}`;

	return {
		path: `src/${projName}.Infra.Data/Repositories/`,
		name: `${propName}Repository.cs`,
		code: code,
	};
};
