import React, {useEffect, useState} from 'react';
import {IApiMap, IDefineFail, IOption} from 'src/@types/app';
import {Input, Select, WidthBox, Wrapper} from 'src/shared/components/UI';
import {OperationsList} from 'src/helpers/select-data/operationsList';

type Props = {
	data: IDefineFail | undefined;
	setData: (value: IDefineFail) => void;
	apiMapData: IApiMap[];
};

const DefineFail = ({data, setData, apiMapData}: Props) => {
	const [listOptions, setListOptions] = useState<IOption[]>([]);

	useEffect(() => {
		const list: string[] = [];
		apiMapData.forEach(item => {
			if (item.type !== 'object') {
				if (!item.parentRef) list.push(item.name ?? '');
			}
		});

		setListOptions(
			list.map(item => {
				return {
					value: item,
					label: item,
				};
			}),
		);
	}, [apiMapData]);

	return (
		<Wrapper>
			<WidthBox width="40%">
				<Select
					value={listOptions?.find(x => x.value === data?.prop)}
					options={listOptions}
					onChange={value => setData({...data, prop: value.value})}
					placeholder="Campo"
					background="transparent"
					border="1px solid #333"
					textColor="#fff"
				/>
			</WidthBox>
			<WidthBox width="20%">
				<Select
					value={OperationsList?.find(x => x.value === data?.condition)}
					options={OperationsList}
					onChange={value => setData({...data, condition: value.value})}
					placeholder="Condição"
					background="transparent"
					border="1px solid #333"
					textColor="#fff"
				/>
			</WidthBox>
			<WidthBox width="40%">
				<Input
					value={data?.value}
					onChange={value => setData({...data, value: value})}
					placeholder="Valor"
					border="1px solid #333"
					background="transparent"
					textColor="#fff"
					mask="Sem espaço"
				/>
			</WidthBox>
		</Wrapper>
	);
};

export default DefineFail;
