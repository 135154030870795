import styled from 'styled-components';
import {shade} from 'polished';
import {applicationTheme} from 'src/shared/theme';

type ItemProps = {
	marginLeft: number;
	select?: boolean;
	disabled?: boolean;
};

export const Item = styled.div<ItemProps>`
	font-size: 14px;
	font-weight: 400;
	background: ${props =>
		props.disabled ? '#ccc' : props.select ? applicationTheme.brand[500] : '#ddd'};
	color: ${props => (props.select ? '#fff' : 'black')};
	width: 35%;
	border-radius: 3px;
	margin-top: 7px;
	padding: 8px 12px;
	margin-left: ${props => `${props.marginLeft * 18}px`};
	transition: 0.2s;

	&:hover {
		background: ${props => (props.select ? shade(0.25, applicationTheme.brand[600]) : '#ccc')};
		cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
		transition: 0.2s;
	}
`;

export const ContentWidgets = styled.div`
	overflow-y: scroll;
	height: 32vw;
`;
