/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {useContext, useEffect, useState} from 'react';
import {IApplication, IResourceFolder} from 'src/@types/app';
import {useNavigate} from 'react-router-dom';
import {ProjectContext, ApplicationContext, LoadingContext} from 'src/context';
import {ResourceApi} from 'src/shared/repositories/resource-api';
import Components from 'src/shared/components';
import {DragDropController} from 'src/helpers/methods/drag-grop-element';
import Layout from 'src/shared/components/System/Layout';
import * as S from './styles';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {TabJoin} from './components/TabJoin';
import {FolderItem} from './ui/FolderItem';
import {FileItem} from './ui/FileItem';
import {AddBarRender} from './components/AddBarRender';
import {MenuRoute} from 'src/shared/components/System/MenuRoute';
import {ROUTER_PATHS} from 'src/router/router.path';
import {NotFoundDataModel} from './ui/NotFoundDataModel';

const ProjectScreen = () => {
	const [project, setProject] = useContext(ProjectContext);
	const [application, setApplication] = useContext(ApplicationContext);
	const [, setLoading] = useContext(LoadingContext);

	const navigate = useNavigate();

	const [filter, setFilter] = useState('');
	const [tabJoin, setTabJoin] = useState<any>({});

	const resourceApi = new ResourceApi();
	const dragDropController = new DragDropController(
		application,
		setApplication,
		setLoading,
		setTabJoin,
	);

	useEffect(() => {
		if (!application.id) navigate('/');
	}, []);

	useEffect(() => {
		setProject({
			...project,
			applications: [...(project.applications ?? [])].map(x => {
				if (x.ref == application.ref) return application;
				return x;
			}),
		});
	}, [application]);

	const selectItem = async (id: number) => {
		try {
			setLoading(true);
			const result = await resourceApi.get(id);

			const newApplication: IApplication = {
				...application,
				resources: [...(application.resources ?? [])].map(item => {
					if (item.id === id) {
						item.selected = true;
						item.apiData = result?.apiData;
						item.effects = result?.effects;
						item.subComponents = result?.subComponents;
						item.widgets = result?.widgets;
						item.expressions = result?.expressions;
						item.workflows = result?.workflows;

						item?.widgets?.forEach(widget => {
							widget.component = Components.find(x => x.name === widget.name)
								?.component as any;
						});
					} else item.selected = false;
					return item;
				}),
			};
			setApplication(newApplication);
		} finally {
			setLoading(false);
			navigate('/builder');
		}
	};

	const selectFolder = (ref: string) => {
		const newApplication: IApplication = {
			...application,
			folders: [...(application.folders ?? [])].map(item => {
				if (item.ref === ref) item.selected = true;
				else item.selected = false;
				return item;
			}),
		};

		setApplication(newApplication);
	};

	const sortResources = (a: IResourceFolder, b: IResourceFolder): number => {
		if (a.type == 'folder') return -1;
		if ((a?.name ?? '') < (b?.name ?? '')) return -1;
		if ((a?.name ?? '') > (b?.name ?? '')) return 1;
		return 0;
	};

	const formatResourceName = (name: string) => {
		if (name.length > 20) return name.substring(0, 20) + '...';
		return name;
	};

	const getResourcesFolder = (): IResourceFolder[] => {
		const data: IResourceFolder[] = [];
		const filterResource = () => {
			const path = application.folders?.find(x => x.selected);
			if (!path) return application.resources?.filter(x => !x.path);
			const defaultPath = path.path ? path.path : '';
			return application.resources?.filter(x => x.path === `${defaultPath}/${path.name}`);
		};

		const filterFolder = () => {
			const path = application.folders?.find(x => x.selected);
			if (!path) return application.folders?.filter(x => !x.path);
			const defaultPath = path.path ? path.path : '';
			return application.folders?.filter(x => x.path === `${defaultPath}/${path.name}`);
		};

		filterResource()?.forEach(resource => {
			data.push({
				name: resource.name!,
				ref: resource.ref!,
				type: 'resource',
				id: resource.id,
			});
		});
		filterFolder()?.forEach(folder => {
			data.push({
				name: folder.name!,
				ref: folder.ref!,
				type: 'folder',
			});
		});

		return data.sort((a, b) => sortResources(a, b));
	};

	const getStyle = (style: any) => {
		if (style.transition == null || style.transition == 'none') {
			return {
				transition: null,
				transform: null,
			};
		}

		return {
			...style,
			transition: '.00001s',
			opacity: '0.5',
		};
	};

	const renderResourceFolder = (item: IResourceFolder, provided: any, snapshot: any) => {
		const dragDropConfig = {
			...provided.draggableProps,
			...provided.dragHandleProps,
			ref: provided.innerRef,
			style: getStyle(provided.draggableProps.style),
		};

		if (item.type === 'resource') {
			return (
				<>
					<FileItem
						name={formatResourceName(item.name)}
						onClick={() => selectItem(item.id!)}
						dragDropConfig={dragDropConfig}
						item={item}
					/>
					{snapshot.isDragging ? <FileItem item={item} name={item.name} /> : <></>}
				</>
			);
		} else {
			return (
				<>
					<FolderItem
						name={formatResourceName(item.name)}
						onClick={() => selectFolder(item.ref)}
						dragDropConfig={dragDropConfig}
						item={item}
					/>
					{snapshot.isDragging ? <FolderItem item={item} name={item.name} /> : <></>}
				</>
			);
		}
	};

	return (
		<Layout expanded={false} search={setFilter} additionalBarContent={<AddBarRender />}>
			<S.Content>
				<MenuRoute
					pathRoute={[
						{
							name: 'Início',
							action: () => navigate(ROUTER_PATHS.HOME),
						},
						{
							name: 'Projetos',
							action: () => navigate(ROUTER_PATHS.PROJECTS),
						},
						{
							name: application.name ?? '',
							action: () => selectFolder(''),
						},
						{
							name: application.folders?.find(x => x.selected)?.name ?? undefined,
						},
					]}
				/>
				{!application.database?.databases?.length || !application.database.tables ? (
					<NotFoundDataModel showUploadBtn={false} />
				) : (
					<DragDropContext onDragEnd={() => console.log()}>
						<Droppable droppableId="characters" direction="horizontal">
							{provider => (
								<S.ResourceContent
									style={{display: 'flex'}}
									{...provider.droppableProps}
									ref={provider.innerRef}
									onMouseDown={dragDropController.dragGetElementRef}
									onMouseUp={dragDropController.dropGetElementRef}>
									{getResourcesFolder()
										?.filter(x =>
											x.name.toUpperCase().includes(filter.toUpperCase()),
										)
										?.map((item, index) => (
											<Draggable
												key={item.ref!}
												draggableId={item.ref!}
												index={index}>
												{(provided, snapshot) =>
													renderResourceFolder(item, provided, snapshot)
												}
											</Draggable>
										))}
									{provider.placeholder}
								</S.ResourceContent>
							)}
						</Droppable>
					</DragDropContext>
				)}
			</S.Content>

			<TabJoin
				isOpen={tabJoin.show ?? false}
				onClose={() => setTabJoin({show: false})}
				data={tabJoin}
				dragDropController={dragDropController}
			/>
		</Layout>
	);
};

export default ProjectScreen;
