import React from 'react';
import * as S from './styles';
import FeatherIcon from 'feather-icons-react';

type Props = {
	icon: string;
	text: string;
	onClick?: () => void;
	color?: string;
	textColor?: string;
	iconSize?: number;
	selected?: boolean;
};

export const AddBarItem = ({
	icon,
	text,
	onClick,
	iconSize = 23,
	color,
	textColor,
	selected,
}: Props) => {
	return (
		<S.Content onClick={onClick} color={color} textColor={textColor}>
			{selected ? <div className="flag"></div> : <></>}
			<div className="icon">
				<FeatherIcon icon={icon} size={iconSize} />
			</div>
			<div className="text">{text}</div>
		</S.Content>
	);
};
