import React, {useEffect} from 'react';
import {Modal} from 'src/shared/components/Complex';
import {Button, IconButton, SizedBox, Wrapper} from 'src/shared/components/UI';
import {v4 as uuidV4} from 'uuid';
import {faClose, faFloppyDisk} from '@fortawesome/free-solid-svg-icons';
import {IWorkFlowItem} from 'src/@types/app';
import {WorkFlowDataTypeEnum, WorkFlowTypeEnum} from 'src/@types/enums';
import {buildWorkFlow} from 'src/helpers/workflow/helper';
import {applicationTheme} from 'src/shared/theme';

type Props = {
	isOpen: boolean;
	onClose: () => void;
	workFlowItem: IWorkFlowItem[];
	setWorkFlowItem: (value: IWorkFlowItem[]) => void;
};

const WorkFlowModal = ({isOpen, onClose, workFlowItem, setWorkFlowItem}: Props) => {
	const close = () => {
		onClose();
	};

	const defaultItem: IWorkFlowItem = {
		ref: uuidV4().toString(),
		text: 'Titulo',
		type: WorkFlowTypeEnum.Variable,
	};

	useEffect(() => {
		if (!workFlowItem || workFlowItem.length == 0) {
			const data = [...workFlowItem];
			data.push(defaultItem);
			setWorkFlowItem(data);
		}
	}, [defaultItem]);

	const addItem = () => {
		const items = [...workFlowItem];
		items.push(defaultItem);
		setWorkFlowItem(items);
	};

	const addItemPosition = (position: number) => {
		const items = [...workFlowItem];
		items.splice(position + 1, 0, defaultItem);
		setWorkFlowItem(items);
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={close}
			width="90%"
			maxWidth="1800px"
			maxHeight="100vh"
			height="95vh">
			<Wrapper width="100%" flexDirection="column">
				<Wrapper alignItems="center" justifyContent="space-between">
					<Wrapper justifyContent="end">
						<IconButton
							icon={faFloppyDisk}
							onClick={() => {
								close();
							}}
							color={applicationTheme.brand[700]}
						/>
						<IconButton icon={faClose} onClick={close} />
					</Wrapper>
				</Wrapper>

				<Wrapper justifyContent="center" flexDirection="column" gap="0px">
					{buildWorkFlow(
						workFlowItem,
						setWorkFlowItem,
						addItemPosition,
						WorkFlowDataTypeEnum.Data,
					)}
					<SizedBox height="20px" />
					<Button
						text="Adicionar nova regra"
						leftIcon2="plus-square"
						onClick={addItem}
						background={applicationTheme.brand[50]}
						textColor={applicationTheme.brand[600]}
						fill="auto"
					/>
				</Wrapper>
				<SizedBox height="120px" />
			</Wrapper>
		</Modal>
	);
};

export default WorkFlowModal;
