import {IComponent} from 'src/@types/app';
import {DataComplexComponent} from 'src/shared/components';

export const addImport = (widget: IComponent, widgetImports: string[]) => {
	if (!widgetImports.includes(widget.name)) {
		widgetImports.push(widget.name);
	}
};

export const getImports = (
	widgetImports: string[],
	iconImports: string[],
	apiImports: string[],
	importContext: string[],
	importSubcomponents: string[],
	anotherImports: string[],
): string => {
	const imports: string[] = [];

	if (widgetImports.length > 0) {
		const simple = widgetImports.filter(
			x => !DataComplexComponent.includes(x) && !x.includes('import '),
		);
		const complex = widgetImports.filter(
			x => DataComplexComponent.includes(x) && !x.includes('import '),
		);

		if (simple.length > 0)
			imports.push(`import {${simple.join(', ')}} from 'src/components/UI';`);
		if (complex.length > 0)
			imports.push(`import {${complex.join(', ')}} from 'src/components/Complex';`);
		widgetImports.filter(x => x.includes('import ')).forEach(x => imports.push(x));
	}

	if (iconImports.length > 0)
		imports.push(
			`import {${removeDuplicates(iconImports).join(
				', ',
			)}} from '@fortawesome/free-solid-svg-icons';`,
		);

	if (importSubcomponents.length > 0) {
		importSubcomponents.forEach(item => {
			imports.push(`import {${item}} from '../components/${item}';`);
		});
	}

	if (apiImports.length > 0) imports.push(apiImports.join('\n'));

	if (importContext.length > 0) imports.push(importContext.join(''));

	if (anotherImports.length > 0) imports.push(anotherImports.join('\n'));

	return imports.join('');
};

const removeDuplicates = (arr: string[]): string[] => {
	return arr.filter((item, index) => arr.indexOf(item) === index);
};
