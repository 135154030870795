/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import * as S from './styles';
import Button from 'src/shared/components/UI/Button';
import {applicationTheme} from 'src/shared/theme';
import {SystemDataGrid} from 'src/shared/components/System/SystemDataGrid';
import {SizedBox, Wrapper} from 'src/shared/components/UI';

interface CellType {
	row: any;
}

export const RenderSnapShot = () => {
	return (
		<>
			<S.Content>
				<Button
					text="Criar snapshot"
					leftIcon2="camera"
					onClick={() => console.log()}
					fill="auto"
					background={applicationTheme.brand[600]}
				/>

				<SizedBox height="10px" />

				<SystemDataGrid
					checkboxSelection
					rowsData={[
						{
							ref: 1,
							name: 'Guilherme',
							function: 'Desenvolvedor',
							version: '160301_HRC_Geneva_launch',
							dateCreated: '09/02/2024 10:15:20',
						},
					]}
					columns={[
						{
							flex: 0.1,
							type: 'string',
							minWidth: 150,
							filterable: true,
							headerAlign: 'left',
							align: 'left',
							sortable: true,
							field: 'name',
							headerName: `Nome`,
							renderCell: ({row}: CellType) => (
								<S.Avatar>
									<div className="avatar"></div>
									<div className="contentInfo">
										<div className="name">{row.name}</div>
										<div className="function">{row.function}</div>
									</div>
								</S.Avatar>
							),
						},
						{
							flex: 0.1,
							type: 'string',
							minWidth: 150,
							filterable: true,
							headerAlign: 'left',
							align: 'left',
							sortable: true,
							field: 'version',
							headerName: `Nome da versão`,
							renderCell: ({row}: CellType) => row.version,
						},
						{
							flex: 0.1,
							type: 'string',
							minWidth: 150,
							filterable: true,
							headerAlign: 'left',
							align: 'left',
							sortable: true,
							field: 'dateCreated',
							headerName: `Data da criação`,
							renderCell: ({row}: CellType) => row.dateCreated,
						},
						{
							flex: 0.2,
							type: 'string',
							minWidth: 150,
							filterable: true,
							headerAlign: 'left',
							align: 'left',
							sortable: true,
							field: 'actions',
							headerName: ``,
							renderCell: ({row}: CellType) => (
								<Wrapper>
									<Button
										text="Restaurar versão"
										leftIcon2="refresh-ccw"
										onClick={() => console.log}
										background={applicationTheme.brand[50]}
										textColor={applicationTheme.brand[600]}
										fill="auto"
										height="36px"
									/>
									<Button
										text="Excluir"
										onClick={() => console.log}
										background="transparent"
										textColor={applicationTheme.error[600]}
										fill="auto"
										height="36px"
									/>
								</Wrapper>
							),
						},
					]}
				/>
			</S.Content>
		</>
	);
};
