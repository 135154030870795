import {applicationTheme} from 'src/shared/theme';
import styled from 'styled-components';

export const Content = styled.div`
	border-radius: 12px;
	background: #fff;
	width: 412px;
	height: 220px;
	box-shadow: 0px 2px 4px -2px #1018280f;

	.top {
		display: flex;
		height: 29px;

		.hour {
			display: flex;
			align-items: end;
			justify-content: start;
			width: 65%;
			font-family: Inter;
			font-size: 10px;
			font-weight: 400;
			line-height: 14px;
			letter-spacing: 0.2750000059604645px;
			text-align: left;
			color: ${applicationTheme.gray[800]};
			margin-left: 16px;
		}

		.type {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 35%;
			background: ${applicationTheme.indigo[500]};
			color: #fff;
			border-top-right-radius: 12px;
			gap: 8px;
			font-family: 'Inter';
			font-size: 16px;
			font-weight: 600;
			line-height: 18px;
			letter-spacing: 0em;
		}
	}

	.body {
		display: flex;
		margin-left: 16px;
		flex-direction: column;

		.title {
			display: flex;
			align-items: end;
			justify-content: start;
			width: 100%;
			font-family: Inter;
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;
			letter-spacing: 0.2750000059604645px;
			text-align: left;
			color: ${applicationTheme.gray[800]};
			margin-top: 16px;
		}

		.description {
			display: flex;
			align-items: end;
			justify-content: start;
			width: 100%;
			font-family: Inter;
			font-size: 14px;
			font-weight: 500;
			line-height: 20px;
			letter-spacing: 0.2750000059604645px;
			text-align: left;
			color: ${applicationTheme.gray[300]};
			margin-top: 12px;
		}
	}

	.footer {
		display: flex;
		height: 36px;
	}
`;
