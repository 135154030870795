import {Options} from 'prettier';
import parserTypeScript from 'prettier/parser-typescript';

export const PrettierConfig: Options = {
	parser: 'typescript',
	plugins: [parserTypeScript],
	singleQuote: true,
	semi: true,
	bracketSpacing: false,
	trailingComma: 'all',
	printWidth: 100,
	arrowParens: 'avoid',
	endOfLine: 'auto',
	jsxBracketSameLine: true,
};
