import React from 'react';
import * as S from '../styles';
import FeatherIcon from 'feather-icons-react';

export const createWorkflowArrow = (addItem: () => void): JSX.Element => {
	return (
		<S.Arrow>
			<div className="arrow">
				<i className="lni lni-arrow-down"></i>
			</div>
			<div className="iconAdd" onClick={addItem}>
				<FeatherIcon icon="plus-square" />
			</div>
		</S.Arrow>
	);
};
