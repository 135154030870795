import React, {useContext} from 'react';
import * as S from './styles';
import {CheckBox, Wrapper} from 'src/shared/components/UI';
import ApplicationContext from 'src/context/ApplicationContext';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import {SystemInputTag} from 'src/shared/components/System/SystemInputTag';
import {languagesList} from 'src/helpers/select-data/languagesList';

export const RenderTranslation = () => {
	const [data, setData] = useContext(ApplicationContext);

	return (
		<S.Content>
			<S.Tag>
				<div className="text">Dados referentes as traduções</div>
			</S.Tag>
			<S.Card>
				<CheckBox
					checked={data?.translation?.enableTranslation ?? false}
					handleToggle={() =>
						setData({
							...data,
							translation: {
								...(data?.translation ?? {}),
								enableTranslation: !data?.translation?.enableTranslation ?? false,
							},
						})
					}
					label="Habilitar traduções"
				/>
				<Wrapper margin="15px 0 0 0" alignItems="start">
					<SystemSelect
						width="50%"
						value={
							languagesList.find(x => x.value === data?.translation?.mainLanguage) ??
							languagesList[0]
						}
						options={languagesList}
						onChange={val =>
							setData({
								...data,
								translation: {
									...(data?.translation ?? {}),
									mainLanguage: val.value,
								},
							})
						}
						label="Idioma principal"
					/>
					<SystemInputTag
						onChange={val =>
							setData({
								...data,
								translation: {
									...(data?.translation ?? {}),
									enableLanguages: val,
								},
							})
						}
						values={data?.translation?.enableLanguages}
						options={languagesList}
						label="Idiomas habilitados"
					/>
				</Wrapper>
			</S.Card>
		</S.Content>
	);
};
