import {applicationTheme} from 'src/shared/theme';
import styled from 'styled-components';

export const Content = styled.div`
	height: 52px;
	width: calc(100vw - 400px);
	background: ${applicationTheme.gray[50]};
	display: flex;
	align-items: center;
`;
