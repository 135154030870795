import {IResource} from 'src/@types/app';
import {upperFirstWord} from 'src/helpers/methods/text-methods';

export const getDataApi = (resource: IResource): string => {
	const apiData = resource.apiData;
	if (!apiData || apiData.length == 0) return '';
	let apiDataString = '';

	apiData.forEach(x => {
		apiDataString += `const [${x.name}, set${upperFirstWord(
			x.name ?? '',
		)}] = useState<any>();\n`;
	});

	return apiDataString;
};
