import React, {useContext} from 'react';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Layout from 'src/shared/components/System/Layout';
import {MenuRoute} from 'src/shared/components/System/MenuRoute';
import {ROUTER_PATHS} from 'src/router/router.path';
import * as S from './styles';
import {PlanCard} from './ui/PlanCard';
import {MyPlanCard} from './ui/MyPlanCard';
import {PlanApi} from 'src/shared/repositories/plan-api';
import {IPlan} from 'src/@types/app';
import {LoadingContext} from 'src/context';

export const PlanScreen = () => {
	const [, setLoading] = useContext(LoadingContext);
	const [data, setData] = useState<IPlan[]>([]);
	const planApi = new PlanApi();

	const navigate = useNavigate();

	useEffect(() => {
		getValeus();
	}, []);

	const getValeus = async () => {
		setLoading(true);
		const result = await planApi.getAll();
		setData(result);
		setLoading(false);
	};
	return (
		<Layout>
			<S.Content>
				<MenuRoute
					pathRoute={[
						{
							name: 'Início',
							action: () => navigate(ROUTER_PATHS.HOME),
						},
						{name: 'Meu Plano'},
					]}
				/>

				<S.Title>Meu Plano</S.Title>
				<S.ContentMyPlan>
					{data.map((item, index) => (
						<MyPlanCard
							key={index}
							id={item.id}
							name={item.name}
							description={item.description}
							benefit={item.benefit}
							icon={item.icon}
							price={item.price}
							term={item.term}
							category={item.category}
						/>
					))}
				</S.ContentMyPlan>

				<S.Title>Planos disponíveis</S.Title>
				<S.ContentPlan>
					{data.map((item, index) => (
						<PlanCard
							key={index}
							id={item.id}
							name={item.name}
							description={item.description}
							benefit={item.benefit}
							icon={item.icon}
							iconColor={item.iconColor}
							price={item.price}
							term={item.term}
							category={item.category}
						/>
					))}
				</S.ContentPlan>
			</S.Content>
		</Layout>
	);
};
