import {applicationTheme} from 'src/shared/theme';
import styled from 'styled-components';

export const TopBar = styled.div`
	background: #fff;
	height: 70px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 2px solid ${applicationTheme.gray[300]};
	transition: 0.2s;
	position: relative;

	h3 {
		color: #fff;
		padding: 0 0 0 5%;
	}
`;

type CircleAction = {
	background?: string;
	textColor?: string;
};
export const CircleAction = styled.div<CircleAction>`
	width: 45px;
	height: 45px;
	border-radius: 40px;
	border: 1px solid #cbd0dd;
	color: ${props => (props.textColor ? props.textColor : '#333')};
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background-color: ${props => (props.background ? props.background : 'transparent')};

	&:hover {
		background: rgba(0, 0, 0, 0.5);
	}
`;

export const UserInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;

	.name {
		color: ${applicationTheme.gray[700]};
		font-size: 14px;
		font-weight: 600;
	}
	.email {
		color: ${applicationTheme.gray[500]};
		font-size: 12px;
	}
`;

export const ContentAppName = styled.div`
	width: 320px;
	display: flex;
	align-items: center;
	justify-content: start;
	margin-left: 20px;

	.icon {
		width: 40px;
		height: 40px;
		background: ${applicationTheme.brand[200]};
		color: ${applicationTheme.brand[500]};
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 40px;
		margin-right: 15px;
	}

	.name {
		font-family: 'Inter';
		font-size: 16px;
		font-weight: 600;
		line-height: 24px;
		text-align: left;
	}

	&:hover {
		cursor: pointer;
	}
`;
