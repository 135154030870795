/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {useContext} from 'react';
import {IOption, IWorkFlowItem} from 'src/@types/app';
import {WidthBox, Wrapper} from 'src/shared/components/UI';
import {setWorkFlowValues} from '../helper';
import * as S from '../styles';
import {ApplicationContext} from 'src/context';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import {SystemInput} from 'src/shared/components/System/SystemInput';

type Props = {
	item: IWorkFlowItem;
	workFlowItem: IWorkFlowItem[];
	setWorkFlowItem: (value: IWorkFlowItem[]) => void;
};

export const FtpComp = ({item, workFlowItem, setWorkFlowItem}: Props) => {
	const [application] = useContext(ApplicationContext);

	const getFtpData = (): IOption[] =>
		(application.ftps ?? []).map(item => ({
			label: `${item.host}`,
			value: item.ref,
		}));

	return (
		<S.ContentBasic>
			<SystemSelect
				value={getFtpData().find(x => x.value === item.values?.ftp)}
				options={getFtpData()}
				onChange={value =>
					setWorkFlowValues(workFlowItem, setWorkFlowItem, item.ref!, 'ftp', value.value)
				}
				label="FTP"
				inputType="minimal"
			/>
			<Wrapper>
				<WidthBox width="100%">
					<SystemInput
						value={item.values?.directory}
						onChange={val =>
							setWorkFlowValues(
								workFlowItem,
								setWorkFlowItem,
								item.ref!,
								'directory',
								val,
							)
						}
						inputType="minimal"
						label="Diretório"
					/>
				</WidthBox>
			</Wrapper>
		</S.ContentBasic>
	);
};
