/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {IAction, IApplication, IOption} from 'src/@types/app';
import {ActionTypeEnum, ResourceTypeEnum} from 'src/@types/enums';
import {Select, SizedBox} from 'src/shared/components/UI';
import * as S from './styles';

type Props = {
	application: IApplication;
	btnAction: IAction | undefined;
	setBtnAction: React.Dispatch<React.SetStateAction<IAction | undefined>>;
};

const OpenModal = ({application, btnAction, setBtnAction}: Props) => {
	const [modals, setModals] = useState<IOption[]>();
	const [selectModal, setSelectModal] = useState('');

	useEffect(() => {
		const resourceModals = application?.resources?.filter(
			x => x.type === ResourceTypeEnum.Modal,
		);

		setModals(
			resourceModals?.map(x => {
				return {
					value: x.ref,
					label: x.name as string,
				};
			}),
		);

		if (btnAction && btnAction.targetRef && btnAction.targetRef != '') {
			setSelectModal(btnAction.targetRef);
		}
	}, [application]);

	if (!modals || modals.length === 0) {
		return <S.NoContent>Nenhuma modal foi encontrada na página</S.NoContent>;
	}

	const setValue = (modalRef: string) => {
		setSelectModal(modalRef);
		const action: IAction = {
			actionType: ActionTypeEnum.OpenModal,
			targetRef: modalRef,
		};

		setBtnAction(action);
	};

	return (
		<>
			<SizedBox height="10px" />
			<Select
				value={modals?.find(x => x.value === selectModal)}
				options={modals as any}
				onChange={value => setValue(value.value)}
				placeholder="Selecione o modal"
				background="transparent"
				border="1px solid #333"
				textColor="#fff"
			/>
		</>
	);
};

export default OpenModal;
