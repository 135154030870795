/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {IApplication, IWorkFlowItem} from 'src/@types/app';
import {WorkFlowTypeEnum} from 'src/@types/enums';
import {
	dotnetFormatFullRepoName,
	dotnetFormatScreenName,
} from 'src/shared/engine-back/core/dotnet/core/dotnet-formatter';
import {builderDotnetExpression} from 'src/pages/builder/Builder/components/Expression/builder/buildDotnetCode';
import {isDisableOperations} from '../../helper';
import {CrudComponentType, CrudOptions} from '../../component/crudComp';
import {IConditionComp} from '../../component/conditionComp';

export const buildWorkFlowCode = (
	workFlowItem: IWorkFlowItem[],
	app: IApplication,
	parentRef?: string,
) => {
	let code = '';
	workFlowItem
		.filter(x => (parentRef ? x.refItem == parentRef : !x.refItem))
		.forEach(flowItem => {
			if (flowItem.type === WorkFlowTypeEnum.Variable) code += buildVariable(flowItem);
			if (flowItem.type === WorkFlowTypeEnum.CRUD) code += buildCrud(flowItem, app);
			if (flowItem.type === WorkFlowTypeEnum.AttrVariable)
				code += buildAttrVariable(flowItem);
			if (flowItem.type === WorkFlowTypeEnum.Condition)
				code += buildCondition(flowItem, workFlowItem, app, !!parentRef);
			if (flowItem.type === WorkFlowTypeEnum.For)
				code += buildFor(flowItem, workFlowItem, app);
		});

	code = code.replaceAll('\t\t\t', '\t');

	code = code
		.split('\n')
		.map(x => '\t\t\t' + x)
		.join('\n');
	return code;
};

const buildVariable = (flowItem: IWorkFlowItem): string => {
	if (!flowItem?.values?.variableName) return '';

	let code = `var ${flowItem.values.variableName} = `;
	if (flowItem.values.expression) code += builderDotnetExpression(flowItem.values.expression);
	return getFormatCode(code);
};

const buildCrud = (flowItem: IWorkFlowItem, app: IApplication): string => {
	let code = '';

	const table = app.database?.tables?.find(x => x.ref === flowItem.values.tableRef);
	const operation = flowItem.values.operation;
	const isQuery = [CrudOptions.Get, CrudOptions.List].includes(operation);
	const isGetAll =
		isQuery && (!flowItem.values.expression || flowItem.values.expression.items?.length == 0);

	if ([CrudOptions.Insert, CrudOptions.Get, CrudOptions.List].includes(operation)) {
		code += `var ${flowItem.values.variableName} = `;
	}

	const awaitCode = [CrudOptions.Get, CrudOptions.List].includes(operation) ? 'await ' : '';

	code += `${awaitCode}${dotnetFormatFullRepoName(table?.name ?? '')}`;
	code += `.${getCrudOptionsString(operation, isGetAll)}(`;

	if (!isGetAll && isQuery) {
		code += `x => `;
		if (flowItem.values.expression) code += builderDotnetExpression(flowItem.values.expression);
	}

	if (!isQuery) {
		if (operation != CrudOptions.Delete)
			code += `new ${dotnetFormatScreenName(table?.name ?? '')} {`;

		table?.columns?.forEach(column => {
			const propData = (flowItem.values.dataMap as CrudComponentType[])?.find(
				item => item.propRef === column.ref,
			);
			if (!propData) return;
			const propValue = propData.propName ?? '';
			let varValue = propData.fieldName ?? '';
			const incledeVarFor = includesVar(varValue) && varValue.includes('for - ');

			if (propData.subFieldRef && propData.subFieldName)
				varValue = `${varValue}.${propData.subFieldName}`;

			const varProp = includesVar(varValue) ? replaceVar(varValue) : `command.${varValue}`;

			if (operation != CrudOptions.Delete) code += `\n	${propValue} = ${varProp},`;
			else code += incledeVarFor ? `item.Id,` : `${varProp},`;
		});
		const lastIdx = code.lastIndexOf(',');
		code = code.substring(0, lastIdx);
		if (operation != CrudOptions.Delete) code += '\n}';
	}

	code += ')';

	return getFormatCode(code);
};

const buildAttrVariable = (flowItem: IWorkFlowItem): string => {
	const flowValues = flowItem.values;
	let varName = '';
	if (!includesVar(flowValues.propName)) varName = `command.${flowValues.propName}`;
	else varName = replaceVar(flowValues.propName);
	if (flowValues.subField) varName += `.${flowValues.subFieldName}`;

	let code = `${varName} = `;
	if (flowValues.expression) code += builderDotnetExpression(flowValues.expression);

	return getFormatCode(code);
};

const buildCondition = (
	flowItem: IWorkFlowItem,
	workFlowItem: IWorkFlowItem[],
	app: IApplication,
	hasParentRef: boolean,
): string => {
	let code = 'if (';
	flowItem.values.conditions?.forEach((cond: IConditionComp, idx: number) => {
		let prop1 = '';
		let prop2 = '';

		if (!includesVar(cond.field1Name ?? '')) prop1 = `command.${cond.field1Name}`;
		else prop1 = replaceVar(cond.field1Name);
		if (!includesVar(cond.field2Name ?? '')) prop2 = `command.${cond.field2Name}`;
		else prop2 = replaceVar(cond.field2Name);
		if (cond.subField1) prop1 += `?.${cond.subField1Name}`;
		if (cond.subField2) prop2 += `?.${cond.subField2Name}`;

		if (!isDisableOperations(cond.operator ?? '')) code += `${prop1} ${cond.operator} ${prop2}`;
		else code += `${prop1} ${cond.operator}`;

		if (flowItem.values.conditions?.length - 1 != idx) code += ` ${cond.ext} `;
	});

	code += `)\n{{{IF_YES_CODE_${flowItem.ref}}}}`;

	const ifYes = workFlowItem.find(
		x => x.type === WorkFlowTypeEnum.IfYes && x.refItem === flowItem.ref,
	);
	const ifNo = workFlowItem.find(
		x => x.type === WorkFlowTypeEnum.IfNo && x.refItem === flowItem.ref,
	);
	if (ifYes) {
		let subCode = buildWorkFlowCode(workFlowItem, app, ifYes.ref);
		const lastTabIndex = subCode.lastIndexOf('\t');
		subCode = subCode.substring(0, lastTabIndex - 2);
		code = code.replace(`{{IF_YES_CODE_${flowItem.ref}}}`, `\n${subCode}`);
	}
	if (ifNo && workFlowItem.filter(x => x.refItem === ifNo.ref)?.length > 0) {
		code += `\nelse\n{`;
		const subCode = buildWorkFlowCode(workFlowItem, app, ifNo.ref);
		code += `\n${subCode}}`;
	}

	if (!hasParentRef) code += '\n';
	return code;
};

const buildFor = (
	flowItem: IWorkFlowItem,
	workFlowItem: IWorkFlowItem[],
	app: IApplication,
): string => {
	let code = '';
	if (!flowItem.values?.property?.label) return code;
	const varList = replaceVar(flowItem.values?.property?.label);
	const subCode = buildWorkFlowCode(workFlowItem, app, flowItem.ref);

	code = `foreach (var item in ${varList})\n{\n${subCode}
}`;
	code += '\n';
	return code;
};

const getFormatCode = (code: string) => `${code};\n`;

const includesVar = (prop: string): boolean => {
	if (prop.includes('var -')) return true;
	if (prop.includes('for -')) return true;
	return false;
};

const replaceVar = (prop: string | null | undefined): string => {
	prop = prop?.replace('var - ', '');
	prop = prop?.replace('for - ', '');
	return prop ?? '';
};

const getCrudOptionsString = (type: CrudOptions, isGetAll: boolean): string => {
	switch (type) {
		case CrudOptions.Insert:
			return 'Criar';
		case CrudOptions.Update:
			return 'Alterar';
		case CrudOptions.Delete:
			return 'Remover';
		case CrudOptions.Get:
			return 'Obter';
		case CrudOptions.List:
			if (isGetAll) return 'ObterTodos().ToListAsync';
			return 'Listar';
		default:
			return '';
	}
};
