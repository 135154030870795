import {applicationTheme} from 'src/shared/theme';
import styled from 'styled-components';

type ContentProps = {
	expanded?: boolean;
};

export const Content = styled.div<ContentProps>`
	width: ${props => (props.expanded ? '220px' : '80px')};
	background: ${applicationTheme.brand[800]};
	height: 100vh;
	transition: 1s;
`;

export const Top = styled.div`
	height: 70px;
	border-bottom: 2px solid ${applicationTheme.brand[600]};
`;

export const MenuItem = styled.div`
	height: 56px;
	color: #fff;
	width: 100%;
	transition: 0.3s;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: start;
	font-size: 14px;
	font-family: 'Inter';
	font-weight: 300;

	&:hover {
		background-color: ${applicationTheme.brand[700]};
		transition: 0.3s;
	}

	span {
		margin-left: 10px;
	}

	.other {
		margin-left: 26px;
	}

	.default {
		margin-left: 20px;
	}

	.space {
		margin-left: 6px;
	}

	.active {
		width: 4px;
		height: 32px;
		background: ${applicationTheme.brand[300]};
	}
`;

export const MenuItemBottom = styled(MenuItem)`
	height: 76px;
	border-top: 2px solid ${applicationTheme.brand[600]};
`;
