export const packagesConstants = {
	Api: `
  <ItemGroup>
    <PackageReference Include="AutoMapper" Version="12.0.1" />
    <PackageReference Include="MediatR" Version="12.0.1" />
    <PackageReference Include="Microsoft.AspNetCore.OData" Version="8.1.0" />
    <PackageReference Include="Microsoft.AspNetCore.OpenApi" Version="7.0.4" />
    <PackageReference Include="Microsoft.EntityFrameworkCore.Design" Version="7.0.4">
      <IncludeAssets>runtime; build; native; contentfiles; analyzers; buildtransitive</IncludeAssets>
      <PrivateAssets>all</PrivateAssets>
    </PackageReference>
    <PackageReference Include="Swashbuckle.AspNetCore" Version="6.5.0" />
    <PackageReference Include="Swashbuckle.AspNetCore.Annotations" Version="6.5.0" />
    <PackageReference Include="OpenTelemetry" Version="1.5.1" />
    <PackageReference Include="OpenTelemetry.Exporter.Jaeger" Version="1.5.1" />
    <PackageReference Include="OpenTelemetry.Exporter.OpenTelemetryProtocol" Version="1.5.1" />
    <PackageReference Include="OpenTelemetry.Exporter.OpenTelemetryProtocol.Logs" Version="1.5.0-rc.1" />
    <PackageReference Include="OpenTelemetry.Extensions.Hosting" Version="1.5.1" />
    <PackageReference Include="OpenTelemetry.Instrumentation.AspNetCore" Version="1.5.0-beta.1" />
    <PackageReference Include="OpenTelemetry.Instrumentation.Http" Version="1.5.0-beta.1" />
    <PackageReference Include="OpenTelemetry.Instrumentation.Process" Version="0.5.0-beta.3" />
    <PackageReference Include="OpenTelemetry.Instrumentation.Runtime" Version="1.5.0" />
  </ItemGroup>`,
	Application: `
  <ItemGroup>
    <PackageReference Include="AutoMapper" Version="12.0.1" />
    <PackageReference Include="MediatR" Version="12.0.1" />
  </ItemGroup>`,
	IoC: `
  <ItemGroup>
    <PackageReference Include="Microsoft.Extensions.DependencyInjection" Version="7.0.0" />
  </ItemGroup>`,
	Identity: `
  <ItemGroup>
    <PackageReference Include="Microsoft.AspNetCore.Authentication.JwtBearer" Version="7.0.4" />
  </ItemGroup>`,
	Data: `
  <ItemGroup>
    <PackageReference Include="Microsoft.EntityFrameworkCore" Version="7.0.4" />
    <PackageReference Include="Microsoft.EntityFrameworkCore.SqlServer" Version="7.0.4" />
  </ItemGroup>`,
};

export const referenceConstants = (projName: string) => ({
	Api: `
  <ItemGroup>
    <ProjectReference Include="..\\${projName}.Application\\${projName}.Application.csproj" />
    <ProjectReference Include="..\\${projName}.CrossCutting.IoC\\${projName}.CrossCutting.IoC.csproj" />
  </ItemGroup>`,
	Application: `
  <ItemGroup>
    <ProjectReference Include="..\\${projName}.Domain\\${projName}.Domain.csproj" />
    <ProjectReference Include="..\\${projName}.Infra.CrossCutting.Identity\\${projName}.Infra.CrossCutting.Identity.csproj" />
  </ItemGroup>`,
	IoC: `
  <ItemGroup>
    <ProjectReference Include="..\\${projName}.Domain\\${projName}.Domain.csproj" />
    <ProjectReference Include="..\\${projName}.Infra.CrossCutting.Identity\\${projName}.Infra.CrossCutting.Identity.csproj" />
    <ProjectReference Include="..\\${projName}.Infra.Data\\${projName}.Infra.Data.csproj" />
    <ProjectReference Include="..\\${projName}.Application\\${projName}.Application.csproj" />
  </ItemGroup>`,
	Identity: `
  <ItemGroup>
    <ProjectReference Include="..\\${projName}.Domain\\${projName}.Domain.csproj" />
  </ItemGroup>`,
	Data: `
  <ItemGroup>
    <ProjectReference Include="..\\${projName}.Domain\\${projName}.Domain.csproj" />
  </ItemGroup>
  `,
});
