import {applicationTheme} from 'src/shared/theme';
import styled from 'styled-components';

export const Content = styled.div`
	background: #fff;
	padding: 16px;
	border-radius: 2px;
	width: 95%;
`;

export const Avatar = styled.div`
	display: flex;
	padding: 10px 0;

	.avatar {
		height: 40px;
		width: 40px;
		border-radius: 40px;
		background-color: ${applicationTheme.gray[100]};
	}

	.contentInfo {
		display: flex;
		flex-direction: column;
		margin-left: 10px;
	}

	.name {
		font-family: Inter;
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		text-align: left;
		color: ${applicationTheme.gray[900]};
	}

	.function {
		font-family: Inter;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		text-align: left;
		color: ${applicationTheme.gray[500]};
	}
`;
