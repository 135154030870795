import styled from 'styled-components';

type Props = {
	isMaximazed: boolean;
};
export const Content = styled.div<Props>`
	display: flex;
	width: 100%;

	.work-screen {
		display: flex;
		align-items: center;
		justify-content: center;
		width: ${props => (props.isMaximazed ? '100%' : 'calc(100% - 722px)')};
	}
`;
