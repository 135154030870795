export const generateReadme = (projName: string) => {
	const code = `
# PROJECT

## Migration

Criar uma migration quando tiver alguma modificação no banco:

- cd cd .\\src\\${projName}.Infra.CrossCutting.Identity\\
- dotnet ef migrations add Initial -s ..\\${projName}.API\\ -c IdentityDataContext

- cd cd .\\src\\${projName}.Infra.Data\\
- dotnet ef migrations add Initial -s ..\\${projName}.API\\ -c DataContext`;

	return {
		path: '',
		name: 'README.md',
		code: code,
	};
};
