/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {useContext, useEffect, useState} from 'react';
import {IconButton, Select, SizedBox, WidthBox, Wrapper} from 'src/shared/components/UI';
import * as S from '../styles';
import TextButton from 'src/shared/components/UI/TextButton';
import {v4 as uuidV4} from 'uuid';
import {faClose} from '@fortawesome/free-solid-svg-icons';
import {comparetorEOuList, comparetorList} from 'src/helpers/select-data/comparetorList';
import {IOption, IWorkFlowItem} from 'src/@types/app';
import {getOptionFieldsList, isDisableOperations, setWorkFlowValues} from '../helper';
import {ApplicationContext} from 'src/context';

export interface IConditionComp {
	ref?: string;
	field1?: string;
	field1Name?: string;
	subField1?: string;
	subField1Name?: string;
	subField1Props?: IOption[];
	operator?: string;
	field2?: string;
	field2Name?: string;
	subField2?: string;
	subField2Name?: string;
	subField2Props?: IOption[];
	ext?: string;
}

type Props = {
	item: IWorkFlowItem;
	workFlowItem: IWorkFlowItem[];
	setWorkFlowItem: (value: IWorkFlowItem[]) => void;
};

export const ConditionComp = ({item, workFlowItem, setWorkFlowItem}: Props) => {
	const [application] = useContext(ApplicationContext);

	const [conditions, setConditions] = useState<IConditionComp[]>([]);
	const [fieldOptions, setFieldOptions] = useState<IOption[]>([]);

	const defaultData = {
		ref: uuidV4().toString(),
	};

	useEffect(() => {
		setFieldOptions(getOptionFieldsList(application, workFlowItem, item));
	}, [application]);

	useEffect(() => {
		if (conditions.length == 0) {
			if (item.values?.conditions) setConditions(item.values?.conditions ?? []);
			else setConditions([defaultData]);
		}
	}, [conditions]);

	const setValue = (value: IConditionComp) => {
		const data = [...conditions].map(x => {
			if (x.ref === value.ref) return value;
			return x;
		});

		setConditions(data);
		setWorkFlowValues(workFlowItem, setWorkFlowItem, item.ref!, 'conditions', data);
	};

	const renderCondition = (conditionItem: IConditionComp, index: number) => {
		return (
			<div key={index}>
				<Wrapper gap="7px">
					<WidthBox width={conditionItem.subField1Props ? '18%' : '36%'}>
						<Select
							value={fieldOptions.find(y => y.value == conditionItem.field1)}
							options={fieldOptions}
							onChange={(val: IOption) => {
								let subProp: any = undefined;
								if (val.custom?.ident === 'TABLE_PROPS')
									subProp = val.custom.value2;

								setValue({
									...conditionItem,
									field1: val.value,
									field1Name: val.label,
									subField1Props: subProp,
								});
							}}
							label="Campo"
							background="transparent"
							inputType="minimal"
						/>
					</WidthBox>
					{conditionItem.subField1Props ? (
						<WidthBox width="18%">
							<Select
								value={conditionItem.subField1Props?.find(
									y => y.value == conditionItem.subField1,
								)}
								options={conditionItem.subField1Props ?? []}
								onChange={(val: IOption) => {
									setValue({
										...conditionItem,
										subField1: val.value,
										subField1Name: val.label,
									});
								}}
								label="Propriedade"
								background="transparent"
								inputType="minimal"
							/>
						</WidthBox>
					) : (
						<></>
					)}

					<WidthBox width="21%">
						<Select
							value={comparetorList.find(y => y.value == conditionItem.operator)}
							options={comparetorList}
							onChange={val => setValue({...conditionItem, operator: val.value})}
							placeholder="Operador"
							background="transparent"
							inputType="minimal"
							label="Comparador"
						/>
					</WidthBox>
					<WidthBox width={conditionItem.subField2Props ? '18%' : '36%'}>
						<Select
							value={fieldOptions.find(y => y.value == conditionItem.field2)}
							options={fieldOptions}
							onChange={val => {
								let subProp: any = undefined;
								if (val.custom?.ident === 'TABLE_PROPS')
									subProp = val.custom.value2;

								setValue({
									...conditionItem,
									field2: val.value,
									field2Name: val.label,
									subField2Props: subProp,
								});
							}}
							label="Campo"
							background="transparent"
							inputType="minimal"
							disabled={isDisableOperations(conditionItem.operator ?? '')}
						/>
					</WidthBox>
					{conditionItem.subField2Props ? (
						<WidthBox width="18%">
							<Select
								value={fieldOptions.find(y => y.value == conditionItem.subField2)}
								options={conditionItem.subField2Props ?? []}
								onChange={(val: IOption) => {
									setValue({
										...conditionItem,
										subField2: val.value,
										subField2Name: val.label,
									});
								}}
								label="Propriedade"
								background="transparent"
								inputType="minimal"
							/>
						</WidthBox>
					) : (
						<></>
					)}

					{conditions.length > 1 ? (
						<WidthBox width="auto" margin="18px 0 0 0">
							<IconButton
								icon={faClose}
								onClick={() => {
									setConditions(
										[...conditions].filter(x => x.ref != conditionItem.ref),
									);
								}}
								color="red"
							/>
						</WidthBox>
					) : (
						<></>
					)}
				</Wrapper>
				{index < conditions.length - 1 ? (
					<Wrapper margin="8px 0">
						<WidthBox width="20%">
							<Select
								value={comparetorEOuList.find(y => y.value == conditionItem.ext)}
								options={comparetorEOuList}
								onChange={val =>
									setValue({
										...conditionItem,
										ext: val.value,
									})
								}
								placeholder="Operador"
								background="transparent"
								inputType="minimal"
							/>
						</WidthBox>
					</Wrapper>
				) : (
					<></>
				)}
			</div>
		);
	};

	return (
		<S.ContentBasic>
			{conditions.map((item, index) => renderCondition(item, index))}
			<SizedBox height="10px" />
			<TextButton
				onClick={() => {
					const data = [...conditions];
					data.push(defaultData);
					setConditions(data);
				}}
				leftLineIcon="lni lni-plus"
				text="Adicionar"
				themeStyle="primary"
			/>
		</S.ContentBasic>
	);
};
