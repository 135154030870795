import {IOption} from 'src/@types/app';
import {ApiBodyType} from 'src/@types/enums';

export const ApiBodyTypeList: IOption[] = [
	{
		value: ApiBodyType.URL,
		label: 'URL',
	},
	{
		value: ApiBodyType.JSON,
		label: 'JSON',
	},
	{
		value: ApiBodyType.QUERY,
		label: 'QUERY',
	},
];
