import {applicationTheme} from 'src/shared/theme';
import styled from 'styled-components';

type CircleAction = {
	background?: string;
	textColor?: string;
};
export const CircleAction = styled.div<CircleAction>`
	width: 45px;
	height: 45px;
	border-radius: 40px;
	border: 1px solid #cbd0dd;
	color: ${props => (props.textColor ? props.textColor : '#333')};
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background-color: ${props => (props.background ? props.background : 'transparent')};
`;

export const Content = styled.div`
	border-radius: 12px;
	background: #fff;
	width: 302px;
	height: 265px;
	box-shadow: 0px 2px 4px -2px #1018280f;

	.top {
		display: flex;
		flex-direction: column;

		.icon {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 16px;
		}

		.name {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			font-family: Inter;
			font-size: 18px;
			font-weight: 600;
			line-height: 28px;
			letter-spacing: 0.2750000059604645px;
			text-align: center;
			color: ${applicationTheme.gray[900]};
			margin-top: 16px;
		}

		.category {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			font-family: Inter;
			font-size: 8px;
			font-weight: 500;
			line-height: 20px;
			letter-spacing: 0.2750000059604645px;
			text-align: left;
			color: ${applicationTheme.gray[900]};
		}

		.price {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			font-family: Inter;
			font-size: 18px;
			font-weight: 600;
			line-height: 28px;
			letter-spacing: 0.2750000059604645px;
			text-align: center;
			color: ${applicationTheme.success[500]};
			margin-top: 16px;
		}

		.term {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			font-family: Inter;
			font-size: 12px;
			font-weight: 400;
			line-height: 18px;
			letter-spacing: 0.2750000059604645px;
			text-align: center;
			color: ${applicationTheme.gray[500]};
			margin-top: 6px;
		}

		.benefit {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			font-family: Inter;
			font-size: 12px;
			font-weight: 400;
			line-height: 18px;
			letter-spacing: 0.2750000059604645px;
			text-align: center;
			color: ${applicationTheme.gray[500]};
			margin-top: 6px;
		}
	}

	.footer {
		display: flex;
		margin-top: 20px;
	}
`;
