import {applicationTheme} from 'src/shared/theme';
import styled from 'styled-components';

export const Content = styled.div`
	width: 70%;
`;

export const Tag = styled.div`
	color: ${applicationTheme.gray[900]};
	display: flex;
	font-size: 14px;
	font-weight: 600;
	gap: 10px;
	margin-bottom: 15px;

	.margin {
		margin-top: 12px;
	}
`;

export const Card = styled.div`
	background-color: #fff;
	border-radius: 8px;
	padding: 22px 16px;
`;

export const NoDataInfo = styled.div`
	border: 1px solid #faefd4;
	border-radius: 4px;
	padding: 10px 12px;
	background: #fcf8e3;
	width: 80%;

	span {
		font-weight: bold;
		color: #8b8471;
	}

	p {
		margin: 0;
		padding: 0;
		color: #8b8471;
	}
`;
