/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useContext, useEffect, useState} from 'react';
import {IApplication, IComponent} from 'src/@types/app';
import {ComponentComplexEnum, ResourceTypeEnum} from 'src/@types/enums';
import Layout from 'src/shared/components/System/Layout';
import PropertyContent from './components/PropertyContent';
import WidgetsContent from './components/WidgetsContent';
import WorkScreen from './components/WorkScreen';
import SpecifyModal from '../Specify';
import * as S from './styles';
import {ApplicationContext, LoadingContext} from 'src/context';
import {getSubcomponentSelected} from 'src/helpers/methods/app-methods';
import {ResourceApi} from 'src/shared/repositories/resource-api';
import {useNavigate} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {Button, Wrapper} from 'src/shared/components/UI';
import {applicationTheme} from 'src/shared/theme';

const BuilderScreen = () => {
	const navigate = useNavigate();
	const {enqueueSnackbar} = useSnackbar();

	const [application, setApplication] = useContext(ApplicationContext);
	const [, setLoading] = useContext(LoadingContext);

	const [pageType, setPageType] = useState<ResourceTypeEnum>();
	const [widgets, setWidgets] = useState<IComponent[]>([]);
	const [selectWidget, setSelectWidget] = useState<IComponent>();
	const [showSpecifyModal, setShowSpecifyModal] = useState(false);
	const [componentTypeSpecify, setComponentTypeSpecify] = useState(ComponentComplexEnum.DataGrid);
	const [resourceWidth, setResourceWidth] = useState('');
	const [isMaximazed, setIsMaximazed] = useState(false);
	const [showSubcomponents, setShowSubcomponents] = useState(false);
	const [hasSelected, setHasSelected] = useState(false);
	const [enableHelpLines, setEnableHelpLines] = useState(true);
	const [selectedItem, setSelectedItem] = useState<any>();
	const [showEditComplexBtn, setShowEditComplexBtn] = useState(false);

	const resourceApi = new ResourceApi();

	useEffect(() => {
		setApplication({...application, hideFuncionBar: false});
	}, []);

	useEffect(() => {
		const selectedResource = application?.resources?.find(x => x.selected);
		const selectedSubComponent = getSubcomponentSelected(application);

		if (selectedSubComponent) {
			setWidgets(selectedSubComponent?.widgets ?? []);
			setPageType(ResourceTypeEnum.SubComponent);
		} else {
			setWidgets(selectedResource?.widgets ?? []);
			setPageType(selectedResource?.type);
		}
	}, [application]);

	useEffect(() => {
		setSelectWidget(widgets.find(x => x.selected));
	}, [widgets]);

	const setProperty = (props: any) => {
		const newWidgets = [...widgets].map(item => {
			if (item.selected) item.properties = props;
			return item;
		});
		setWidgets(newWidgets);
	};

	const toggleSpecifyModal = (type: ComponentComplexEnum) => {
		saveApplicationState(true);
		setComponentTypeSpecify(type);
		setShowSpecifyModal(true);
	};

	const saveApplicationState = async (isSpecifyComponent = false, isSave = false) => {
		try {
			const hasSubComponent = getSubcomponentSelected(application);

			const newApplication: IApplication = {
				...application,
				resources: [...(application.resources ?? [])].map(resource => {
					if (hasSubComponent) {
						resource.subComponents = [
							...(resource.subComponents ?? []).map(sub => {
								if (sub.selected) sub.widgets = widgets;
								if (!isSpecifyComponent) sub.selected = false;
								return sub;
							}),
						];
					} else {
						if (resource.selected) {
							resource.widgets = widgets;
							resource.width = resourceWidth;
						}
					}
					return resource;
				}),
			};

			if (isSave) {
				await saveApi(newApplication);
				if (!hasSubComponent) navigate(-1);
				enqueueSnackbar('Registro salvo com sucesso', {variant: 'success'});
			}

			setApplication(newApplication);
			if (hasSubComponent && !isSpecifyComponent) setShowSubcomponents(true);
		} finally {
		}
	};

	const saveApi = async (app: IApplication) => {
		try {
			setLoading(true);
			const resource = app.resources!.find(x => x.selected)!;
			await resourceApi.updateComplex(resource);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Layout
			expanded={false}
			save={() => saveApplicationState(false, true)}
			middleContent={
				<Wrapper justifyContent="space-between">
					<div></div>
					<Button
						text="Live app"
						leftIcon2={isMaximazed ? 'pause' : 'play'}
						onClick={() => setIsMaximazed(!isMaximazed)}
						background={applicationTheme.brand[50]}
						textColor={applicationTheme.brand[600]}
						fill="auto"
						height="36px"
					/>
				</Wrapper>
			}>
			<S.Content isMaximazed={isMaximazed}>
				{!isMaximazed ? (
					<WidgetsContent
						application={application}
						value={widgets}
						setValue={setWidgets}
						toggleSpecifyModal={toggleSpecifyModal}
						saveApplicationState={saveApplicationState}
						enableHelpLines={enableHelpLines}
						setEnableHelpLines={setEnableHelpLines}
						selectedItem={selectedItem}
						setShowEditComplexBtn={setShowEditComplexBtn}
						widgets={widgets}
						setWidgets={setWidgets}
						hasSelected={hasSelected}
						setShowSubcomponents={setShowSubcomponents}
					/>
				) : (
					<></>
				)}
				<div className="work-screen">
					<WorkScreen
						application={application}
						widgets={widgets}
						setWidgets={setWidgets}
						pageType={pageType}
						resourceWidth={resourceWidth}
						setResourceWidth={setResourceWidth}
						isMaximazed={isMaximazed}
						setShowSubcomponents={setShowSubcomponents}
						showSubcomponents={showSubcomponents}
						setHasSelected={setHasSelected}
						enableHelpLines={enableHelpLines}
						setSelectedItem={setSelectedItem}
						setShowEditComplexBtn={setShowEditComplexBtn}
						showEditComplexBtn={showEditComplexBtn}
						toggleSpecifyModal={toggleSpecifyModal}
						selectedItem={selectedItem}
					/>
				</div>
				{!isMaximazed ? (
					<PropertyContent
						widget={selectWidget}
						setProperty={setProperty}
						saveApplicationState={saveApplicationState}
					/>
				) : (
					<></>
				)}
			</S.Content>

			<SpecifyModal
				isOpen={showSpecifyModal}
				onClose={() => setShowSpecifyModal(false)}
				componentType={componentTypeSpecify}
			/>
		</Layout>
	);
};

export default BuilderScreen;
