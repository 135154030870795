/* eslint-disable @typescript-eslint/no-explicit-any */
import {IApplication, IComponent, IOption, IProject, ISubComponent} from 'src/@types/app';
import {ComponentComplexEnum} from 'src/@types/enums';
import Components, {DataComplexComponent, DataInputComponent} from 'src/shared/components';
import {v4 as uuidV4} from 'uuid';
import {backFormatPropertyName} from 'src/shared/engine-back/common/common-formatters';

export const getSelectedWidget = (app: IApplication, sub: ISubComponent | undefined) => {
	if (sub) return sub.widgets?.find(x => x.selected);
	return app.resources?.find(x => x.selected)?.widgets?.find(x => x.selected);
};

export const getWidgetsInput = (app: IApplication, originRef?: string): IOption[] => {
	if (!originRef) {
		const resourceSelected = app.resources?.find(x => x.selected);
		const resourceTableRef = resourceSelected?.databaseTableRef;
		let columnCodeName = '';
		if (resourceTableRef) {
			const findedColumn = app.database?.tables
				?.find(x => x.ref === resourceTableRef)
				?.columns?.find(x => x.isPrimaryKey && !x.constraint);

			if (findedColumn?.suggestName) columnCodeName = findedColumn.suggestName ?? '';
			else columnCodeName = backFormatPropertyName(findedColumn?.name ?? '');
		}

		return convertToOption([
			{
				value: columnCodeName,
				label: columnCodeName,
				name: columnCodeName,
			},
			...(resourceSelected?.widgets
				?.filter(item => DataInputComponent.includes(item.name))
				?.map(item => {
					return {
						value: item.ref,
						label: item.properties?.name ?? '',
						name: item.name,
					};
				}) ?? []),
		]);
	}

	return convertToOption(
		app.database?.tables
			?.find(item => item.ref === originRef)
			?.columns?.map(item => {
				return {
					value: item.ref,
					label: backFormatPropertyName(item.name ?? ''),
					name: backFormatPropertyName(item.name ?? ''),
				};
			}) ?? [],
	);
};

export const getWidgetsInput2 = (app: IApplication, originRef?: string): IOption[] => {
	if (!originRef) {
		const resourceSelected = app.resources?.find(x => x.selected);

		return convertToOption([
			...(resourceSelected?.widgets
				?.filter(item => DataInputComponent.includes(item.name))
				?.map(item => {
					return {
						value: item.ref,
						label: item.properties?.name ?? '',
						name: item.name,
					};
				}) ?? []),
		]);
	}

	return convertToOption(
		app.database?.tables
			?.find(item => item.ref === originRef)
			?.columns?.map(item => {
				return {
					value: item.ref,
					label: backFormatPropertyName(item.name ?? ''),
					name: backFormatPropertyName(item.name ?? ''),
				};
			}) ?? [],
	);
};

export const getWidgetsInputAndComplex = (app: IApplication) =>
	convertToOption(
		app.resources
			?.find(x => x.selected)
			?.widgets?.filter(item =>
				[...DataInputComponent, ...DataComplexComponent].includes(item.name),
			)
			?.map(item => {
				return {
					value: item.ref,
					label: item.properties?.name ?? '',
					name: item.name,
				};
			}) ?? [],
	);

export const getWidthByRef = (app: IApplication, ref: string | undefined) =>
	app.resources?.find(x => x.selected)?.widgets?.find(x => x.ref === ref);

export const mergeComponent = (
	application: IApplication,
	selectedWidget: IComponent | undefined,
	complexType: ComponentComplexEnum,
	properties: any,
) => {
	const componentObject = Components.find(x => x.name === complexType);
	if (!componentObject) throw new Error('Invalid object');

	const selectedSubComponent = getSubcomponentSelected(application);
	const newComponent = {...componentObject};
	const parentSelected = application.resources
		?.find(x => x.selected)
		?.widgets?.find(x => x.selected);
	const parentTab =
		parentSelected && parentSelected.name == ComponentComplexEnum.Tab
			? parentSelected
			: undefined;
	newComponent.parentRef = getSelectedWidget(application, selectedSubComponent)?.ref;
	newComponent.properties = properties;
	newComponent.tabRef = parentTab?.properties?.tabs?.find((x: any) => x.active)?.ref;

	if (selectedWidget && selectedWidget.name !== complexType) selectedWidget = undefined;

	const newApplication = {
		...application,
		resources: [...(application.resources ?? [])].map(item => {
			if (item.selected) {
				if (selectedSubComponent) {
					if (selectedWidget) {
						item.subComponents = item.subComponents?.map(sub => {
							sub.widgets = [
								...(sub.widgets ?? []).map(subWidget => {
									if (subWidget.ref === selectedWidget?.ref) {
										newComponent.ref = subWidget.ref;
										newComponent.parentRef = selectedWidget?.parentRef;
										return newComponent;
									}
									return subWidget;
								}),
							];
							return sub;
						});
					} else {
						const sub = [
							...(item.subComponents ?? []).map(subData => {
								if (subData.selected) {
									newComponent.ref = uuidV4().toString();
									subData.widgets?.push(newComponent);
								}
								return subData;
							}),
						];

						item.subComponents = sub;
					}
				} else {
					if (selectedWidget) {
						item.widgets = item.widgets?.map(widget => {
							if (widget.ref === selectedWidget?.ref) {
								newComponent.ref = widget.ref;
								newComponent.parentRef = selectedWidget?.parentRef;
								return newComponent;
							}
							return widget;
						});
					} else {
						const widgets = [...(item.widgets ?? [])];
						newComponent.ref = uuidV4().toString();
						widgets.push(newComponent);
						item.widgets = widgets;
					}
				}
			}
			return item;
		}),
	};

	return newApplication;
};

export const removeComponent = (application: IApplication, ref: string): IApplication => {
	const selectedSubComponent = getSubcomponentSelected(application);
	const newApplication: IApplication = {
		...application,
		resources: [...(application.resources ?? [])].map(item => {
			if (selectedSubComponent)
				item.subComponents = item.subComponents?.filter(widget => widget.ref !== ref);
			else item.widgets = item.widgets?.filter(widget => widget.ref !== ref);
			return item;
		}),
	};

	return newApplication;
};

export const getApiList = (application: IApplication): IOption[] => {
	return (
		application.apis?.map(item => {
			return {
				value: item.ref ?? '',
				label: item.name ?? '',
			};
		}) ?? []
	);
};

export const getListRoute = (application: IApplication, value?: any): IOption[] => {
	return (
		application.apis
			?.find(x => x.ref === value)
			?.endpoints?.map(item => {
				return {
					value: item.ref,
					label: item.name,
				};
			}) ?? []
	);
};

const convertToOption = (data: any[]) => {
	return [
		{
			value: undefined,
			label: 'Selecione...',
		},
		...data,
	];
};

export const getValidSubComponents = (application: IApplication): ISubComponent[] =>
	getSubcomponentSaved(application) ?? [];

export const getApiDataList = (
	application: IApplication,
	selectText = 'Selecione...',
): IOption[] => {
	const data: IOption[] = [];
	data.push({
		value: undefined,
		label: selectText,
	});

	const resource = application.resources?.find(x => x.selected);
	if (!resource) return data;

	resource.apiData?.forEach(x => {
		data.push({
			value: x.action,
			label: x.name ?? '',
		});
	});

	return data;
};

export const getSubComponentList = (application: IApplication): IOption[] => {
	const data: IOption[] = [];
	data.push({
		value: undefined,
		label: 'Selecione o subcomponente...',
	});
	const subComponents = getSubcomponentAll(application);
	if (!subComponents || subComponents.length <= 0) return data;

	subComponents.map(x => {
		data.push({
			value: x.ref,
			label: x.name ?? '',
		});
	});

	return data;
};

export const isEffect = (application: IApplication) =>
	application?.resources?.find(x => x.selected)?.effects?.find(x => x.selected) ? true : false;

export const getSelectedEffect = (application: IApplication) =>
	application?.resources?.find(x => x.selected)?.effects?.find(x => x.selected);

export const getSubcomponentSelected = (application: IApplication) =>
	application?.resources?.find(x => x.selected)?.subComponents?.find(x => x.selected);

export const getSubcomponentSaved = (application: IApplication) =>
	application?.resources?.find(x => x.selected)?.subComponents?.filter(x => x.saved);

export const getSubcomponentAll = (application: IApplication) =>
	application?.resources?.find(x => x.selected)?.subComponents;

export const getSelectedApplication = (project: IProject): IApplication | undefined =>
	project.applications?.find(x => x.selected || x.selectedEdit);
