/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {useContext, useEffect, useState} from 'react';
import {Select, SizedBox, WidthBox, Wrapper} from 'src/shared/components/UI';
import * as S from '../styles';
import {IExpression, IOption, IWorkFlowItem} from 'src/@types/app';
import {getOptionFieldsList, setWorkFlowValues} from '../helper';
import {ApplicationContext} from 'src/context';
import ExpressionItem from 'src/pages/builder/Builder/components/Expression/ExpressionItem';

type Props = {
	item: IWorkFlowItem;
	workFlowItem: IWorkFlowItem[];
	setWorkFlowItem: (value: IWorkFlowItem[]) => void;
};

export const AttrVariableComp = ({item, workFlowItem, setWorkFlowItem}: Props) => {
	const [application] = useContext(ApplicationContext);
	const [fieldOptions, setFieldOptions] = useState<IOption[]>([]);

	useEffect(() => {
		setFieldOptions(getOptionFieldsList(application, workFlowItem, item));
	}, [application]);

	const saveExp = (data: IExpression) => {
		setWorkFlowValues(workFlowItem, setWorkFlowItem, item.ref!, 'expression', data);
	};

	return (
		<S.ContentBasic>
			<Wrapper>
				<WidthBox width={item.values?.propOptions ? '50%' : '100%'}>
					<Select
						value={fieldOptions.find(y => y.value == item.values?.field)}
						options={fieldOptions}
						onChange={val => {
							const data = [...workFlowItem].map(x => {
								if (x.ref === item.ref!) {
									x.values = {
										...(x.values ?? {}),
										field: val.value,
										propName: val.label,
										propOptions: val.custom?.value2,
									};
								}
								return x;
							});

							setWorkFlowItem(data);
						}}
						label="Campo"
						background="transparent"
						inputType="minimal"
					/>
				</WidthBox>
				{item.values?.propOptions ? (
					<WidthBox width="50%">
						<Select
							value={item.values?.propOptions?.find(
								(y: IOption) => y.value == item.values?.subField,
							)}
							options={item.values?.propOptions ?? []}
							onChange={(val: IOption) => {
								const data = [...workFlowItem].map(x => {
									if (x.ref === item.ref!) {
										x.values = {
											...(x.values ?? {}),
											subField: val.value,
											subFieldName: val.label,
										};
									}
									return x;
								});

								setWorkFlowItem(data);
							}}
							label="Propriedade"
							background="transparent"
							inputType="minimal"
						/>
					</WidthBox>
				) : (
					<></>
				)}
			</Wrapper>
			<SizedBox height="10px" />
			<ExpressionItem
				currentItem={item?.values?.expression ?? {}}
				inputType="minimal"
				useWorkflow={true}
				workFlowItem={workFlowItem}
				customSave={saveExp}
			/>
		</S.ContentBasic>
	);
};
