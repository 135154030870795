/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {Text} from '../../UI';

type Props = {
	text?: string;
	fontSize?: string;
	color?: string;
	fontWeight?: string;
	margin?: string;
	dataRef?: string;
	propRef?: string;
};

const LabelValue = ({text, fontSize, color, fontWeight, margin}: Props) => {
	return (
		<>
			<Text
				text={'LabelValue'}
				fontSize={fontSize}
				color={color}
				fontWeight={fontWeight}
				margin={margin}
			/>
		</>
	);
};

export default LabelValue;

export const labelValueProperties = {
	fontSize: 'string',
	color: 'color',
	fontWeight: 'normal,bold,light,100,400,600,800',
	margin: 'string',
};
