import {faAdd, faEdit, faTrash} from '@fortawesome/free-solid-svg-icons';
import React, {useContext, useState} from 'react';
import {ISubComponent} from 'src/@types/app';
import {Modal} from 'src/shared/components/Complex';
import {Button, Wrapper} from 'src/shared/components/UI';
import {ApplicationContext} from 'src/context';
import {getValidSubComponents} from 'src/helpers/methods/app-methods';
import CreateSubComponent from './Create';

type Props = {
	isOpen: boolean;
	onClose: () => void;
};

const SubComponent = ({isOpen, onClose}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);

	const [addNew, setAddNew] = useState(false);
	const [selectedItem, setSelectedItem] = useState<ISubComponent | undefined>();

	const close = () => {
		onClose();
	};

	const editItem = (item: ISubComponent) => {
		setSelectedItem(item);
		setAddNew(true);
	};

	const removeItem = (item: ISubComponent) => {
		setApplication({
			...application,
			resources: [
				...(application.resources ?? []).map(resource => {
					if (resource.selected) {
						resource.subComponents = [
							...(resource.subComponents ?? []).filter(x => x.ref != item.ref),
						];
					}
					return resource;
				}),
			],
		});
	};

	const render = () => {
		return (
			<>
				<Wrapper margin="20px 0 0 0" justifyContent="end">
					<Button
						onClick={() => setAddNew(true)}
						text="Adicionar"
						leftIcon={faAdd}
						fill="auto"
						themeStyle="primary"
						type="ghost"
					/>
				</Wrapper>

				{/* {(function () {
					if (getValidSubComponents(application).length > 0) {
						return (
							<Grid
								header={['Componente']}
								template="4fr 1fr"
								headerTextColor={'#000'}
								bodyBackground={'#000'}
								bodyTextColor="#fff"
								data={getValidSubComponents(application)}
								properties={['name']}
								dataActions={[
									{
										icon: faEdit,
										color: '#008AD2',
										onClick: editItem,
									},
									{
										icon: faTrash,
										color: '#F44336',
										onClick: removeItem,
									},
								]}
							/>
						);
					}
				})()} */}

				<Wrapper margin="20px 0 0 0" justifyContent="end">
					<Button
						onClick={close}
						text="Fechar"
						fill="auto"
						themeStyle="primary"
						type="ghost"
					/>
				</Wrapper>
			</>
		);
	};

	return (
		<Modal isOpen={isOpen} onClose={close} width="50%" maxWidth="900px">
			<>
				{(function () {
					if (addNew)
						return (
							<CreateSubComponent
								cancel={() => setAddNew(false)}
								close={close}
								component={selectedItem}
								setSelectedItem={setSelectedItem}
							/>
						);
					return render();
				})()}
			</>
		</Modal>
	);
};

export default SubComponent;
