import React, {useContext, useEffect, useState} from 'react';
import {ApiParamTypeEnum} from 'src/@types/enums';
import {ApplicationContext} from 'src/context';
import {getResponseValues} from 'src/helpers/methods/action-methods';
import {getApiDataList, getSubcomponentSelected} from 'src/helpers/methods/app-methods';
import {WidthBox, Wrapper, Input} from '..';
import {IOption} from '../Select';
import {SystemSelect} from '../../System/SystemSelect';

export interface ILocalDataSelect {
	data?: IOption;
	prop?: string;
}

type Props = {
	compWidth?: string;
	localData: ILocalDataSelect | undefined;
	setLocalData: (val: ILocalDataSelect | undefined) => void;
	acceptTypes?: ApiParamTypeEnum[];
};

const LocalDataSelect = ({
	compWidth = '100%',
	localData,
	setLocalData,
	acceptTypes = [
		ApiParamTypeEnum.STRING,
		ApiParamTypeEnum.NUMBER,
		ApiParamTypeEnum.BOOLEAN,
		ApiParamTypeEnum.OBJECT,
		ApiParamTypeEnum.ARRAY,
		ApiParamTypeEnum.FILE,
	],
}: Props) => {
	const [application] = useContext(ApplicationContext);

	const [listData, setListData] = useState<IOption[]>([]);
	const [listProp, setListProp] = useState<IOption[]>([]);

	const isSubcomponent = getSubcomponentSelected(application) ? true : false;

	useEffect(() => {
		setListData(getApiDataList(application));
	}, [application]);

	useEffect(() => {
		if (!localData || !localData.data || !localData.data.value) {
			setListProp([]);
			return;
		}
		setListProp(getResponseValues(localData?.data?.value, application, acceptTypes));
	}, [localData]);

	return (
		<Wrapper flexDirection="column" alignItems="start" gap="0">
			<Wrapper width={compWidth}>
				{!isSubcomponent ? (
					<WidthBox>
						<SystemSelect
							value={localData?.data}
							options={listData}
							onChange={val => setLocalData({...(localData ?? {}), data: val})}
							label="Origem de dados"
						/>
					</WidthBox>
				) : (
					<></>
				)}
				<WidthBox>
					{isSubcomponent ? (
						<Input
							value={localData?.prop}
							onChange={val => setLocalData({...(localData ?? {}), prop: val})}
							background="transparent"
							border="1px solid #333"
							textColor={'#000'}
						/>
					) : (
						<SystemSelect
							value={listProp.find(x => x.label === localData?.prop)}
							options={listProp}
							onChange={value =>
								setLocalData({...(localData ?? {}), prop: value.label})
							}
							label="Propriedade com o valor do status"
							disabled={!localData || !localData.data || !localData.data.value}
						/>
					)}
				</WidthBox>
			</Wrapper>
		</Wrapper>
	);
};

export default LocalDataSelect;
