import React, {useContext, useState} from 'react';
import * as S from './styles';
import {Button, Wrapper, Text, SizedBox, ModalTitle} from 'src/shared/components/UI';
import ApplicationContext from 'src/context/ApplicationContext';
import {IEnvironment} from 'src/@types/app';
import {SystemInput} from 'src/shared/components/System/SystemInput';
import {applicationTheme} from 'src/shared/theme';
import {SystemDataGrid} from 'src/shared/components/System/SystemDataGrid';
import {SystemRowOptions} from 'src/shared/components/System/SystemRowOptions';
import {MenuItem} from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import Modal from 'src/shared/components/Complex/Modal';
import {IconWrapper} from 'src/shared/components/UI/ModalTitle/styles';

interface CellType {
	row: IEnvironment;
}

export const RenderEnvironment = () => {
	const [data, setData] = useContext(ApplicationContext);
	const [env, setEnv] = useState<IEnvironment>();
	const [isOpenDelete, setIsOpenDelete] = useState(false);
	const [itemToDelete, setItemToDelete] = useState<IEnvironment | null>(null);

	const handleAdd = () => {
		if (
			!env?.name ||
			data.environments
				?.map(x => x.name?.toLocaleLowerCase())
				.includes(env.name.toLocaleLowerCase())
		)
			return;
		const environments = [...(data.environments ?? [])];
		environments.push({
			ref: crypto.randomUUID(),
			name: env?.name,
		});
		setData({...data, environments});
		setEnv(undefined);
	};

	const removeItem = (item: IEnvironment) => {
		setData({
			...data,
			environments: [...(data.environments ?? [])].filter(x => x.ref != item.ref),
		});
	};

	const openDeleteModal = (item: IEnvironment) => {
		setItemToDelete(item);
		setIsOpenDelete(true);
	};

	const onRemove = () => {
		if (itemToDelete) {
			removeItem(itemToDelete);
			setItemToDelete(null); // Limpa o item após a exclusão
			setIsOpenDelete(false);
		}
	};

	return (
		<>
			<S.Content>
				<S.Tag>
					<div className="text">Ambientes</div>
				</S.Tag>
				<S.Card>
					<Wrapper flexDirection="column">
						<SystemInput
							value={env?.name}
							onChange={value => setEnv({...env, name: value})}
							label="Nome"
							onPressEnter={handleAdd}
						/>
					</Wrapper>
					<Wrapper margin="25px 0 0 0" justifyContent="end">
						<Button
							text="Adicionar"
							onClick={handleAdd}
							fill="auto"
							background={applicationTheme.brand[600]}
						/>
					</Wrapper>

					<SizedBox height="20px" />

					<SystemDataGrid
						rowsData={data.environments ?? []}
						columns={[
							{
								flex: 0.1,
								minWidth: 90,
								type: 'actions',
								filterable: false,
								hideable: false,
								headerAlign: 'left',
								align: 'left',
								sortable: false,
								field: 'actions',
								headerName: '',
								renderCell: ({row}: CellType) => (
									<SystemRowOptions
										childrens={
											<>
												<MenuItem
													onClick={() => openDeleteModal(row)}
													sx={{'& svg': {mr: 2}}}>
													<FeatherIcon icon="trash-2" size={16} />
													<Text
														text="Excluir"
														color={applicationTheme.error[900]}
													/>
												</MenuItem>
											</>
										}
									/>
								),
							},
							{
								flex: 0.5,
								type: 'string',
								minWidth: 150,
								filterable: true,
								headerAlign: 'left',
								align: 'left',
								sortable: true,
								field: 'name',
								headerName: `Nome`,
								renderCell: ({row}: CellType) => row.name,
							},
						]}
					/>
				</S.Card>
			</S.Content>
			<Modal isOpen={isOpenDelete} onClose={() => setIsOpenDelete(false)} width="auto">
				<>
					<ModalTitle
						title="Deletar Item?"
						icon={
							<IconWrapper>
								<FeatherIcon icon="alert-circle" size={20} />
							</IconWrapper>
						}
						centerTitle={true}
					/>
					<div
						style={{
							color: applicationTheme.gray[500],
							textAlign: 'center',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							fontSize: '14px',
							paddingBottom: '24px',
						}}>
						Tem certeza de que deseja excluir este item? <br />
						Essa ação não pode ser desfeita.
					</div>
					<Wrapper margin="20px 0 0 0" justifyContent="end">
						<Button
							text="Cancelar"
							onClick={() => setIsOpenDelete(false)}
							fill="full"
							background={applicationTheme.gray[300]}
							textColor={applicationTheme.gray[700]}
							type="ghost"
						/>
						<Button
							text="Deletar"
							onClick={onRemove}
							fill="full"
							background={applicationTheme.error[600]}
						/>
					</Wrapper>
				</>
			</Modal>
		</>
	);
};
