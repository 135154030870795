/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {IWorkFlowItem} from 'src/@types/app';
import * as S from '../styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClose} from '@fortawesome/free-solid-svg-icons';
import {workflowIcons, workflowSubcomponent, workflowTitle} from '../helper';
import {WorkFlowTypeEnum} from 'src/@types/enums';
import {Input, WidthBox, Wrapper} from 'src/shared/components/UI';

export const createWorkflowItemFront = (
	item: IWorkFlowItem,
	workFlowItem: IWorkFlowItem[],
	setWorkFlowItem: (value: IWorkFlowItem[]) => void,
): JSX.Element => {
	const buildOption = (type: WorkFlowTypeEnum) => (
		<S.OptionItem
			title={workflowTitle(type)}
			onClick={() =>
				changeFlowItem(workFlowItem, setWorkFlowItem, {
					...item,
					type: type,
				})
			}>
			<i className={workflowIcons(type)}></i>
		</S.OptionItem>
	);

	return (
		<Wrapper flexDirection="column" gap="0px">
			<S.Content
				onClick={(e: any) => {
					e.stopPropagation();
					changeFlowItem(workFlowItem, setWorkFlowItem, {
						...item,
						showConfig: !item.showConfig,
						showTypes: false,
					});
				}}>
				<Wrapper gap="0px" width="90%">
					<S.IconAction
						onClick={(e: any) => {
							e.stopPropagation();
							changeFlowItem(workFlowItem, setWorkFlowItem, {
								...item,
								showTypes: !item.showTypes,
								showConfig: false,
							});
						}}>
						<i className={workflowIcons(item.type ?? WorkFlowTypeEnum.Default)}></i>
					</S.IconAction>
					{item.isEditText ? (
						<WidthBox width="70%">
							<Input
								value={item.text}
								onChange={val =>
									changeFlowItem(workFlowItem, setWorkFlowItem, {
										...item,
										text: val,
									})
								}
								inputType="undeline"
								onPressEnter={() =>
									changeFlowItem(workFlowItem, setWorkFlowItem, {
										...item,
										isEditText: false,
									})
								}
							/>
						</WidthBox>
					) : (
						<S.Text
							onClick={(e: any) => e.stopPropagation()}
							onDoubleClick={(e: any) => {
								e.stopPropagation();
								changeFlowItem(workFlowItem, setWorkFlowItem, {
									...item,
									isEditText: true,
								});
							}}>
							{item?.text}
						</S.Text>
					)}
				</Wrapper>
				<Wrapper gap="0px" width="10%" justifyContent="end" margin="0 2px 0 0">
					{createOptionsMenu(workFlowItem, setWorkFlowItem, item)}
				</Wrapper>
			</S.Content>
			{item.showConfig ? (
				<S.SubContent>
					<Wrapper margin="7px" gap="7px">
						{workflowSubcomponent(
							item.type ?? WorkFlowTypeEnum.Default,
							item,
							workFlowItem,
							setWorkFlowItem,
						)}
					</Wrapper>
				</S.SubContent>
			) : (
				<></>
			)}
			{item.showTypes ? (
				<S.SubContent>
					<Wrapper margin="7px" gap="7px" flexWrap="wrap">
						{buildOption(WorkFlowTypeEnum.Variable)}
						{buildOption(WorkFlowTypeEnum.AttrVariable)}
						{buildOption(WorkFlowTypeEnum.EnableDisableFields)}
						{buildOption(WorkFlowTypeEnum.ShowHideFields)}
					</Wrapper>
				</S.SubContent>
			) : (
				<></>
			)}
		</Wrapper>
	);
};

const changeFlowItem = (
	workFlowItem: IWorkFlowItem[],
	setWorkFlowItem: (value: IWorkFlowItem[]) => void,
	flowItem: IWorkFlowItem,
	isRemove?: boolean,
) => {
	let workflowData: IWorkFlowItem[];
	if (!isRemove) {
		workflowData = [...workFlowItem].map(item => {
			if (item.ref === flowItem.ref) return flowItem;
			item.showMenu = false;
			return item;
		});

		if (workFlowItem.filter(item => item.refItem == flowItem.ref)?.length > 0) {
			workflowData = [...workflowData].filter(item => item.refItem != flowItem.ref);
		}
	} else {
		workflowData = [...workFlowItem].filter(item => item.ref != flowItem.ref);
	}

	setWorkFlowItem(workflowData);
};

const createOptionsMenu = (
	workFlowItem: IWorkFlowItem[],
	setWorkFlowItem: (value: IWorkFlowItem[]) => void,
	item: IWorkFlowItem,
) => {
	return (
		<S.OptionsMenu
			onClick={(e: any) => {
				e.stopPropagation();
				changeFlowItem(workFlowItem, setWorkFlowItem, item, true);
			}}>
			<FontAwesomeIcon icon={faClose} />
		</S.OptionsMenu>
	);
};
