import React, {useContext, useEffect, useState} from 'react';
import * as S from './styles';
import Wrapper from '../../../../UI/Wrapper';
import {ApplicationContext, BuilderContext, ProjectContext, UserContext} from 'src/context';
import {ApplicationApi} from 'src/shared/repositories/application-api';
import {Button} from 'src/shared/components/UI';
import FeatherIcon from 'feather-icons-react';
import {SystemInput} from '../../../SystemInput';
import {useNavigate} from 'react-router-dom';
import {ROUTER_PATHS} from 'src/router/router.path';
import {applicationTheme} from 'src/shared/theme';
import {Alert} from '@mui/material';

type Props = {
	search?: (value: string) => void;
	save?: () => void;
	messageWarning?: string;
	middleContent?: JSX.Element;
};

export const TopBarMenu = ({search, save, messageWarning, middleContent}: Props) => {
	const [project] = useContext(ProjectContext);
	const [application] = useContext(ApplicationContext);
	const [builder, setBuilder] = useContext(BuilderContext);
	const [user] = useContext(UserContext);
	const [searchInput, setSearchInput] = useState('');
	const [showFloat, setShowFloat] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		if (builder.buildStatus == 0 && project.applications?.find(x => x.selected)) {
			getBuildingStatus();
		}
	}, [builder]);

	const getBuildingStatus = async () => {
		setTimeout(async () => {
			const result = await new ApplicationApi().getBuildingStatus(application.id ?? 0);
			setBuilder({
				buildStatus: result?.buildStatus,
				projectLink: result?.projectLink,
			});
		}, 8000);
	};

	return (
		<>
			<S.TopBar>
				{search ? (
					<SystemInput
						width="20%"
						margin="0 0 0 50px"
						maxWidth="450px"
						value={searchInput}
						onChange={e => {
							if (search) search(e);
							setSearchInput(e);
						}}
						icon="search"
						placeholder="Search"
					/>
				) : application?.name ? (
					<S.ContentAppName onClick={() => navigate(ROUTER_PATHS.PROJECT)}>
						<div className="icon">
							<FeatherIcon icon="folder" />
						</div>
						<div className="name">{application?.name}</div>
					</S.ContentAppName>
				) : (
					<></>
				)}

				<Wrapper width="50%" justifyContent="space-between">
					{middleContent ? middleContent : <></>}
				</Wrapper>
				<Wrapper width="30%" justifyContent="end" margin="0 50px 0 0">
					{messageWarning ? <Alert severity="warning">{messageWarning}</Alert> : <></>}
					{save ? (
						<Button
							text="Salvar"
							leftIcon2="save"
							onClick={save}
							background={applicationTheme.brand[50]}
							textColor={applicationTheme.brand[600]}
							fill="auto"
						/>
					) : (
						<></>
					)}

					<S.CircleAction textColor="#000" onClick={() => setShowFloat(!showFloat)}>
						<i className="fa fa-user"></i>
					</S.CircleAction>
					<S.UserInfo>
						<div className="name">{user.name}</div>
						<div className="email">{user.email}</div>
					</S.UserInfo>
					<FeatherIcon icon="inbox" />
				</Wrapper>
			</S.TopBar>
		</>
	);
};
