import styled from 'styled-components';

export const NoContent = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px 0 0 0;
`;

type ItemProps = {
	parents: number;
	color: string;
};
export const Item = styled.div<ItemProps>`
	display: flex;
	box-sizing: border-box;
	width: 100%;
	cursor: pointer;
	padding: 2px 16px;
	font-size: 13px;
	font-family: Arial;
	margin-left: ${props => props.parents * 16}px;
	color: ${props => props.color};
`;
