import {IOption} from 'src/shared/components/UI/Select';
import {TemplateEnum} from 'src/templates/template-enum';

export const TemplateListData: IOption[] = [
	// {
	// 	value: undefined,
	// 	label: 'Selecione...',
	// },
	{
		value: TemplateEnum.None,
		label: 'None',
	},
	{
		value: TemplateEnum.Nifty,
		label: 'Nifty',
	},
];

export const CheckNewMessages: IOption[] = [
	{
		value: undefined,
		label: 'Selecione...',
	},
	{
		value: 1,
		label: 'Verificar automáticamente',
	},
	{
		value: 2,
		label: 'Não verificar automáticamente',
	},
];

export const CheckNewNotifications: IOption[] = [
	{
		value: undefined,
		label: 'Selecione...',
	},
	{
		value: 1,
		label: 'Verificar automáticamente',
	},
	{
		value: 2,
		label: 'Não verificar automáticamente',
	},
];
