import {faGear} from '@fortawesome/free-solid-svg-icons';
import React, {useContext, useEffect, useState} from 'react';
import {IOption, ISubComponent} from 'src/@types/app';
import {Button, Input, Select, WidthBox, Wrapper} from 'src/shared/components/UI';
import {ApplicationContext} from 'src/context';
import {getSubcomponentAll, getValidSubComponents} from 'src/helpers/methods/app-methods';

import {v4 as uuidV4} from 'uuid';

type Props = {
	cancel: () => void;
	close: () => void;
	component?: ISubComponent;
	setSelectedItem: (value: ISubComponent | undefined) => void;
};

const CreateSubComponent = ({cancel, close, component, setSelectedItem}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);

	const [name, setName] = useState('');
	const [dataName, setDataName] = useState<IOption>();
	const [listDataName, setListDataName] = useState<IOption[]>([]);

	useEffect(() => {
		if (!component) return;
		setName(component?.name ?? '');
		setDataName(component?.originData);
		setApplication({
			...application,
			resources: [...(application.resources ?? [])].map(resource => {
				if (resource.selected) {
					resource.subComponents = [
						...getValidSubComponents(application).map(x => {
							if (x.ref === component.ref) x.saved = false;
							return x;
						}),
					];
				}
				return resource;
			}),
		});
	}, [component]);

	useEffect(() => {
		const resource = application.resources?.find(x => x.selected);
		if (!resource) return;

		setListDataName(
			resource.apiData?.map(x => {
				return {
					value: x.action,
					label: x.name ?? '',
				};
			}) ?? [],
		);
	}, [application]);

	const create = () => {
		if (!component) {
			const data: ISubComponent = {
				ref: uuidV4().toString(),
				name: '',
				selected: true,
				widgets: [],
				saved: false,
			};

			setApplication({
				...application,
				resources: [...(application.resources ?? [])].map(resource => {
					if (resource.selected) {
						resource.subComponents = [...getValidSubComponents(application), data];
					}
					return resource;
				}),
			});
		} else {
			setApplication({
				...application,
				resources: [...(application.resources ?? [])].map(resource => {
					if (resource.selected) {
						resource.subComponents = [
							...(resource.subComponents ?? []).map(x => {
								if (x.ref === component.ref) {
									x.selected = true;
								}
								return x;
							}),
						];
					}
					return resource;
				}),
			});
		}

		close();
	};

	const cancelCreate = () => {
		if (!component) {
			setApplication({
				...application,
				resources: [...(application.resources ?? [])].map(resource => {
					if (resource.selected) {
						resource.subComponents = [...getValidSubComponents(application)];
					}
					return resource;
				}),
			});
		} else {
			setApplication({
				...application,
				resources: [...(application.resources ?? [])].map(resource => {
					if (resource.selected) {
						resource.subComponents = [
							...(resource.subComponents ?? []).map(x => {
								if (x.ref === component.ref) {
									component.saved = true;
									return component;
								}
								return x;
							}),
						];
					}
					return resource;
				}),
			});
		}

		clear();
	};

	const save = () => {
		const data = [...(getSubcomponentAll(application) ?? [])].map(x => {
			if (!x.saved) {
				x.name = name;
				x.saved = true;
				x.originData = dataName;
			}
			return x;
		});

		setApplication({
			...application,
			resources: [
				...(application.resources ?? []).map(resource => {
					if (resource.selected) resource.subComponents = data;
					return resource;
				}),
			],
		});

		clear();
	};

	const clear = () => {
		setName('');
		cancel();
		setSelectedItem(undefined);
	};

	const valid = () =>
		!application?.resources?.find(x => x.selected)?.subComponents?.find(x => !x.saved) ||
		!name ||
		name == '';

	return (
		<>
			<Wrapper>
				<WidthBox width="50%">
					<Input
						value={name}
						onChange={setName}
						border="1px solid #333"
						background="transparent"
						textColor={'#000'}
						mask="Sem espaço"
						label="Nome do componente"
						inputType="light"
					/>
				</WidthBox>
				<WidthBox width="50%">
					<Select
						value={dataName}
						options={listDataName}
						onChange={setDataName}
						label="Origem de dados"
						background="transparent"
						border="1px solid #333"
						textColor={'#000'}
						inputType="light"
					/>
				</WidthBox>
			</Wrapper>

			<Wrapper margin="10px 0 0 0">
				<Button
					onClick={create}
					text="Configurar"
					leftIcon={faGear}
					fill="auto"
					themeStyle="primary"
					type="ghost"
				/>
			</Wrapper>
			<Wrapper margin="20px 0 0 0" justifyContent="end">
				<Button
					onClick={cancelCreate}
					text="Cancelar"
					fill="auto"
					themeStyle="primary"
					type="ghost"
				/>
				<Button
					onClick={save}
					text={component ? 'Alterar' : 'Criar'}
					fill="auto"
					themeStyle="primary"
					disabled={valid()}
				/>
			</Wrapper>
		</>
	);
};

export default CreateSubComponent;
