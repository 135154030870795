import styled, {css} from 'styled-components';
import {shade} from 'polished';

export const Container = styled.div`
	width: 100%;
	height: 50px;
	display: flex;
	color: white;
	align-items: center;
`;

type LogoProps = {
	isOpen: boolean;
};
export const Logo = styled.div<LogoProps>`
	${({isOpen}) => css`
		width: ${isOpen ? '200px' : '70px'};
		transition: 0.1s;
	`}
`;

type MenuActionProps = {
	isOpen: boolean;
};
export const MenuAction = styled.div<MenuActionProps>`
	${({isOpen}) => css`
		width: ${isOpen ? 'calc(85% - 200px)' : 'calc(85% - 70px)'};
		transition: 0.1s;
	`}
`;

export const IconsContent = styled.div`
	width: calc(15%);
	display: flex;
	align-items: center;
	justify-content: space-around;
`;

export const MenuActionBtn = styled.div`
	border-radius: 10px;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		background-color: ${shade(0.3, '#1bb355')};
		cursor: pointer;
	}
	&:active {
		background-color: ${shade(0.5, '#1bb355')};
	}
`;
