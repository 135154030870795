import {IApplication, IResourceApi} from 'src/@types/app';
import {PrettierConfig} from '../common/prettier-config';
import prettier from 'prettier/standalone';
import {lowerFirstWord} from 'src/helpers/methods/text-methods';
import {backFormatPropertyName} from 'src/shared/engine-back/common/common-formatters';

export const addTypes = (app: IApplication): IResourceApi => {
	let code = `
  /* eslint-disable @typescript-eslint/no-explicit-any */
export interface IOption {
	value: any;
	label: string;
}

{{USER_CODE}}`;

	let userType = '';
	app.userConfiguration?.mapping
		?.filter(x => x.isEnvironment)
		.forEach(mapItem => {
			userType += `${backFormatPropertyName(
				lowerFirstWord(mapItem.columnName ?? ''),
			)}?: any;\n`;
		});

	app.userConfiguration?.mapCustom?.forEach(mapItem => {
		const lastItem = mapItem.customMapVars[mapItem.customMapVars.length - 1];
		userType += `${backFormatPropertyName(
			lowerFirstWord(lastItem.table?.label ?? ''),
		)}${backFormatPropertyName(lastItem.column?.label ?? '')}?: any;\n`;
	});

	if (userType) code = code.replace('{{USER_CODE}}', `export interface IUser {${userType}}`);
	else code = code.replace('{{USER_CODE}}', `export interface IUser {email?: string;}`);
	code = prettier.format(code, PrettierConfig);

	return {
		code,
		name: 'app.ts',
		path: 'src/@types/',
	};
};
