import styled, {css} from 'styled-components';
import {shade} from 'polished';
import {ComponentComplexEnum, ComponentTypeEnum} from 'src/@types/enums';

type ContainerProps = {
	isSelected: boolean;
	width: string;
	typeComponent: ComponentTypeEnum;
	enableHelpLines: boolean;
	nameComponent?: string;
};

const getStyleByType = (
	type: ComponentTypeEnum,
	isSelected: boolean,
	width: string,
	enableHelpLines: boolean,
	nameComponent?: string,
) => {
	if (enableHelpLines) {
		if (type === ComponentTypeEnum.Container) {
			return css`
				border: 1px solid black;
				border-radius: 2px;
				padding: 8px;
				margin: 0 0 3px 0;
				background: ${isSelected ? shade(0.05, '#ADD8E6') : 'transparent'};
				transition: 0.2s;
				width: 100%;
				max-width: 100%;
				box-sizing: border-box;
			`;
		} else if (type === ComponentTypeEnum.UI || nameComponent === ComponentComplexEnum.Tab) {
			return css`
				width: ${width && width != '' ? width : '100%'};
				max-width: 100%;
				transition: 0.2s;
				${() => {
					if (isSelected) {
						return css`
							border: 1.5px solid green;
							padding: 5px 3px;
							box-sizing: border-box;
						`;
					}
				}}
			`;
		}
	} else {
		if (type === ComponentTypeEnum.Container) {
			return css`
				width: 100%;
				max-width: 99%;
				transition: 0.2s;
			`;
		} else if (type === ComponentTypeEnum.UI) {
			return css`
				width: ${width && width != '' ? width : '100%'};
				max-width: 100%;
			`;
		}
	}
};

export const Container = styled.div<ContainerProps>`
	${props =>
		getStyleByType(
			props.typeComponent,
			props.isSelected,
			props.width,
			props.enableHelpLines,
			props.nameComponent,
		)}

	&:hover {
		background: ${shade(0.2, '#fff')};
		cursor: pointer;
		transition: 0.2s;
	}

	&:active {
		background: ${shade(0.4, '#fff')};
	}
`;

export const ContainerActionContent = styled.div`
	display: flex;
`;
export const ContainerAction = styled.div`
	width: 28px;
	height: 25px;
	border: 1px solid black;
	border-bottom: none;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 1px;
	background: #31374a;

	&:hover {
		cursor: pointer;
		background: ${shade(0.7, '#31374a')};
	}
`;

type ContentProps = {
	width: string;
};

export const Content = styled.div<ContentProps>`
	${({width}) => css`
		align-items: start;
		width: ${width && width != '' ? width : '100%'};
	`}
`;
