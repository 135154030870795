import {IResourceApi} from 'src/@types/app';

export const generateProject = (
	name: string,
	refs: string,
	packages: string,
	isApi = false,
): IResourceApi => {
	const code = `<Project Sdk="Microsoft.NET.Sdk${isApi ? '.Web' : ''}">

  <PropertyGroup>
    <TargetFramework>net7.0</TargetFramework>
    <ImplicitUsings>enable</ImplicitUsings>
    <Nullable>enable</Nullable>
  </PropertyGroup>

  ${packages}

  ${refs}

</Project>`;

	return {
		path: `src/${name}/`,
		name: `${name}.csproj`,
		code: code,
	};
};
