/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {useContext, useEffect, useState} from 'react';
import {IOption, IWorkFlowItem} from 'src/@types/app';
import {ComponentSimpleEnum} from 'src/@types/enums';
import {ApplicationContext} from 'src/context';
import * as S from '../styles';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import {setWorkFlowValues} from '../helper';
import {backFormatPropertyName} from 'src/shared/engine-back/common/common-formatters';

type Props = {
	item: IWorkFlowItem;
	workFlowItem: IWorkFlowItem[];
	setWorkFlowItem: (value: IWorkFlowItem[]) => void;
};

export const SetAutocompleteValueComp = ({item, workFlowItem, setWorkFlowItem}: Props) => {
	const [application] = useContext(ApplicationContext);

	const [autocompletes, setAutocompletes] = useState<IOption[]>([]);
	const [customPropValues, setCustomPropValues] = useState<IOption[]>([]);
	const [inputs, setInputs] = useState<IOption[]>([]);

	useEffect(() => {
		const autocompleteComps = application.resources
			?.find(x => x.selected)
			?.widgets?.filter(x => x.name === ComponentSimpleEnum.Autocomplete);

		const inputComps = application.resources
			?.find(x => x.selected)
			?.widgets?.filter(x => x.name === ComponentSimpleEnum.Input);

		setAutocompletes(
			autocompleteComps?.map(item => ({
				value: item.properties.name,
				label: item.properties.name,
				custom: item.properties,
			})) ?? [],
		);

		setInputs(
			inputComps?.map(item => ({
				value: item.properties.name,
				label: item.properties.name,
			})) ?? [],
		);
	}, []);

	return (
		<S.ContentBasic>
			<SystemSelect
				value={autocompletes.find(x => x.value === item.values?.field)}
				options={autocompletes}
				onChange={(value: any) => {
					setWorkFlowValues(
						workFlowItem,
						setWorkFlowItem,
						item.ref!,
						'field',
						value.value,
					);
					setWorkFlowValues(workFlowItem, setWorkFlowItem, item.ref!, 'value', undefined);

					const columnsNames: IOption[] = [];

					value.custom?.finderData?.propData?.customProperties?.forEach(
						(customProp: any) => {
							const column = application.database?.tables
								?.find(table =>
									table.columns?.find(column => column.ref === customProp.value),
								)
								?.columns?.find(column => column.ref === customProp.value);
							const columnName = backFormatPropertyName(column?.name ?? '');
							columnsNames.push({
								label: columnName,
								value: columnName,
							});
						},
					);

					setCustomPropValues(columnsNames);
				}}
				label="Campo"
				inputType="minimal"
			/>

			{item.values?.field ? (
				<>
					<SystemSelect
						value={customPropValues.find(x => x.value === item.values?.value)}
						options={
							customPropValues?.length > 0
								? customPropValues
								: [{value: item.values?.value, label: item.values?.value}]
						}
						onChange={value =>
							setWorkFlowValues(
								workFlowItem,
								setWorkFlowItem,
								item.ref!,
								'value',
								value.value,
							)
						}
						label="Valor"
						inputType="minimal"
					/>

					{item.values?.value ? (
						<SystemSelect
							value={inputs.find(x => x.value === item.values?.dest)}
							options={inputs}
							onChange={value =>
								setWorkFlowValues(
									workFlowItem,
									setWorkFlowItem,
									item.ref!,
									'dest',
									value.value,
								)
							}
							label="Destino"
							inputType="minimal"
						/>
					) : (
						<></>
					)}
				</>
			) : (
				<></>
			)}
		</S.ContentBasic>
	);
};
