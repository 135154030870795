import React, {ReactNode} from 'react';

type Props = {
	children: ReactNode;
	pageName?: string;
};

export const DefaultLayout = ({children, pageName}: Props) => {
	return <div>{children}</div>;
};

export default DefaultLayout;
