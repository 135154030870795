import React, {useContext, useState} from 'react';
import * as S from './styles';
import FeatherIcon from 'feather-icons-react';
import {Unstable_Popup as BasePopup} from '@mui/base/Unstable_Popup';
import {Button, Line, WidthBox, Wrapper, ModalTitle} from 'src/shared/components/UI';
import {applicationTheme} from 'src/shared/theme';
import {ApplicationContext, LoadingContext} from 'src/context';
import {ResourceApi} from 'src/shared/repositories/resource-api';
import {ApplicationApi} from 'src/shared/repositories/application-api';
import AddResource from '../../components/AddResource';
import {IResourceFolder} from 'src/@types/app';
import {IconWrapper} from 'src/shared/components/UI/ModalTitle/styles';
import {Modal} from 'src/shared/components/Complex';
import {useSnackbar} from 'notistack';

type Props = {
	item: IResourceFolder;
	name: string;
	onClick?: () => void;
	dragDropConfig?: any;
};

export const FileItem = ({item, name, onClick, dragDropConfig}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);
	const [, setLoading] = useContext(LoadingContext);
	const {enqueueSnackbar} = useSnackbar();

	const [anchor, setAnchor] = useState<null | HTMLElement>(null);
	const [showAddResource, setShowAddResource] = useState(false);
	const [itemSelected, setItemSelected] = useState<IResourceFolder>();
	const [isOpenDelete, showIsOpenDelete] = useState(false);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		setAnchor(anchor ? null : event.currentTarget);
	};

	const handleDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
		// Previne a propagação do evento para evitar que onClick no S.Content seja acionado
		event.stopPropagation();
		showIsOpenDelete(true);
	};

	const open = Boolean(anchor);
	const id = open ? 'simple-popper' : undefined;
	const resourceApi = new ResourceApi();
	const applicationApi = new ApplicationApi();

	const onRemove = async () => {
		try {
			setLoading(true);
			const resource = application.resources?.find(x => x.ref === item.ref);

			if (resource) await resourceApi.delete(resource.id!);

			const app = await applicationApi.getResources(application);
			setApplication(app!);

			enqueueSnackbar('Registro deletado com sucesso', {variant: 'success'});
		} catch (error) {
			enqueueSnackbar('Erro ao deletar o registro', {variant: 'error'});
		} finally {
			setLoading(false);
			showIsOpenDelete(false);
		}
	};

	const onEdit = (e: any) => {
		handleClick(e);
		const resource = application.resources?.find(x => x.ref === item.ref);

		setItemSelected(item);
		if (resource) setShowAddResource(true);
	};

	const onMoveOut = async (e: any) => {
		handleClick(e);
		try {
			setLoading(true);
			const out = application.resources?.find(x => x.ref === item.ref);
			if (!out) return;
			const newPath = out.path?.split('/') ?? [];
			newPath.pop();
			out.path = newPath.join('/');

			await resourceApi.update({
				...(out! as any),
			});
		} finally {
			const app = await applicationApi.getResources(application);
			setApplication(app!);
			setLoading(false);
		}
	};

	return (
		<>
			<S.Content onClick={onClick} {...dragDropConfig}>
				<S.Principal>
					<FeatherIcon icon="file" className="icon" size={25} />
					<div className="name">{name}</div>
				</S.Principal>
				<S.Second onClick={handleClick} aria-describedby={id}>
					<FeatherIcon icon="more-vertical" className="icon" size={22} />
				</S.Second>
				<BasePopup id={id} open={open} anchor={anchor} placement="right-start" offset={5}>
					<S.PopUpContent>
						{![undefined, '', '/'].includes(
							application.resources?.find(x => x.ref === item.ref)?.path,
						) ? (
							<S.PopBtn onClick={onMoveOut}>
								<FeatherIcon icon="folder-minus" className="icon" size={16} />
								Mover para fora
							</S.PopBtn>
						) : (
							<></>
						)}
						<S.PopBtn onClick={onEdit}>
							<FeatherIcon icon="edit" className="icon" size={16} />
							Editar
						</S.PopBtn>
						<Wrapper alignItems="center" justifyContent="center">
							<WidthBox width="90%">
								<Line height="0.5px" background={applicationTheme.gray[300]} />
							</WidthBox>
						</Wrapper>
						<S.PopBtn onClick={handleDeleteClick} isRemove>
							<FeatherIcon icon="trash-2" className="icon" size={16} />
							Excluir
						</S.PopBtn>
						<S.PopBtn onClick={handleClick} aria-describedby={id}>
							<FeatherIcon icon="x" className="icon" size={16} />
							Fechar
						</S.PopBtn>
					</S.PopUpContent>
				</BasePopup>
			</S.Content>

			{/* Modal de Exclusão */}
			<Modal isOpen={isOpenDelete} onClose={() => showIsOpenDelete(false)} width="auto">
				<>
					<ModalTitle
						title="Deletar recurso?"
						icon={
							<IconWrapper>
								<FeatherIcon icon="alert-circle" size={20} />
							</IconWrapper>
						}
						centerTitle={true}
					/>
					<div
						style={{
							color: applicationTheme.gray[500],
							textAlign: 'center',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							fontSize: '14px',
							paddingBottom: '24px',
						}}>
						Tem certeza de que deseja excluir este item? <br />
						Essa ação não pode ser desfeita.
					</div>
					<Wrapper margin="20px 0 0 0" justifyContent="end">
						<Button
							text="Cancelar"
							onClick={() => showIsOpenDelete(false)}
							fill="full"
							background={applicationTheme.gray[300]}
							textColor={applicationTheme.gray[700]}
							type="ghost"
						/>
						<Button
							text="Deletar"
							onClick={onRemove}
							fill="full"
							background={applicationTheme.error[600]}
						/>
					</Wrapper>
				</>
			</Modal>

			<AddResource
				isOpen={showAddResource}
				onClose={() => setShowAddResource(false)}
				itemSelected={application.resources?.find(x => x.ref === itemSelected?.ref)}
			/>
		</>
	);
};
