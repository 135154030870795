import {applicationTheme} from 'src/shared/theme';
import styled from 'styled-components';

export const Content = styled.div`
	font-size: 18px;
	font-weight: 600;

	.title {
		font-family: Inter;
		font-size: 18px;
		font-weight: 600;
		line-height: 28px;
		letter-spacing: 0em;
		text-align: left;
		color: ${applicationTheme.gray[900]};
	}
	.subtitle {
		font-size: 11px;
		color: ${applicationTheme.gray[600]};
		font-weight: 400;
	}

	.container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
`;

export const Line = styled.div`
	width: 100%;
	height: 0.5px;
	background: ${applicationTheme.gray[300]};
	margin-top: 22px;
	margin-bottom: 22px;
`;

export const Line2 = styled.div`
	width: 100%;
	height: 0.5px;
	background: ${applicationTheme.gray[300]};
	margin-top: 10px;
	margin-bottom: 22px;
`;

export const ContentModal = styled.div`
	//width: 400px; /* Largura fixa de 400px */
	height: auto; /* Altura definida pelo conteúdo (Hug) */
	max-height: 268px; /* Define um limite de altura de 268px */
	overflow-y: auto; /* Adiciona rolagem se o conteúdo exceder a altura máxima */
	font-size: 18px;
	font-weight: 600;
	padding: 16px; /* Espaçamento interno para o conteúdo */
	box-sizing: border-box; /* Inclui padding e border na largura e altura */

	.text-container {
		text-align: center; /* Centraliza o texto e o ícone horizontalmente */
	}

	.title {
		font-family: Inter;
		font-size: 18px;
		font-weight: 600;
		line-height: 28px;
		letter-spacing: 0em;
		color: ${applicationTheme.gray[900]};
	}

	.subtitle {
		font-size: 11px;
		color: ${applicationTheme.gray[600]};
		font-weight: 400;
	}

	.container {
		display: flex;
		flex-direction: column;
		align-items: center; /* Centraliza os itens no eixo vertical */

		&.centered {
			justify-content: center;
		}

		.icon {
			margin-bottom: 8px; /* Espaçamento entre o ícone e o título */
			display: flex;
			justify-content: center; /* Centraliza o ícone */
			align-items: center;
		}
	}
`;

export const IconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: ${applicationTheme.error[100]};
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
	margin-bottom: 24px;
`;

export const TitleLabel = styled.h6`
	font-family: Inter;
	font-size: 14px;
	font-weight: 600;
	line-height: 28px;
	letter-spacing: 0em;
	color: ${applicationTheme.gray[900]};
	padding-bottom: 6px;
`;
