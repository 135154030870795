import React, {useState} from 'react';
import {AddBarItem} from 'src/shared/components/System/Layout/ui/AddBarItem';
import {AddProject} from '../AddProject';

export const AddBarRender = () => {
	const [showAddProject, setShowAddProject] = useState(false);

	return (
		<>
			<AddBarItem
				icon="folder"
				text="Adicionar projeto"
				onClick={() => setShowAddProject(true)}
			/>

			<AddProject isOpen={showAddProject} onClose={() => setShowAddProject(false)} />
		</>
	);
};
