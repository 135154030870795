import React from 'react';
import * as S from './styles';
import FeatherIcon from 'feather-icons-react';
import {Button, Wrapper} from 'src/shared/components/UI';
import {applicationTheme} from 'src/shared/theme';

type Props = {
	publicationDate: string;
	classification: string;
	title: string;
	description: string;
	icon: string;
	iconColor: string;
	url: string;
};

const openInNewTab = (url: string) => {
	window.open(url, '_blank', 'noreferrer');
};

export const NoticeCard = ({
	publicationDate,
	classification,
	title,
	description,
	icon,
	iconColor,
	url,
}: Props) => {
	return (
		<S.Content>
			<div className="top">
				<div className="hour">{publicationDate}</div>
				<div className="type" style={{backgroundColor: `${iconColor}`}}>
					<FeatherIcon icon={icon} size={16} />
					<span>{classification}</span>
				</div>
			</div>

			<div className="body">
				<div className="title">{title}</div>
				<div className="description">{description}</div>
			</div>

			<div className="footer">
				<Wrapper
					alignItems="center"
					justifyContent="center"
					flexDirection="column"
					gap="0px">
					<Button
						text="Ler matéria"
						onClick={() => {
							openInNewTab(url);
						}}
						background={applicationTheme.gray[50]}
						textColor={applicationTheme.brand[600]}
						fill="auto"
						height="36px"
					/>
				</Wrapper>
			</div>
		</S.Content>
	);
};
