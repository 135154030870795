import {IOption} from 'src/@types/app';
import {DatabaseMaskEnum} from 'src/@types/enums';

export const DatabaseMaskList: IOption[] = [
	{
		label: 'Selecione...',
		value: undefined,
	},
	{
		label: 'Número',
		value: DatabaseMaskEnum.Numero,
	},
	{
		label: 'CPF',
		value: DatabaseMaskEnum.Cpf,
	},
	{
		label: 'CNPJ',
		value: DatabaseMaskEnum.Cnpj,
	},
	{
		label: 'CPF e CNPJ',
		value: DatabaseMaskEnum.CpfCnpj,
	},
	{
		label: 'CEP',
		value: DatabaseMaskEnum.Cep,
	},
	{
		label: 'Telefone',
		value: DatabaseMaskEnum.Telefone,
	},
	{
		label: 'Hora',
		value: DatabaseMaskEnum.Hora,
	},
	{
		label: 'Monetário',
		value: DatabaseMaskEnum.Monetario,
	},
	{
		label: 'Placa',
		value: DatabaseMaskEnum.Placa,
	},
	{
		label: 'Sem espaco',
		value: DatabaseMaskEnum.SemEspaco,
	},
	{
		label: 'Senha',
		value: DatabaseMaskEnum.Senha,
	},
	{
		label: 'Guid',
		value: DatabaseMaskEnum.Guid,
	},
	{
		label: 'URL',
		value: DatabaseMaskEnum.URL,
	},
	{
		label: 'E-mail',
		value: DatabaseMaskEnum.Email,
	},
];
