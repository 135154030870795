import {IResource} from 'src/@types/app';
import {buildWorkFlowFrontCode} from 'src/helpers/workflow/workflowFront/builder/buildWorkFlowFrontCode';

export const getWorkflowEffects = (resource: IResource): string => {
	let code = '';

	resource.workflows?.forEach(workflow => {
		const mCode = buildWorkFlowFrontCode(workflow.components ?? [], resource);
		code += `
    ${mCode}\n`;
	});

	return code;
};
