import React, {useContext, useEffect, useState} from 'react';
import {IComponent} from 'src/@types/app';
import {ComponentComplexEnum, ComponentTypeEnum} from 'src/@types/enums';
import {Modal} from 'src/shared/components/Complex';
import {ApplicationContext} from 'src/context';
import {getSubcomponentSelected} from 'src/helpers/methods/app-methods';
import ChartSpecify from './subpages/Chart';
import GridSpecify from './subpages/DataGrid';
import LabelValueSpecify from './subpages/LabelValue';
import TabSpecify from './subpages/Tab';
import TimelineSpecify from './subpages/Timeline';
import UploadFileSpecify from './subpages/UploadFile';

type Props = {
	componentType: ComponentComplexEnum;
	isOpen: boolean;
	onClose: () => void;
};

const SpecifyModal = ({componentType, isOpen, onClose}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);

	const [selectedWidget, setSelectedWidget] = useState<IComponent | undefined>();

	useEffect(() => {
		if (isOpen) {
			const widget = application?.resources
				?.find(x => x.selected)
				?.widgets?.find(x => x.selected);

			const subWidget = getSubcomponentSelected(application)?.widgets?.find(x => x.selected);

			if (
				widget?.type === ComponentTypeEnum.Complex ||
				widget?.type === ComponentTypeEnum.Container
			)
				setSelectedWidget(widget);
			else if (subWidget?.type === ComponentTypeEnum.Complex) setSelectedWidget(subWidget);
			else setSelectedWidget(undefined);
		}
	}, [isOpen]);

	const closeModal = () => {
		const newApplication = {
			...application,
			resources: [...(application.resources ?? [])].map(item => {
				if (item.selected) {
					if (selectedWidget) {
						item.widgets = item.widgets?.map(widget => {
							widget.selected = false;
							return widget;
						});
					}
				}
				return item;
			}),
		};

		setApplication(newApplication);
		setSelectedWidget(undefined);
		onClose();
	};

	const render = () => {
		if (componentType === ComponentComplexEnum.DataGrid)
			return <GridSpecify onClose={closeModal} selectedWidget={selectedWidget} />;
		if (componentType === ComponentComplexEnum.Tab)
			return <TabSpecify onClose={closeModal} selectedWidget={selectedWidget} />;
		if (componentType === ComponentComplexEnum.UploadFile)
			return <UploadFileSpecify onClose={closeModal} selectedWidget={selectedWidget} />;
		if (componentType === ComponentComplexEnum.Timeline)
			return <TimelineSpecify onClose={closeModal} selectedWidget={selectedWidget} />;
		if (componentType === ComponentComplexEnum.LabelValue)
			return <LabelValueSpecify onClose={closeModal} selectedWidget={selectedWidget} />;
		if (componentType === ComponentComplexEnum.Chart)
			return <ChartSpecify onClose={closeModal} selectedWidget={selectedWidget} />;

		return <></>;
	};

	return (
		<Modal isOpen={isOpen} onClose={closeModal} maxWidth="1150px">
			<div>{render()}</div>
		</Modal>
	);
};

export default SpecifyModal;
