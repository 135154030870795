import {IOption} from 'src/@types/app';
import {FtpEnum} from 'src/@types/enums';

export const FtpList: IOption[] = [
	{
		value: undefined,
		label: 'Selecione...',
	},
	{
		value: FtpEnum.FTP,
		label: 'FTP',
	},
	{
		value: FtpEnum.SFTP,
		label: 'SFTP',
	},
	{
		value: FtpEnum.WinSCP,
		label: 'WinSCP',
	},
];
