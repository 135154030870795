/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {IApplication, IWorkFlowItem} from 'src/@types/app';
import {WorkFlowTypeEnum} from 'src/@types/enums';
import {isDisableOperations} from '../../helper';
import {builderJavaScriptExpression} from 'src/pages/builder/Builder/components/Expression/builder/buildJavaScript';
import {IConditionComp} from '../../component/conditionComp';

export const buildWorkFlowCodeInput = (
	workFlowItem: IWorkFlowItem[],
	app: IApplication,
	parentRef?: string,
) => {
	let code = '';
	workFlowItem
		.filter(x => (parentRef ? x.refItem == parentRef : !x.refItem))
		.forEach(flowItem => {
			if (flowItem.type === WorkFlowTypeEnum.Variable) code += buildVariable(flowItem);
			if (flowItem.type === WorkFlowTypeEnum.AttrVariable)
				code += buildAttrVariable(flowItem);
			if (flowItem.type === WorkFlowTypeEnum.Condition)
				code += buildCondition(flowItem, workFlowItem, app, !!parentRef);
			if (flowItem.type === WorkFlowTypeEnum.Alert) code += buildAlert(flowItem);
			if (flowItem.type === WorkFlowTypeEnum.AlertConfirm)
				code += buildAlertConfirm(flowItem, workFlowItem, app);
			if (flowItem.type === WorkFlowTypeEnum.SetAutocompleteValue)
				code += buildSetAutocompleteValue(flowItem);
		});

	code = code.replaceAll('\t\t\t', '\t');

	code = code
		.split('\n')
		.map(x => '\t\t\t' + x)
		.join('\n');
	return code;
};

const buildVariable = (flowItem: IWorkFlowItem): string => {
	if (!flowItem?.values?.variableName) return '';

	let code = `let ${flowItem.values.variableName} = `;
	if (flowItem.values.expression) code += builderJavaScriptExpression(flowItem.values.expression);
	return getFormatCode(code);
};

const buildAttrVariable = (flowItem: IWorkFlowItem): string => {
	const flowValues = flowItem.values;
	let varName = '';
	let code = '';
	if (includesVar(flowValues.propName)) {
		varName = replaceVar(flowValues.propName);
	} else {
		varName = `${flowValues.propName}`;
	}
	if (flowValues.subField) varName += `.${flowValues.subFieldName}`;

	if (includesVar(flowValues.propName)) {
		code = `${varName} = `;
		if (flowValues.expression) code += builderJavaScriptExpression(flowValues.expression);
		code = getFormatCode(code);
	} else {
		code += `${varName}: ${builderJavaScriptExpression(flowValues.expression)}`;
	}

	return code;
};

const buildCondition = (
	flowItem: IWorkFlowItem,
	workFlowItem: IWorkFlowItem[],
	app: IApplication,
	hasParentRef: boolean,
): string => {
	let code = 'if (';
	flowItem.values.conditions?.forEach((cond: IConditionComp, idx: number) => {
		let prop1 = '';
		let prop2 = '';

		if (!includesVar(cond.field1Name ?? '')) prop1 = `formData!.${cond.field1Name}`;
		else prop1 = replaceVar(cond.field1Name);
		if (!includesVar(cond.field2Name ?? '')) prop2 = `formData!.${cond.field2Name}`;
		else prop2 = replaceVar(cond.field2Name);
		if (cond.subField1) prop1 += `?.${cond.subField1Name}`;
		if (cond.subField2) prop2 += `?.${cond.subField2Name}`;

		if (!isDisableOperations(cond.operator ?? '')) code += `${prop1} ${cond.operator} ${prop2}`;
		else code += `${prop1} ${cond.operator}`;

		if (flowItem.values.conditions?.length - 1 != idx) code += ` ${cond.ext} `;
	});

	code += `)\n{{{IF_YES_CODE_${flowItem.ref}}}}`;

	const ifYes = workFlowItem.find(
		x => x.type === WorkFlowTypeEnum.IfYes && x.refItem === flowItem.ref,
	);
	const ifNo = workFlowItem.find(
		x => x.type === WorkFlowTypeEnum.IfNo && x.refItem === flowItem.ref,
	);
	if (ifYes) {
		let subCode = buildWorkFlowCodeInput(workFlowItem, app, ifYes.ref);
		const lastTabIndex = subCode.lastIndexOf('\t');
		subCode = subCode.substring(0, lastTabIndex - 2);
		code = code.replace(`{{IF_YES_CODE_${flowItem.ref}}}`, `\n${subCode}`);
	}
	if (ifNo && workFlowItem.filter(x => x.refItem === ifNo.ref)?.length > 0) {
		code += `\nelse\n{`;
		const subCode = buildWorkFlowCodeInput(workFlowItem, app, ifNo.ref);
		code += `\n${subCode}}`;
	}

	if (!hasParentRef) code += '\n';
	return code;
};

const getFormatCode = (code: string) => `${code};\n`;

const includesVar = (prop: string): boolean => {
	if (prop.includes('var -')) return true;
	return false;
};

const replaceVar = (prop: string | null | undefined): string => {
	prop = prop?.replace('var - ', '');
	return prop ?? '';
};

const buildAlert = (flowItem: IWorkFlowItem): string => {
	const code = `
	setCustomModal({
		isShow: true,
		title: '${flowItem.values.title}',
		description: '${flowItem.values.description}',
	})
	`;

	return code;
};

const buildAlertConfirm = (
	flowItem: IWorkFlowItem,
	workFlowItem: IWorkFlowItem[],
	app: IApplication,
): string => {
	let subCode = buildWorkFlowCodeInput(workFlowItem, app, flowItem?.ref);
	const lastTabIndex = subCode.lastIndexOf('\t');
	subCode = subCode.substring(0, lastTabIndex - 2);
	const code = `
	setCustomModal({
		isShow: true,
		onConfirm: () => {
			${subCode}
		},
		title: '${flowItem.values.title}',
		description: '${flowItem.values.description}',
	});`;

	return code;
};

const buildSetAutocompleteValue = (flowItem: IWorkFlowItem): string => {
	return `setFormData({...formData, VlItemNotaFiscal: val.custom.${flowItem.values.value}})`;
};
