/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState} from 'react';
import {v4 as uuidV4} from 'uuid';
import {IApplication, IComponent} from 'src/@types/app';
import {ComponentComplexEnum, ComponentTypeEnum} from 'src/@types/enums';
import * as S from './styles';
import FeatherIcon from 'feather-icons-react';
import {SizedBox, Wrapper} from 'src/shared/components/UI';
import Components from 'src/shared/components';
import Ordination from '../Ordination';
import AddEffects from '../AddEffects';
import AddAction from '../AddAction';
import {AddWorkflow} from '../AddWorkflow';

type Props = {
	application: IApplication;
	value: IComponent[];
	setValue: (value: IComponent[]) => void;
	toggleSpecifyModal: (type: ComponentComplexEnum) => void;
	saveApplicationState: (isSpecifyComponent?: boolean, isSave?: boolean) => Promise<void>;
	enableHelpLines: boolean;
	setEnableHelpLines: (value: boolean) => void;
	selectedItem: any;
	widgets: IComponent[];
	setWidgets: (value: IComponent[]) => void;
	setShowEditComplexBtn: (value: boolean) => void;
	hasSelected: boolean;
	setShowSubcomponents: (value: boolean) => void;
};

const WidgetsContent = ({
	application,
	value,
	setValue,
	toggleSpecifyModal,
	saveApplicationState,
	enableHelpLines,
	setEnableHelpLines,
	selectedItem,
	widgets,
	setWidgets,
	setShowEditComplexBtn,
	hasSelected,
}: Props) => {
	const [isOpenState, setIsOpenState] = useState<any>({});
	const [ordenationModal, setOrdenationModal] = useState(false);
	const [isOpenAddEffect, setIsOpenAddEffect] = useState(false);
	const [isOpenAddAction, setIsOpenAddAction] = useState(false);
	const [isOpenAddWorkflow, setIsOpenAddWorkflow] = useState(false);

	const onClickItem = (widget: IComponent) => {
		if (widget.type === ComponentTypeEnum.Complex) {
			toggleSpecifyModal(widget.name as ComponentComplexEnum);
		} else {
			const item = {...widget};
			const newValue = [...value];
			const selectedItem = value.find(x => !!x.selected);

			item.ref = uuidV4().toString();

			if (selectedItem) {
				if (
					selectedItem.name !== ComponentComplexEnum.Tab &&
					!selectedItem.options.hasOwnProperty('children')
				)
					return;

				if (selectedItem.name === ComponentComplexEnum.Tab)
					item.tabRef = selectedItem.properties.tabs?.find((x: any) => x.active).ref;
				item.parentRef = selectedItem.ref;
			}

			const layoutProperties = application.layout?.templateConfig
				? application.layout?.templateConfig[item.name]
				: {};

			if (item.type === ComponentTypeEnum.UI) {
				let count = 0;
				value.forEach(x => {
					if (x.name === item.name) count++;
				});

				item.properties = {
					...item.properties,
					name: `${item.name.toLowerCase()}${count}`,
					...layoutProperties,
				};
			} else if (item.type === ComponentTypeEnum.Container) {
				item.properties = {
					...item.properties,
					...layoutProperties,
				};
			}

			newValue.push(item);
			setValue(newValue);
		}
	};

	const collapseItem = (name: string, content: any) => {
		return (
			<S.Collapse>
				<S.CollapseHeader
					onClick={() =>
						setIsOpenState({...isOpenState, [name]: !isOpenState[name] ?? false})
					}>
					<div className="cont">
						<div className="flag"></div>
						<div className="icon">
							<FeatherIcon icon="server" />
						</div>
						<div className="text">{name}</div>
					</div>
					<div className="leftIcon">
						<FeatherIcon icon={isOpenState[name] ? 'chevron-up' : 'chevron-down'} />
					</div>
				</S.CollapseHeader>
				{isOpenState[name] ? <S.CollapseBody>{content}</S.CollapseBody> : <></>}
			</S.Collapse>
		);
	};

	const clearSelection = () => {
		const newWidgets = [...widgets].map(item => {
			item.selected = false;
			return item;
		});

		setWidgets(newWidgets);
		saveApplicationState();
		setShowEditComplexBtn(false);
		selectedItem(undefined);
	};

	return (
		<>
			<S.Container>
				<S.ContentBody>
					<SizedBox height="15px" />

					{collapseItem(
						'Controles',
						Components?.filter(x => x.type === ComponentTypeEnum.UI)?.map(
							(item, index) => (
								<S.CollapseItem key={index} onClick={() => onClickItem(item)}>
									<FeatherIcon icon={item.icon} className="icon" />
									<div className="text">{item.name}</div>
								</S.CollapseItem>
							),
						),
					)}

					{collapseItem(
						'Container',
						Components?.filter(x => x.type === ComponentTypeEnum.Container)?.map(
							(item, index) => (
								<S.CollapseItem key={index} onClick={() => onClickItem(item)}>
									<FeatherIcon icon={item.icon} className="icon" />
									<div className="text">{item.name}</div>
								</S.CollapseItem>
							),
						),
					)}

					{collapseItem(
						'Complexos',
						Components?.filter(x => x.type === ComponentTypeEnum.Complex)?.map(
							(item, index) => (
								<S.CollapseItem key={index} onClick={() => onClickItem(item)}>
									<FeatherIcon icon={item.icon} className="icon" />
									<div className="text">{item.name}</div>
								</S.CollapseItem>
							),
						),
					)}
				</S.ContentBody>

				<Wrapper margin="0 5px 5px 5px" gap="5px" flexWrap="wrap">
					{/* <S.ActionItem
						onClick={() => {
							saveApplicationState();
							setShowSubcomponents(true);
						}}>
						<FeatherIcon icon="settings" />
						<div className="text">Sub</div>
					</S.ActionItem> */}
					<S.ActionItem
						onClick={() => {
							setOrdenationModal(true);
							saveApplicationState();
						}}>
						<FeatherIcon icon="align-justify" />
						<div className="text">Ordenar</div>
					</S.ActionItem>
					<S.ActionItem
						onClick={() => {
							setEnableHelpLines(!enableHelpLines);
						}}>
						<FeatherIcon icon="underline" />
						<div className="text">Linhas</div>
					</S.ActionItem>
					<S.ActionItem
						onClick={() => {
							setIsOpenAddWorkflow(true);
						}}>
						<FeatherIcon icon="share-2" />
						<div className="text">Fluxo</div>
					</S.ActionItem>

					{hasSelected ? (
						<S.ActionItem onClick={clearSelection}>
							<FeatherIcon icon="x" />
							<div className="text">Limpar</div>
						</S.ActionItem>
					) : (
						<S.ActionItem
							onClick={() => {
								setIsOpenAddEffect(true);
							}}>
							<FeatherIcon icon="zap" />
							<div className="text">Efeitos</div>
						</S.ActionItem>
					)}
				</Wrapper>
			</S.Container>

			<Ordination isOpen={ordenationModal} onClose={() => setOrdenationModal(false)} />
			<AddEffects
				isOpen={isOpenAddEffect}
				onClose={() => setIsOpenAddEffect(false)}
				openNext={() => setIsOpenAddAction(true)}
			/>
			<AddAction
				isOpen={isOpenAddAction}
				onClose={() => setIsOpenAddAction(false)}
				openNext={() => setIsOpenAddEffect(true)}
				setIsOpenWorkFlow={setIsOpenAddWorkflow}
				type="front"
			/>
			<AddWorkflow isOpen={isOpenAddWorkflow} setIsOpen={setIsOpenAddWorkflow} />
		</>
	);
};

export default WidgetsContent;
