export const removeNoUse = (data: string): string => {
	let codeData = data;
	const countUseEffect = data.match(/useEffect/g);
	const countCurrentId = data.match(/setCurrentId/g);
	const countUseContext = data.match(/useContext/g);

	if (countUseEffect?.length === 1) {
		codeData = codeData.replace('useEffect,', '');
	}
	if (countCurrentId?.length === 1) {
		codeData = codeData.replace('const [currentId, setCurrentId] = useState<any>();', '');
	}
	if (countUseContext?.length === 1) {
		codeData = codeData.replace(', useContext', '');
	}

	return codeData;
};
