import {IOption} from 'src/@types/app';
import {AmqpEnum} from 'src/@types/enums';

export const AmqpList: IOption[] = [
	{
		value: undefined,
		label: 'Selecione...',
	},
	{
		value: AmqpEnum.Rabbit,
		label: 'Rabbit',
	},
	{
		value: AmqpEnum.Kafka,
		label: 'Kafka',
	},
	{
		value: AmqpEnum.AzureServiceBus,
		label: 'Azure Service Bus',
	},
];
