/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {Input, Wrapper} from 'src/shared/components/UI';
import * as S from '../styles';
import {IWorkFlowItem} from 'src/@types/app';
import {setWorkFlowValues} from '../helper';

type Props = {
	item: IWorkFlowItem;
	workFlowItem: IWorkFlowItem[];
	setWorkFlowItem: (value: IWorkFlowItem[]) => void;
};

export const AlertComp = ({item, workFlowItem, setWorkFlowItem}: Props) => {
	return (
		<S.ContentBasic>
			<Wrapper>
				<Input
					value={item.values?.title}
					onChange={val =>
						setWorkFlowValues(workFlowItem, setWorkFlowItem, item.ref!, 'title', val)
					}
					label="Titulo"
					inputType="minimal"
					background="transparent"
					textColor={'#000'}
				/>
			</Wrapper>
			<Wrapper>
				<Input
					value={item.values?.description}
					onChange={val =>
						setWorkFlowValues(
							workFlowItem,
							setWorkFlowItem,
							item.ref!,
							'description',
							val,
						)
					}
					label="Descrição"
					inputType="minimal"
					background="transparent"
					textColor={'#000'}
				/>
			</Wrapper>
		</S.ContentBasic>
	);
};
