import React, {useContext, useEffect, useState} from 'react';
import {ILogin} from 'src/@types/app';
import {AuthApi} from 'src/shared/repositories/auth-api';
import {setAuthentication} from 'src/helpers/auth/authentication';
import {useNavigate} from 'react-router-dom';
import {LoadingContext, UserContext} from 'src/context';
import {Input, SizedBox, CheckBox, Button} from 'src/shared/components/UI';
import * as S from './styles';
import mainIcon from 'src/assets/icon.png';

const REMMEMBER_KEY = 'REMMEMBER_KEY';

const AuthScreen = () => {
	const navigate = useNavigate();

	const [, setLoading] = useContext(LoadingContext);
	const [, setUser] = useContext(UserContext);

	const [login, setLogin] = useState<ILogin>();

	const authApi = new AuthApi();

	useEffect(() => {
		const remmemberEmail = localStorage.getItem(REMMEMBER_KEY);

		if (remmemberEmail) {
			setLogin({...login, email: remmemberEmail});
		}
	}, []);

	const loginUser = async () => {
		if (!login?.email || !login?.password) return;
		setLoading(true);
		if (login.remmember) localStorage.setItem(REMMEMBER_KEY, login.email);
		else localStorage.setItem(REMMEMBER_KEY, '');

		try {
			const result = await authApi.login(login);

			if (result && result.token) {
				setAuthentication(result.token);
				setTimeout(() => {
					setUser({
						name: result.name,
						email: result.userEmail,
					});
					navigate('/');
				}, 700);
			}
		} finally {
			setTimeout(() => {
				setLoading(false);
			}, 700);
		}
	};

	return (
		<S.Container>
			<S.Header>
				<img src={mainIcon} className="LogoHeader" />
			</S.Header>
			<S.Content>
				<S.ContentLogin>
					<S.FormLogin>
						<S.TitleLogin>Bem vindo ao myKlik</S.TitleLogin>
						<span>Insira seu e-mail e senha para entrar</span>
						<SizedBox height="10px" />
						<S.Title>E-mail</S.Title>
						<Input
							value={login?.email}
							onChange={val => setLogin({...login, email: val})}
							placeholder="E-mail"
							border="1px solid #ddd"
							background="transparent"
							textColor="#777"
						/>
						<SizedBox height="10px" />
						<S.Title>Senha</S.Title>
						<Input
							value={login?.password}
							onChange={val => setLogin({...login, password: val})}
							placeholder="Senha"
							border="1px solid #ddd"
							background="transparent"
							textColor="#000"
							type="password"
						/>
						<SizedBox height="10px" />
						<CheckBox
							checked={login?.remmember ?? false}
							handleToggle={() =>
								setLogin({...login, remmember: !login?.remmember ?? false})
							}
							label="Lembrar e-mail"
							textColor="#ccc"
							fontWeight="400"
						/>
						<SizedBox height="18px" />
						<Button text="Entrar" onClick={loginUser} background="#179B92" />
					</S.FormLogin>
				</S.ContentLogin>
			</S.Content>
			<S.Footer></S.Footer>
		</S.Container>
	);
};

export default AuthScreen;
