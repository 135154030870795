import {applicationTheme} from 'src/shared/theme';
import styled from 'styled-components';

export const ValueInput = styled.div`
	width: 100%;
	color: black;
`;

export const Content = styled.div`
	width: 70%;
`;

type ColorButtonProps = {
	background?: string;
};
export const ColorButton = styled.div<ColorButtonProps>`
	width: 100%;
	height: 25px;
	border-radius: 4px;
	background: ${props =>
		props.background && props.background != '' ? props.background : '#555'};
	margin: 5px 0;
	border: 0.5px solid white;
	cursor: pointer;
	position: relative;
	border: 1px solid #555;
`;

type PickerContentProps = {
	top: number;
	left: number;
};
export const PickerContent = styled.div<PickerContentProps>`
	position: absolute;
	z-index: 1000;
	top: calc(${props => props.top} + 10) px;
	left: ${props => props.left}px;
`;

export const Card = styled.div`
	background-color: #fff;
	border-radius: 8px;
	padding: 22px 16px;
`;

export const Tag = styled.div`
	color: ${applicationTheme.gray[900]};
	display: flex;
	font-size: 14px;
	font-weight: 600;
	gap: 10px;
	margin-bottom: 15px;

	.margin {
		margin-top: 12px;
	}
`;

export const LayoutItem = styled.div`
	font-family: Inter;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	text-align: left;
	cursor: pointer;
	color: ${applicationTheme.gray[500]};

	&:hover {
		color: ${applicationTheme.gray[700]};
	}

	&:active {
		color: ${applicationTheme.gray[900]};
	}

	.bold {
		font-weight: 600;
	}

	margin: 12px 0 0 0;
	margin-left: 5px;
`;

export const Margin = styled.div`
	margin-top: 20px;
`;

export const ContentUpload = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	.text {
		font-family: Inter;
		font-size: 12px;
		font-weight: 400;
		line-height: 22px;
		text-align: center;
		color: ${applicationTheme.gray[300]};
	}

	.img {
		border-radius: 8px;
		margin-bottom: 10px;
	}

	.content {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
`;

export const LayoutContent = styled.div`
	display: flex;

	.left {
		border-right: 1px solid ${applicationTheme.gray[300]};
		width: 30%;
	}
	.right {
		width: 69%;
		margin-left: 20px;
	}
`;
