/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {useContext, useEffect, useState} from 'react';
import {ApplicationContext, ProjectContext, LoadingContext} from 'src/context';
import {IApplication} from 'src/@types/app';
import {useNavigate} from 'react-router-dom';
import {ApplicationApi} from 'src/shared/repositories/application-api';
import Layout from 'src/shared/components/System/Layout';
import {MenuRoute} from 'src/shared/components/System/MenuRoute';
import * as S from './styles';
import {ProjectItem} from 'src/pages/projects/ui/ProjectItem';
import {NotFoundProject} from './ui/NotFoundProject';
import {AddBarRender} from './components/AddBarRender';
import {ROUTER_PATHS} from 'src/router/router.path';

const PrjectsScreen = () => {
	const navigate = useNavigate();
	const [project, setProject] = useContext(ProjectContext);
	const [, setApplication] = useContext(ApplicationContext);
	const [, setLoading] = useContext(LoadingContext);

	const [applications, setApplications] = useState<IApplication[]>([]);

	const applicationApi = new ApplicationApi();

	useEffect(() => {
		findAllProject();
	}, []);

	useEffect(() => {
		setApplications([...(project.applications ?? [])]);
	}, [project]);

	const findAllProject = async () => {
		setLoading(true);
		const result = await applicationApi.getAll();
		setLoading(false);
		if (result) {
			setProject(result);
		}
	};

	const onSelectProject = async (data: IApplication) => {
		setLoading(true);
		let app = await applicationApi.getById(data.id!);
		app = await applicationApi.getResources(app!, false);
		setLoading(false);

		setProject({
			...project,
			applications: [...(project.applications ?? [])].map(x => {
				if (x.ref == app!.ref) {
					x = {
						...app,
						selected: true,
					};
				} else x.selected = false;
				return x;
			}),
		});

		setApplication(app!);
		navigate(ROUTER_PATHS.PROJECT);
	};

	const search = (value: string) => {
		setApplications([
			...(project.applications?.filter(item =>
				item.name?.toUpperCase().includes(value.toUpperCase()),
			) ?? []),
		]);
	};

	return (
		<Layout search={search} expanded={false} additionalBarContent={<AddBarRender />}>
			<>
				{project.applications?.length === 0 ? (
					<NotFoundProject />
				) : (
					<S.Content>
						<MenuRoute
							pathRoute={[
								{
									name: 'Início',
									action: () => navigate(ROUTER_PATHS.HOME),
								},
								{name: 'Projetos'},
							]}
						/>

						<S.ContentProjects>
							{applications?.map(application => (
								<>
									<ProjectItem
										projRef={application.ref ?? ''}
										name={application.name ?? ''}
										onClick={() => onSelectProject(application)}
									/>
								</>
							))}
						</S.ContentProjects>
					</S.Content>
				)}
			</>
		</Layout>
	);
};

export default PrjectsScreen;
