/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	IResourceApiComplexUpdate,
	IResourceApiCreate,
	IResourceApiUpdate,
} from 'src/@types/api-request';
import {IResourceApiResult} from 'src/@types/api-result';
import {IResource} from 'src/@types/app';
import {extractSubComponents, extractWidgets} from 'src/helpers/methods/api-methods';
import {Api} from './base';

export class ResourceApi {
	async get(id: number): Promise<IResource | undefined> {
		const result = await Api.get(`/resource/${id}`);

		if (result.status == 200) {
			const resultData: IResourceApiResult = result.data;

			const resource: IResource = {
				apiData: resultData.apiData ? JSON.parse(resultData.apiData) : undefined,
				effects: resultData.effects ? JSON.parse(resultData.effects) : undefined,
				widgets: resultData.widgets ? JSON.parse(resultData.widgets) : undefined,
				subComponents: resultData.subComponents
					? JSON.parse(resultData.subComponents)
					: undefined,
				workflows: resultData.expressions ? JSON.parse(resultData.expressions) : undefined,
				expressions: resultData.workflow ? JSON.parse(resultData.workflow) : undefined,
			};

			return resource;
		}

		return undefined;
	}

	async getAll(applicationId: number): Promise<IResource[] | null> {
		const result = await Api.get(`/resource/application/${applicationId}`);

		if (result.status == 200) {
			const resultData: IResourceApiResult[] = result.data;
			const resources: IResource[] = [];

			resultData.forEach(item => {
				resources.push({
					id: item.id,
					name: item.name,
					type: item.type,
					ref: item.reference,
					width: item.width,
					path: item.path,
					menu: JSON.parse(item.menu),
					isRoot: item.isRoot,
					useDefaultLayout: item.useDefaultLayout,
					isLogin: item.isLogin,
					databaseTableRef: item.databaseTableRef,
					apiData: item.apiData ? JSON.parse(item.apiData) : undefined,
					effects: item.effects ? JSON.parse(item.effects) : undefined,
					widgets: item.widgets ? JSON.parse(item.widgets) : undefined,
					subComponents: item.subComponents ? JSON.parse(item.subComponents) : undefined,
					workflows: item.expressions ? JSON.parse(item.expressions) : undefined,
					expressions: item.workflow ? JSON.parse(item.workflow) : undefined,
				});
			});

			return resources;
		}

		return null;
	}

	async create(data: IResourceApiCreate): Promise<void> {
		await Api.post('/resource', {...data});
	}

	async update(data: IResourceApiUpdate): Promise<boolean> {
		const result = await Api.put('/resource', {...data});

		if (result.status == 200) return true;
		return false;
	}

	async updateComplex(resource: IResource): Promise<boolean> {
		const apiData: IResourceApiComplexUpdate = {
			id: resource.id!,
			apiData: resource.apiData ? JSON.stringify(resource.apiData) : undefined,
			effects: resource.effects ? JSON.stringify(resource.effects) : undefined,
			subComponents: extractSubComponents(resource.subComponents ?? []),
			widgets: extractWidgets(resource.widgets ?? []),
			expressions: resource.workflows ? JSON.stringify(resource.workflows) : undefined,
			workflow: resource.expressions ? JSON.stringify(resource.expressions) : undefined,
		};

		const result = await Api.put('/resource/complex', {...apiData});

		if (result.status == 200) return true;
		return false;
	}

	async deleteAll(id: number): Promise<boolean> {
		try {
			const result = await Api.delete(`/resource/all/${id}`);

			if (result.status == 200) return true;
			return false;
		} finally {
			return false;
		}
	}

	async delete(id: number): Promise<boolean> {
		const result = await Api.delete(`/resource/${id}`);

		if (result.status == 200) return true;
		return false;
	}
}
