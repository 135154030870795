import React, {useContext, useEffect, useState} from 'react';
import {IColumn} from 'src/@types/app';
import {DatabaseColumnEnum} from 'src/@types/enums';
import {ApplicationContext} from 'src/context';
import {DatabaseMaskList} from 'src/helpers/select-data/databaseMaskList';
import {DatabaseTypeList} from 'src/helpers/select-data/databaseTypeList';
import {Modal} from 'src/shared/components/Complex';
import {SystemInput} from 'src/shared/components/System/SystemInput';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import {Button, CheckBox, ModalTitle, WidthBox, Wrapper} from 'src/shared/components/UI';
import {applicationTheme} from 'src/shared/theme';
import {v4 as uuidV4} from 'uuid';

type Props = {
	isOpen: boolean;
	setIsOpen: (value: boolean) => void;
	selectedItem?: IColumn;
};

export const AddField = ({isOpen, setIsOpen, selectedItem}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);
	const [column, setColumn] = useState<IColumn>();

	useEffect(() => {
		if (selectedItem) setColumn(selectedItem);
		else setColumn(undefined);
	}, [isOpen]);

	const closeAddField = () => {
		setColumn(undefined);
		setIsOpen(false);
	};

	const handleAddField = () => {
		setApplication({
			...application,
			database: {
				...application.database,
				tables: application.database?.tables?.map(table => {
					if (table.selected) {
						let columns = [...(table.columns ?? [])];
						if (selectedItem) {
							columns = columns.map(item => {
								if (item.ref === selectedItem.ref) {
									item.type = column?.type;
									item.name = column?.name;
									item.suggestName = column?.suggestName;
									item.isPrimaryKey = column?.isPrimaryKey;
									item.mask = column?.mask;
									item.nullable = column?.nullable;
								}
								return item;
							});
						} else {
							columns.push({
								...column,
								ref: uuidV4().toString(),
							});
						}
						table.columns = columns;
					}

					return table;
				}),
			},
		});
		setIsOpen(false);
		setColumn(undefined);
	};

	return (
		<Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
			<>
				<ModalTitle title={selectedItem ? 'Editar campo' : 'Adicionar campo'} />
				<Wrapper margin="0 0 10px 0">
					<SystemSelect
						width="50%"
						value={DatabaseTypeList.find(x => x.value === column?.type)}
						options={DatabaseTypeList}
						onChange={value =>
							setColumn({
								...(column ?? {}),
								type: value?.value,
							})
						}
						label="Tipo"
					/>
					<SystemInput
						width="50%"
						value={column?.name}
						onChange={val => setColumn({...column, name: val})}
						mask="Sem espaço"
						label="Nome"
					/>
				</Wrapper>
				{column?.constraint == undefined && column?.type === DatabaseColumnEnum.String ? (
					<Wrapper margin="0 0 10px 0">
						<SystemSelect
							width="50%"
							value={DatabaseMaskList?.find(x => x.value === column?.mask)}
							options={DatabaseMaskList}
							onChange={value => setColumn({...(column ?? {}), mask: value?.value})}
							label="Máscara"
							disabled={column?.type != DatabaseColumnEnum.String}
						/>
						<SystemInput
							width="50%"
							value={column?.maxLength}
							onChange={val => setColumn({...(column ?? {}), maxLength: val})}
							label="Tamanho"
							mask="Numero"
						/>
					</Wrapper>
				) : (
					<></>
				)}
				{column?.constraint == undefined && column?.type === DatabaseColumnEnum.Decimal ? (
					<Wrapper margin="0 0 10px 0">
						<SystemInput
							width="50%"
							value={column?.precision}
							onChange={val => setColumn({...(column ?? {}), precision: val})}
							label="Precisão"
						/>
						<SystemInput
							width="50%"
							value={column?.scale}
							onChange={val => setColumn({...(column ?? {}), scale: val})}
							label="Escala"
						/>
					</Wrapper>
				) : (
					<></>
				)}
				<Wrapper margin="0 0 10px 0">
					<WidthBox width="50%">
						<CheckBox
							checked={column?.nullable ?? false}
							handleToggle={() =>
								setColumn({...(column ?? {}), nullable: !column?.nullable})
							}
							label="Permite vazio?"
							textColor="#000"
							fontWeight="400"
						/>
					</WidthBox>
					<WidthBox width="50%">
						<CheckBox
							checked={column?.isPrimaryKey ?? false}
							handleToggle={() =>
								setColumn({
									...(column ?? {}),
									isPrimaryKey: !column?.isPrimaryKey,
								})
							}
							label="Chave primária?"
							textColor="#000"
							fontWeight="400"
						/>
					</WidthBox>
				</Wrapper>

				{column?.constraint == undefined &&
				(column?.type === DatabaseColumnEnum.Byte ||
					column?.type === DatabaseColumnEnum.Int16 ||
					column?.type === DatabaseColumnEnum.Int32 ||
					column?.type === DatabaseColumnEnum.Int64) ? (
					<Wrapper margin="0 0 10px 0">
						<CheckBox
							checked={column?.autoIncremente ?? false}
							handleToggle={() =>
								setColumn({
									...(column ?? {}),
									autoIncremente: !column?.autoIncremente,
								})
							}
							label="Incremento automático?"
							textColor="#000"
							fontWeight="400"
						/>
					</Wrapper>
				) : (
					<></>
				)}

				<SystemInput
					value={column?.suggestName}
					onChange={val => setColumn({...column, suggestName: val})}
					label="Rótulo"
				/>

				<Wrapper margin="20px 0 0 0" justifyContent="end">
					<Button
						text="Cancelar"
						onClick={() => closeAddField()}
						fill="auto"
						background={applicationTheme.gray[300]}
						textColor={applicationTheme.gray[700]}
						type="ghost"
					/>
					<Button
						text={selectedItem ? 'Editar' : 'Salvar'}
						onClick={handleAddField}
						fill="auto"
						background={applicationTheme.brand[600]}
						disabled={column?.type == undefined || !column?.name}
					/>
				</Wrapper>
			</>
		</Modal>
	);
};
