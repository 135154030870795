import {applicationTheme} from 'src/shared/theme';
import styled from 'styled-components';

type ContentProps = {
	useMargin: boolean;
};

export const Content = styled.div<ContentProps>`
	font-family: 'Inter';
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: ${applicationTheme.gray[700]};
	margin-bottom: ${props => (props.useMargin ? '30px' : '0px')};

	.weight {
		font-weight: 700;
	}

	.action {
		cursor: pointer;
	}
`;
