import React, {useContext, useState} from 'react';
import Layout from 'src/shared/components/System/Layout';
import {LeftBarRender} from './components/LeftBarRender';
import {RenderLayout} from './components/RenderLayout';
import * as S from './styles';
import {useSnackbar} from 'notistack';
import {ApplicationContext, LoadingContext} from 'src/context';
import {ApplicationApi} from 'src/shared/repositories/application-api';
import {RenderSnapShot} from './components/RenderSnapShot';
import {RenderDataBase} from './components/RenderDataBase';
import {RenderEnvironment} from './components/RenderEnvironment';
import {RenderVariables} from './components/RenderVariables';
import {RenderApi} from './components/RenderApi';
import {RenderEmail} from './components/RenderEmail';
import {RenderFtp} from './components/RenderFtp';
import {RenderTranslation} from './components/RenderTranslation';
import {RenderVersioning} from './components/RenderVersioning';
import {RenderAmqp} from './components/RenderAmqp';
import {RenderCache} from './components/RenderCache';
import {Wrapper} from 'src/shared/components/UI';
import {useNavigate} from 'react-router-dom';
import {MenuRoute} from 'src/shared/components/System/MenuRoute';
import {ROUTER_PATHS} from 'src/router/router.path';

const GlobalConfigurationScreen = () => {
	const {enqueueSnackbar} = useSnackbar();
	const [data] = useContext(ApplicationContext);
	const [, setLoading] = useContext(LoadingContext);
	const [activeItem, setActiveItem] = useState('');

	const navigate = useNavigate();

	const save = async () => {
		if (!data.id) return;
		setLoading(true);

		try {
			await new ApplicationApi().updateComplex({
				id: data.id,
				database: data?.database ? JSON.stringify(data.database) : undefined,
				apis: data?.apis ? JSON.stringify(data.apis) : undefined,
				databaseRules: data?.databaseRules ? JSON.stringify(data.databaseRules) : undefined,
				contextEnvironment: data.userConfiguration
					? JSON.stringify(data.userConfiguration)
					: undefined,
				amqps: data.amqps ? JSON.stringify(data.amqps) : undefined,
				caches: data.caches ? JSON.stringify(data.caches) : undefined,
				emails: data.emails ? JSON.stringify(data.emails) : undefined,
				environments: data.environments ? JSON.stringify(data.environments) : undefined,
				ftps: data.ftps ? JSON.stringify(data.ftps) : undefined,
			});

			await new ApplicationApi().update({
				id: data.id,
				name: data.name ?? '',
				companyName: data.companyName,
				account: data.account ? JSON.stringify(data.account) : undefined,
				git: data.git ? JSON.stringify(data.git) : undefined,
				layout: data.layout ? JSON.stringify(data.layout) : undefined,
				security: data.security ? JSON.stringify(data.security) : undefined,
				translation: data.translation ? JSON.stringify(data.translation) : undefined,
				contextEnvironment: data.userConfiguration
					? JSON.stringify(data.userConfiguration)
					: undefined,
			});

			enqueueSnackbar('Registro salvo com sucesso', {variant: 'success'});
		} finally {
			setLoading(false);
		}
	};

	return (
		<Layout
			expanded={false}
			additionalBarContent={
				<LeftBarRender setActiveItem={setActiveItem} activeItem={activeItem} />
			}
			save={save}>
			<S.Content>
				<Wrapper>
					<MenuRoute
						pathRoute={[
							{
								name: 'Início',
								action: () => navigate(ROUTER_PATHS.HOME),
							},
							{
								name: 'Projetos',
								action: () => navigate(ROUTER_PATHS.PROJECTS),
							},
							{
								name: data.name ?? '',
								action: () => navigate(ROUTER_PATHS.PROJECT),
							},
							{
								name: 'Configurações',
							},
						]}
					/>
				</Wrapper>
				{activeItem == 'layout' ? <RenderLayout /> : <></>}
				{activeItem == 'snapshot' ? <RenderSnapShot /> : <></>}
				{activeItem == 'translation' ? <RenderTranslation /> : <></>}
				{activeItem == 'database' ? <RenderDataBase /> : <></>}
				{activeItem == 'environment' ? <RenderEnvironment /> : <></>}
				{activeItem == 'variables' ? <RenderVariables /> : <></>}
				{activeItem == 'api' ? <RenderApi /> : <></>}
				{activeItem == 'email' ? <RenderEmail /> : <></>}
				{activeItem == 'ftp' ? <RenderFtp /> : <></>}
				{activeItem == 'versioning' ? <RenderVersioning /> : <></>}
				{activeItem == 'messager' ? <RenderAmqp /> : <></>}
				{activeItem == 'cache' ? <RenderCache /> : <></>}
			</S.Content>
		</Layout>
	);
};

export default GlobalConfigurationScreen;
