/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {useContext, useEffect, useState} from 'react';
import * as S from './styles';
import {
	Button,
	Wrapper,
	Text,
	SizedBox,
	WidthBox,
	Line,
	ModalTitle,
} from 'src/shared/components/UI';
import ApplicationContext from 'src/context/ApplicationContext';
import {IApi, IOption} from 'src/@types/app';
import {SystemInput} from 'src/shared/components/System/SystemInput';
import {applicationTheme} from 'src/shared/theme';
import {SystemDataGrid} from 'src/shared/components/System/SystemDataGrid';
import {SystemRowOptions} from 'src/shared/components/System/SystemRowOptions';
import {MenuItem} from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import {TransformToEndpoints} from 'src/helpers/methods/database-to-screen';
import AddApiEndpoint from './components/AddApiEndpoint';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import {v4 as uuidV4} from 'uuid';
import Modal from 'src/shared/components/Complex/Modal';
import {IconWrapper} from 'src/shared/components/UI/ModalTitle/styles';

interface CellType {
	row: IApi;
}

export const RenderApi = () => {
	const [application, setApplication] = useContext(ApplicationContext);
	const [data, setData] = useState<IApi>();
	const [selectedApi, setSelectedApi] = useState<IApi>();
	const [envs, setEnvs] = useState<IOption[]>([]);
	const [isOpenDelete, setIsOpenDelete] = useState(false);
	const [itemToDelete, setItemToDelete] = useState<IApi | null>(null);

	useEffect(() => {
		setEnvs([
			{value: undefined, label: 'Selecione...'},
			...(application.environments?.map(item => ({
				label: item.name as string,
				value: item.ref as string,
			})) ?? []),
		]);
	}, [application]);

	const getApiData = (): IApi[] => {
		if (application) return application.apis ?? [];
		return [];
	};

	const isValid = () => {
		const getNameToCompare = (api: IApi) => `${api.name?.toLowerCase()}_${api.envRef}`;
		return (
			data?.name?.trim() != '' &&
			data?.baseUrl?.trim() != '' &&
			getApiData()
				.filter(x => x.ref && x.ref != data?.ref)
				.filter(x => getNameToCompare(x) === getNameToCompare(data!)).length === 0
		);
	};

	const save = () => {
		if (isValid()) {
			let mData = [...getApiData()];

			if (data && data.ref) {
				mData = mData.map(item => {
					if (item.ref === data?.ref) {
						item.name = data?.name;
						item.baseUrl = data?.baseUrl;
						item.envRef = data?.envRef;
					}
					return item;
				});
			} else {
				mData.push({
					ref: uuidV4().toString(),
					baseUrl: data?.baseUrl,
					name: data?.name,
					endpoints: [],
				});
			}

			setApplication({
				...application,
				apis: mData,
			});

			setData(undefined);
		}
	};

	const syncApi = () => {
		const apis = TransformToEndpoints(application);
		setApplication({...application, apis});
	};

	const removeItem = (item: IApi) => {
		setApplication({
			...application,
			apis: application?.apis?.filter(x => x.ref != item.ref),
		});
	};

	const openDeleteModal = (item: IApi) => {
		setItemToDelete(item);
		setIsOpenDelete(true);
	};

	const onRemove = () => {
		if (itemToDelete) {
			removeItem(itemToDelete);
			setItemToDelete(null); // Limpa o item após a exclusão
			setIsOpenDelete(false);
		}
	};

	if (selectedApi) {
		const saveEndpoint = (api: IApi) => {
			setApplication({
				...application,
				apis: [...(application?.apis ?? [])].map(item => {
					if (item.ref === api.ref) return api;
					return item;
				}),
			});

			setData(api);
			setSelectedApi(api);
		};

		return (
			<AddApiEndpoint
				apiData={selectedApi}
				save={saveEndpoint}
				back={() => setSelectedApi(undefined)}
			/>
		);
	}

	const clone = (item: IApi) => {
		const apis = [...(application.apis ?? [])];
		apis.push({
			...item,
			ref: uuidV4().toString(),
			envRef: undefined,
		});

		setApplication({...application, apis});
	};

	return (
		<>
			<S.Content>
				<S.Tag>
					<div className="text">Configuração das APIs disponíveis no projeto</div>
				</S.Tag>
				<S.Card>
					<Wrapper>
						<SystemSelect
							width="20%"
							value={envs.find(x => x.value == data?.envRef)}
							onChange={val => setData({...(data ?? {}), envRef: val.value})}
							label="Ambiente"
							options={envs}
						/>
						<SystemInput
							width="30%"
							value={data?.name}
							onChange={value => setData({...data, name: value})}
							label="Nome da API"
							mask="Sem espaço"
							onPressEnter={save}
						/>
						<SystemInput
							width="50%"
							value={data?.baseUrl}
							onChange={value => setData({...data, baseUrl: value})}
							label="URL Base"
							mask="Sem espaço"
							onPressEnter={save}
						/>
					</Wrapper>
					<Wrapper margin="25px 0 0 0" justifyContent="end">
						<Button
							text="Sincronizar"
							onClick={syncApi}
							fill="auto"
							leftIcon2="refresh-cw"
							background={applicationTheme.brand[600]}
						/>
						<Button
							text="Salvar"
							onClick={save}
							fill="auto"
							background={applicationTheme.brand[600]}
						/>
					</Wrapper>

					<SizedBox height="20px" />

					<SystemDataGrid
						rowsData={getApiData()}
						columns={[
							{
								flex: 0.1,
								minWidth: 90,
								type: 'actions',
								filterable: false,
								hideable: false,
								headerAlign: 'left',
								align: 'left',
								sortable: false,
								field: 'actions',
								headerName: '',
								renderCell: ({row}: CellType) => (
									<SystemRowOptions
										childrens={
											<>
												<MenuItem
													onClick={() => setSelectedApi(row)}
													sx={{'& svg': {mr: 2}}}>
													<FeatherIcon icon="settings" size={16} />
													<Text text="Configurar" />
												</MenuItem>
												<MenuItem
													onClick={() => setData(row)}
													sx={{'& svg': {mr: 2}}}>
													<FeatherIcon icon="edit-3" size={16} />
													<Text text="Editar" />
												</MenuItem>
												<MenuItem
													onClick={() => clone(row)}
													sx={{'& svg': {mr: 2}}}>
													<FeatherIcon icon="copy" size={16} />
													<Text text="Clonar" />
												</MenuItem>
												<Wrapper
													alignItems="center"
													justifyContent="center">
													<WidthBox width="90%">
														<Line
															height="0.5px"
															background={applicationTheme.gray[300]}
														/>
													</WidthBox>
												</Wrapper>
												<MenuItem
													onClick={() => openDeleteModal(row)}
													sx={{'& svg': {mr: 2}}}>
													<FeatherIcon icon="trash-2" size={16} />
													<Text
														text="Excluir"
														color={applicationTheme.error[900]}
													/>
												</MenuItem>
											</>
										}
									/>
								),
							},
							{
								flex: 0.1,
								type: 'string',
								minWidth: 150,
								filterable: true,
								headerAlign: 'left',
								align: 'left',
								sortable: true,
								field: 'env',
								headerName: `Ambiente`,
								renderCell: ({row}: CellType) =>
									application.environments?.find(x => x.ref == row.envRef)
										?.name ?? '',
							},
							{
								flex: 0.4,
								type: 'string',
								minWidth: 150,
								filterable: true,
								headerAlign: 'left',
								align: 'left',
								sortable: true,
								field: 'name',
								headerName: `Nome`,
								renderCell: ({row}: CellType) => row.name,
							},
							{
								flex: 0.5,
								type: 'string',
								minWidth: 150,
								filterable: true,
								headerAlign: 'left',
								align: 'left',
								sortable: true,
								field: 'baseUrl',
								headerName: `URL Base`,
								renderCell: ({row}: CellType) => row.baseUrl,
							},
						]}
					/>
				</S.Card>
			</S.Content>

			<Modal isOpen={isOpenDelete} onClose={() => setIsOpenDelete(false)} width="auto">
				<>
					<ModalTitle
						title="Deletar Item?"
						icon={
							<IconWrapper>
								<FeatherIcon icon="alert-circle" size={20} />
							</IconWrapper>
						}
						centerTitle={true}
					/>
					<div
						style={{
							color: applicationTheme.gray[500],
							textAlign: 'center',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							fontSize: '14px',
							paddingBottom: '24px',
						}}>
						Tem certeza de que deseja excluir este item? <br />
						Essa ação não pode ser desfeita.
					</div>
					<Wrapper margin="20px 0 0 0" justifyContent="end">
						<Button
							text="Cancelar"
							onClick={() => setIsOpenDelete(false)}
							fill="full"
							background={applicationTheme.gray[300]}
							textColor={applicationTheme.gray[700]}
							type="ghost"
						/>
						<Button
							text="Deletar"
							onClick={onRemove}
							fill="full"
							background={applicationTheme.error[600]}
						/>
					</Wrapper>
				</>
			</Modal>
		</>
	);
};
