import {IComponent, IOption} from 'src/@types/app';
import {ComponentSimpleEnum, ValidationTypeEnum} from 'src/@types/enums';
import {ValidationList} from 'src/helpers/select-data/validationList';

export const COMPONENT_VALIDATION: ComponentSimpleEnum[] = [
	ComponentSimpleEnum.Autocomplete,
	ComponentSimpleEnum.Input,
	ComponentSimpleEnum.InputDate,
	ComponentSimpleEnum.Select,
	ComponentSimpleEnum.InputTag,
];

export const NUMBER_INPUT_TYPE = [
	ValidationTypeEnum.MinValue,
	ValidationTypeEnum.MaxValue,
	ValidationTypeEnum.MinLength,
	ValidationTypeEnum.MaxLength,
];

export const DATE_INPUT_TYPE = [
	ValidationTypeEnum.GreatThan,
	ValidationTypeEnum.LessThan,
	ValidationTypeEnum.Between,
];

export const getValidationList = (component: IComponent): IOption[] => {
	const listOptions: IOption[] = [
		{
			label: 'Selecione ...',
			value: '',
		},
	];
	const allowValidation = getAllowValidation(component);

	if (allowValidation) {
		ValidationList.forEach(item => {
			if (allowValidation.includes(item.value)) listOptions.push(item);
		});
	}

	return listOptions;
};

const getAllowValidation = (component: IComponent): ValidationTypeEnum[] | undefined => {
	const type = component.name as ComponentSimpleEnum;
	const allowValidation: ValidationTypeEnum[] = [ValidationTypeEnum.Required];

	switch (type) {
		case ComponentSimpleEnum.Input:
			if (component.properties?.mask === 'Numero') {
				allowValidation.push(ValidationTypeEnum.MinValue);
				allowValidation.push(ValidationTypeEnum.MaxValue);
			} else if (['CPF/CNPJ', 'CPF', 'CNPJ'].includes(component.properties?.mask)) {
				allowValidation.push(ValidationTypeEnum.CnpjCpf);
			} else {
				allowValidation.push(ValidationTypeEnum.MinLength);
				allowValidation.push(ValidationTypeEnum.MaxLength);
				allowValidation.push(ValidationTypeEnum.Email);
				allowValidation.push(ValidationTypeEnum.Url);
			}
			break;
		case ComponentSimpleEnum.Autocomplete:
		case ComponentSimpleEnum.Select:
		case ComponentSimpleEnum.InputTag:
			break;
		case ComponentSimpleEnum.InputDate:
			allowValidation.push(ValidationTypeEnum.GreatThan);
			allowValidation.push(ValidationTypeEnum.LessThan);
			allowValidation.push(ValidationTypeEnum.Between);
	}

	return allowValidation;
};
