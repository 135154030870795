import styled from 'styled-components';

export const Content = styled.div`
	margin: 25px;
	display: flex;
	flex-direction: column;
`;

export const ContentProjects = styled.div`
	display: flex;
	gap: 25px;
	flex-wrap: wrap;
	flex-direction: row;
`;
