export enum ROUTER_PATHS {
	AUTH = '/',
	BUILDER = 'builder',
	HOME = '/',
	PROJECT = '/project',
	PROJECTS = '/projects',
	DATABASE = '/database',
	PLAN = '/plan',
	CONFIGURATION = '/global-configuration',
	DIAGRAM = '/diagram',
}
