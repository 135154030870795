import React from 'react';
import * as S from './styles';
import NotFoundImage from 'src/assets/images/notFound.svg';
import {Button} from 'src/shared/components/UI';
import {applicationTheme} from 'src/shared/theme';

export const NotFoundProject = () => {
	return (
		<S.Container>
			<S.Content>
				<img src={NotFoundImage} height="180px" />
				<div className="text1">Você ainda não tem projetos</div>
				<div className="text2">Comece criando um novo projeto por aqui</div>
				<Button
					text="Criar projeto"
					leftIcon2="plus-square"
					onClick={() => console.log()}
					fill="auto"
					background={applicationTheme.brand[600]}
				/>
			</S.Content>
		</S.Container>
	);
};
