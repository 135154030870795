import {IResource, IValidation} from 'src/@types/app';
import {ComponentSimpleEnum, ValidationTypeEnum} from 'src/@types/enums';

export const addValidation = (resource: IResource): string => {
	let code = '';
	let widgets = resource.widgets?.filter(
		x =>
			x.valition &&
			x.valition.length > 0 &&
			x.name != ComponentSimpleEnum.Switch &&
			x.name != ComponentSimpleEnum.Checkbox,
	);

	widgets = widgets?.filter(
		widget =>
			(widget.parentLevel &&
				!!resource.widgets?.map(y => y.ref).includes(widget.parentRef)) ||
			!widget.parentLevel,
	);

	widgets?.forEach(widget => {
		const name = widget.properties.name;
		widget.valition?.forEach((item, idx) => {
			if (idx != 0) code += 'else ';
			const method = getValidationMethod(item.type);
			const complementAttr = getComplementAttribute(item);
			const message = getMessage(item);

			code += `if (!${method}(formData?.${name} ${complementAttr})) formErros.${name} = ${message};`;
		});
	});

	return `${code}\n`;
};

const attrTypes = [
	ValidationTypeEnum.MinLength,
	ValidationTypeEnum.MaxLength,
	ValidationTypeEnum.MinValue,
	ValidationTypeEnum.MaxValue,
	ValidationTypeEnum.LessThan,
	ValidationTypeEnum.GreatThan,
	ValidationTypeEnum.Between,
];

const attrDateTypes = [ValidationTypeEnum.LessThan, ValidationTypeEnum.GreatThan];

const getValidationMethod = (type: ValidationTypeEnum): string => {
	switch (type) {
		case ValidationTypeEnum.CnpjCpf:
			return 'validateCpfCnpj';
		case ValidationTypeEnum.Required:
			return 'validateRequired';
		case ValidationTypeEnum.MinLength:
			return 'validateMinLength';
		case ValidationTypeEnum.MaxLength:
			return 'validateMaxLength';
		case ValidationTypeEnum.MinValue:
			return 'validateMinValue';
		case ValidationTypeEnum.MaxValue:
			return 'validateMaxValue';
		case ValidationTypeEnum.GreatThan:
			return 'validateGreatThan';
		case ValidationTypeEnum.LessThan:
			return 'validateLessThan';
		case ValidationTypeEnum.Between:
			return 'validateBetween';
		case ValidationTypeEnum.Email:
			return 'validateEmail';
		case ValidationTypeEnum.Url:
			return 'validateUrl';
		default:
			return '';
	}
};

const getComplementAttribute = (validation: IValidation): string => {
	let code = '';

	if (attrTypes.includes(validation.type) && validation.value) {
		if (validation.type === ValidationTypeEnum.Between) {
			code = `, new Date(Date.parse('${validation.value}')), new Date(Date.parse('${validation.value2}'))`;
		} else if (attrDateTypes.includes(validation.type))
			code = `, new Date(Date.parse('${validation.value}'))`;
		else code = `, ${validation.value}`;
	}

	return code;
};

const getMessage = (validation: IValidation): string => {
	let message = '';

	if (validation.message) message = validation.message;

	if (validation.type === ValidationTypeEnum.Between) {
		message = message.replace('{0}', formatDate(validation.value));
		message = message.replace('{1}', formatDate(validation.value2));
	} else if (attrDateTypes.includes(validation.type))
		message = message.replace('{0}', formatDate(validation.value));
	else if (message.includes('{0}') && validation.value && attrTypes.includes(validation.type))
		message = message.replace('{0}', validation.value);

	return `'${message}'`;
};

const formatDate = (dateString: string): string => {
	const date = new Date(Date.parse(dateString));
	const day = date.getDate() < 9 ? `0${date.getDate()}` : date.getDate();
	const month = date.getMonth() + 1 < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
	return `${day}/${month}/${date.getFullYear()}`;
};
