/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {IAction, IApiMap, IApplication, IOption} from 'src/@types/app';
import {ApiParamTypeEnum} from 'src/@types/enums';

export const getDataStateName = (action: IAction, app: IApplication): string => {
	const api = app.apis?.find(x => x.ref === action.api?.targetApi);
	const endpoint = api?.endpoints?.find(x => x.ref === action.api?.targetEndpoint);
	return endpoint?.name + 'Data';
};

export const getResponseValues = (
	action: IAction,
	app: IApplication,
	listAcceptTypes?: ApiParamTypeEnum[],
): IOption[] => {
	const api = app.apis?.find(x => x.ref === action.api?.targetApi);
	const endpoint = api?.endpoints?.find(x => x.ref === action.api?.targetEndpoint);

	if (!endpoint || !endpoint.response || endpoint.response.length == 0) return [];

	const response = listAcceptTypes
		? endpoint.response.filter(x => listAcceptTypes.includes(x.type as ApiParamTypeEnum))
		: endpoint.response;

	return (
		response.map(x => {
			return {
				value: x.ref,
				label: getParentName(x, endpoint.response!, [x.name ?? '']),
			};
		}) ?? []
	);
};

const getParentName = (api: IApiMap, data: IApiMap[], label: string[]): string => {
	if (!api.parentRef || api.parentRef == '') return getParentNameReturn(label);
	const result = data.find(x => x.ref === api.parentRef);

	if (!result) return getParentNameReturn(label);
	label.push(result.name ?? '');
	if (result.parentRef && result.parentRef != '') return getParentName(result, data, label);

	return getParentNameReturn(label);
};

const getParentNameReturn = (label?: string[]): string => label?.reverse()?.join('.') ?? '';
