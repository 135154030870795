import React, {useContext} from 'react';
import * as S from './styles';
import {FloatMenuContext} from 'src/context';
import {Line, WidthBox} from 'src/shared/components/UI';
import {ExpressionFunctionEnum} from 'src/@types/enums';

export type IFloatMenu = {
	show?: boolean;
	x?: number;
	y?: number;
	event?: (type: ExpressionFunctionEnum) => void;
	changeFields?: boolean;
	fieldName?: IExpressionTextFields;
	showComparetor?: boolean;
	showDataProp?: boolean;
};

type IExpressionTextFields = {
	concat: string;
	upperCase: string;
	lowerCase: string;
	freeText: string;
	field: string;
	arithmetic: string;
	comparetor: string;
	dataProp: string;
};

const defaultExpressionTextFields: IExpressionTextFields = {
	concat: 'Concatenar',
	upperCase: 'Caixa alta',
	lowerCase: 'Caixa baixa',
	freeText: 'Texto livre',
	field: 'Propriedade',
	arithmetic: 'Aritimético',
	comparetor: 'Comparador',
	dataProp: 'Propriedade de dados',
};

const FloatMenu = () => {
	const [floatMenu, setFloatMenu] = useContext(FloatMenuContext);

	const close = () => {
		setFloatMenu({show: false});
	};

	const set = (type: ExpressionFunctionEnum) => {
		if (!floatMenu || !floatMenu.event) return;

		floatMenu.event(type);
		close();
	};

	const renderStart = () => {
		return (
			<>
				<S.Item onClick={() => set(ExpressionFunctionEnum.Concat)}>
					{floatMenu?.fieldName?.concat ?? defaultExpressionTextFields.concat}
				</S.Item>
				<S.Item onClick={() => set(ExpressionFunctionEnum.UpperCase)}>
					{floatMenu?.fieldName?.upperCase ?? defaultExpressionTextFields.upperCase}
				</S.Item>
				<S.Item onClick={() => set(ExpressionFunctionEnum.LowerCase)}>
					{floatMenu?.fieldName?.lowerCase ?? defaultExpressionTextFields.lowerCase}
				</S.Item>
				{/* <S.Item onClick={() => set(ExpressionFunctionEnum.ConvertFromDate)}>
					Formatar data
				</S.Item> */}
				<S.Item onClick={() => set(ExpressionFunctionEnum.Arithmetic)}>
					{floatMenu?.fieldName?.arithmetic ?? defaultExpressionTextFields.arithmetic}
				</S.Item>
				{floatMenu.showComparetor ? (
					<S.Item onClick={() => set(ExpressionFunctionEnum.Comparetor)}>
						{floatMenu?.fieldName?.comparetor ?? defaultExpressionTextFields.comparetor}
					</S.Item>
				) : (
					<></>
				)}
			</>
		);
	};

	const renderField = () => {
		return (
			<>
				<S.Item onClick={() => set(ExpressionFunctionEnum.FreeText)}>
					{floatMenu?.fieldName?.freeText ?? defaultExpressionTextFields.freeText}
				</S.Item>
				<S.Item onClick={() => set(ExpressionFunctionEnum.Field)}>
					{floatMenu?.fieldName?.field ?? defaultExpressionTextFields.field}
				</S.Item>
				{floatMenu.showDataProp ? (
					<S.Item onClick={() => set(ExpressionFunctionEnum.DataProp)}>
						{floatMenu?.fieldName?.dataProp ?? defaultExpressionTextFields.dataProp}
					</S.Item>
				) : (
					<></>
				)}
			</>
		);
	};

	if (floatMenu.show) {
		return (
			<>
				<S.Content x={floatMenu.x ?? 0} y={floatMenu.y ?? 0}>
					{/* {floatMenu?.changeFields ? renderStart() : renderField()} */}
					{renderField()}
					<WidthBox width="98%" margin="5px auto">
						<Line background="#555" height="0.1px" />
					</WidthBox>
					{renderStart()}
					<WidthBox width="98%" margin="5px auto">
						<Line background="#555" height="0.1px" />
					</WidthBox>
					<S.Item onClick={close}>Fechar</S.Item>
				</S.Content>
			</>
		);
	}

	return <></>;
};

export default FloatMenu;
