import {WidgetPropertyTypes} from 'src/@types/enums';

export const getWidgetPropertyGroup = (prop: string): string => {
	switch (prop) {
		case 'fontWeight':
		case 'inputType':
		case 'labelColor':
		case 'focusLabelColor':
		case 'textColor':
		case 'background':
		case 'border':
		case 'focusBorder':
		case 'borderRadius':
		case 'leftIcon':
		case 'leftIconColor':
		case 'rightIcon':
		case 'rightIconColor':
		case 'color':
		case 'fill':
		case 'type':
		case 'format':
		case 'themeStyle':
		case 'icon':
		case 'flexDirection':
		case 'alignItems':
		case 'justifyContent':
		case 'margin':
		case 'gap':
		case 'width':
		case 'children':
		case 'fontSize':
		case 'iconPosition':
		case 'textPosition':
			return WidgetPropertyTypes.Style;
		default:
			return WidgetPropertyTypes.Configuration;
	}
};
