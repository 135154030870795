/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useContext, useEffect, useState} from 'react';
import Layout from 'src/shared/components/System/Layout';
import {MenuRoute} from 'src/shared/components/System/MenuRoute';
import * as S from './styles';
import {NoticeCard} from './ui/NoticeCard';
import {CommunicationApi} from 'src/shared/repositories/communication-api';
import {ICommunication} from 'src/@types/app';
import {LoadingContext} from 'src/context';

const HomeScreen = () => {
	const [data, setData] = useState<ICommunication>();
	const [, setLoading] = useContext(LoadingContext);

	const communicationApi = new CommunicationApi();

	useEffect(() => {
		getValeus();
	}, []);

	const getValeus = async () => {
		setLoading(true);
		const result = await communicationApi.getAll();
		setData(result as ICommunication);
		setLoading(false);
	};

	return (
		<Layout>
			<S.Content>
				<MenuRoute pathRoute={[{name: 'Início'}]} />

				<S.Title>Notícias em destaque</S.Title>
				<S.ContentNotice>
					{data?.news?.map((item: any, index: number) => (
						<NoticeCard
							key={index}
							publicationDate={item.publicationDate}
							classification={item.classification}
							title={item.title}
							description={item.description}
							icon={item.icon}
							iconColor={item.iconColor}
							url={item.url}
						/>
					))}
				</S.ContentNotice>

				<S.Title>Artigos</S.Title>
				<S.ContentNotice>
					{data?.articles?.map((item: any, index: number) => (
						<NoticeCard
							key={index}
							publicationDate={item.publicationDate}
							classification={item.classification}
							title={item.title}
							description={item.description}
							icon={item.icon}
							iconColor={item.iconColor}
							url={item.url}
						/>
					))}
				</S.ContentNotice>

				<S.Title>Videos</S.Title>
				<S.ContentNotice>
					{data?.videos?.map((item: any, index: number) => (
						<NoticeCard
							key={index}
							publicationDate={item.publicationDate}
							classification={item.classification}
							title={item.title}
							description={item.description}
							icon={item.icon}
							iconColor={item.iconColor}
							url={item.url}
						/>
					))}
				</S.ContentNotice>
			</S.Content>
		</Layout>
	);
};

export default HomeScreen;
