/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {IApplication, IResourceApi} from 'src/@types/app';
import {
	dotnetFormatRepoName,
	dotnetFormatScreenName,
	dotnetSchemaName,
} from '../core/dotnet-formatter';
import {lowerFirstWord} from 'src/helpers/methods/text-methods';
import {backFormatPropertyName} from 'src/shared/engine-back/common/common-formatters';

export const generateAuth = (app: IApplication, projName: string): IResourceApi[] => {
	const resourceApi: IResourceApi[] = [];
	resourceApi.push(getController(projName));
	resourceApi.push(getCommand(projName));
	resourceApi.push(getHandler(app, projName));

	return resourceApi;
};

const getController = (projName: string): IResourceApi => {
	const code = `using MediatR;
using Microsoft.AspNetCore.Mvc;
using Swashbuckle.AspNetCore.Annotations;
using System.Net;
using ${projName}.Application.Commands.Auth;
using ${projName}.Application.Utils;

namespace ${projName}.API.Controllers;

[ApiController]
[Route("v1/[controller]")]
[Produces("application/json")]
public class AuthController : ControllerBase
{
  private readonly IMediator _mediator;

  /// <summary>
  /// Construtor
  /// </summary>
  /// <param name="mediator">Orquestrador de requisições</param>
  public AuthController(IMediator mediator)
  {
    _mediator = mediator;
  }

  /// <summary>
  /// Faz o login do usuário
  /// </summary>
  /// <param name="command">Estrutura de dados necessária para execução das operações</param>
  /// <param name="requestId">Identificador exclusivo para cada solicitação HTTP envolvida no processamento da operação</param>
  /// <returns>Resultado do processamento da operação através do tipo <see cref="IActionResult"/></returns>
  /// <response code="200">Processamento realizado com êxito</response>
  /// <response code="400">Requisição não está dentro do padrão esperado</response>
  [SwaggerResponse((int)HttpStatusCode.OK, Description = "Processamento realizado com êxito", Type = typeof(ResponseService))]
  [SwaggerResponse((int)HttpStatusCode.BadRequest, Description = "Requisição não está dentro do padrão esperado", Type = typeof(ResponseBadRequestService))]
  [SwaggerResponse((int)HttpStatusCode.Unauthorized, Description = "Usuário não possui permissão para acessar a URL informada ou o TOKEN não for informado", Type = typeof(ResponseUnauthorizedService))]
  [SwaggerResponse((int)HttpStatusCode.NotFound, Description = "Nenhum registro foi localizado", Type = typeof(ResponseNotFoundService))]
  [SwaggerResponse((int)HttpStatusCode.MethodNotAllowed, Description = "Requisição não está dentro do padrão esperado", Type = typeof(ResponseMethodNotAllowedService))]
  [SwaggerResponse((int)HttpStatusCode.InternalServerError, Description = "Requisição gerou uma dificuldade de processamento do servidor", Type = typeof(ResponseInternalServerErrorService))]
  [HttpPost]
  [Route("login")]
  public async Task<IActionResult> Login(AuthCommand command, [FromHeader(Name = "x-requestid")] string? requestId)
  {
    try
    {
      var result = await _mediator.Send(command);
			if (!result.Status)
        throw new Exception(result.Message);

      return Ok(result);
    }
    catch (Exception ex)
    {
      return BadRequest(new ResponseBadRequestService(ex.Message));
    }
  }
}`;

	return {
		code,
		name: 'AuthController.cs',
		path: `src/${projName}.API/Controllers/`,
	};
};

const getCommand = (projName: string): IResourceApi => {
	const code = `
using MediatR;
using ${projName}.Application.Utils;

namespace ${projName}.Application.Commands.Auth;

/// <summary>
/// Estrutura de dados necessária para fazer o login
/// </summary>
public class AuthCommand : IRequest<ResponseService>
{
	/// <summary>
	/// Login do usuário
	/// </summary>
	public required string Login { get; set; }

	/// <summary>
	/// Senha
	/// </summary>
	public required string Password { get; set; }
}`;

	return {
		code,
		name: 'AuthCommand.cs',
		path: `src/${projName}.Application/Commands/Auth/`,
	};
};

const getHandler = (app: IApplication, projName: string): IResourceApi => {
	const table = app.database?.tables?.find(x => x.isUser);
	const className = dotnetFormatScreenName(table!.name!);
	const classNameLow = lowerFirstWord(className);

	const mCodeInterface = `private readonly I${className}Repository ${dotnetFormatRepoName(
		classNameLow,
	)};`;
	const sumaryDesc = `/// <param name="${classNameLow}Repository">Repositório do(a) ${classNameLow}</param>`;
	const codeConstructor = `I${className}Repository ${classNameLow}Repository`;
	const codeAttr = `_${classNameLow}Repository = ${classNameLow}Repository;`;
	const mapping = app.userConfiguration!.mapping;
	let userImport = '';

	if (table?.schema) {
		userImport = `using ${projName}.Domain.Models.${dotnetSchemaName(table.schema!)};`;
	} else {
		userImport += `using ${projName}.Domain.Models;`;
	}

	const user = app.database?.tables?.find(table => table.isUser);
	const mPropName = lowerFirstWord(
		backFormatPropertyName(dotnetFormatScreenName(user?.name ?? '')),
	);

	const authCommandMethod = `
	/// <summary>
	/// Autentica usuário
	/// </summary>
	/// <param name="command">Estrutura de dados necessária para execução da operação</param>
	/// <param name="cancellationToken">Estrutura do .NET responsável por indicar ou notificar que a operação em andamento deve ser cancelada</param>
	/// <returns>Retorna o resultado do processamento da operação através do tipo <see cref="${projName}.Application.Utils.ResponseService"/></returns>
	public async Task<ResponseService> Handle(AuthCommand command, CancellationToken cancellationToken)
	{
		var user = await _${mPropName}Repository.Buscar(x => x.${
		mapping?.find(x => x.isLogin)?.columnName
	} == command.Login);

			if (user == null)
				throw new Exception("Login or password is incorret");

			var isValidPassword = _userService.VerifyHashedPassword(user.${
				mapping?.find(x => x.isPassword)?.columnName
			}, command.Password);

			if (isValidPassword)
			{
				var token = _userService.GenerateJwtToken(user);

				return new ResponseService(new
				{
						Token = token
				});
			}

			throw new Exception("Login or password is incorret");
	}`;

	const authCommandMethodNot = `
	public Task<ResponseService> Handle(AuthCommand request, CancellationToken cancellationToken)
    {
        throw new NotImplementedException();
    }`;

	const code = `using MediatR;
using ${projName}.Application.Utils;
using ${projName}.Application.Commands.Auth;
using ${projName}.Infra.CrossCutting.Identity.Interfaces;
using ${projName}.Domain.Interfaces.Repositories.Base;
using ${projName}.Domain.Interfaces.Repositories;
${userImport}

namespace ${projName}.Application.Handlers;

/// <summary>
/// Realiza operações referentes ao autenticação
/// </summary>
public class AuthHandler : IRequestHandler<AuthCommand, ResponseService>
{
	private readonly IUserService _userService;
	${mCodeInterface}

	/// <summary>
	/// Construtor
	/// </summary>
	/// <param name="userService">Responsável por realizar a autenticação do usuário</param>
	${sumaryDesc}
	public AuthHandler(IUserService userService, ${codeConstructor})
	{
		_userService = userService;
		${codeAttr}
	}

	${mapping?.find(x => x.isLogin)?.columnName ? authCommandMethod : authCommandMethodNot}
}
`;

	return {
		code,
		name: 'AuthHandler.cs',
		path: `src/${projName}.Application/Handlers/`,
	};
};
