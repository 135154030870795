/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {IAction, IApplication, IOption} from 'src/@types/app';
import {Button, SizedBox, Wrapper, WidthBox, Collapse} from 'src/shared/components/UI';
import {ActionTypeEnum} from 'src/@types/enums';
import * as S from './styles';
import {getApiList, getListRoute, getWidgetsInput} from 'src/helpers/methods/app-methods';
import {faAdd, faFilter} from '@fortawesome/free-solid-svg-icons';
import {DataGrid} from 'src/shared/components/Complex';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';

type Props = {
	application: IApplication;
	btnAction: IAction | undefined;
	setBtnAction: React.Dispatch<React.SetStateAction<IAction | undefined>>;
};

const Search = ({application, btnAction, setBtnAction}: Props) => {
	const [listApis, setListApis] = useState<IOption[]>([]);
	const [listRoutes, setListRoutes] = useState<IOption[]>([]);
	const [selectedApi, setSelectedApi] = useState<IOption>();
	const [selectedEndpoint, setSelectedEndpoint] = useState<IOption>();
	const [listComponents, setListComponents] = useState<IOption[]>([]);
	const [selectWidgetFilter, setSelectWidgetFilter] = useState<IOption>();
	const [listWidgetFilter, setListWidgetFilter] = useState<string[]>([]);

	useEffect(() => {
		setListWidgetFilter(btnAction?.data?.listWidgetFilter ?? []);
		setListApis(getApiList(application));
		setListComponents(getWidgetsInput(application));
	}, [application]);

	useEffect(() => {
		setListRoutes(getListRoute(application, selectedApi?.value));
	}, [selectedApi]);

	useEffect(() => {
		setSelectedApi(listApis?.find(x => x.value === btnAction?.api?.targetApi));
	}, [listApis]);

	useEffect(() => {
		setSelectedEndpoint(listRoutes?.find(x => x.value === btnAction?.api?.targetEndpoint));
	}, [listRoutes]);

	const getListComponents = (): IOption[] => {
		return listComponents.filter(x => !listWidgetFilter.includes(x.value));
	};

	const addWidgetToList = () => {
		const data = [...listWidgetFilter];
		data.push(selectWidgetFilter?.value);
		setListWidgetFilter(data);
		setSelectWidgetFilter(undefined);
		setAction({
			data: {
				...btnAction?.data,
				listWidgetFilter: data,
			},
		});
	};

	const setAction = (data: any) => {
		setBtnAction({
			...(btnAction ?? {}),
			...data,
			actionType: ActionTypeEnum.Search,
		});
	};

	if (listApis?.length === 0)
		return <S.NoContent>Nenhuma API foi configurada neste projeto</S.NoContent>;

	return (
		<>
			<SizedBox height="10px" />
			<Wrapper>
				<WidthBox width="50%">
					<SystemSelect
						value={selectedApi}
						options={listApis}
						onChange={value => {
							setSelectedApi(value);
							setAction({
								api: {
									...btnAction?.api,
									targetApi: value.value ?? '',
								},
							});
						}}
						label="API"
					/>
				</WidthBox>
				{selectedApi ? (
					<WidthBox width="50%">
						<SystemSelect
							value={selectedEndpoint}
							options={listRoutes}
							onChange={value => {
								setSelectedEndpoint(value);
								setAction({
									api: {
										...btnAction?.api,
										targetEndpoint: value.value ?? '',
									},
								});
							}}
							label="Endpoint"
						/>
					</WidthBox>
				) : (
					<></>
				)}
			</Wrapper>

			{selectedApi && selectedEndpoint ? (
				<>
					<SizedBox height="10px" />
					<Collapse
						startOpen={false}
						text="Filtros"
						headerTextColor={'#000'}
						border="1px solid #333"
						borderRadius="4px"
						leftIcon={faFilter}
						leftIconColor={'#000'}>
						<>
							<Wrapper margin="10px 0 0 0">
								<SystemSelect
									value={getListComponents().find(
										val => val.value === selectWidgetFilter,
									)}
									options={getListComponents()}
									onChange={setSelectWidgetFilter}
									label="Parâmetro"
								/>
								<WidthBox width="auto" margin="20px 0 0 0">
									<Button
										onClick={addWidgetToList}
										fill="auto"
										themeStyle="success"
										icon={faAdd}
										disabled={selectWidgetFilter?.value == undefined}
									/>
								</WidthBox>
							</Wrapper>

							{/* <Grid
								header={['Nome', 'Tipo']}
								template="2fr 2fr 1fr"
								data={listComponents.filter(x =>
									listWidgetFilter.includes(x.value),
								)}
								headerTextColor={'#000'}
								properties={['label', 'name']}
								bodyTextColor="#fff"
								bodyBackground={'#000'}
								dataActions={[
									{
										icon: faTrash,
										color: '#F44336',
										onClick: (_, i) => {
											setListWidgetFilter(
												[...listWidgetFilter].filter((x, idx) => idx != i),
											);
										},
									},
								]}
							/> */}
						</>
					</Collapse>
				</>
			) : (
				<></>
			)}
		</>
	);
};

export default Search;
