/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {useContext, useEffect, useState} from 'react';
import * as S from './styles';
import Layout from 'src/shared/components/System/Layout';
import {Wrapper} from 'src/shared/components/UI';
import {useNavigate} from 'react-router-dom';
import {MenuRoute} from 'src/shared/components/System/MenuRoute';
import {ROUTER_PATHS} from 'src/router/router.path';
import {ApplicationContext} from 'src/context';
import mermaid from 'mermaid';
import {mapToDotnet} from 'src/helpers/methods/map-type-database';
import {AddBarRender} from './components/AddBarRender';

mermaid.initialize({
	theme: 'forest',
});

export type DiagramConfigType = {
	showColumns: boolean;
	columnsEnable: {ref: string; show: boolean}[];
};

const defaultValues: DiagramConfigType = {
	showColumns: true,
	columnsEnable: [],
};

export const DiagramScreen = () => {
	const [application] = useContext(ApplicationContext);
	const [configs, setConfigs] = useState<DiagramConfigType>(defaultValues);

	const navigate = useNavigate();

	useEffect(() => {
		buildDiagram();
		onMouseWhellScroll();
		setConfigs({
			...defaultValues,
			columnsEnable:
				application.database?.tables?.map(table => ({
					ref: table.ref ?? '',
					show: false,
				})) ?? [],
		});
	}, []);

	useEffect(() => {
		buildDiagram();
	}, [configs]);

	const buildDiagram = () => {
		document.getElementById('dbDiagram')?.removeAttribute('data-processed');
		document.getElementById('dbDiagram')!.innerHTML = getDiagramData();
		mermaid.contentLoaded();
	};

	let startX: any = 0;
	let startY: any = 0;
	let scrollTop: any = 0;
	let scrollLeft: any = 0;
	let baseZoom: any = 100;

	const getDiagramData = () => {
		let textData = 'erDiagram\n';

		application.database?.tables
			?.filter(table => configs.columnsEnable.find(x => x.ref == table.ref)?.show)
			.forEach(table => {
				const constraints = table.columns
					?.filter(x => x.constraint)
					.map(x => x.constraint)
					.filter(c => configs.columnsEnable.find(x => x.ref == c)?.show);

				if (!!constraints && constraints.length > 0) {
					constraints.forEach(c => {
						const tableRelation = application.database?.tables?.find(x => x.ref == c);
						const columns = table?.columns?.find(x => x.constraint == c);
						const relationName = columns?.relationName ?? 'has';

						textData += `${table.schema}_${table.name} }|..|{ ${tableRelation?.schema}_${tableRelation?.name} : ${relationName}\n`;
					});
				}

				if (configs.showColumns) {
					textData += `${table.schema}_${table.name}["${table.schema}.${table.name}"] {`;
					table.columns?.forEach(column => {
						let dataType = mapToDotnet(column.type!);

						if (dataType === 'string') {
							dataType += `(${column.maxLength})`;
						}

						textData += `${dataType} ${column.name} ${
							column.isPrimaryKey ? 'PK' : ''
						} ${column?.relationName ? 'FK' : ''}\n`;
					});
					textData += '}\n';
				}
			});

		return textData;
	};

	const onMouseDown = (e: any) => {
		startX = e.clientX;
		startY = e.clientY;
		scrollTop = (document.getElementById('contentDiagram') as any).scrollTop;
		scrollLeft = (document.getElementById('contentDiagram') as any).scrollLeft;
		document.onmousemove = onMouseMove;

		if (e.stopPropagation) e.stopPropagation();
		if (e.preventDefault) e.preventDefault();
		e.cancelBubble = true;
		e.returnValue = false;
		return false;
	};

	const onMouseMove = (e: any) => {
		(document.getElementById('contentDiagram') as any).scrollTo({
			left: scrollLeft + (startX - e.clientX),
			top: scrollTop + (startY - e.clientY),
		});

		if (e.stopPropagation) e.stopPropagation();
		if (e.preventDefault) e.preventDefault();
		e.cancelBubble = true;
		e.returnValue = false;
		return false;
	};

	const onMouseWhellScroll = () => {
		(document.getElementById('contentDiagram') as any).addEventListener(
			'wheel',
			(event: any) => {
				const deltaY = event.deltaY == 100 ? -100 : 100;
				const zoom = baseZoom + deltaY / 10;
				if (zoom < 100)
					(document.querySelectorAll('[id^="mermaid-"]')[0] as any).style.width = '100%';
				else {
					(
						document.querySelectorAll('[id^="mermaid-"]')[0] as any
					).style.width = `${zoom}%`;
					baseZoom = zoom;
				}
			},
		);

		window.addEventListener('mouseup', () => {
			document.onmousemove = null;
		});
	};

	return (
		<Layout
			expanded={false}
			additionalBarContent={<AddBarRender configs={configs} setConfigs={setConfigs} />}>
			<S.Content>
				<Wrapper>
					<MenuRoute
						pathRoute={[
							{
								name: 'Início',
								action: () => navigate(ROUTER_PATHS.HOME),
							},
							{
								name: 'Projetos',
								action: () => navigate(ROUTER_PATHS.PROJECTS),
							},
							{
								name: application.name ?? '',
								action: () => navigate(ROUTER_PATHS.PROJECT),
							},
							{
								name: 'Diagrama',
							},
						]}
					/>
				</Wrapper>

				<S.ContentDiagram
					id="contentDiagram"
					onMouseDown={onMouseDown}
					onMouseUp={() => (document.onmousemove = null)}>
					<div className="mermaid" id="dbDiagram"></div>
				</S.ContentDiagram>
			</S.Content>
		</Layout>
	);
};
