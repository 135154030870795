/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {Input, SizedBox, Wrapper} from 'src/shared/components/UI';
import * as S from '../styles';
import {IWorkFlowItem} from 'src/@types/app';
import {buildWorkflowSub, setWorkFlowValues} from '../helper';
import {v4 as uuidV4} from 'uuid';
import {WorkFlowDataTypeEnum, WorkFlowTypeEnum} from 'src/@types/enums';
import TextButton from 'src/shared/components/UI/TextButton';

type Props = {
	item: IWorkFlowItem;
	workFlowItem: IWorkFlowItem[];
	setWorkFlowItem: (value: IWorkFlowItem[]) => void;
};

export const AlertConfirmComp = ({item, workFlowItem, setWorkFlowItem}: Props) => {
	const addItemPosition = () => {
		const items = [...workFlowItem];
		items.push({
			ref: uuidV4().toString(),
			text: 'Titulo',
			refItem: item.ref,
			type: WorkFlowTypeEnum.Variable,
		});

		setWorkFlowItem(items);
	};

	return (
		<S.ContentBasic>
			<Wrapper>
				<Input
					value={item.values?.title}
					onChange={val =>
						setWorkFlowValues(workFlowItem, setWorkFlowItem, item.ref!, 'title', val)
					}
					label="Titulo"
					inputType="minimal"
					background="transparent"
					textColor={'#000'}
				/>
			</Wrapper>
			<Wrapper>
				<Input
					value={item.values?.description}
					onChange={val =>
						setWorkFlowValues(
							workFlowItem,
							setWorkFlowItem,
							item.ref!,
							'description',
							val,
						)
					}
					label="Descrição"
					inputType="minimal"
					background="transparent"
					textColor={'#000'}
				/>
			</Wrapper>

			<SizedBox height="12px" />
			{buildWorkflowSub(
				workFlowItem,
				setWorkFlowItem,
				addItemPosition,
				item.ref ?? '',
				WorkFlowDataTypeEnum.Input,
			)}

			<SizedBox height="12px" />
			<Wrapper alignItems="center" justifyContent="center" flexDirection="column" gap="0px">
				<TextButton
					onClick={() => {
						const data = [...workFlowItem];
						data.push({
							ref: uuidV4().toString(),
							text: 'Titulo',
							type: WorkFlowTypeEnum.Variable,
							refItem: item.ref,
						});

						setWorkFlowItem(data);
					}}
					text="Adicionar recurso"
					themeStyle="primary"
					leftLineIcon="lni lni-plus"
				/>
			</Wrapper>
		</S.ContentBasic>
	);
};
