/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useContext, useEffect} from 'react';
import {IAction, IApplication, IWorkFlow, IWorkFlowItem} from 'src/@types/app';
import {ActionTypeEnum} from 'src/@types/enums';
import {Button, WidthBox, Wrapper} from 'src/shared/components/UI';
import {ApplicationContext} from 'src/context';
import {WorkFlowTargetList} from 'src/helpers/select-data/workflowTargetList';
import {v4 as uuidV4} from 'uuid';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import {applicationTheme} from 'src/shared/theme';

type Props = {
	application: IApplication;
	btnAction: IAction | undefined;
	setBtnAction: React.Dispatch<React.SetStateAction<IAction | undefined>>;
	setIsOpenWorkFlow: (value: boolean) => void;
	workFlowItem: IWorkFlowItem[];
	setWorkFlowItem: (value: IWorkFlowItem[]) => void;
};

const WorkFlow = ({
	btnAction,
	setBtnAction,
	setIsOpenWorkFlow,
	workFlowItem,
	setWorkFlowItem,
}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);

	const changeViewFunctionBar = (hide: boolean) =>
		setApplication({...application, hideFuncionBar: hide});

	useEffect(() => {
		setWorkFlowItem(btnAction?.workflow?.components ?? []);
	}, []);

	useEffect(() => {
		let workflow: IWorkFlow;
		if (btnAction?.workflow) {
			workflow = {
				...btnAction.workflow,
				components: workFlowItem,
			};
		} else {
			workflow = {
				components: workFlowItem,
				ref: uuidV4().toString(),
			};
		}

		setBtnAction({
			...btnAction,
			actionType: ActionTypeEnum.WorkFlow,
			workflow: {
				...workflow,
			},
		});
	}, [workFlowItem]);

	const setTarget = (value: any) => {
		setBtnAction({
			...btnAction,
			actionType: ActionTypeEnum.WorkFlow,
			workflow: {
				...(btnAction?.workflow ?? {}),
				taget: value,
			},
		});
	};

	return (
		<>
			<Wrapper margin="12px 0 0 0">
				<WidthBox width="95%">
					<SystemSelect
						value={WorkFlowTargetList?.find(
							x => x.value === btnAction?.workflow?.taget,
						)}
						options={WorkFlowTargetList}
						onChange={val => setTarget(val.value)}
						label="Alvo"
					/>
				</WidthBox>

				<WidthBox width="auto" margin="22px 0 0 0">
					<Button
						icon2={'settings'}
						onClick={() => {
							setIsOpenWorkFlow(true);
							changeViewFunctionBar(true);
						}}
						fill="auto"
						textColor={applicationTheme.brand[600]}
						background={applicationTheme.brand[100]}
						height="41px"
					/>
				</WidthBox>
			</Wrapper>
		</>
	);
};

export default WorkFlow;
