import styled from 'styled-components';
import {transparentize} from 'polished';
import {applicationTheme} from 'src/shared/theme';

export const ContentBasic = styled.div`
	box-sizing: border-box;
	width: 97%;
`;

export const Content = styled.div`
	width: 650px;
	max-width: 100%;
	display: flex;
	align-items: center;
	background: ${transparentize(0.85, applicationTheme.brand[600])};
	cursor: pointer;
	box-sizing: border-box;
	border-radius: 8px;

	&:hover {
		transition: 0.2s;
		background: ${transparentize(0.8, applicationTheme.brand[600])};
	}

	&:active {
		transition: 0.2s;
		background: ${transparentize(0.7, applicationTheme.brand[600])};
	}
`;

export const SubContent = styled.div`
	width: 650px;
	max-width: 100%;
	background-color: white;
	border: 1px solid #ccc;
	border-radius: 8px;
	border-top: none;
	box-sizing: border-box;
	padding: 3px;
`;

export const ContentSubComponent = styled.div`
	display: flex;
	gap: 4px;
	margin-top: 10px;
	max-width: 100%;
`;

export const SubComponent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0px;
	margin-top: 0px;
	max-width: calc(50% - 2px);
`;

export const Text = styled.div`
	color: black;
	cursor: pointer;
`;

export const IconAction = styled.div`
	border-radius: 8px;
	width: 50px;
	height: 38px;
	margin-right: 10px;
	background: blue;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	cursor: pointer;
	transition: 0.2s;
	background: ${applicationTheme.brand[600]};
	font-size: 25px;

	&:hover {
		transition: 0.2s;
		background: ${transparentize(0.2, applicationTheme.brand[600])};
	}

	&:active {
		transition: 0.2s;
		background: ${transparentize(0.35, applicationTheme.brand[600])};
	}
`;

export const IconActionSub = styled.div`
	border-radius: 8px;
	width: 50px;
	height: 38px;
	margin-right: 10px;
	background: blue;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	background: ${applicationTheme.brand[600]};
	font-size: 25px;
`;

export const OptionItem = styled.div`
	border-radius: 8px;
	width: 55px;
	height: 55px;
	background: ${applicationTheme.brand[600]};
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	font-size: 25px;
	cursor: pointer;

	&:hover {
		transition: 0.2s;
		background: ${transparentize(0.2, applicationTheme.brand[600])};
	}

	&:active {
		transition: 0.2s;
		background: ${transparentize(0.35, applicationTheme.brand[600])};
	}
`;

export const OptionsMenu = styled.div`
	width: 30px;
	height: 30px;
	border-radius: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	&:hover {
		transition: 0.2s;
		background: ${transparentize(0.6, applicationTheme.brand[600])};
	}

	&:active {
		transition: 0.2s;
		background: ${transparentize(0.4, applicationTheme.brand[600])};
	}
`;

type MenuProps = {
	x: number;
	y: number;
};
export const Menu = styled.div<MenuProps>`
	width: 240px;
	position: fixed !important;
	border-radius: 3px;
	border-radius: 5px;
	background: #141824;
	padding: 7px 0;
	top: ${props => props.y}px;
	left: ${props => props.x}px;
	z-index: 100000000;
`;

export const MenuItem = styled.div`
	font-size: 13px;
	color: #fff;
	cursor: pointer;
	padding: 3px 18px;

	:hover {
		background-color: #444;
	}
	:active {
		background-color: #666;
	}
`;

export const Arrow = styled.div`
	height: 41px;
	position: relative;
	color: black;

	.arrow {
		font-size: 36px;
		margin: 0;
		padding: 0;
		width: 40px;
		height: 40px;
	}

	.iconAdd {
		position: absolute;
		top: 9px;
		left: -13px;
		font-size: 14px;
		color: blue;
		width: 25px;
		height: 25px;
		display: flex;
		align-items: center;
		justify-content: center;
		display: none;
		color: ${applicationTheme.brand[700]};
	}

	:hover {
		.arrow {
			display: none;
		}

		.iconAdd {
			display: flex;
			cursor: pointer;
		}
	}
`;

type ItemProps = {
	parents: number;
	textColor: string;
};
export const Item = styled.div<ItemProps>`
	display: flex;
	box-sizing: border-box;
	width: 100%;
	cursor: pointer;
	padding: 2px 16px;
	font-size: 13px;
	font-family: Arial;
	color: ${props => props.textColor};

	&:hover {
		background: #315553;
		color: white;
	}

	.param {
		display: flex;
		margin-left: ${props => props.parents * 16}px;
	}

	span {
		color: orange;
	}
`;

export const BodyContent = styled.div`
	width: 100%;
	border: 0.1px solid #666;
	border-radius: 7px;
	padding: 12px 0;
	margin-top: 16px;
`;
