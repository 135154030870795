import {IOption} from 'src/@types/app';

export const ProjectTypeList: IOption[] = [
	{
		label: 'Multi-layer',
		value: 'Multi-layer',
	},
	{
		label: 'Single-layer',
		value: 'Single-layer',
	},
];
