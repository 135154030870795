/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {faChevronLeft, faClose} from '@fortawesome/free-solid-svg-icons';
import React, {useEffect, useState} from 'react';
import {IApiEndpoint, IApiMap, IOption} from 'src/@types/app';
import {Button, IconButton, Text, Wrapper} from 'src/shared/components/UI';
import {ParamTypeList} from 'src/helpers/select-data/paramTypeList';
import * as S from './styles';
import * as SS from '../../styles';
import {v4 as uuidV4} from 'uuid';
import {SystemInput} from 'src/shared/components/System/SystemInput';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import {applicationTheme} from 'src/shared/theme';

type Props = {
	endpointData: IApiEndpoint;
	save: (data: IApiEndpoint) => void;
	back: () => void;
};

const AddApiEndpointRequest = ({endpointData, save, back}: Props) => {
	const [apiMap, setApiMap] = useState<IApiMap>();
	const [objectsParam, setObjectsParam] = useState<IOption[]>([]);

	useEffect(() => {
		setObjectsParam([
			{
				value: undefined,
				label: 'Selecione...',
			},
			...(endpointData.request
				?.filter(x => x.type === 'object')
				?.map(x => {
					return {
						label: x.name ?? '',
						value: x.ref ?? '',
					};
				}) ?? []),
		]);
	}, [endpointData]);

	const addItem = () => {
		const newData = [...(endpointData.request ?? [])];
		newData.push({
			ref: uuidV4().toString(),
			name: apiMap?.name,
			type: apiMap?.type,
			parentRef: apiMap?.parentRef,
		});

		save({...endpointData, request: newData});
		clear();
	};

	const removeItem = (ref: string) => {
		const newData = [...(endpointData.request ?? [])].filter(x => x.ref != ref);
		save({...endpointData, request: newData});
	};

	const clear = () => {
		setApiMap(undefined);
	};

	const renderItem = (item: IApiMap, index: number, parent?: number): any => {
		let calcParent: number = parent ?? 0;
		if (item.parentRef) calcParent += 1;

		return (
			<div key={index}>
				<S.Item parents={calcParent} textColor={'#000'}>
					<div className="param">
						{item.name}: <span>{`"${item.type}"`}</span>
					</div>
					<Wrapper justifyContent="end">
						<IconButton
							icon={faClose}
							size="12px"
							color={'#000'}
							onClick={() => removeItem(item!.ref!)}
						/>
					</Wrapper>
				</S.Item>
				{endpointData.request
					?.filter(x => x.parentRef === item.ref)
					?.map((subItem, subIndex) => renderItem(subItem, subIndex, calcParent))}
			</div>
		);
	};

	return (
		<SS.Content>
			<Wrapper margin="0 0 20px 0">
				<IconButton icon={faChevronLeft} color={'#000'} useBorder={true} onClick={back} />
				<Text
					text="Configuração do Request"
					fontSize="18px"
					color={'#000'}
					fontWeight="600"
				/>
			</Wrapper>

			<SS.Card>
				<Wrapper margin="14px 0">
					<SystemInput
						width="60%"
						value={apiMap?.name}
						onChange={value => setApiMap({...apiMap, name: value})}
						label="Nome do parâmetro"
					/>
					<SystemSelect
						width="40%"
						value={ParamTypeList.find(x => x.value === apiMap?.type)}
						options={ParamTypeList}
						onChange={value => setApiMap({...apiMap, type: value.value})}
						label="Tipo"
					/>
					{objectsParam.length > 1 ? (
						<SystemSelect
							width="25%"
							value={objectsParam.find(x => x.value === apiMap?.parentRef)}
							options={objectsParam}
							onChange={value => setApiMap({...apiMap, parentRef: value.value})}
							label="Parent"
						/>
					) : (
						<></>
					)}
				</Wrapper>

				<Wrapper justifyContent="end">
					<Button
						text={'Adicionar'}
						onClick={addItem}
						fill="auto"
						background={applicationTheme.brand[600]}
						disabled={
							!(
								apiMap?.name?.trim() != '' &&
								apiMap?.type &&
								(endpointData.request ?? []).filter(x => x.name === name).length ===
									0
							)
						}
					/>
				</Wrapper>

				<S.BodyContent>
					{endpointData.request
						?.filter(x => !x.parentRef)
						?.map((item, index) => renderItem(item, index))}
				</S.BodyContent>
			</SS.Card>
		</SS.Content>
	);
};

export default AddApiEndpointRequest;
