import {IOption} from 'src/@types/app';

export const OperationsList: IOption[] = [
	{
		value: undefined,
		label: 'Selecione...',
	},
	{
		value: '==',
		label: '==',
	},
	{
		value: '<',
		label: '<',
	},
	{
		value: '>',
		label: '>',
	},
	{
		value: '>=',
		label: '>=',
	},
	{
		value: '<=',
		label: '<=',
	},
];
