/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {useTheme} from 'styled-components';
import * as S from './styles';
import Wrapper from '../Wrapper';

type Props = {
	onClick: (e?: any) => void;
	leftLineIcon?: string;
	themeStyle?: 'success' | 'danger' | 'info' | 'alert' | 'black' | 'primary' | 'light';
	fontSize?: string;
	text?: string;
};

const TextButton = ({onClick, leftLineIcon, themeStyle, fontSize, text}: Props) => {
	const themeData = useTheme();

	return (
		<Wrapper width="fit-content">
			<S.Content
				onClick={onClick}
				theme={themeData}
				themeStyle={themeStyle}
				fontSize={fontSize}>
				<span>
					<i className={leftLineIcon}></i> {text}
				</span>
			</S.Content>
		</Wrapper>
	);
};

export default TextButton;
