export const generateReadme = () => {
	const code = `
# PROJECT

## Para iniciar o projeto, execute os seguintes comandos:
 - yarn install
 - yarn run lint
 - yarn start`;

	return {
		path: '',
		name: 'README.md',
		code: code,
	};
};
