import React, {useContext, useEffect, useState} from 'react';
import FeatherIcon from 'feather-icons-react';
import {styled} from '@mui/material/styles';
import {TreeView} from '@mui/x-tree-view/TreeView';
import {TreeItem, TreeItemProps, treeItemClasses} from '@mui/x-tree-view/TreeItem';
import * as S from './styles';
import {Wrapper} from 'src/shared/components/UI';
import {ApplicationContext} from 'src/context';
import {useNavigate} from 'react-router-dom';
import {Box, Typography} from '@mui/material';
import {AddTable} from '../AddTable';
import {IDatabaseConfig, ITable} from 'src/@types/app';

type StyledTreeItemProps = TreeItemProps & {
	labelIcon?: JSX.Element;
};

const StyledTreeItemLabel = styled(Typography)({
	fontSize: '14px',
	fontFamily: 'Inter',
}) as unknown as typeof Typography;

export const LeftBarRender = () => {
	const navigate = useNavigate();

	const [application, setApplication] = useContext(ApplicationContext);
	const [isOpenAddTable, setIsOpenAddTable] = useState(false);
	const [editTableData, setEditTableData] = useState<ITable>();

	useEffect(() => {
		if (!application) navigate('/');
	}, []);

	const StyledTreeItemRoot = styled(TreeItem)(({}) => ({
		[`& .${treeItemClasses.iconContainer}`]: {
			'& .close': {
				opacity: 0.3,
			},
		},
		[`& .${treeItemClasses.group}`]: {
			marginLeft: 1,
			paddingLeft: 15,
		},
	}));

	const StyledTreeItem = React.forwardRef(function StyledTreeItem(
		props: StyledTreeItemProps,
		ref: React.Ref<HTMLLIElement>,
	) {
		const {labelIcon, label, ...other} = props;

		return (
			<StyledTreeItemRoot
				label={
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
						}}>
						<StyledTreeItemLabel variant="body2">{label}</StyledTreeItemLabel>
						<Wrapper justifyContent="end">{labelIcon}</Wrapper>
					</Box>
				}
				{...other}
				ref={ref}
			/>
		);
	});

	const custonIcon = (handleClick: () => void) => {
		return (
			<div
				className="cont"
				onClick={e => {
					e.stopPropagation();
					handleClick();
				}}>
				<FeatherIcon icon="plus-square" className="labelIcon" size={20} />
			</div>
		);
	};

	const custonIconEdit = (handleClick: () => void) => {
		return (
			<div
				className="cont"
				onClick={e => {
					e.stopPropagation();
					handleClick();
				}}>
				<FeatherIcon icon="edit" className="labelIcon" size={20} />
			</div>
		);
	};

	const createTable = () => {
		setEditTableData(undefined);
		setIsOpenAddTable(true);
	};

	const editTable = (table: ITable) => {
		setEditTableData(table);
		setIsOpenAddTable(true);
	};

	const selectTable = (table: ITable) => {
		setApplication({
			...application,
			database: {
				...application.database,
				tables: [...(application.database?.tables ?? [])].map(tb => {
					if (tb.ref === table.ref) tb.selected = true;
					else tb.selected = false;
					return tb;
				}),
			},
		});
	};

	const selectDatabase = (db: IDatabaseConfig) => {
		setApplication({
			...application,
			database: {
				...application.database,
				databases: [...(application.database?.databases ?? [])].map(x => {
					if (x.ref == db.ref) x.selected = true;
					else x.selected = false;
					return x;
				}),
				tables: [...(application.database?.tables ?? [])].map(tb => {
					tb.selected = false;
					return tb;
				}),
			},
		});
	};

	const getName = (name?: string) => {
		if (!name) return '';

		if (name.length > 20) {
			return name.substring(0, 20) + '...';
		}
		return name;
	};

	return (
		<S.Content>
			<TreeView defaultExpanded={['1']} sx={{overflowX: 'hidden'}}>
				<StyledTreeItem
					nodeId="0"
					label="Banco"
					icon={<FeatherIcon icon="hard-drive" className="icon" />}>
					{application.database?.databases?.map((database, index) => (
						<>
							<StyledTreeItem
								nodeId={`db-${index}`}
								label={database.name}
								icon={<FeatherIcon icon="database" className="icon" />}
								onClick={() => selectDatabase(database)}>
								<StyledTreeItem
									nodeId={`folder-table-${index}`}
									label="Tabelas"
									icon={<FeatherIcon icon="folder" className="icon" />}
									labelIcon={custonIcon(() => createTable())}>
									{application.database?.tables
										?.filter(table => table.databaseRef === database.ref)
										.map((table, tbIndex) => (
											<>
												<StyledTreeItem
													nodeId={`table-${index}-${tbIndex}`}
													label={getName(`${table.schema}.${table.name}`)}
													onClick={() => selectTable(table)}
													icon={
														<FeatherIcon
															icon="table"
															className="icon"
														/>
													}
													labelIcon={custonIconEdit(() =>
														editTable(table),
													)}
												/>
											</>
										))}
								</StyledTreeItem>
							</StyledTreeItem>
						</>
					))}
				</StyledTreeItem>
			</TreeView>
			<AddTable
				isOpen={isOpenAddTable}
				setIsOpen={() => setIsOpenAddTable(false)}
				selectedItem={editTableData}
			/>
		</S.Content>
	);
};
