import React from 'react';
import {IModalConfig} from 'src/@types/app';
import {Collapse, Input, Switch, WidthBox, Wrapper} from 'src/shared/components/UI';

type Props = {
	config: IModalConfig;
	setConfig: (config: IModalConfig) => void;
	switchText?: string;
};

export const DefaultModalConfig: IModalConfig = {
	enable: false,
	description: '',
	title: '',
};

const ModalConfig = ({config, setConfig, switchText = 'Exibir modal de confirmação?'}: Props) => {
	return (
		<Collapse text="Modal" headerTextColor={'#000'} border="1px solid #333" borderRadius="4px">
			<Switch
				isOn={config.enable}
				handleToggle={() => setConfig({...config, enable: !config.enable})}
				text={switchText}
				textColor={'#000'}
			/>

			{(function () {
				if (config.enable) {
					return (
						<>
							<Wrapper margin="12px 0 5px 0">
								<WidthBox width="30%">
									<Input
										value={config.title}
										onChange={val => setConfig({...config, title: val})}
										placeholder="Titulo"
										border="1px solid #333"
										background="transparent"
										textColor={'#000'}
									/>
								</WidthBox>
								<WidthBox width="70%">
									<Input
										value={config.description}
										onChange={val => setConfig({...config, description: val})}
										placeholder="Descrição"
										border="1px solid #333"
										background="transparent"
										textColor={'#000'}
									/>
								</WidthBox>
							</Wrapper>
						</>
					);
				}
			})()}
		</Collapse>
	);
};

export default ModalConfig;
