/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {IPlanApiResult} from 'src/@types/api-result';
import {IPlan, IResource} from 'src/@types/app';
import {extractSubComponents, extractWidgets} from 'src/helpers/methods/api-methods';
import {Api} from './base';

export class PlanApi {
	async getAll(): Promise<IPlan[]> {
		const result = await Api.get('/plan');

		if (result.status == 200) {
			const resultData: IPlanApiResult[] = result.data;

			return resultData;
		}

		return [];
	}
	// async get(id: number): Promise<IPlan | undefined> {
	// 	const result = await Api.get(`/plan/${id}`);

	// 	if (result.status == 200) {
	// 		const resultData: IPlan = result.data;

	// 		const resource: IPlan = {
	// 			apiData: resultData.apiData ? JSON.parse(resultData.apiData) : undefined,
	// 			effects: resultData.effects ? JSON.parse(resultData.effects) : undefined,
	// 			widgets: resultData.widgets ? JSON.parse(resultData.widgets) : undefined,
	// 			subComponents: resultData.subComponents
	// 				? JSON.parse(resultData.subComponents)
	// 				: undefined,
	// 			workflows: resultData.expressions ? JSON.parse(resultData.expressions) : undefined,
	// 			expressions: resultData.workflow ? JSON.parse(resultData.workflow) : undefined,
	// 		};

	// 		return resource;
	// 	}

	// 	return undefined;
	// }
}
