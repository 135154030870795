import {IApplication, IResourceApi} from 'src/@types/app';
import {TemplateEnum} from 'src/templates/template-enum';
import {generateDefaultLayout} from './default';
import {generateNiftyLayout} from './nifty';

export const getLayoutTemplate = (app: IApplication): IResourceApi[] => {
	switch (app.layout?.templateName) {
		case TemplateEnum.None:
			return generateDefaultLayout();
		case TemplateEnum.Nifty:
			return generateNiftyLayout(app);
		default:
			return [];
	}
};
