/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useContext, useState} from 'react';
import * as S from './styles';
import FeatherIcon from 'feather-icons-react';
import {Unstable_Popup as BasePopup} from '@mui/base/Unstable_Popup';
import {LoadingContext, ProjectContext} from 'src/context';
import {ApplicationApi} from 'src/shared/repositories/application-api';
import {AddProject} from '../../components/AddProject';
import {Button, ModalTitle, Wrapper, WidthBox, Line} from 'src/shared/components/UI';
import {applicationTheme} from 'src/shared/theme';
import {Modal} from 'src/shared/components/Complex';
import {IconWrapper} from 'src/shared/components/UI/ModalTitle/styles';

type Props = {
	projRef: string;
	name: string;
	onClick: () => void;
};

export const ProjectItem = ({projRef, name, onClick}: Props) => {
	const [project, setProject] = useContext(ProjectContext);
	const [, setLoading] = useContext(LoadingContext);

	const [anchor, setAnchor] = useState<null | HTMLElement>(null);
	const [showAddProject, setShowAddProject] = useState(false);
	const [isOpenDelete, showIsOpenDelete] = useState(false);

	const applicationApi = new ApplicationApi();

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		setAnchor(anchor ? null : event.currentTarget);
	};

	const handleDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
		// Previne a propagação do evento para evitar que onClick no S.Content seja acionado
		event.stopPropagation();
		showIsOpenDelete(true);
	};

	const open = Boolean(anchor);
	const id = open ? 'simple-popper' : undefined;

	const onEdit = async (e: any) => {
		handleClick(e);
		const finded = project.applications?.find(item => item.ref === projRef);
		if (!finded) return;

		setLoading(true);
		const app = await applicationApi.getById(finded.id!);
		setLoading(false);

		setProject({
			...project,
			applications: [...(project.applications ?? [])].map(x => {
				if (x.ref === projRef) {
					x = {
						...app!,
						selectedEdit: true,
					};
				} else x.selectedEdit = false;
				return x;
			}),
		});
		setShowAddProject(true);
	};

	const onRemove = async (e: any) => {
		handleClick(e);

		setLoading(true);
		const finded = project.applications?.find(item => item.ref === projRef);
		if (finded) await applicationApi.delete(finded.id!);
		findAllProject();
	};

	const findAllProject = async () => {
		setLoading(true);
		const result = await applicationApi.getAll();
		setLoading(false);
		if (result) {
			setProject(result);
		}
	};

	return (
		<>
			<S.Content onClick={onClick}>
				<S.Principal>
					<FeatherIcon icon="briefcase" className="icon" size={25} />
					<div className="name">{name}</div>
				</S.Principal>
				<S.Second onClick={handleClick} aria-describedby={id}>
					<FeatherIcon icon="more-vertical" className="icon" size={22} />
				</S.Second>
				<BasePopup id={id} open={open} anchor={anchor} placement="right-start" offset={5}>
					<S.PopUpContent>
						<S.PopBtn onClick={onEdit}>
							<FeatherIcon icon="edit" className="icon" size={16} />
							Editar
						</S.PopBtn>
						<Wrapper alignItems="center" justifyContent="center">
							<WidthBox width="90%">
								<Line height="0.5px" background={applicationTheme.gray[300]} />
							</WidthBox>
						</Wrapper>
						<S.PopBtn onClick={handleDeleteClick} isRemove>
							<FeatherIcon icon="trash-2" className="icon" size={16} />
							Excluir
						</S.PopBtn>
						<Wrapper alignItems="center" justifyContent="center">
							<WidthBox width="90%">
								<Line height="0.5px" background={applicationTheme.gray[300]} />
							</WidthBox>
						</Wrapper>
						<S.PopBtn onClick={handleClick}>
							<FeatherIcon icon="x" className="icon" size={16} />
							Fechar
						</S.PopBtn>
					</S.PopUpContent>
				</BasePopup>
			</S.Content>

			<Modal isOpen={isOpenDelete} onClose={() => showIsOpenDelete(false)} width="auto">
				<>
					<ModalTitle
						title="Deletar Item?"
						icon={
							<IconWrapper>
								<FeatherIcon icon="alert-circle" size={20} />
							</IconWrapper>
						}
						centerTitle={true}
					/>
					<div
						style={{
							color: applicationTheme.gray[500],
							textAlign: 'center',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							fontSize: '14px',
							paddingBottom: '24px',
						}}>
						Tem certeza de que deseja excluir este item? <br />
						Essa ação não pode ser desfeita.
						{/* <b>{application.database?.tables?.find(x => x.selected)?.name}</b>? */}
					</div>
					<Wrapper margin="20px 0 0 0" justifyContent="end">
						<Button
							text="Cancelar"
							onClick={() => showIsOpenDelete(false)}
							fill="full"
							background={applicationTheme.gray[300]}
							textColor={applicationTheme.gray[700]}
							type="ghost"
						/>
						<Button
							text="Deletar"
							onClick={onRemove}
							fill="full"
							background={applicationTheme.error[600]}
						/>
					</Wrapper>
				</>
			</Modal>

			<AddProject isOpen={showAddProject} onClose={() => setShowAddProject(false)} />
		</>
	);
};
