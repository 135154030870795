/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {IAction, IApplication, IOption} from 'src/@types/app';
import {ActionTypeEnum, ResourceTypeEnum} from 'src/@types/enums';
import {SizedBox} from 'src/shared/components/UI';
import * as S from './styles';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';

type Props = {
	application: IApplication;
	btnAction: IAction | undefined;
	setBtnAction: React.Dispatch<React.SetStateAction<IAction | undefined>>;
};

const NextPage = ({application, btnAction, setBtnAction}: Props) => {
	const [pages, setPages] = useState<IOption[]>();
	const [selectModal, setSelectModal] = useState('');

	useEffect(() => {
		const resourcePages = application?.resources?.filter(x => x.type === ResourceTypeEnum.Page);

		setPages(
			resourcePages
				?.filter(x => x.ref != application.resources?.find(y => y.selected)?.ref)
				?.map(x => {
					return {
						value: x.ref,
						label: x.name as string,
					};
				}),
		);

		if (btnAction && btnAction.targetRef && btnAction.targetRef != '') {
			setSelectModal(btnAction.targetRef);
		}
	}, [application]);

	if (!pages || pages.length === 0) {
		return <S.NoContent>Nenhuma página foi encontrada na página</S.NoContent>;
	}

	const setValue = (modalRef: string) => {
		setSelectModal(modalRef);
		const action: IAction = {
			actionType: ActionTypeEnum.NextPage,
			targetRef: modalRef,
		};

		setBtnAction(action);
	};

	return (
		<>
			<SizedBox height="10px" />
			<SystemSelect
				value={pages?.find(x => x.value === selectModal)}
				options={pages as any}
				onChange={value => setValue(value.value)}
				label="Selecione a página"
			/>
		</>
	);
};

export default NextPage;
