/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {ActionTypeEnum} from 'src/@types/enums';
import API from 'src/pages/builder/Builder/components/AddAction/API';
import NextPage from 'src/pages/builder/Builder/components/AddAction/NextPage';
import OpenModal from 'src/pages/builder/Builder/components/AddAction/OpenModal';
import Search from 'src/pages/builder/Builder/components/AddAction/Search';
import SetValue from 'src/pages/builder/Builder/components/AddAction/SetValue';
import TypeGrid from 'src/pages/builder/Builder/components/AddAction/TypeGrid';
import WorkFlow from 'src/pages/builder/Builder/components/AddAction/WorkFlow';

export const complexTypeMapper = (type?: ActionTypeEnum, props?: any): JSX.Element => {
	switch (type) {
		case ActionTypeEnum.Api:
			return <API {...props} />;
		case ActionTypeEnum.DataGrid:
			return <TypeGrid {...props} />;
		case ActionTypeEnum.OpenModal:
			return <OpenModal {...props} />;
		case ActionTypeEnum.NextPage:
			return <NextPage {...props} />;
		case ActionTypeEnum.Search:
			return <Search {...props} />;
		case ActionTypeEnum.SetValue:
			return <SetValue {...props} />;
		case ActionTypeEnum.WorkFlow:
			return <WorkFlow {...props} />;
		default:
			return <></>;
	}
};
