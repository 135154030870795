/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {IApplication, IWorkFlow} from 'src/@types/app';

export const getWorkFlowFromResource = (
	app: IApplication,
	tableRef: string,
): IWorkFlow[] | undefined => {
	const resource = app.resources?.find(x => x.databaseTableRef === tableRef);
	if (!resource) return undefined;
	const components = resource.widgets?.filter(x => x.actions && x.actions.length > 0);
	if (!components) return undefined;
	const actionsArray = components.map(component => {
		return component.actions!;
	});
	const actions = actionsArray.flat(1).filter(x => x.workflow);

	return actions.map(x => x.workflow!);
};
