import {upperFirstWord} from 'src/helpers/methods/text-methods';
import {
	BODY,
	DATA_API,
	FORM_DATA_ERROR_TYPE,
	FORM_DATA_TYPE,
	FORM_DATA_VALIDATION,
	IMPORT_AREA,
	INSTANCES_AREA,
	USE_CONTEXT,
	USE_EFFECT,
	USE_EFFECT_EXP,
	USE_EFFECT_WORKFLOW,
	USE_STATE,
} from './engine-enum';

export const createTemplate = (name?: string, useDefaultLayout?: boolean): string => {
	return `
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    /* eslint-disable @typescript-eslint/no-explicit-any */
    import React, {useEffect, useState, useContext} from 'react';
    import {
      validateRequired,
      validateMinValue,
      validateMinLength,
      validateMaxValue,
      validateMaxLength,
      validateEmail,
      validateUrl,
      validateLessThan,
      validateGreatThan,
      validateCpfCnpj,
      validateBetween,
      checkFormIsValid,
    } from 'src/helpers/validation/validation-methods';
    ${useDefaultLayout == false ? '' : "import TemplateData from 'src/template';"}
    ${IMPORT_AREA}
    import UserContext from 'src/context/UserContext';

    type FormData = {
      Id?: any;
      ${FORM_DATA_TYPE}
    };

    type FormDataError = {
      ${FORM_DATA_ERROR_TYPE}
    };

    const ${upperFirstWord(name ?? '')} = () => {
      const [authUser, setUser] = useContext(UserContext);
      ${USE_CONTEXT}
      ${USE_STATE}
      const [formData, setFormData] = useState<FormData>();
      const [formError, setFormError] = useState<FormDataError>();
      const [hideFields, setHideFields] = useState<any>({});
      const [disableFields, setDisableFields] = useState<any>({});
      ${DATA_API}
      ${INSTANCES_AREA}
      ${USE_EFFECT}
      ${USE_EFFECT_EXP}
      ${USE_EFFECT_WORKFLOW}

      const clearPage = () => {
        setFormData(undefined);
        setFormError(undefined);
      };

      const validForm = (): boolean => {
        const [hasError, errors] = formValidation(formData);
        setFormError(errors);
        return hasError;
      };

      return (
        <${useDefaultLayout == false ? '' : `TemplateData pageName='${name ?? 'Não definido'}'`}>
          ${BODY}
        </${useDefaultLayout == false ? '' : 'TemplateData'}>
      );
    }

    const formValidation = (formData?: FormData): [boolean, FormDataError] => {
      const formErros: FormDataError = {};
      ${FORM_DATA_VALIDATION}

      return [checkFormIsValid(formErros), formErros];
    };


    export default ${upperFirstWord(name ?? '')};
  `;
};

export const createTemplateSubcomponent = (name?: string): string => {
	return `
    import React, {useState, useContext} from 'react';
    ${IMPORT_AREA}

    type Props = {
      data: any;
    };

    const ${upperFirstWord(name ?? '')} = ({data}: Props) => {
      ${USE_CONTEXT}
      ${USE_STATE}
      ${INSTANCES_AREA}

      const clearPage = () => {
        setFormData(undefined);
      };

      return (
        <>
          ${BODY}
        </>
      );
    }

    export default ${name};
  `;
};
