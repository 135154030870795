import {applicationTheme} from 'src/shared/theme';
import styled from 'styled-components';

export const BodyContent = styled.div`
	width: 100%;
	border: 0.1px solid #666;
	border-radius: 7px;
	padding: 12px 0;
	margin-top: 16px;
`;

type ItemProps = {
	parents: number;
	textColor: string;
};
export const Item = styled.div<ItemProps>`
	display: flex;
	box-sizing: border-box;
	width: 100%;
	cursor: pointer;
	padding: 2px 16px;
	font-size: 13px;
	font-family: Arial;
	color: ${props => props.textColor};

	&:hover {
		background: ${applicationTheme.gray[300]};
	}

	.param {
		display: flex;
		margin-left: ${props => props.parents * 16}px;
	}

	span {
		color: orange;
	}
`;
