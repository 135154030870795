import React from 'react';
import {ReactNode} from 'react';
import * as S from './styles';

type Props = {
	children?: ReactNode;
	flexDirection?: 'row' | 'column';
	alignItems?: 'center' | 'start' | 'end';
	justifyContent?: 'space-between' | 'flex-start' | 'center' | 'end';
	margin?: string;
	gap?: string;
	width?: string;
	flexWrap?: 'nowrap' | 'wrap';
	maxWidth?: string;
};

const Wrapper = ({
	children,
	flexDirection = 'row',
	alignItems = 'center',
	justifyContent = 'flex-start',
	margin = '0',
	gap = '16px',
	width = '100%',
	flexWrap = 'nowrap',
	maxWidth,
}: Props) => {
	return (
		<S.Wrapper
			flexDirection={flexDirection}
			alignItems={alignItems}
			justifyContent={justifyContent}
			margin={margin}
			gap={gap}
			width={width}
			flexWrap={flexWrap}
			maxWidth={maxWidth}>
			{children}
		</S.Wrapper>
	);
};

export default Wrapper;

export const wrapperProperties = {
	children: 'ReactNode',
	flexDirection: 'row,column',
	alignItems: 'center,start,end',
	justifyContent: 'space-between,start,center,end',
	margin: 'string',
	gap: 'string',
	width: 'string',
};
