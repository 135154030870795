/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import React, {useContext, useEffect, useState} from 'react';
import {IComponent} from 'src/@types/app';
import {ApiParamTypeEnum, ComponentComplexEnum} from 'src/@types/enums';
import {Button, ModalTitle, Wrapper} from 'src/shared/components/UI';
import LocalDataSelect, {ILocalDataSelect} from 'src/shared/components/UI/LocalDataSelect';
import {ApplicationContext} from 'src/context';
import {
	getSubcomponentSelected,
	mergeComponent,
	removeComponent,
} from 'src/helpers/methods/app-methods';
import * as S from './styles';
import {applicationTheme} from 'src/shared/theme';

type Props = {
	onClose: () => void;
	selectedWidget: IComponent | undefined;
};

const LabelValueSpecify = ({onClose, selectedWidget}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);

	const [localData, setLocalData] = useState<ILocalDataSelect>();
	const isSubcomponent = getSubcomponentSelected(application) ? true : false;

	useEffect(() => {
		setLocalData({
			data: selectedWidget?.properties?.dataName,
			prop: selectedWidget?.properties?.propStatus,
		});
	}, [selectedWidget]);

	const save = () => {
		let props: any;
		if (isSubcomponent) {
			props = {
				...(selectedWidget?.properties ?? {}),
				propRef: localData?.prop,
				text: localData?.prop,
			};
		} else {
			props = {
				...(selectedWidget?.properties ?? {}),
				dataRef: localData?.data,
				propRef: localData?.prop,
				text: `${localData?.data?.label}.${localData?.prop}`,
			};
		}
		const parentWidget = isSubcomponent
			? getSubcomponentSelected(application)?.widgets?.find(x => x.selected)
			: selectedWidget;

		const newApplication = mergeComponent(
			application,
			parentWidget,
			ComponentComplexEnum.LabelValue,
			props,
		);
		setApplication(newApplication);
		close();
	};

	const remove = (ref: string) => {
		setApplication(removeComponent(application, ref));
		close();
	};

	const close = () => {
		setLocalData(undefined);
		onClose();
	};

	return (
		<div>
			<ModalTitle title="Label Value" />

			<LocalDataSelect
				localData={localData}
				setLocalData={setLocalData}
				acceptTypes={[ApiParamTypeEnum.STRING, ApiParamTypeEnum.NUMBER]}
			/>

			<Wrapper margin="20px 0 0 0" justifyContent="end">
				<Button
					text="Fechar"
					onClick={close}
					fill="auto"
					background={applicationTheme.gray[300]}
					textColor={applicationTheme.gray[700]}
					type="ghost"
				/>
				{selectedWidget ? (
					<Button
						text="Remover"
						fill="auto"
						leftIcon={faTrash}
						themeStyle="primary"
						type="ghost"
						onClick={() => remove(selectedWidget.ref as string)}
					/>
				) : (
					<></>
				)}
				<Button
					text="Salvar"
					onClick={save}
					fill="auto"
					background={applicationTheme.brand[600]}
					disabled={
						isSubcomponent
							? !localData || !localData.prop || localData.prop == ''
							: !localData ||
							  !localData.data ||
							  !localData.prop ||
							  !localData.data.value
					}
				/>
			</Wrapper>
		</div>
	);
};

export default LabelValueSpecify;
