import styled from 'styled-components';

export const Content = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 8px;
`;

export const Exclude = styled.div`
	border: 1px solid transparent;
	display: flex;
	align-items: center;
	padding: 4px 2px;
	:hover {
		border: 1px solid #aaa;
	}
`;
