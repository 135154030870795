import {faAdd} from '@fortawesome/free-solid-svg-icons';
import React, {useState} from 'react';
import {PhotoshopPicker} from 'react-color';
import {ChartProps} from 'src/shared/components/Complex/Charts';
import {Button, Input, Switch, WidthBox, Wrapper} from 'src/shared/components/UI';
import * as S from '../../styles';

type Props = {
	chart: ChartProps | undefined;
	setChart: (val: ChartProps | undefined) => void;
};

const PieChartSpecify = ({chart, setChart}: Props) => {
	const [showPicker, setShowPicker] = useState(false);
	const [property, setProperty] = useState('');
	const [labelProp, setLabelProp] = useState('');
	const [fillColor, setFillColor] = useState('#000');

	const addGrid = () => {
		const data = [...(chart?.dataOptions ?? [])];
		data.push({
			dataKey: property,
			fillColor: fillColor,
			label: labelProp && labelProp != '' ? labelProp : property,
		});
		setChart({...chart, dataOptions: data});
		setProperty('');
		setFillColor('#000');
		setLabelProp('');
	};

	return (
		<>
			{showPicker ? (
				<S.PickerContent>
					<PhotoshopPicker
						color={fillColor}
						onChangeComplete={color => setFillColor(color.hex)}
						onAccept={() => setShowPicker(false)}
						onCancel={() => setShowPicker(false)}
					/>
				</S.PickerContent>
			) : (
				<></>
			)}
			<Wrapper margin="12px 0">
				<WidthBox width="25%">
					<Input
						value={chart?.singleOption?.innerRadius?.toString()}
						onChange={val =>
							setChart({
								...chart,
								singleOption: {
									...chart?.singleOption,
									innerRadius: val && val != '' ? Number(val) : undefined,
								},
							})
						}
						label="Borda interna"
						inputType="light"
						border="1px solid #333"
						background="transparent"
						textColor={'#000'}
					/>
				</WidthBox>
				<WidthBox width="25%">
					<Input
						value={chart?.singleOption?.outerRadius?.toString()}
						onChange={val =>
							setChart({
								...chart,
								singleOption: {
									...chart?.singleOption,
									outerRadius: val && val != '' ? Number(val) : undefined,
								},
							})
						}
						label="Borda Externa"
						inputType="light"
						border="1px solid #333"
						background="transparent"
						textColor={'#000'}
					/>
				</WidthBox>
			</Wrapper>

			<Wrapper>
				<WidthBox width="25%" margin="20px 0 0 0">
					<Switch
						isOn={chart?.useOriginData ?? true}
						handleToggle={() =>
							setChart({...chart, useOriginData: !chart?.useOriginData ?? true})
						}
						text="Dados custmizados?"
						textColor={'#000'}
					/>
				</WidthBox>
				{!chart?.useOriginData ? (
					<>
						<WidthBox width="25%">
							<Input
								value={property}
								onChange={setProperty}
								label="Propriedade"
								inputType="light"
								border="1px solid #333"
								background="transparent"
								textColor={'#000'}
								onPressEnter={addGrid}
							/>
						</WidthBox>
						<WidthBox width="25%">
							<Input
								value={labelProp}
								onChange={setLabelProp}
								label="Label"
								inputType="light"
								border="1px solid #333"
								background="transparent"
								textColor={'#000'}
								onPressEnter={addGrid}
							/>
						</WidthBox>
						<WidthBox width="15%" margin="20px 0 0 0">
							<S.ColorButton
								background={fillColor}
								onClick={() => setShowPicker(true)}
							/>
						</WidthBox>
						<WidthBox width="auto" margin="20px 0 0 0">
							<Button
								icon={faAdd}
								themeStyle="success"
								onClick={addGrid}
								fill="auto"
								disabled={!property || property == ''}
							/>
						</WidthBox>
					</>
				) : (
					<></>
				)}
			</Wrapper>

			{/* {!chart?.useOriginData ? (
				<Grid
					data={chart?.dataOptions ?? []}
					template="1fr 0.7fr 0.2fr"
					properties={['dataKey', 'fillColor']}
					header={['Propriedade', 'Cor']}
					headerTextColor={'#000'}
					dataActions={[
						{
							icon: faRemove,
							color: 'red',
							onClick: item => {
								const data = {
									...chart,
									dataOptions: [...(chart?.dataOptions ?? [])].filter(
										x => item.dataKey != x.dataKey,
									),
								};

								setChart(data);
							},
						},
					]}
				/>
			) : (
				<></>
			)} */}
		</>
	);
};

export default PieChartSpecify;
