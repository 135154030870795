/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from 'react';
import {Button, IconButton, Input, Select, WidthBox, Wrapper} from 'src/shared/components/UI';
import * as S from '../styles';
import {ApiMethodsList} from 'src/helpers/select-data/apiMethodsList';
import {faClose, faPlus} from '@fortawesome/free-solid-svg-icons';
import {IApiMap, IOption, IWorkFlowItem} from 'src/@types/app';
import {ParamTypeList} from 'src/helpers/select-data/paramTypeList';
import {v4 as uuidV4} from 'uuid';
import {ApiMethods} from 'src/@types/enums';
import {setWorkFlowValues} from '../helper';

type Props = {
	item: IWorkFlowItem;
	workFlowItem: IWorkFlowItem[];
	setWorkFlowItem: (value: IWorkFlowItem[]) => void;
};

export const HttpComp = ({item, workFlowItem, setWorkFlowItem}: Props) => {
	const [name, setName] = useState('');
	const [paramType, setParamType] = useState<IOption>();
	const [parent, setParent] = useState<IOption>();
	const [objectsParam, setObjectsParam] = useState<IOption[]>([]);

	useEffect(() => {
		setObjectsParam([
			{
				value: undefined,
				label: 'Selecione...',
			},
			...(item.values?.params
				?.filter((x: any) => x.type === 'object')
				?.map((x: any) => {
					return {
						label: x.name,
						value: x.ref,
					};
				}) ?? []),
		]);
	}, [item]);

	const renderItem = (apiItem: IApiMap, index: number, parent?: number): any => {
		let calcParent: number = parent ?? 0;
		if (apiItem.parentRef) calcParent += 1;

		return (
			<div key={index}>
				<S.Item parents={calcParent} textColor={'#000'}>
					<div className="param">
						{apiItem.name}: <span>{`"${item.type}"`}</span>
					</div>
					<Wrapper justifyContent="end">
						<IconButton
							icon={faClose}
							size="12px"
							color={'#000'}
							onClick={() => removeItem(apiItem.ref ?? '')}
						/>
					</Wrapper>
				</S.Item>
				{item.values?.params
					?.filter((x: any) => x.parentRef === item.ref)
					?.map((subItem: any, subIndex: number) =>
						renderItem(subItem, subIndex, calcParent),
					)}
			</div>
		);
	};

	const removeItem = (ref: string) => {
		const newData = [...(item.values?.params ?? [])].filter(x => x.ref != ref);
		const data = workFlowItem.map(flowItem => {
			if (flowItem.ref === item.ref) flowItem.values.params = newData;
			return flowItem;
		});

		setWorkFlowItem(data);
	};

	const addItem = () => {
		const newData = [...(item.values?.params ?? [])];
		newData.push({
			ref: uuidV4().toString(),
			name: name,
			type: paramType?.value,
			parentRef: parent?.value,
		});
		const data = workFlowItem.map(flowItem => {
			if (flowItem.ref === item.ref) flowItem.values.params = newData;
			return flowItem;
		});

		setWorkFlowItem(data);
		clear();
	};

	const clear = () => {
		setName('');
		setParamType(ParamTypeList[0]);
		setParent(objectsParam[0]);
	};

	return (
		<S.ContentBasic>
			<Wrapper>
				<WidthBox width="25%">
					<Select
						value={ApiMethodsList.find(y => y.value == item.values?.method)}
						options={ApiMethodsList}
						onChange={val =>
							setWorkFlowValues(
								workFlowItem,
								setWorkFlowItem,
								item.ref!,
								'method',
								val.value,
							)
						}
						label="Metodo"
						background="transparent"
						inputType="minimal"
					/>
				</WidthBox>
				<WidthBox width="75%">
					<Input
						value={item.values?.url}
						onChange={val =>
							setWorkFlowValues(workFlowItem, setWorkFlowItem, item.ref!, 'url', val)
						}
						inputType="minimal"
						label="Url"
					/>
				</WidthBox>
			</Wrapper>

			{item.values?.method && item.values?.method != ApiMethods.GET ? (
				<>
					<Wrapper margin="14px 0">
						<WidthBox width="35%">
							<Input
								value={name}
								onChange={setName}
								label="Nome do parâmetro"
								inputType="minimal"
								background="transparent"
								textColor={'#000'}
							/>
						</WidthBox>
						<WidthBox width="20%">
							<Select
								value={paramType}
								options={ParamTypeList}
								onChange={setParamType}
								label="Tipo"
								inputType="minimal"
								background="transparent"
								textColor={'#000'}
							/>
						</WidthBox>
						{objectsParam.length > 1 ? (
							<WidthBox width="25%">
								<Select
									value={parent}
									options={objectsParam}
									onChange={setParent}
									label="Parent"
									inputType="minimal"
									background="transparent"
									textColor={'#000'}
								/>
							</WidthBox>
						) : (
							<></>
						)}

						<WidthBox width="auto" margin="22px 0 0 0">
							<Button
								icon={faPlus}
								onClick={addItem}
								fill="auto"
								themeStyle="primary"
								type="ghost"
								borderRadius="4px"
								disabled={
									!(
										name.trim() != '' &&
										paramType &&
										(item.values?.params ?? []).filter(
											(x: any) => x.name === name,
										).length === 0
									)
								}
							/>
						</WidthBox>
					</Wrapper>

					<S.BodyContent>
						{item.values?.params
							?.filter((x: any) => !x.parentRef)
							?.map((item: any, index: number) => renderItem(item, index))}
					</S.BodyContent>
				</>
			) : (
				<></>
			)}
		</S.ContentBasic>
	);
};
