/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {Routes, Route, useLocation} from 'react-router-dom';
import BuilderScreen from 'src/pages/builder/Builder';
import {ROUTER_PATHS} from './router.path';
import ProjectScreen from 'src/pages/projects';
import Projectcreen from 'src/pages/project';
import StartScreen from 'src/pages/home';
import AuthScreen from 'src/pages/auth';
import DatabaseScreen from 'src/pages/database';
import {isAuthenticated} from 'src/helpers/auth/authentication';
import {PlanScreen} from 'src/pages/plan';
import GlobalConfigurationScreen from 'src/pages/configurations';
import {DiagramScreen} from 'src/pages/diagram';

export const RouterConfig = () => {
	const location = useLocation();

	if (isAuthenticated()) {
		return (
			<Routes location={location} key={location.pathname}>
				<Route path={ROUTER_PATHS.HOME} element={<StartScreen />}></Route>
				<Route path={ROUTER_PATHS.PROJECTS} element={<ProjectScreen />}></Route>
				<Route path={ROUTER_PATHS.PROJECT} element={<Projectcreen />}></Route>
				<Route path={ROUTER_PATHS.BUILDER} element={<BuilderScreen />}></Route>
				<Route path={ROUTER_PATHS.DATABASE} element={<DatabaseScreen />}></Route>
				<Route path={ROUTER_PATHS.PLAN} element={<PlanScreen />}></Route>
				<Route path={ROUTER_PATHS.DIAGRAM} element={<DiagramScreen />}></Route>
				<Route
					path={ROUTER_PATHS.CONFIGURATION}
					element={<GlobalConfigurationScreen />}></Route>
			</Routes>
		);
	}

	return (
		<Routes location={location} key={location.pathname}>
			<Route path={ROUTER_PATHS.AUTH} element={<AuthScreen />}></Route>
		</Routes>
	);
};
