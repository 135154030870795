import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {ReactNode, useEffect, useState} from 'react';
import Wrapper from '../Wrapper';
import * as S from './styles';

type Props = {
	children: ReactNode;
	startOpen?: boolean;
	text?: string;
	leftIcon?: IconProp;
	leftIconColor?: string;
	leftIconSize?: string;
	headerPadding?: string;
	headerTextColor?: string;
	headerBackground?: string;
	bodyPadding?: string;
	bodyBackground?: string;
	border?: string;
	borderRadius?: string;
	showArrow?: boolean;
	width?: string;
};

const Collapse = ({
	children,
	startOpen = false,
	text,
	leftIcon,
	leftIconColor,
	leftIconSize,
	headerPadding,
	headerTextColor,
	headerBackground,
	bodyPadding,
	bodyBackground,
	border,
	borderRadius,
	showArrow = true,
	width = '100%',
}: Props) => {
	const [isOpen, setIsOpen] = useState(startOpen);

	useEffect(() => {
		setIsOpen(false);
	}, [showArrow]);

	return (
		<S.Container border={border} borderRadius={borderRadius} width={width}>
			<S.Header
				textColor={headerTextColor}
				padding={headerPadding}
				background={headerBackground}>
				<Wrapper>
					{leftIcon ? (
						<S.Icon color={leftIconColor} size={leftIconSize}>
							<FontAwesomeIcon icon={leftIcon} />
						</S.Icon>
					) : (
						<></>
					)}

					{text ?? ''}
				</Wrapper>
				{showArrow ? (
					<S.CollapseIcon>
						<FontAwesomeIcon
							icon={isOpen ? faChevronUp : faChevronDown}
							onClick={() => setIsOpen(!isOpen)}
						/>
					</S.CollapseIcon>
				) : (
					<></>
				)}
			</S.Header>
			{isOpen ? (
				<S.Body padding={bodyPadding} background={bodyBackground}>
					{children}
				</S.Body>
			) : (
				<></>
			)}
		</S.Container>
	);
};

export default Collapse;

export const collapseProperties = {
	children: 'ReactNode',
	startOpen: 'boolean',
	text: 'string',
	leftIcon: 'icon',
	leftIconColor: 'color',
	leftIconSize: 'string',
	headerPadding: 'string',
	headerTextColor: 'color',
	headerBackground: 'color',
	bodyPadding: 'string',
	bodyBackground: 'color',
	border: 'string',
	borderRadius: 'string',
};
