/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {IApplication, ITable, IResourceApi} from 'src/@types/app';
import {
	dotnetFormatRepoName,
	dotnetFormatScreenName,
	dotnetSchemaName,
} from '../core/dotnet-formatter';
import {mapToDotnet} from 'src/helpers/methods/map-type-database';
import {lowerFirstWord, substringText, upperFirstWord} from 'src/helpers/methods/text-methods';
import {getWorkFlowFromResource} from 'src/helpers/methods/get-workflow';
import {WorkFlowTargetEnum} from 'src/@types/enums';
import {getConstraintName} from 'src/helpers/methods/database-to-screen';
import {backFormatPropertyName} from 'src/shared/engine-back/common/common-formatters';
import {buildWorkFlowCode} from 'src/helpers/workflow/workflowData/builder/buildWorkFlowCode';

export const generateApplication = (app: IApplication): IResourceApi[] => {
	const resources: IResourceApi[] = [];
	const projName = app.name!.replaceAll(' ', '');
	const tables = app.database!.tables!;
	const hasSchemas = true;

	resources.push(...getCommand(projName, hasSchemas, tables, app));
	resources.push(...getHandlers(hasSchemas, tables, projName, app));
	resources.push(...getMappings(tables, hasSchemas, projName));
	resources.push(...getQueries(projName, tables, hasSchemas));
	resources.push(...getUtils(projName));

	return resources;
};

const getCommand = (
	projName: string,
	hasSchemas: boolean,
	tables: ITable[],
	app: IApplication,
): IResourceApi[] => {
	const resources: IResourceApi[] = [];

	tables.forEach(table => {
		resources.push(getCommandFactory(projName, hasSchemas, table, 'Criar', app));
		resources.push(getCommandFactory(projName, hasSchemas, table, 'Alterar', app));
		resources.push(getCommandFactory(projName, hasSchemas, table, 'Remover', app, true));
	});

	return resources;
};

const getCommandFactory = (
	projName: string,
	hasSchemas: boolean,
	table: ITable,
	preName: string,
	app: IApplication,
	isSimple = false,
): IResourceApi => {
	const path = hasSchemas ? `${dotnetSchemaName(table.schema!)}/` : '';
	const className = dotnetFormatScreenName(table.name!);

	return {
		path: `src/${projName}.Application/Commands/${path}${className}`,
		name: `${preName}${className}Command.cs`,
		code: getCommandCode(projName, preName, className, table, isSimple, app),
	};
};

const getCommandCode = (
	projName: string,
	preName: string,
	className: string,
	table: ITable,
	isSimple: boolean,
	app: IApplication,
): string => {
	let properties = '';
	let subClasses = '';
	const mName = `${preName}${className}`;

	if (isSimple) {
		table.columns
			?.filter(x => x.autoIncremente)
			.forEach(column => {
				properties += `
	/// <summary>
	/// ${decodeURIComponent(escape(column.description ?? '')) ?? backFormatPropertyName(column.name!)}
	/// </summary>
	public ${!column.nullable ? 'required ' : ''}${mapToDotnet(column.type!)} ${backFormatPropertyName(
					column.name!,
				)} { get; set; }
	`;
			});
	} else {
		const result = getCommandProperties(table, app, mName, preName === 'Criar');
		properties = result.properties;
		subClasses = result.subClasses;
	}

	const code = `using MediatR;
using ${projName}.Application.Utils;

namespace ${projName}.Application.Commands.${className};

/// <summary>
/// Estrutura de dados necessária para ${preName.toLocaleLowerCase()} registro do(a) ${className.toLocaleLowerCase()}
/// </summary>
public class ${mName}Command : IRequest<ResponseService>
{
    ${properties}
}

${subClasses}`;

	//console.log(code);

	return code;
};

const getCommandProperties = (
	table: ITable,
	app: IApplication,
	mName: string,
	isCreate = false,
) => {
	let properties = '';
	let subClasses = '';
	const columns = isCreate ? table.columns?.filter(x => !x.autoIncremente) : table.columns;

	columns
		?.filter(x => !app.databaseRules?.find(y => x.name!.includes(y.findedName!)))
		?.forEach(column => {
			const nullableValue = column.nullable ? '?' : '';

			properties += `
	/// <summary>
	/// ${
		column.description
			? decodeURIComponent(escape(column.description))
			: dotnetFormatScreenName(column.name!)
	}
	/// </summary>
	public ${!column.nullable ? 'required ' : ''}${mapToDotnet(
				column.type!,
			)}${nullableValue} ${backFormatPropertyName(column.name!)} { get; set; }
`;
		});

	app.database?.tables
		?.filter(t => t.ref != table.ref)
		.forEach(t => {
			const constTable = t.columns?.find(column => column.constraint == table.ref);
			const subName = `${mName}${dotnetFormatScreenName(t.name ?? '')}Command`;

			if (!!constTable) {
				properties += `
	/// <summary>
	///  ${dotnetFormatScreenName(t.name ?? '')}s
	/// </summary>
	public List<${subName}>? ${dotnetFormatScreenName(t.name ?? '')}s { get; set; }
`;
				subClasses += createSubClass(
					t,
					subName,
					table.columns?.find(x => x.autoIncremente)?.name ?? '',
					app,
				);
			}
		});

	return {properties, subClasses};
};

const createSubClass = (
	table: ITable,
	mName: string,
	mainRef: string,
	app: IApplication,
): string => {
	let properties = '';

	table.columns
		?.filter(x => !app.databaseRules?.find(y => x.name!.includes(y.findedName!)))
		?.filter(column => column.name != mainRef)
		?.filter(column => !column.autoIncremente)
		.forEach(column => {
			const nullableValue = column.nullable ? '?' : '';
			properties += `
	/// <summary>
	/// ${
		column.description
			? decodeURIComponent(escape(column.description))
			: dotnetFormatScreenName(column.name!)
	}
	/// </summary>
	public ${!column.nullable ? 'required ' : ''}${mapToDotnet(
				column.type!,
			)}${nullableValue} ${dotnetFormatScreenName(column.name!)} { get; set; }
`;
		});

	return `
/// <summary>
/// ${dotnetFormatScreenName(table.name ?? '')}s
/// </summary>
public class ${mName}
{
${properties}
}`;
};

const getHandlers = (
	hasSchemas: boolean,
	tables: ITable[],
	projName: string,
	app: IApplication,
): IResourceApi[] => {
	const resources: IResourceApi[] = [];

	if (hasSchemas) {
		const schemas = Array.from(new Set(tables?.map(x => x.schema) ?? []));

		schemas.forEach(schema => {
			const mTables = tables.filter(x => x.schema == schema);
			const code = getHandler(
				mTables,
				projName,
				dotnetSchemaName(schema!),
				hasSchemas,
				tables,
				app,
			);

			resources.push({
				path: `src/${projName}.Application/Handlers/`,
				name: `${dotnetSchemaName(schema!)}Handler.cs`,
				code,
			});
		});
	} else {
		tables.forEach(table => {
			resources.push({
				path: `src/${projName}.Application/Handlers/`,
				name: `${dotnetFormatScreenName(table.name!)}Handler.cs`,
				code: getHandler([table], projName, table.name!, hasSchemas, tables, app),
			});
		});
	}

	return resources;
};

const getHandler = (
	tables: ITable[],
	projName: string,
	handlerName: string,
	hasSchemas: boolean,
	allTables: ITable[],
	app: IApplication,
): string => {
	return `using System.Transactions;
using AutoMapper;
using MediatR;
using ${projName}.Application.Utils;
using ${projName}.Domain.Interfaces;
using ${projName}.Domain.Interfaces.Repositories.Base;
using ${projName}.Infra.CrossCutting.Identity.Interfaces;
${getImports(tables, projName, hasSchemas, allTables)}
namespace ${projName}.Application.Handlers;

/// <summary>
/// Realiza operações referentes ao ${handlerName.toLocaleLowerCase()}
/// </summary>
public class ${upperFirstWord(handlerName)}Handler : ${getHandlerInterfaces(tables)}
{
${getHandlerImplementation(tables, handlerName, allTables)}

${getHandlerMethods(tables, projName, allTables, app)}
}`;
};

const getImports = (
	tables: ITable[],
	projName: string,
	hasSchemas: boolean,
	allTables: ITable[],
): string => {
	let code = '';

	if (hasSchemas) {
		const schemas = Array.from(new Set(tables?.map(x => x.schema) ?? []));
		schemas.forEach(schema => {
			code += `using ${projName}.Domain.Models.${dotnetSchemaName(schema!)};\n`;
		});
	} else {
		code += `using ${projName}.Domain.Models;`;
	}

	tables.forEach(table => {
		table.columns
			?.filter(column => column.constraint)
			?.forEach(column => {
				const constraintTable = allTables.find(x => x.ref === column.constraint);
				if (constraintTable) {
					const mSchema = dotnetSchemaName(constraintTable!.schema ?? '');
					if (!code.includes(`Domain.Models.${mSchema}`))
						code += `using ${projName}.Domain.Models.${mSchema};\n`;
				}
			});

		const className = dotnetFormatScreenName(table.name!);
		code += `using ${projName}.Application.Commands.${className};\n`;
	});
	return code;
};

const getHandlerInterfaces = (tables: ITable[]): string => {
	let code = '';

	tables.forEach((table, index) => {
		const space = `							    `;
		code += `${index != 0 ? space : ''}IRequestHandler<Criar${dotnetFormatScreenName(
			table.name!,
		)}Command, ResponseService>,\n`;
		code += `${space}IRequestHandler<Alterar${dotnetFormatScreenName(
			table.name!,
		)}Command, ResponseService>,\n`;
		code += `${space}IRequestHandler<Remover${dotnetFormatScreenName(
			table.name!,
		)}Command, ResponseService>,\n`;
	});

	code = code.substring(0, code.length - 2);

	return code;
};

const getHandlerImplementation = (
	tables: ITable[],
	handlerName: string,
	allTables: ITable[],
): string => {
	let codeInterface = '';
	let codeConstructor = '';
	let codeAttr = '';
	let sumaryDesc = '';

	tables.forEach(table => {
		const className = dotnetFormatScreenName(table.name!);
		const classNameLow = lowerFirstWord(className);

		const mCodeInterface = `	private readonly IRepository<${className}> ${dotnetFormatRepoName(
			classNameLow,
		)};`;
		if (!codeInterface.toUpperCase().includes(mCodeInterface.trim().toUpperCase()))
			codeInterface += `${mCodeInterface}\n`;

		const mCodeConstructor = `		IRepository<${className}> ${classNameLow}Repository,`;
		if (!codeConstructor.toUpperCase().includes(mCodeConstructor.trim().toUpperCase()))
			codeConstructor += `${mCodeConstructor}\n`;

		const mCodeAttr = `		${dotnetFormatRepoName(classNameLow)} = ${classNameLow}Repository;`;
		if (!codeAttr.toUpperCase().includes(mCodeAttr.trim().toUpperCase()))
			codeAttr += `${mCodeAttr}\n`;

		const mSumaryDesc = `	/// <param name="${classNameLow}Repository">Repositório do(a) ${classNameLow}</param>`;
		if (!sumaryDesc.toUpperCase().includes(mSumaryDesc.trim().toUpperCase()))
			sumaryDesc += `${mSumaryDesc}\n`;

		table.columns
			?.filter(column => column.constraint)
			?.forEach(column => {
				const constraintTable = allTables.find(x => x.ref === column.constraint);
				if (constraintTable) {
					const mClass = dotnetFormatScreenName(constraintTable!.name!);
					const mLow = lowerFirstWord(mClass);
					const mCode = `	private readonly IRepository<${mClass}> _${mLow}Repository;`;
					if (!codeInterface.toUpperCase().includes(mCode.trim().toUpperCase()))
						codeInterface += `${mCode}\n`;

					const mmCodeConstructor = `		IRepository<${mClass}> ${mLow}Repository,`;
					if (
						!codeConstructor
							.toUpperCase()
							.includes(mmCodeConstructor.trim().toUpperCase())
					)
						codeConstructor += `${mmCodeConstructor}\n`;

					codeAttr += `		_${mLow}Repository = ${mLow}Repository;\n`;

					const mmSumaryDesc = `	/// <param name="${mLow}Repository">Repositório do(a) ${mLow}</param>`;
					if (!sumaryDesc.toUpperCase().includes(mmSumaryDesc.trim().toUpperCase()))
						sumaryDesc += `${mmSumaryDesc}\n`;
				}
			});
	});

	sumaryDesc = substringText(sumaryDesc);
	codeAttr = substringText(codeAttr);
	codeConstructor = substringText(codeConstructor, 2);

	return `	private readonly IUnitOfWork _unitOfWork;
  	private readonly IMapper _mapper;
		private readonly IUserService _userService;
		private readonly IIdentityService _identityService;
${codeInterface}
	/// <summary>
	/// Construtor
	/// </summary>
	/// <param name="mapper">Responsável por realizar o mapeamento automática entre DTO e Domain</param>
	/// <param name="unitOfWork">Responsável por armazenar informações referentes aos processamentos no banco de dados</param>
	/// <param name="userService">Repositório de controle de usuário</param>
${sumaryDesc}
	public ${upperFirstWord(handlerName)}Handler(
		IMapper mapper,
		IUserService userService,
		IIdentityService identityService,
		IUnitOfWork unitOfWork,
${codeConstructor})
	{
		_unitOfWork = unitOfWork;
		_mapper = mapper;
		_userService = userService;
		_identityService = identityService;
${codeAttr}
	}`;
};

const getHandlerMethods = (
	tables: ITable[],
	projName: string,
	allTables: ITable[],
	app: IApplication,
): string => {
	let code = '';

	tables.forEach(table => {
		const className = dotnetFormatScreenName(table.name!);
		const classNameLow = lowerFirstWord(className);
		let finders = '';
		const propIdName = backFormatPropertyName(
			table.columns?.find(x => x.autoIncremente)?.name ?? '',
		);

		table.columns
			?.filter(column => column.constraint && !column.autoIncremente)
			?.forEach(column => {
				const constraintTable = allTables.find(x => x.ref === column.constraint);
				const findedRule = app.databaseRules?.find(x => x.findedName == column.name);
				const findedMap = app.userConfiguration?.mapVars?.find(
					x => x.ruleRef === findedRule?.ref,
				);

				if (constraintTable) {
					const mClass = dotnetFormatScreenName(constraintTable!.name!);
					const mProperty = getConstraintName(backFormatPropertyName(column.name ?? ''));
					const mLow = lowerFirstWord(mClass);
					let finderProperty = `command.${backFormatPropertyName(column.name!)}`;

					if (findedRule && findedMap) {
						const mPropName = app.userConfiguration?.mapping?.find(
							x => x.columnRef === findedMap.columnRef,
						)?.columnName;
						finderProperty = `_identityService.GetUser${mPropName}()`;
					}

					let finderCode = '';
					if (column.nullable) {
						finderCode = `${finderProperty} != null ? (await _${mLow}Repository.ObterPorIdAsync(${finderProperty}))! : null;\n`;
					} else {
						finderCode = `(await _${mLow}Repository.ObterPorIdAsync(${finderProperty}))!;\n`;
					}
					finders += `			${classNameLow}.${mProperty} = ${finderCode}`;
				}
			});

		table.columns
			?.filter(column => !column.constraint && !column.autoIncremente)
			?.filter(column => app.databaseRules?.find(x => x.findedName === column.name))
			?.forEach(column => {
				const findedRule = app.databaseRules?.find(x => x.findedName == column.name);
				const findedMap = app.userConfiguration?.mapVars?.find(
					x => x.ruleRef === findedRule?.ref,
				);

				// if (findedRule && findedMap) {
				// 	finders += `			${classNameLow}.${backFormatPropertyName(
				// 		column.name ?? '',
				// 	)} = DateTime.Now;\n`;
				// }
			});

		code += `
	${getDefaultCreateHandler(className, classNameLow, projName, finders, app, table.ref!)}
	${getDefaultUpdateHandler(className, classNameLow, projName, finders, propIdName, app, table.ref!)}
	${getDefaultRemoveHandler(className, classNameLow, projName, propIdName, app, table.ref!)}
`;
	});

	return code;
};

const getMappings = (tables: ITable[], hasSchemas: boolean, projName: string): IResourceApi[] => {
	const resources: IResourceApi[] = [];

	tables.forEach(table => {
		const className = dotnetFormatScreenName(table.name!);

		const properties = `/// <summary>
		/// Responsável por mapear a inclusão entre DTO e Domain
		/// </summary>
		/// <param name="CriarClienteCommand">Contêm uma instância da interface <see cref="CriarClienteCommand"/></param>
		/// <param name="Cliente">Contêm uma instância da interface <see cref="Cliente"/></param>
		CreateMap<Criar${className}Command, ${className}>();

		/// <summary>
		/// Responsável por mapear a alteração entre DTO e Domain
		/// </summary>
		/// <param name="AlterarClienteCommand">Contêm uma instância da interface <see cref="AlterarClienteCommand"/></param>
		/// <param name="Cliente">Contêm uma instância da interface <see cref="Cliente"/></param>
		CreateMap<Alterar${className}Command, ${className}>();

		/// <summary>
		/// Responsável por mapear a exclusão entre DTO e Domain
		/// </summary>
		/// <param name="RemoverClienteCommand">Contêm uma instância da interface <see cref="AlterarClienteCommand"/></param>
		/// <param name="Cliente">Contêm uma instância da interface <see cref="Cliente"/></param>
		CreateMap<Remover${className}Command, ${className}>();`;

		let modelsImport = '';

		if (hasSchemas) {
			modelsImport += `using ${projName}.Domain.Models.${dotnetSchemaName(table.schema!)};\n`;
		} else {
			modelsImport = `using ${projName}.Domain.Models;`;
		}

		const path = hasSchemas ? `${dotnetSchemaName(table.schema!)}/` : '';

		resources.push({
			path: `src/${projName}.Application/Mapping/${path}`,
			name: `${className}Mapping.cs`,
			code: `using AutoMapper;
using ${projName}.Application.Commands.${className};
${modelsImport}
namespace ${projName}.Application.Mapping;

/// <summary>
/// Responsável por realizar o mapeamento automático entre DTO e Domain
/// </summary>
public class ${className}Mapping : Profile
{
	/// <summary>
	/// Construtor
	/// </summary>
	public ${className}Mapping()
	{
		${properties}
	}
}`,
		});
	});

	return resources;
};

const getQueries = (projName: string, tables: ITable[], hasSchemas: boolean): IResourceApi[] => {
	const resources: IResourceApi[] = [];

	tables.forEach(table => {
		const className = dotnetFormatScreenName(table.name!);
		const classNameLow = lowerFirstWord(className);
		const path = hasSchemas ? `${dotnetSchemaName(table.schema!)}/` : '';

		resources.push({
			path: `src/${projName}.Application/Queries/${path}${className}Query/`,
			name: `I${className}Query.cs`,
			code: getQueryInterface(projName, className, hasSchemas ? table.schema : undefined),
		});
		resources.push({
			path: `src/${projName}.Application/Queries/${path}${className}Query/`,
			name: `${className}Query.cs`,
			code: getQueryImplementation(
				projName,
				className,
				classNameLow,
				hasSchemas ? table.schema : undefined,
			),
		});
	});

	return resources;
};

const getQueryInterface = (projName: string, className: string, schema?: string) => {
	return `using ${projName}.Domain.Models${schema ? `.${dotnetSchemaName(schema)}` : ''};

namespace ${projName}.Application.Queries.${className}Query;

/// <summary>
/// Interface referente a consulta do(a) ${className.toLocaleLowerCase()}
/// </summary>
public interface I${className}Query
{
	/// <summary>
	/// Obtêm dados do(a) ${className.toLocaleLowerCase()} através do OData
	/// </summary>
	IQueryable<${className}> GetOData();
}`;
};

const getQueryImplementation = (
	projName: string,
	className: string,
	classNameLow: string,
	schema?: string,
): string => {
	return `
using ${projName}.Domain.Interfaces.Repositories.Base;
using ${projName}.Domain.Models${schema ? `.${dotnetSchemaName(schema)}` : ''};
using ${projName}.Infra.CrossCutting.Identity.Constants;
using ${projName}.Infra.CrossCutting.Identity.Interfaces;

namespace ${projName}.Application.Queries.${className}Query;

/// <summary>
/// Implementação da interface referente a consulta do(a) ${className.toLocaleLowerCase()}
/// </summary>
public class ${className}Query : I${className}Query
{
	private readonly IRepository<${className}> ${dotnetFormatRepoName(classNameLow)};
	private readonly IIdentityService _identityService;

	/// <summary>
	/// Construtor
	/// </summary>
	/// <param name="${classNameLow}Repository">Responsável por acessar os dados referentes a ${classNameLow}</param>
	/// <param name="identityService">Responsável por obter os dados do usuário logado</param>
	public ${className}Query(IRepository<${className}> ${classNameLow}Repository, IIdentityService identityService)
	{
		${dotnetFormatRepoName(classNameLow)} = ${classNameLow}Repository;
		_identityService = identityService;
	}

	/// <summary>
	/// Obtêm dados do(a) ${className.toLocaleLowerCase()} através do OData
	/// </summary>
	public IQueryable<${className}> GetOData()
	{
		return ${dotnetFormatRepoName(classNameLow)}.ObterTodos();
	}
}`;
};

const getUtils = (projName: string): IResourceApi[] => {
	const resources: IResourceApi[] = [];

	resources.push(getResponseService(projName));
	resources.push(getResponseBadRequestService(projName));
	resources.push(getResponseInternalServerErrorService(projName));
	resources.push(getResponseMethodNotAllowedService(projName));
	resources.push(getResponseNotFoundService(projName));
	resources.push(getResponseUnauthorizedService(projName));

	return resources;
};

const getResponseService = (projName: string): IResourceApi => {
	return {
		path: `src/${projName}.Application/Utils/`,
		name: 'ResponseService.cs',
		code: `using System.Text.Json.Serialization;
using System.Collections.ObjectModel;

namespace ${projName}.Application.Utils;

/// <summary>
/// Responsável por retornar o resultado do processamento com código de status 200 - OK
/// </summary>
public class ResponseService
{
	private readonly IList<string> _messages = new List<string>();

	/// <summary>
	/// Contêm uma lista das exceções
	/// </summary>
	[JsonIgnore]
	public IEnumerable<string> Errors { get; }

	/// <summary>
	/// Contêm a instância de um objeto de retorno
	/// </summary>
	public object Result { get; private set; }

	/// <summary>
	/// Contêm a informação sobre o processamento
	/// </summary>
	public string Message => _messages.Count == 0 ? "OK" : string.Join(";", _messages.ToArray());

	/// <summary>
	/// Contêm a informação sobre a situação do processamento
	/// </summary>
	public bool Status => _messages.Count == 0 ? true : false;

	/// <summary>
	/// Construtor
	/// </summary>
	public ResponseService() => Errors = new ReadOnlyCollection<string>(_messages);

	/// <summary>
	/// Construtor
	/// </summary>
	/// <param name="result">Contêm a instância de um objeto de retorno</param>
	public ResponseService(object result) : this() => Result = result;

	/// <summary>
	/// Registra as exceções
	/// </summary>
	/// <param name="message">Contêm a exceção</param>
	/// <returns>Retorna a instância de um objeto de retorno</returns>
	public ResponseService AddError(string message)
	{
			_messages.Add(message);

			return this;
	}

	/// <summary>
	/// Altera o conteúdo do objeto de retorno
	/// </summary>
	/// <param name="result">Contêm a instância de um objeto de retorno</param>
	public ResponseService ChangeResult(object result)
	{
			Result = result;

			return this;
	}
}`,
	};
};

const getResponseBadRequestService = (projName: string): IResourceApi => {
	return {
		path: `src/${projName}.Application/Utils/`,
		name: 'ResponseBadRequestService.cs',
		code: `namespace ${projName}.Application.Utils;

/// <summary>
/// Responsável por retornar o resultado do processamento com código de status 400 - BadRequest
/// </summary>
public class ResponseBadRequestService
{
	/// <summary>
	/// Contêm a instância de um objeto de retorno
	/// </summary>
	public object Result { get; private set; }

	/// <summary>
	/// Contêm a informação sobre o processamento
	/// </summary>
	public string Message => "Erro nos dados enviados";

	/// <summary>
	/// Contêm a informação sobre a situação do processamento
	/// </summary>
	public bool Status => false;

	/// <summary>
	/// Contêm a informação sobre a data do processamento
	/// </summary>
	public DateTime TransactionDate => DateTime.Now;

	/// <summary>
	/// Construtor
	/// </summary>
	/// <param name="result">Contêm a instância de um objeto de retorno</param>
	public ResponseBadRequestService(object result) => Result = result;
}`,
	};
};

const getResponseInternalServerErrorService = (projName: string): IResourceApi => {
	return {
		path: `src/${projName}.Application/Utils/`,
		name: 'ResponseInternalServerErrorService.cs',
		code: `namespace ${projName}.Application.Utils;

/// <summary>
/// Responsável por retornar o resultado do processamento com código de status 500 - InternalServerError
/// </summary>
public class ResponseInternalServerErrorService
{
	/// <summary>
	/// Contêm a instância de um objeto de retorno
	/// </summary>
	public object Result { get; private set; }

	/// <summary>
	/// Contêm a informação sobre o processamento
	/// </summary>
	public string Message => "Erro durante o processamento da requisição";

	/// <summary>
	/// Contêm a informação sobre a situação do processamento
	/// </summary>
	public bool Status => false;

	/// <summary>
	/// Contêm a informação sobre a data do processamento
	/// </summary>
	public DateTime TransactionDate => DateTime.Now;

	/// <summary>
	/// Construtor
	/// </summary>
	/// <param name="result">Contêm a instância de um objeto de retorno</param>
	public ResponseInternalServerErrorService(object result) => Result = result;
}`,
	};
};

const getResponseMethodNotAllowedService = (projName: string): IResourceApi => {
	return {
		path: `src/${projName}.Application/Utils/`,
		name: 'ResponseMethodNotAllowedService.cs',
		code: `namespace ${projName}.Application.Utils;

/// <summary>
/// Responsável por retornar o resultado do processamento com código de status 405 - MethodNotAllowed
/// </summary>
public class ResponseMethodNotAllowedService
{
	/// <summary>
	/// Contêm a informação sobre o processamento
	/// </summary>
	public string Message => "Método não permitido";

	/// <summary>
	/// Contêm a informação sobre a situação do processamento
	/// </summary>
	public bool Status => false;

	/// <summary>
	/// Contêm a informação sobre a data do processamento
	/// </summary>
	public DateTime TransactionDate => DateTime.Now;
}`,
	};
};

const getResponseNotFoundService = (projName: string): IResourceApi => {
	return {
		path: `src/${projName}.Application/Utils/`,
		name: 'ResponseNotFoundService.cs',
		code: `namespace ${projName}.Application.Utils;

/// <summary>
/// Responsável por retornar o resultado do processamento com código de status 404 - NotFound
/// </summary>
public class ResponseNotFoundService
{
	/// <summary>
	/// Contêm a informação sobre o processamento
	/// </summary>
	public string Message => "Nenhum resultado encontrado";

	/// <summary>
	/// Contêm a informação sobre a situação do processamento
	/// </summary>
	public bool Status => false;

	/// <summary>
	/// Contêm a informação sobre a data do processamento
	/// </summary>
	public DateTime TransactionDate => DateTime.Now;
}`,
	};
};

const getResponseUnauthorizedService = (projName: string): IResourceApi => {
	return {
		path: `src/${projName}.Application/Utils/`,
		name: 'ResponseUnauthorizedService.cs',
		code: `namespace ${projName}.Application.Utils;

/// <summary>
/// Responsável por retornar o resultado do processamento com código de status 401 - Unauthorized
/// </summary>
public class ResponseUnauthorizedService
{
	/// <summary>
	/// Contêm a informação sobre o processamento
	/// </summary>
	public string Message => "Acesso não autorizado";

	/// <summary>
	/// Contêm a informação sobre a situação do processamento
	/// </summary>
	public bool Status => false;

	/// <summary>
	/// Contêm a informação sobre a data do processamento
	/// </summary>
	public DateTime TransactionDate => DateTime.Now;
}`,
	};
};

const getDefaultCreateHandler = (
	className: string,
	classNameLow: string,
	projName: string,
	finders: string,
	app: IApplication,
	tableRef: string,
): string => {
	let additionalCode = '';

	if (app.database?.tables?.find(x => x.ref === tableRef)?.isUser) {
		app.userConfiguration?.mapping?.forEach(mapItem => {
			if (mapItem.isPassword) {
				additionalCode += `			${classNameLow}.${mapItem.columnName} = _userService.HashPassword(command.${mapItem.columnName});`;
			}
		});
	}

	const defaultCode = `			var ${classNameLow} = _mapper.Map<${className}>(command);
${additionalCode}
${finders}
			${dotnetFormatRepoName(classNameLow)}.Criar(${classNameLow});`;

	const customCode = getWorkFlowCodeToTarget(app, tableRef, WorkFlowTargetEnum.Insert);

	return `
	/// <summary>
	/// Cria registro do(a) ${className.toLocaleLowerCase()}
	/// </summary>
	/// <param name="command">Estrutura de dados necessária para execução da operação</param>
	/// <param name="cancellationToken">Estrutura do .NET responsável por indicar ou notificar que a operação em andamento deve ser cancelada</param>
	/// <returns>Retorna o resultado do processamento da operação através do tipo <see cref="${projName}.Application.Utils.ResponseService"/></returns>
	public async Task<ResponseService> Handle(Criar${className}Command command, CancellationToken cancellationToken)
	{
		try
		{
${customCode ?? defaultCode}

			var result = await _unitOfWork.CommitAsync();
			return new ResponseService(result);
		}
		catch (Exception ex)
		{
			return new ResponseService().AddError(ex.Message);
		}
	}`;
};

const getDefaultUpdateHandler = (
	className: string,
	classNameLow: string,
	projName: string,
	finders: string,
	propIdName: string,
	app: IApplication,
	tableRef: string,
): string => {
	const defaultCode = `			var ${classNameLow} = await ${dotnetFormatRepoName(
		classNameLow,
	)}.ObterPorIdAsync(command.${propIdName});
			if (${classNameLow} == null)
				return new ResponseService().AddError("${className} not found!");

			var ${classNameLow}Alterar = _mapper.Map(command, ${classNameLow})!;

${finders}
			${dotnetFormatRepoName(classNameLow)}.Alterar(${classNameLow}Alterar);`;

	const customCode = getWorkFlowCodeToTarget(app, tableRef, WorkFlowTargetEnum.Update);
	return `
	/// <summary>
	/// Altera registro do(a) ${className.toLocaleLowerCase()}
	/// </summary>
	/// <param name="command">Estrutura de dados necessária para execução da operação</param>
	/// <param name="cancellationToken">Estrutura do .NET responsável por indicar ou notificar que a operação em andamento deve ser cancelada</param>
	/// <returns>Retorna o resultado do processamento da operação através do tipo <see cref="${projName}.Application.Utils.ResponseService"/></returns>
	public async Task<ResponseService> Handle(Alterar${className}Command command, CancellationToken cancellationToken)
	{
		try
		{
${customCode ?? defaultCode}
			var result = await _unitOfWork.CommitAsync();
			return new ResponseService(result);
		}
		catch (Exception ex)
		{
			return new ResponseService().AddError(ex.Message);
		}
	}`;
};

const getDefaultRemoveHandler = (
	className: string,
	classNameLow: string,
	projName: string,
	propIdName: string,
	app: IApplication,
	tableRef: string,
): string => {
	const defaultCode = `			var ${classNameLow} = await ${dotnetFormatRepoName(
		classNameLow,
	)}.ObterPorIdAsync(command.${propIdName});
			if (${classNameLow} == null)
				return new ResponseService().AddError("${className} not found!");

			${dotnetFormatRepoName(classNameLow)}.Remover(${classNameLow}.Id);`;

	const customCode = getWorkFlowCodeToTarget(app, tableRef, WorkFlowTargetEnum.Delete);

	return `
	/// <summary>
	/// Remove registro do(a) ${className.toLocaleLowerCase()}
	/// </summary>
	/// <param name="command">Estrutura de dados necessária para execução da operação</param>
	/// <param name="cancellationToken">Estrutura do .NET responsável por indicar ou notificar que a operação em andamento deve ser cancelada</param>
	/// <returns>Retorna o resultado do processamento da operação através do tipo <see cref="${projName}.Application.Utils.ResponseService"/></returns>
	public async Task<ResponseService> Handle(Remover${className}Command command, CancellationToken cancellationToken)
	{
		try
		{
${customCode ?? defaultCode}
			var result = await _unitOfWork.CommitAsync();
			return new ResponseService(result);
		}
		catch (Exception ex)
		{
			return new ResponseService().AddError(ex.Message);
		}
	}`;
};

const getWorkFlowCodeToTarget = (
	app: IApplication,
	tableRef: string,
	target: WorkFlowTargetEnum,
): string | undefined => {
	const workflows = getWorkFlowFromResource(app, tableRef) ?? [];
	const workflow = workflows.find(x => x.taget === target);
	if (!workflow) return undefined;

	return buildWorkFlowCode(workflow.components ?? [], app);
};
