import {IOption} from 'src/@types/app';
import {ChartsEnum} from 'src/shared/components/Complex/Charts';

export const ChartList: IOption[] = [
	{
		value: undefined,
		label: 'Selecione...',
	},
	{
		value: ChartsEnum.AreaChart,
		label: 'AreaChart',
	},
	{
		value: ChartsEnum.BarChart,
		label: 'BarChart',
	},
	{
		value: ChartsEnum.LineChart,
		label: 'LineChart',
	},
	{
		value: ChartsEnum.PieChart,
		label: 'PieChart',
	},
	{
		value: ChartsEnum.RadarChart,
		label: 'RadarChart',
	},
];
