import {applicationTheme} from 'src/shared/theme';
import styled from 'styled-components';

export const Content = styled.div`
	width: 100%;
	max-width: 310px;
	padding: 20px;
	background: #fff;
`;

export const Title = styled.div`
	display: flex;
	align-items: center;

	.icon {
		height: 40px;
		width: 40px;
		border-radius: 40px;
		background: ${applicationTheme.brand[100]};
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.text {
		font-family: 'Inter';
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
		text-align: left;
		color: ${applicationTheme.gray[800]};
		margin-left: 10px;
	}
`;

export const SubTitle = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 10px;

	.icon {
		color: ${applicationTheme.gray[400]};
	}

	.text {
		font-family: Inter;
		font-size: 14px;
		font-weight: 500;
		line-height: 20px;
		text-align: left;
		color: ${applicationTheme.gray[700]};
		margin-left: 10px;
	}
`;

export const Line = styled.div`
	height: 1px;
	width: 100%;
	background: ${applicationTheme.gray[200]};
	margin: 18px 0;
`;

export const Item = styled.div`
	border-left: 3px solid ${applicationTheme.gray[200]};
	align-items: center;
	height: 43px;
	width: 300px;
	display: flex;
	justify-content: space-between;

	.text {
		font-family: Inter;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		text-align: left;
		color: ${applicationTheme.gray[500]};
		margin-left: 15px;
	}

	.icon {
		color: ${applicationTheme.gray[500]};
		cursor: pointer;

		&:hover {
			color: ${applicationTheme.brand[500]};
		}
	}
`;
