import {applicationTheme} from 'src/shared/theme';
import styled from 'styled-components';

export const Content = styled.div`
	display: flex;
	gap: 0;
	padding: 0;
	margin: 0;
	width: 100%;
`;

interface ContainerProps {
	hasPanel: boolean;
}

export const Container = styled.main<ContainerProps>`
	background: ${applicationTheme.gray[100]};
	color: white;
	width: ${props => (props.hasPanel ? 'calc(100% - 320px)' : '100%')};
	height: calc(100vh - 72px);
	overflow-x: hidden;
	display: flex;
`;

export const AdditionalBar = styled.div`
	width: 320px !important;
	height: calc(100vh - 92px);
	padding-top: 20px;
`;
