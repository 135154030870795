/* eslint-disable @typescript-eslint/no-explicit-any */
import {faChevronLeft, faClose} from '@fortawesome/free-solid-svg-icons';
import React, {useEffect, useState} from 'react';
import {IApiEndpoint, IApiMap, IOption} from 'src/@types/app';
import {IconButton, SizedBox, WidthBox, Wrapper, Text, Button} from 'src/shared/components/UI';
import {ParamTypeList} from 'src/helpers/select-data/paramTypeList';
import * as S from './styles';
import * as SS from '../../styles';
import {v4 as uuidV4} from 'uuid';
import {ApiParamTypeEnum} from 'src/@types/enums';
import {SystemInput} from 'src/shared/components/System/SystemInput';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import {applicationTheme} from 'src/shared/theme';

type Props = {
	endpointData: IApiEndpoint;
	save: (data: IApiEndpoint) => void;
	back: () => void;
};

const objType = [ApiParamTypeEnum.OBJECT, ApiParamTypeEnum.ARRAY];

const AddApiEndpointResponse = ({endpointData, save, back}: Props) => {
	const [apiMap, setApiMap] = useState<IApiMap>();
	const [propList, setPropList] = useState<IOption[]>([]);
	const [objectsParam, setObjectsParam] = useState<IOption[]>([]);

	useEffect(() => {
		setObjectsParam([
			{
				value: undefined,
				label: 'Selecione...',
			},
			...(endpointData.response
				?.filter(x => objType.includes(x.type as ApiParamTypeEnum))
				?.map(x => {
					return {
						label: x.name ?? '',
						value: x.ref ?? '',
					};
				}) ?? []),
		]);

		setPropList([
			{
				value: undefined,
				label: 'Selecione...',
			},
			...(endpointData.response
				?.filter(x => !objType.includes(x.type as ApiParamTypeEnum))
				?.map(x => {
					return {
						label: x.name ?? '',
						value: x.ref ?? '',
					};
				}) ?? []),
		]);
	}, [endpointData]);

	const addItem = () => {
		const newData = [...(endpointData.response ?? [])];
		newData.push({
			ref: uuidV4().toString(),
			name: apiMap?.name,
			type: apiMap?.type,
			parentRef: apiMap?.parentRef,
		});

		save({...endpointData, response: newData});
		clear();
	};

	const removeItem = (ref: string) => {
		const newData = [...(endpointData.response ?? [])].filter(
			x => x.ref != ref && x.parentRef != ref,
		);
		save({...endpointData, response: newData});
	};

	const clear = () => {
		setApiMap(undefined);
	};

	const renderItem = (item: IApiMap, index: number, parent?: number): any => {
		let calcParent: number = parent ?? 0;
		if (item.parentRef) calcParent += 1;

		return (
			<div key={index}>
				<S.Item parents={calcParent}>
					<div className="param">
						{item.name}: <span>{`"${item.type}"`}</span>
					</div>
					<Wrapper justifyContent="end">
						<IconButton
							icon={faClose}
							size="12px"
							color="white"
							onClick={() => removeItem(item.ref ?? '')}
						/>
					</Wrapper>
				</S.Item>
				{endpointData.response
					?.filter(x => x.parentRef === item.ref)
					?.map((subItem, subIndex) => renderItem(subItem, subIndex, calcParent))}
			</div>
		);
	};

	return (
		<SS.Content>
			<Wrapper margin="0 0 20px 0">
				<IconButton icon={faChevronLeft} color={'#000'} useBorder={true} onClick={back} />
				<Text
					text="Configuração do Response"
					fontSize="18px"
					color={'#000'}
					fontWeight="600"
				/>
			</Wrapper>

			<SS.Card>
				<Wrapper margin="14px 0">
					<SystemInput
						width="45%"
						value={apiMap?.name}
						onChange={value => setApiMap({...apiMap, name: value})}
						label="Nome do parâmetro"
					/>
					<SystemSelect
						width="30%"
						value={ParamTypeList.find(x => x.value == apiMap?.type)}
						options={ParamTypeList}
						onChange={value => setApiMap({...apiMap, type: value.value})}
						label="Tipo"
					/>
					{objectsParam.length > 1 ? (
						<WidthBox width="25%">
							<SystemSelect
								value={objectsParam.find(x => x.value == apiMap?.parentRef)}
								options={objectsParam}
								onChange={value => setApiMap({...apiMap, parentRef: value.value})}
								label="Parent"
							/>
						</WidthBox>
					) : (
						<></>
					)}
				</Wrapper>

				<Wrapper justifyContent="end">
					<Button
						text={'Adicionar'}
						onClick={addItem}
						fill="auto"
						background={applicationTheme.brand[600]}
						disabled={
							!(
								apiMap?.name?.trim() != '' &&
								apiMap?.type &&
								(endpointData.request ?? []).filter(x => x.name === name).length ===
									0
							)
						}
					/>
				</Wrapper>

				<S.BodyContent>
					{endpointData.response
						?.filter(x => !x.parentRef)
						?.map((item, index) => renderItem(item, index))}
				</S.BodyContent>

				{endpointData.isAuth ? (
					<>
						<SizedBox height="12px" />
						<SystemSelect
							options={propList}
							value={propList.find(x => x.label === endpointData.authProp)}
							onChange={val => save({...endpointData, authProp: val.label})}
							label="Parametro que contém o token"
						/>
					</>
				) : (
					<></>
				)}
			</SS.Card>
		</SS.Content>
	);
};

export default AddApiEndpointResponse;
