/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {useContext, useEffect, useState} from 'react';
import {IOption, IWorkFlowItem} from 'src/@types/app';
import {
	IconButton,
	Line,
	Select,
	SizedBox,
	WidthBox,
	Wrapper,
	Text,
	Switch,
} from 'src/shared/components/UI';
import {ApplicationContext} from 'src/context';
import {comparetorEOuList, comparetorJavaScriptList} from 'src/helpers/select-data/comparetorList';
import {faAdd, faClose} from '@fortawesome/free-solid-svg-icons';
import TextButton from 'src/shared/components/UI/TextButton';
import * as S from '../styles';
import {v4 as uuidV4} from 'uuid';
import {getWidgetsInput2} from 'src/helpers/methods/app-methods';
import {getOptionFieldsListAlternative, isDisableOperations, setWorkFlowValues} from '../helper';

export interface IConditionFrontComp {
	ref?: string;
	field1?: string;
	field1Name?: string;
	operator?: string;
	field2?: string;
	field2Name?: string;
	ext?: string;
}

interface IEnableDisasble {
	ident: string;
	label: string;
	value: boolean;
}

type Props = {
	item: IWorkFlowItem;
	workFlowItem: IWorkFlowItem[];
	setWorkFlowItem: (value: IWorkFlowItem[]) => void;
};

export const EnableDisableFieldsComp = ({item, workFlowItem, setWorkFlowItem}: Props) => {
	const [application] = useContext(ApplicationContext);

	const [isStart, setIsStart] = useState(false);
	const [fieldOptions, setFieldOptions] = useState<IOption[]>([]);
	const [fieldOptions2, setFieldOptions2] = useState<IOption[]>([]);
	const [field, setField] = useState<IOption>({value: undefined, label: 'Selecione...'});

	useEffect(() => {
		if (isStart) return;

		setFieldOptions(getOptionFieldsListAlternative(application, workFlowItem));
		setFieldOptions2(getWidgetsInput2(application));
		setIsStart(true);
	}, [application]);

	const setValue = (value: IConditionFrontComp) => {
		const data = [...(item.values?.conditions ?? [])].map(x => {
			if (x.ref === value.ref) return value;
			return x;
		});

		setWorkFlowValues(workFlowItem, setWorkFlowItem, item.ref!, 'conditions', data);
	};

	const renderCondition = (conditionItem: IConditionFrontComp, index: number) => {
		return (
			<div key={index}>
				<Wrapper>
					<WidthBox width="36%">
						<Select
							value={fieldOptions.find(y => y.value == conditionItem.field1)}
							options={fieldOptions}
							onChange={(val: IOption) => {
								setValue({
									...conditionItem,
									field1: val.value,
									field1Name: val.label,
								});
							}}
							label="Campo"
							background="transparent"
							inputType="minimal"
						/>
					</WidthBox>
					<WidthBox width="21%">
						<Select
							value={comparetorJavaScriptList.find(
								y => y.value == conditionItem.operator,
							)}
							options={comparetorJavaScriptList}
							onChange={val => setValue({...conditionItem, operator: val.value})}
							placeholder="Operador"
							background="transparent"
							inputType="minimal"
							label="Comparador"
						/>
					</WidthBox>
					<WidthBox width="36%">
						<Select
							value={fieldOptions.find(y => y.value == conditionItem.field2)}
							options={fieldOptions}
							onChange={val => {
								setValue({
									...conditionItem,
									field2: val.value,
									field2Name: val.label,
								});
							}}
							label="Campo"
							background="transparent"
							inputType="minimal"
							disabled={isDisableOperations(conditionItem.operator ?? '')}
						/>
					</WidthBox>
					{item.values?.conditions?.length > 1 ? (
						<WidthBox width="auto" margin="18px 0 0 0">
							<IconButton
								icon={faClose}
								onClick={() => {
									const dataList = [...(item.values?.conditions ?? [])].filter(
										x => x.ref != conditionItem.ref,
									);
									setWorkFlowValues(
										workFlowItem,
										setWorkFlowItem,
										item.ref!,
										'conditions',
										dataList,
									);
								}}
								color="red"
							/>
						</WidthBox>
					) : (
						<></>
					)}
				</Wrapper>
				{index < item.values?.conditions?.length - 1 ? (
					<Wrapper margin="8px 0">
						<WidthBox width="20%">
							<Select
								value={comparetorEOuList.find(y => y.value == conditionItem.ext)}
								options={comparetorEOuList}
								onChange={val =>
									setValue({
										...conditionItem,
										ext: val.value,
									})
								}
								label="Operador"
								background="transparent"
								inputType="minimal"
							/>
						</WidthBox>
					</Wrapper>
				) : (
					<></>
				)}
			</div>
		);
	};

	const renderEnableDisable = (listItem: IEnableDisasble, index: number) => {
		return (
			<Wrapper key={index}>
				<WidthBox width="47%">
					<Text text={listItem.label} />
				</WidthBox>
				<WidthBox width="47%">
					<Switch
						isOn={listItem.value}
						handleToggle={() => {
							const dataList = [...(item.values?.enableDisableList ?? [])].map(x => {
								if (x.ident === listItem.ident) x.value = !x.value;
								return x;
							});

							setWorkFlowValues(
								workFlowItem,
								setWorkFlowItem,
								item.ref!,
								'enableDisableList',
								dataList,
							);
						}}
					/>
				</WidthBox>
				<WidthBox width="6%">
					<IconButton
						icon={faClose}
						onClick={() => {
							const dataList = (item.values?.enableDisableList ?? []).filter(
								(x: IEnableDisasble) => x.ident !== listItem.ident,
							);
							setWorkFlowValues(
								workFlowItem,
								setWorkFlowItem,
								item.ref!,
								'enableDisableList',
								dataList,
							);
						}}
						color="red"
					/>
				</WidthBox>
			</Wrapper>
		);
	};

	return (
		<S.ContentBasic>
			{(item.values?.conditions ?? []).map((lItem: IConditionFrontComp, index: number) =>
				renderCondition(lItem, index),
			)}
			<SizedBox height="10px" />
			<TextButton
				onClick={() => {
					const data = [...(item.values?.conditions ?? [])];
					data.push({
						ref: uuidV4().toString(),
					});
					setWorkFlowValues(workFlowItem, setWorkFlowItem, item.ref!, 'conditions', data);
				}}
				leftLineIcon="lni lni-plus"
				text="Adicionar"
				themeStyle="primary"
			/>

			<WidthBox margin="12px 0">
				<Line height="0.5px" background="#555" />
			</WidthBox>

			<Wrapper margin="0 0 10px 0">
				<WidthBox width="95%">
					<Select
						value={field}
						options={fieldOptions2}
						onChange={setField}
						label="Campo"
						background="transparent"
						inputType="minimal"
					/>
				</WidthBox>
				<WidthBox width="5%" margin="18px 0 0 0">
					<IconButton
						icon={faAdd}
						onClick={() => {
							if (!field || !field.value) return;
							if (
								item.values?.enableDisableList &&
								item.values?.enableDisableList.find(
									(x: IEnableDisasble) => x.ident === field.value,
								)
							)
								return;

							const dataList = item.values?.enableDisableList ?? [];
							dataList.push({
								ident: field.value,
								label: field.label,
								value: false,
							});
							setWorkFlowValues(
								workFlowItem,
								setWorkFlowItem,
								item.ref!,
								'enableDisableList',
								dataList,
							);
						}}
						color="blue"
					/>
				</WidthBox>
			</Wrapper>

			<Wrapper margin="0 0 5px 0">
				<WidthBox width="45%">
					<Text text="Campo" fontWeight="bold" />
				</WidthBox>
				<WidthBox width="47%">
					<Text text="Desabilitar?" fontWeight="bold" />
				</WidthBox>
			</Wrapper>
			{(item.values?.enableDisableList ?? []).map((lItem: IEnableDisasble, index: number) =>
				renderEnableDisable(lItem, index),
			)}
		</S.ContentBasic>
	);
};
