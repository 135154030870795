import FeatherIcon from 'feather-icons-react';
import React from 'react';
import {IApplication} from 'src/@types/app';
import {SystemInput} from 'src/shared/components/System/SystemInput';
import {ModalTitle, SizedBox, Wrapper} from 'src/shared/components/UI';
import * as S from './styles';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import {ProjectTypeList} from 'src/helpers/select-data/projectTypeList';
import {UiFrameworkList} from 'src/helpers/select-data/uiFrameworkList';
import {BackendLanguages, BackendLanguagesVersion} from 'src/helpers/select-data/backendLanguages';

type Props = {
	data: IApplication | undefined;
	setData: (value: IApplication) => void;
};

export const AddProjetRender = ({data, setData}: Props) => {
	return (
		<>
			<Wrapper>
				<SystemInput
					width="50%"
					value={data?.name}
					onChange={e => setData({...data, name: e})}
					label="Nome do projeto"
					placeholder="Controle de clientes"
				/>
				<SystemInput
					width="50%"
					value={data?.companyName}
					onChange={e => setData({...data, companyName: e})}
					label="Nome da empresa"
					placeholder="Configuração LTDA"
				/>
			</Wrapper>

			<SizedBox height="30px" />
			<ModalTitle title="Selecione um template" />

			<S.ContentBottom>
				<div className="left">
					<S.Tag>
						<FeatherIcon icon="layers" size={20} />
						<div className="text">Web</div>
					</S.Tag>
					<SizedBox height="10px" />
					<S.Tag>
						<FeatherIcon icon="layers" size={20} />
						<div className="text">Mobile</div>
					</S.Tag>
				</div>
				<div className="right">
					<S.Tag>
						<div className="text">Geral</div>
					</S.Tag>
					<SystemSelect
						value={ProjectTypeList.find(
							x => x.value == data?.projectConfiguration?.type,
						)}
						options={ProjectTypeList}
						margin="10px 0 10px 0"
						onChange={value =>
							setData({
								...data,
								projectConfiguration: {
									...data?.projectConfiguration,
									type: value.value,
								},
							})
						}
						label="Tipo do projeto"
					/>
					<SystemSelect
						value={UiFrameworkList.find(
							x => x.value == data?.projectConfiguration?.uiFramework,
						)}
						options={UiFrameworkList}
						margin="0 0 10px 0"
						onChange={value =>
							setData({
								...data,
								projectConfiguration: {
									...data?.projectConfiguration,
									uiFramework: value.value,
								},
							})
						}
						label="UI Framework"
					/>
					<SystemSelect
						value={BackendLanguages.find(
							x => x.value == data?.projectConfiguration?.language,
						)}
						options={BackendLanguages}
						margin="10px 0 10px 0"
						onChange={value =>
							setData({
								...data,
								projectConfiguration: {
									...data?.projectConfiguration,
									language: value.value,
								},
							})
						}
						label="Linguagem"
					/>
					<SystemSelect
						value={BackendLanguagesVersion.find(
							x => x.value == data?.projectConfiguration?.version,
						)}
						options={BackendLanguagesVersion}
						margin="0 0 10px 0"
						onChange={value =>
							setData({
								...data,
								projectConfiguration: {
									...data?.projectConfiguration,
									version: value.value,
								},
							})
						}
						label="Versão"
					/>
				</div>
			</S.ContentBottom>
		</>
	);
};
