/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {IApplication, IComponent, IColumn, IResource} from 'src/@types/app';
import {ComponentComplexEnum} from 'src/@types/enums';
import {ApplicationApi} from 'src/shared/repositories/application-api';
import {ResourceApi} from 'src/shared/repositories/resource-api';

export class DragDropController {
	private application: IApplication;
	private setApplication: (value: IApplication) => void;
	private setLoading: (value: boolean) => void;
	private ATTR = 'data-rbd-draggable-id';
	private originRef = '';
	private destinyRef = '';
	private resourceApi = new ResourceApi();
	private applicationApi = new ApplicationApi();
	private setTabJoin: (value: any) => void;
	private originItem?: IResource;
	private destinyItem?: IResource;
	public originResult?: IResource;
	public destinyResult?: IResource;

	constructor(
		application: IApplication,
		setApplication: (value: IApplication) => void,
		setLoading: (value: boolean) => void,
		setTabJoin: (value: any) => void,
	) {
		this.application = application;
		this.setApplication = setApplication;
		this.setLoading = setLoading;
		this.setTabJoin = setTabJoin;
	}

	public dragGetElementRef = (e: any) => {
		const element: any = document.elementFromPoint(e.clientX, e.clientY);
		this.originRef = element.getAttribute(this.ATTR);

		if (!this.originRef) {
			if (element.nodeName === 'path')
				this.originRef = element.parentElement.parentElement.getAttribute(this.ATTR);
			else if (element.nodeName === 'H3')
				this.originRef = element.parentElement.getAttribute(this.ATTR);
		}
	};

	public dropGetElementRef = (e: any) => {
		const element: any = document.elementFromPoint(e.clientX, e.clientY);
		this.destinyRef = element.getAttribute(this.ATTR);

		if (!this.destinyRef) {
			if (element.nodeName === 'path')
				this.destinyRef = element.parentElement.parentElement.getAttribute(this.ATTR);
			else if (element.nodeName === 'H3')
				this.destinyRef = element.parentElement.getAttribute(this.ATTR);
		}

		if (!this.originRef || !this.destinyRef || this.originRef == this.destinyRef) return;

		this.moveWidgets();
	};

	public transferAndRemoveResource = async (
		originResult: IResource,
		originItem: IResource,
		destinyResult: IResource,
		destinyItem: IResource,
		parentRef?: string,
		tableRef?: string,
	) => {
		const originColumns = this.application?.database?.tables?.find(
			x => x.ref === originItem!.databaseTableRef,
		)?.columns;

		this.prepareWidgets(
			originResult?.widgets ?? [],
			!!originItem!.databaseTableRef,
			originColumns,
			destinyItem!.databaseTableRef,
		).forEach(item =>
			destinyResult?.widgets?.push({
				...item,
				parentRef: !item.parentRef ? parentRef : item.parentRef,
				tabRef: !item.parentRef ? tableRef : item.tabRef,
			}),
		);

		await this.resourceApi.updateComplex({
			...destinyResult!,
			id: destinyItem!.id,
		} as any);

		await this.resourceApi.delete(originItem!.id!);
	};

	private getTypeElement = (ref: string): 'resource' | 'folder' => {
		const result = this.application.resources?.find(x => x.ref === ref);

		return result ? 'resource' : 'folder';
	};

	private moveWidgets = async () => {
		const origin = this.getTypeElement(this.originRef);
		const destiny = this.getTypeElement(this.destinyRef);

		if (origin === 'folder' && destiny === 'folder') return;

		try {
			this.setLoading(true);

			if (origin === 'resource' && destiny === 'resource') {
				this.originItem = this.application.resources?.find(x => x.ref == this.originRef);
				this.destinyItem = this.application.resources?.find(x => x.ref == this.destinyRef);

				if (!this.originItem || !this.destinyItem) throw Error('Error when find!');

				this.originResult = await this.resourceApi.get(this.originItem.id!);
				if (
					!this.originResult ||
					!this.originResult.widgets ||
					this.originResult.widgets.length == 0
				)
					throw Error('Origin Not found!');

				this.destinyResult = await this.resourceApi.get(this.destinyItem.id!);
				if (!this.destinyResult) throw Error('Destiny Not found!');
				if (!this.destinyResult.widgets) this.destinyResult.widgets = [];
				let findedTab = false;
				this.destinyResult.widgets.forEach(widget => {
					if (widget.name === ComponentComplexEnum.Tab) {
						this.setTabJoin({
							show: true,
							resources: this.destinyResult,
							originResult: this.originResult,
							originItem: this.originItem,
							destinyResult: this.destinyResult,
							destinyItem: this.destinyItem,
						});
						findedTab = true;
						return;
					}
				});

				if (!findedTab)
					await this.transferAndRemoveResource(
						this.originResult,
						this.originItem,
						this.destinyResult,
						this.destinyItem,
					);
			} else if (origin === 'resource' && destiny === 'folder') {
				const path = this.application.folders?.find(x => x.selected);
				const resource = this.application.resources?.find(
					x => x.ref === this.originRef,
				) as IResource;
				const folder = this.application.folders?.find(x => x.ref === this.destinyRef);

				const localFolder = path
					? `${path.path ? path.path : ''}/${path.name}/${folder?.name}`
					: `/${folder?.name}`;

				await this.resourceApi.update({
					id: resource.id!,
					name: resource.name ?? '',
					menu: JSON.stringify({
						use: resource.menu!.use,
						name: resource.menu!.name,
						icon: resource.menu!.icon,
					}),
					width: resource.width ?? '',
					isLogin: resource.isLogin ?? false,
					isRoot: resource.isRoot ?? false,
					path: localFolder,
					type: resource.type!,
					useDefaultLayout: resource.useDefaultLayout ?? true,
					databaseTableRef: resource.databaseTableRef ?? '',
				});
			}
		} finally {
			const app = await this.applicationApi.getResources(this.application);
			this.setApplication(app!);
			this.setLoading(false);
		}
	};

	private prepareWidgets = (
		widgets: IComponent[],
		hasTableRef: boolean,
		columns?: IColumn[],
		destinyTableRef?: string,
	): IComponent[] => {
		let newWidgets = structuredClone(widgets);

		if (hasTableRef) {
			newWidgets = this.removeButtons(newWidgets);
			if (columns && destinyTableRef)
				newWidgets = this.removeForeignKeyWidget(newWidgets, columns, destinyTableRef);
		}

		return newWidgets;
	};

	private removeButtons = (widgets: IComponent[]): IComponent[] => {
		const removedButtonName = ['Adicionar', 'Alterar', 'Consultar'];
		const parentWidgetRef = widgets.find(x =>
			removedButtonName.includes(x.properties.text),
		)?.parentRef;

		widgets = widgets.filter(
			x => !removedButtonName.includes(x.properties.text) && x.ref !== parentWidgetRef,
		);

		return widgets;
	};

	private removeForeignKeyWidget = (
		widgets: IComponent[],
		columns: IColumn[],
		destinyTableRef: string,
	): IComponent[] => {
		const parentRefToRemove: string[] = [];
		const removeRefs = widgets
			.filter(
				widget =>
					columns.find(column => column.ref === widget.columnRef)?.constraint ===
					destinyTableRef,
			)
			.map(x => x.ref);

		removeRefs.forEach(item => {
			const parentRef = widgets.find(x => x.ref === item)?.parentRef;
			if (parentRef) parentRefToRemove.push(parentRef);
		});

		widgets = widgets.filter(x => !removeRefs.includes(x.ref));
		widgets = widgets.filter(x => !parentRefToRemove.includes(x.ref!));

		return widgets;
	};
}
