import {IOption} from 'src/@types/app';
import {GridDataActionEnum} from 'src/@types/enums';

export const GridDatActionList: IOption[] = [
	{
		value: GridDataActionEnum.Edit,
		label: 'Editar',
	},
	{
		value: GridDataActionEnum.Remove,
		label: 'Remover',
	},
];
