import styled from 'styled-components';
import {applicationTheme} from 'src/shared/theme';

export const Content = styled.div`
	width: 100%;
	height: 100%;
	overflow-y: auto;
	padding-left: 5px;

	.icon {
		color: ${applicationTheme.gray[400]} !important;
	}

	.labelIcon {
		color: ${applicationTheme.brand[500]} !important;

		&:hover {
			color: ${applicationTheme.brand[700]} !important;
		}
	}

	.cont {
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;
