import {IOption} from 'src/@types/app';
import {DatabaseType} from 'src/@types/enums';

export const DatabaseList: IOption[] = [
	{
		value: undefined,
		label: 'Selecione...',
	},
	{
		value: DatabaseType.SqlServer,
		label: 'SQL Server',
	},
	{
		value: DatabaseType.Postgres,
		label: 'PostgreSQL',
	},
	{
		value: DatabaseType.MySql,
		label: 'MySql',
	},
	{
		value: DatabaseType.Oracle,
		label: 'Oracle',
	},
	{
		value: DatabaseType.OracleDevart,
		label: 'Oracle Devart',
	},
	{
		value: DatabaseType.SQLite,
		label: 'SQLite',
	},
];
