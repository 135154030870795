import styled from 'styled-components';
import {transparentize} from 'polished';

type Props = {
	textColor?: string;
	isExcluded?: boolean;
};

export const Content = styled.div`
	margin: 25px;
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const ResourceContent = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 0.5%;
	max-height: 75vh;
	overflow-y: auto;
	overflow-x: hidden;
	padding: 0 12px;
	width: 100%;
`;

export const LeftContainer = styled.div<Props>`
	width: calc(18% + 15px);
	height: calc(100vh - 62px);
	background: #000;
	border-right: 1px solid #000;
	z-index: 100;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #000;
	transition: 0.2s;
`;

export const LeftSectionTitle = styled.div`
	width: 80%;
	font-size: 1rem;
	padding: 9px 8px 9px 16px;
	font-weight: 600;
	font-size: 13px;
`;

type ActionButtonProps = {
	disabled?: boolean;
};

export const ActionButton = styled.div<ActionButtonProps>`
	width: 80%;
	font-size: 1rem;
	padding: 9px 8px 9px 16px;
	display: flex;
	align-items: center;
	justify-content: start;
	cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

	i {
		margin-right: 12px;
	}

	&:hover {
		background-color: ${props => (props.disabled ? 'transparent' : '#e3e6ed')};
		border-radius: 5px;
	}
`;

export const ResourceAction = styled.div<Props>`
	width: 28px;
	height: 28px;
	border-radius: 28px;
	border: 1px solid #777;
	font-size: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #000;
	transition: 0.2s;
	cursor: pointer;

	&:hover {
		background: ${props => (props.isExcluded ? 'red' : '#555')};
		color: #ddd;
		transition: 0.2s;
	}

	&:active {
		background: #222;
	}
`;

export const TopInfoBar = styled.div`
	height: 60px;
	width: 100%;
	background: transparent;
	color: #6d798f;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #ddd;

	i {
		font-size: 28px;
		margin: 0 8px 0 15px;
	}

	h3,
	h4 {
		margin: 0;
		padding: 0;
	}

	h4 {
		font-weight: 400;
		font-size: 11px;
	}

	h3 {
		font-weight: 600;
		font-size: 18px;
	}
`;

export const InfoBarAction = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fd9308;
	cursor: pointer;
	width: 30px;
	height: 30px;
	border-radius: 30px;
	border: 1px solid #fd9308;
	background: transparent;
	transition: 0.2s;

	i {
		font-size: 15px !important;
		margin: 0;
	}

	&:hover {
		background: ${transparentize(0.8, '#fd9308')};
		transition: 0.2s;
	}

	&:active {
		background: ${transparentize(0.7, '#fd9308')};
		transition: 0.2s;
	}
`;

export const NoDataInfo = styled.div`
	border: 1px solid #faefd4;
	border-radius: 4px;
	padding: 10px 12px;
	background: #fcf8e3;
	width: 80%;

	span {
		font-weight: bold;
		color: #8b8471;
	}

	p {
		margin: 0;
		padding: 0;
		color: #8b8471;
	}
`;
