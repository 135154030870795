import React from 'react';
import * as S from './styles';
import NotFoundDataImage from 'src/assets/images/notFoundData.svg';
import {Button} from 'src/shared/components/UI';
import {applicationTheme} from 'src/shared/theme';
import {useNavigate} from 'react-router-dom';
import {ROUTER_PATHS} from 'src/router/router.path';

export const NotFoundDataBase = () => {
	const navigate = useNavigate();

	return (
		<S.Container>
			<S.Content>
				<img src={NotFoundDataImage} height="180px" />
				<div className="text1">Você ainda não tem banco de dados</div>
				<div className="text2">Comece criando um novo aqui</div>
				<Button
					text="Criar novo"
					leftIcon2="plus-square"
					onClick={() => navigate(ROUTER_PATHS.CONFIGURATION)}
					fill="auto"
					background={applicationTheme.brand[600]}
				/>
			</S.Content>
		</S.Container>
	);
};
