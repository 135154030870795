/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useTheme} from 'styled-components';
import * as S from './styles';
import Wrapper from '../Wrapper';
import FeatherIcon from 'feather-icons-react';

type Props = {
	text?: string;
	type?: 'default' | 'ghost' | 'mininum';
	format?: 'default' | 'circle' | 'square' | 'oval';
	borderRadius?: string;
	themeStyle?: 'success' | 'danger' | 'info' | 'alert' | 'black' | 'primary' | 'light';
	textColor?: string;
	background?: string;
	icon?: IconProp;
	leftIcon?: IconProp;
	rightIcon?: IconProp;
	icon2?: string;
	leftIcon2?: string;
	fill?: 'full' | 'auto';
	onClick: (e?: any) => void;
	disabled?: boolean;
	tooltip?: string;
	leftLineIcon?: string;
	fontWeight?: string;
	leftLineIconColor?: string;
	height?: string;
};

const Button = ({
	text,
	type = 'default',
	format = 'default',
	themeStyle = 'info',
	borderRadius,
	textColor,
	background,
	rightIcon,
	icon2,
	leftIcon2,
	fill,
	onClick,
	disabled = false,
	tooltip,
	leftLineIcon,
	fontWeight,
	leftLineIconColor,
	height,
}: Props) => {
	const themeData = useTheme();
	const themeStyleM =
		(textColor && textColor != '') || (background && background != '') ? undefined : themeStyle;

	return (
		<Wrapper width={fill === 'auto' ? 'fit-content' : '100%'}>
			<S.Button
				onClick={disabled ? undefined : onClick}
				typeBtn={type}
				fontWeight={fontWeight}>
				<S.Container
					title={tooltip}
					theme={themeData}
					themeStyle={themeStyleM}
					type={type}
					format={format}
					borderRadius={borderRadius}
					textColor={textColor}
					background={background}
					fill={fill}
					disabled={disabled}
					leftLineIconColor={leftLineIconColor}
					height={height}>
					{leftIcon2 ? (
						<div className="icon">
							<FeatherIcon icon={leftIcon2} />
						</div>
					) : (
						<></>
					)}
					{leftLineIcon ? (
						<div className="icon">
							<i className={leftLineIcon}></i>
						</div>
					) : (
						<></>
					)}
					{icon2 ? <FeatherIcon icon={icon2} /> : text}
					{rightIcon ? (
						<div className="icon">
							<FontAwesomeIcon icon={rightIcon} />
						</div>
					) : (
						<></>
					)}
				</S.Container>
			</S.Button>
		</Wrapper>
	);
};

export default Button;

export const buttonProperties = {
	name: 'string',
	text: 'string',
	fill: 'full,auto',
	type: 'default,ghost',
	format: 'default,circle,square,oval',
	borderRadius: 'string',
	themeStyle: 'success,danger,info,alert,black,primary,light',
	textColor: 'color',
	background: 'color',
	icon: 'icon',
	leftIcon: 'icon',
	rightIcon: 'icon',
	disabled: 'boolean',
	fontWeight: 'normal,bold,light,100,400,600,800',
	action: 'action',
};
