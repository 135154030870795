import {applicationTheme} from 'src/shared/theme';
import styled from 'styled-components';

export const ContentBottom = styled.div`
	display: flex;
	width: 100%;

	.left {
		width: 50%;
		border-right: 1px solid ${applicationTheme.gray[300]};
	}
	.right {
		width: 47%;
		margin-left: 3%;
	}
`;

export const Tag = styled.div`
	color: ${applicationTheme.gray[900]};
	display: flex;
	font-size: 14px;
	font-weight: 600;
	gap: 10px;
`;
