import {IOption} from 'src/@types/app';

export const RelationTypeList: IOption[] = [
	{
		value: undefined,
		label: 'Selecione...',
	},
	{
		value: '1To1',
		label: '1 para 1',
	},
	{
		value: '1ToN',
		label: '1 para N',
	},
];
