import {applicationTheme} from 'src/shared/theme';
import styled from 'styled-components';

type Props = {
	color?: string;
	textColor?: string;
};

export const Content = styled.div<Props>`
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: start;
	gap: 12px;
	padding-left: 20px;
	cursor: pointer;
	margin-bottom: 15px;

	&:hover {
		background: ${applicationTheme.brand[100]};
	}
	&:active {
		background: ${applicationTheme.brand[200]};
	}

	.icon {
		color: ${props => props.color ?? applicationTheme.brand[700]};
	}

	.text {
		font-family: Inter;
		font-size: 14px;
		font-weight: 600;
		line-height: 20px;
		letter-spacing: 0em;
		text-align: left;
		color: ${props => props.textColor ?? applicationTheme.brand[700]};
	}

	.flag {
		width: 4px;
		height: 32px;
		background: ${applicationTheme.brand[300]};
	}
`;
