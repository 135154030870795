import {IApiData, IApplication, IResource} from 'src/@types/app';
import {addAction} from './addAction';

export const getEffect = (
	resource: IResource,
	widgetImports: string[],
	apiImports: string[],
	app: IApplication,
	fileInstances: string[],
	instanceContext: string[],
	importContext: string[],
): string => {
	let code = '';
	resource.effects?.forEach(effect => {
		const actions = effect.actions;
		let changeData = '';
		let effectApiData: IApiData | undefined;

		if (effect.apiDataRef) {
			effectApiData = resource.apiData?.find(x => x.actionRef === effect.apiDataRef);
			changeData = effectApiData?.name ?? '';
		}

		if (!actions || actions.length == 0) return '';
		const actionString = addAction(
			resource.widgets ?? [],
			widgetImports,
			apiImports,
			app,
			fileInstances,
			instanceContext,
			importContext,
			undefined,
			actions,
			resource,
			effectApiData,
		);

		code += getTemplate(actionString, changeData);
	});

	return code;
};

const getTemplate = (code: string, changeData: string): string => {
	return `
    useEffect(() => {
      const fetchData = async () => {
				${code}
      }

      fetchData();
    }, [${changeData}]);\n
  `;
};
