import React, {useContext, useState} from 'react';
import * as S from './styles';
import {Button, ModalTitle, SizedBox, Wrapper} from 'src/shared/components/UI';
import FeatherIcon from 'feather-icons-react';
import {logOff} from 'src/helpers/auth/authentication';
import {useLocation, useNavigate} from 'react-router-dom';
import {ROUTER_PATHS} from 'src/router/router.path';
import {Modal} from 'src/shared/components/Complex';
import {applicationTheme} from 'src/shared/theme';
import {ApplicationContext} from 'src/context';

export type Props = {
	expanded?: boolean;
};

export const LeftBar = ({expanded = true}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);
	const navigate = useNavigate();
	const location = useLocation();

	const [isOpenLogout, setIsOpenLogout] = useState(false);

	const logout = () => {
		logOff();
		navigate('/');
	};

	return (
		<S.Content expanded={expanded}>
			<>
				<S.Top />
				<SizedBox height="20px" />

				<S.MenuItem
					title="Home"
					onClick={() => {
						navigate(ROUTER_PATHS.HOME);
						setApplication({});
					}}>
					{location.pathname == '/' ? (
						<div className="active"></div>
					) : (
						<div className="space"></div>
					)}
					<div className="default">
						<FeatherIcon icon="home" size={24} />
					</div>
					{expanded ? <span>Home</span> : <></>}
				</S.MenuItem>

				<S.MenuItem
					title="Projetos"
					onClick={() => {
						navigate(ROUTER_PATHS.PROJECTS);
						setApplication({});
					}}>
					{location.pathname == '/a' ? (
						<div className="active"></div>
					) : (
						<div className="space"></div>
					)}
					<div className="default">
						<FeatherIcon icon="folder" size={24} />
					</div>
					{expanded ? <span>Projetos</span> : <></>}
				</S.MenuItem>

				<S.MenuItem title="Aplicativos">
					{location.pathname == '/b' ? (
						<div className="active"></div>
					) : (
						<div className="space"></div>
					)}
					<div className="default">
						<FeatherIcon icon="monitor" size={24} />
					</div>
					{expanded ? <span>Aplicativos</span> : <></>}
				</S.MenuItem>

				<S.MenuItem title="Planos" onClick={() => navigate(ROUTER_PATHS.PLAN)}>
					{location.pathname == '/p' ? (
						<div className="active"></div>
					) : (
						<div className="space"></div>
					)}
					<div className="default">
						<FeatherIcon icon="folder" size={24} />
					</div>
					{expanded ? <span>Planos Temp</span> : <></>}
				</S.MenuItem>

				{application && application.id ? (
					<S.MenuItem
						title="Configurações"
						onClick={() => navigate(ROUTER_PATHS.CONFIGURATION)}>
						{location.pathname == '/c' ? (
							<div className="active"></div>
						) : (
							<div className="space"></div>
						)}
						<div className="default">
							<FeatherIcon icon="settings" size={24} />
						</div>
						{expanded ? <span>Configurações</span> : <></>}
					</S.MenuItem>
				) : (
					<div style={{height: '56px'}}></div>
				)}

				<S.MenuItem
					style={{
						marginTop: 'calc(100vh - 506px)',
					}}>
					<div className="other">
						<FeatherIcon icon="info" size={24} />
					</div>
					{expanded ? <span>FAQ</span> : <></>}
				</S.MenuItem>

				<S.MenuItemBottom onClick={() => setIsOpenLogout(true)}>
					<div className="other">
						<FeatherIcon icon="power" size={24} />
					</div>
					{expanded ? <span>Log out</span> : <></>}
				</S.MenuItemBottom>
			</>

			<Modal isOpen={isOpenLogout} onClose={() => setIsOpenLogout(false)} width="auto">
				<>
					<ModalTitle title="Deseja realmente sair?" />
					<Wrapper margin="20px 0 0 0" justifyContent="end">
						<Button
							text="Não"
							onClick={() => setIsOpenLogout(false)}
							fill="auto"
							background={applicationTheme.gray[300]}
							textColor={applicationTheme.gray[700]}
							type="ghost"
						/>
						<Button
							text="Sim"
							onClick={logout}
							fill="auto"
							background={applicationTheme.brand[600]}
						/>
					</Wrapper>
				</>
			</Modal>
		</S.Content>
	);
};
