/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {Select, Wrapper} from '../../UI';
import {applicationTheme} from 'src/shared/theme';
import {IOption} from '../../UI/Select';

type Props = {
	value: IOption | undefined;
	onChange: (value: IOption) => void;
	options: IOption[];
	placeholder?: string;
	width?: string;
	margin?: string;
	maxWidth?: string;
	label?: string;
	components?: any;
	onMenuScrollToBottom?: (data: any) => void;
	labelColor?: string;
	disabled?: boolean;
	inputType?: 'light' | 'minimal';
};

export const SystemSelect = ({
	value,
	onChange,
	options,
	placeholder,
	margin,
	maxWidth,
	width,
	label,
	components,
	onMenuScrollToBottom,
	labelColor,
	disabled,
	inputType = 'light',
}: Props) => {
	return (
		<Wrapper width={width} margin={margin} maxWidth={maxWidth}>
			<Select
				value={value}
				onChange={onChange}
				options={options ?? []}
				border={`1px solid ${applicationTheme.gray[300]}`}
				inputType={inputType}
				placeholder={placeholder}
				label={label}
				labelColor={labelColor ?? applicationTheme.gray[700]}
				components={components}
				onMenuScrollToBottom={onMenuScrollToBottom}
				disabled={disabled}
			/>
		</Wrapper>
	);
};
