import styled from 'styled-components';

type ContentProps = {
	x: number;
	y: number;
};

export const Content = styled.div<ContentProps>`
	width: 240px;
	border-radius: 5px;
	background: #141824;
	padding: 7px 0;
	position: absolute !important;
	top: ${props => props.y}px;
	left: ${props => props.x}px;
	z-index: 100000000;
`;

export const Item = styled.div`
	font-size: 13px;
	color: #fff;
	cursor: pointer;
	padding: 3px 18px;

	:hover {
		background-color: #444;
	}
	:active {
		background-color: #666;
	}
`;
