import React, {useContext, useEffect, useState} from 'react';
import * as S from './styles';
import {
	Button,
	Wrapper,
	Text,
	SizedBox,
	WidthBox,
	Line,
	ModalTitle,
} from 'src/shared/components/UI';
import ApplicationContext from 'src/context/ApplicationContext';
import {IFtpConfig, IOption} from 'src/@types/app';
import {SystemInput} from 'src/shared/components/System/SystemInput';
import {applicationTheme} from 'src/shared/theme';
import {SystemDataGrid} from 'src/shared/components/System/SystemDataGrid';
import {SystemRowOptions} from 'src/shared/components/System/SystemRowOptions';
import {MenuItem} from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import {FtpList} from 'src/helpers/select-data/ftpList';
import Modal from 'src/shared/components/Complex/Modal';
import {IconWrapper} from 'src/shared/components/UI/ModalTitle/styles';

interface CellType {
	row: IFtpConfig;
}

export const RenderFtp = () => {
	const [data, setData] = useContext(ApplicationContext);
	const [ftp, setFtp] = useState<IFtpConfig>();
	const [envs, setEnvs] = useState<IOption[]>([]);
	const [isOpenDelete, setIsOpenDelete] = useState(false);
	const [itemToDelete, setItemToDelete] = useState<IFtpConfig | null>(null);

	useEffect(() => {
		setEnvs([
			{value: undefined, label: 'Selecione...'},
			...(data.environments?.map(item => ({
				label: item.name as string,
				value: item.ref as string,
			})) ?? []),
		]);
	}, [data]);

	const handleSave = () => {
		let ftps = [...(data.ftps ?? [])];

		if (ftp?.ref) {
			ftps = ftps.map(x => {
				if (x.ref === ftp.ref) return ftp;
				return x;
			});
		} else {
			ftps.push({
				...ftp,
				ref: crypto.randomUUID(),
			});
		}
		setData({...data, ftps});
		setFtp(undefined);
	};

	const removeItem = (item: IFtpConfig) => {
		setData({
			...data,
			ftps: [...(data.ftps ?? [])].filter(x => x.ref != item.ref),
		});
	};

	const openDeleteModal = (item: IFtpConfig) => {
		setItemToDelete(item);
		setIsOpenDelete(true);
	};

	const onRemove = () => {
		if (itemToDelete) {
			removeItem(itemToDelete);
			setItemToDelete(null); // Limpa o item após a exclusão
			setIsOpenDelete(false);
		}
	};

	return (
		<>
			<S.Content>
				<S.Tag>
					<div className="text">Configurações de FTP</div>
				</S.Tag>
				<S.Card>
					<Wrapper margin="0 0 14px 0">
						<SystemSelect
							width="15%"
							value={envs.find(x => x.value == ftp?.envRef)}
							onChange={val => setFtp({...ftp, envRef: val.value})}
							label="Ambiente"
							options={envs}
						/>
						<SystemInput
							width="50%"
							value={ftp?.host}
							onChange={value => setFtp({...ftp, host: value})}
							label="Host"
						/>
						<SystemInput
							width="15%"
							value={ftp?.port}
							onChange={value => setFtp({...ftp, port: value})}
							label="Porta"
							mask="Numero"
						/>
						<SystemSelect
							width="20%"
							value={FtpList.find(y => y.value == ftp?.type)}
							options={FtpList}
							onChange={value => setFtp({...ftp, type: value.value})}
							label="Tipo"
						/>
					</Wrapper>
					<Wrapper>
						<SystemInput
							width="50%"
							value={ftp?.user}
							onChange={value => setFtp({...ftp, user: value})}
							label="Usuário"
						/>
						<SystemInput
							width="50%"
							value={ftp?.password}
							onChange={value => setFtp({...ftp, password: value})}
							label="Senha"
							type="password"
						/>
					</Wrapper>
					<Wrapper margin="25px 0 0 0" justifyContent="end">
						<Button
							text={ftp?.ref ? 'Editar' : 'Adicionar'}
							onClick={handleSave}
							fill="auto"
							background={applicationTheme.brand[600]}
						/>
					</Wrapper>

					<SizedBox height="20px" />

					<SystemDataGrid
						rowsData={data.ftps ?? []}
						columns={[
							{
								flex: 0.1,
								minWidth: 90,
								type: 'actions',
								filterable: false,
								hideable: false,
								headerAlign: 'left',
								align: 'left',
								sortable: false,
								field: 'actions',
								headerName: '',
								renderCell: ({row}: CellType) => (
									<SystemRowOptions
										childrens={
											<>
												<MenuItem
													onClick={() => setFtp(row)}
													sx={{'& svg': {mr: 2}}}>
													<FeatherIcon icon="edit-3" size={16} />
													<Text text="Editar" />
												</MenuItem>
												<Wrapper
													alignItems="center"
													justifyContent="center">
													<WidthBox width="90%">
														<Line
															height="0.5px"
															background={applicationTheme.gray[300]}
														/>
													</WidthBox>
												</Wrapper>
												<MenuItem
													onClick={() => openDeleteModal(row)}
													sx={{'& svg': {mr: 2}}}>
													<FeatherIcon icon="trash-2" size={16} />
													<Text
														text="Excluir"
														color={applicationTheme.error[900]}
													/>
												</MenuItem>
											</>
										}
									/>
								),
							},
							{
								flex: 0.1,
								type: 'string',
								minWidth: 150,
								filterable: true,
								headerAlign: 'left',
								align: 'left',
								sortable: true,
								field: 'env',
								headerName: `Ambiente`,
								renderCell: ({row}: CellType) =>
									data.environments?.find(x => x.ref == row.envRef)?.name ?? '',
							},
							{
								flex: 0.2,
								type: 'string',
								minWidth: 150,
								filterable: true,
								headerAlign: 'left',
								align: 'left',
								sortable: true,
								field: 'host',
								headerName: `Host`,
								renderCell: ({row}: CellType) => row.host,
							},
							{
								flex: 0.1,
								type: 'string',
								minWidth: 150,
								filterable: true,
								headerAlign: 'left',
								align: 'left',
								sortable: true,
								field: 'port',
								headerName: `Porta`,
								renderCell: ({row}: CellType) => row.port,
							},
							{
								flex: 0.1,
								type: 'string',
								minWidth: 150,
								filterable: true,
								headerAlign: 'left',
								align: 'left',
								sortable: true,
								field: 'type',
								headerName: `Tipo`,
								renderCell: ({row}: CellType) => row.type,
							},
							{
								flex: 0.2,
								type: 'string',
								minWidth: 150,
								filterable: true,
								headerAlign: 'left',
								align: 'left',
								sortable: true,
								field: 'user',
								headerName: `Usuário`,
								renderCell: ({row}: CellType) => row.user,
							},
						]}
					/>
				</S.Card>
			</S.Content>
			<Modal isOpen={isOpenDelete} onClose={() => setIsOpenDelete(false)} width="auto">
				<>
					<ModalTitle
						title="Deletar Item?"
						icon={
							<IconWrapper>
								<FeatherIcon icon="alert-circle" size={20} />
							</IconWrapper>
						}
						centerTitle={true}
					/>
					<div
						style={{
							color: applicationTheme.gray[500],
							textAlign: 'center',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							fontSize: '14px',
							paddingBottom: '24px',
						}}>
						Tem certeza de que deseja excluir este item? <br />
						Essa ação não pode ser desfeita.
					</div>
					<Wrapper margin="20px 0 0 0" justifyContent="end">
						<Button
							text="Cancelar"
							onClick={() => setIsOpenDelete(false)}
							fill="full"
							background={applicationTheme.gray[300]}
							textColor={applicationTheme.gray[700]}
							type="ghost"
						/>
						<Button
							text="Deletar"
							onClick={onRemove}
							fill="full"
							background={applicationTheme.error[600]}
						/>
					</Wrapper>
				</>
			</Modal>
		</>
	);
};
