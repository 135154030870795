/* eslint-disable @typescript-eslint/no-explicit-any */
import {faAdd} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import {PhotoshopPicker} from 'react-color';
import {TimelineIconType} from 'src/shared/components/Complex/Timeline';
import {Wrapper, Text, WidthBox, Input, Button, SelectIcon} from 'src/shared/components/UI';
import * as S from './styles';

type Props = {
	value: TimelineIconType | undefined;
	setValue: (val: TimelineIconType) => void;
	text: string;
	hasAddBtn?: boolean;
	useStatus?: boolean;
	onClick?: any;
};

const IconRender = ({
	value,
	setValue,
	text,
	onClick,
	hasAddBtn = false,
	useStatus = false,
}: Props) => {
	return (
		<>
			{value?.show ? (
				<S.PickerContent>
					<PhotoshopPicker
						color={value?.color ?? '#000'}
						onChangeComplete={color => setValue({...value, color: color.hex})}
						onAccept={() => setValue({...value, show: false})}
						onCancel={() => setValue({...value, show: false})}
					/>
				</S.PickerContent>
			) : (
				<></>
			)}
			<Text text={text} color="#75868f" margin="0 0 0 10px" fontSize="12px" />
			<Wrapper margin="0 0 10px 0">
				{useStatus ? (
					<>
						<WidthBox width="50%">
							<Input
								placeholder="Status"
								value={value?.status}
								onChange={val => setValue({...value, status: val})}
								border="1px solid #444"
								background="transparent"
								textColor={'#000'}
							/>
						</WidthBox>
					</>
				) : (
					<></>
				)}

				<WidthBox width="60%">
					<SelectIcon
						value={value?.icon}
						onChange={val => setValue({...value, icon: val})}
						background="transparent"
						border="1px solid #333"
						textColor={'#000'}
					/>
				</WidthBox>
				<WidthBox width="40%">
					<S.ColorButton
						background={value?.color}
						onClick={() => {
							setValue({...value, show: true});
						}}
					/>
				</WidthBox>

				{hasAddBtn ? (
					<Button
						onClick={onClick}
						icon={faAdd}
						fill="auto"
						disabled={onClick == undefined}
					/>
				) : (
					<></>
				)}
			</Wrapper>
		</>
	);
};

export default IconRender;
