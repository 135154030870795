/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {IApplication, ITable, IResourceApi} from 'src/@types/app';
import {mapToDotnet} from 'src/helpers/methods/map-type-database';
import {dotnetFormatScreenName, dotnetSchemaName} from '../core/dotnet-formatter';
import {getConstraintName} from 'src/helpers/methods/database-to-screen';
import {backFormatPropertyName} from 'src/shared/engine-back/common/common-formatters';

export const generateDomain = (app: IApplication): IResourceApi[] => {
	const resources: IResourceApi[] = [];
	const projName = app.name!.replaceAll(' ', '');
	const hasSchemas = true;
	const tables = app.database!.tables!;

	resources.push(getBaseModel(projName));
	resources.push(getUnitOfWork(projName));
	resources.push(getBaseRepository(projName));
	if (!!app.database?.tables?.find(table => table.isUser))
		resources.push(getUserRepository(app, projName));

	tables.forEach(table => {
		resources.push({
			path: getPath(table, projName, hasSchemas),
			name: `${dotnetFormatScreenName(table.name!)}Entity.cs`,
			code: getModelCode(table, tables, projName, hasSchemas),
		});
	});

	return resources;
};

const getPath = (table: ITable, projName: string, hasSchemas: boolean): string => {
	if (hasSchemas) return `src/${projName}.Domain/Models/${dotnetSchemaName(table.schema!)}/`;
	else return `src/${projName}.Domain/Models/`;
};

const getModelCode = (
	table: ITable,
	tables: ITable[],
	projName: string,
	hasSchemas: boolean,
): string => {
	let properties = '';
	let importsRef = '';
	const tableName = dotnetFormatScreenName(table.name!);

	table.columns?.forEach(column => {
		let columnName = backFormatPropertyName(column.name!);
		let columnType: string | undefined = '';

		if (column.constraint) {
			const refTable = tables.find(x => x.ref == column.constraint);

			if (refTable) {
				columnType = dotnetFormatScreenName(refTable!.name!);
				columnName = getConstraintName(columnName);
				if (column.nullable) columnName;
				if (refTable?.schema != table.schema) {
					const importRef = `using ${projName}.Domain.Models.${dotnetSchemaName(
						refTable!.schema!,
					)};`;
					if (!importsRef.includes(importRef)) {
						importsRef += `${importRef}\n`;
					}
				}
			} else columnType = mapToDotnet(column.type!);
		} else columnType = mapToDotnet(column.type!);

		if (!column.autoIncremente) {
			properties += `
    /// <summary>
    /// ${decodeURIComponent(escape(column.description ?? '')) ?? columnName}
    /// </summary>
    public ${!column.nullable ? 'required ' : ''}${columnType}${
				!column.nullable ? '' : '?'
			} ${columnName} { get; set; }
`;
		}
	});

	if (!table.isUser) {
		tables
			.filter(t => t.ref != table.ref)
			.forEach(t => {
				const constTable = t.columns?.find(column => column.constraint == table.ref);
				if (!!constTable) {
					const importRef = `using ${projName}.Domain.Models.${dotnetSchemaName(
						t!.schema!,
					)};`;
					if (!importsRef.includes(importRef)) {
						importsRef += `${importRef}\n`;
					}
					properties += `
	/// <summary>
	/// Relação com a tabela ${dotnetFormatScreenName(t.name ?? '')}
	/// </summary>
	public List<${dotnetFormatScreenName(t.name ?? '')}>? ${dotnetFormatScreenName(
						t.name ?? '',
					)}s { get; set; }
`;
				}
			});
	}

	const code = `using ${projName}.Domain.Models.Base;
${importsRef}
namespace ${projName}.Domain.Models${hasSchemas ? `.${dotnetSchemaName(table.schema!)}` : ''};

/// <summary>
/// Estrutura de dados do(a) ${table.description ?? tableName}
/// </summary>
public class ${tableName} : BaseEntity
{${properties}}`;

	return code;
};

const getBaseModel = (projName: string): IResourceApi => {
	const code = `namespace ${projName}.Domain.Models.Base;

public class BaseEntity
{
    /// <summary>
    /// Id
    /// </summary>
    public int Id { get; set; }
}`;

	return {
		path: `src/${projName}.Domain/Models/Base/`,
		name: 'BaseEntity.cs',
		code: code,
	};
};

const getUnitOfWork = (projName: string): IResourceApi => {
	const code = `namespace ${projName}.Domain.Interfaces;

/// <summary>
/// Interface necessária para armazenar informações referentes aos processamentos no banco de dados
/// </summary>
public interface IUnitOfWork : IDisposable
{
	/// <summary>
	/// Responsável por comitar os dados no banco
	/// </summary>
    Task<bool> CommitAsync();
}`;

	return {
		path: `src/${projName}.Domain/Interfaces/`,
		name: 'IUnitOfWork.cs',
		code: code,
	};
};

const getBaseRepository = (projName: string): IResourceApi => {
	const code = `using ${projName}.Domain.Models.Base;
using System.Linq.Expressions;

namespace ${projName}.Domain.Interfaces.Repositories.Base;

/// <summary>
/// Interface necessária para armazenar informações referentes as operações no banco de dados
/// </summary>
public interface IRepository<T> where T : BaseEntity
{
	/// <summary>
	/// Obtém registros pelo Id
	/// </summary>
	Task<T?> ObterPorIdAsync(int id);

	/// <summary>
	/// Obtém registro
	/// </summary>
	Task<T?> Obter(Expression<Func<T, bool>> predicate);

	/// <summary>
	/// Lista registros
	/// </summary>
	Task<List<T>?> Listar(Expression<Func<T, bool>> predicate);

	/// <summary>
	/// Obtém todos os registros
	/// </summary>
	IQueryable<T> ObterTodos();

	/// <summary>
	/// Cria um registro
	/// </summary>
	T Criar(T model);

	/// <summary>
	/// Atualiza um registro
	/// </summary>
	T Alterar(T model);

	/// <summary>
	/// Remove um registro
	/// </summary>
	void Remover(int id);
}`;

	return {
		path: `src/${projName}.Domain/Interfaces/Repositories/Base/`,
		name: 'IRepository.cs',
		code: code,
	};
};

const getUserRepository = (app: IApplication, projName: string): IResourceApi => {
	const user = app.database?.tables?.find(table => table.isUser);
	const schema = dotnetSchemaName(user?.schema ?? '');
	const propName = backFormatPropertyName(dotnetFormatScreenName(user?.name ?? ''));

	const code = `using System.Linq.Expressions;
using ${projName}.Domain.Models.${schema ?? 'Base'};

namespace ${projName}.Domain.Interfaces.Repositories
{
    public interface I${propName}Repository
    {
        /// <summary>
        /// Obtém registro
        /// </summary>
        Task<${propName}?> Buscar(Expression<Func<${propName}, bool>> predicate);
    }
}`;

	return {
		path: `src/${projName}.Domain/Interfaces/Repositories/`,
		name: `I${propName}Repository.cs`,
		code: code,
	};
};
