import React, {useContext, useEffect, useState} from 'react';
import {IExpression, IExpressionItem} from 'src/@types/app';
import {Modal} from 'src/shared/components/Complex';
import {
	Button,
	Text,
	ModalTitle,
	WidthBox,
	Wrapper,
	SizedBox,
	Line,
} from 'src/shared/components/UI';
import {ApplicationContext} from 'src/context';

import {v4 as uuidv4} from 'uuid';
import ExpressionItem from './ExpressionItem';
import {SystemInput} from 'src/shared/components/System/SystemInput';
import {applicationTheme} from 'src/shared/theme';
import {SystemDataGrid} from 'src/shared/components/System/SystemDataGrid';
import {SystemRowOptions} from 'src/shared/components/System/SystemRowOptions';
import {MenuItem} from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import {IconWrapper} from 'src/shared/components/UI/ModalTitle/styles';

type Props = {
	isOpen: boolean;
	onClose: () => void;
};

interface CellType {
	row: IExpression;
}

const Expression = ({isOpen, onClose}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);

	const [expression, setExpression] = useState<IExpression>();
	const [expressions, setExpressions] = useState<IExpression[]>([]);
	const [editItem, setEditItem] = useState<IExpression>();
	const [refSelected, setRefSelected] = useState('');
	const [isOpenDelete, setIsOpenDelete] = useState(false);
	const [selectedRow, setSelectedRow] = useState<IExpression | null>(null);

	useEffect(() => {
		const selectedItem = application.resources?.find(item => item.selected);
		if (!selectedItem || !selectedItem.expressions) {
			setExpressions([]);
			return;
		}
		setExpressions(selectedItem.expressions);

		setRefSelected(
			application.resources?.find(x => x.selected)?.widgets?.find(x => x.selected)?.ref ?? '',
		);
	}, [application, isOpen]);

	const close = () => {
		onClose();
		setExpressions([]);
		setExpression(undefined);
	};

	const add = () => {
		let data = [...expressions];
		const currItem = {...expression};
		currItem.refField = refSelected;

		if (expression?.ref) {
			data = data.map(item => {
				if (item.ref === expression.ref) return currItem;
				return item;
			});
		} else {
			data.push({
				...currItem,
				ref: uuidv4().toString(),
			});
		}

		setExpressions(data);
		setExpression(undefined);
	};

	const isValid = () => {
		if (!expression || !expression.name) return true;
		if (
			expressions
				.filter(x => x.refField === refSelected)
				.find(x => x.name?.toLocaleLowerCase() == expression?.name?.toLocaleLowerCase())
		)
			return true;
	};

	const render = () => {
		return (
			<>
				<Wrapper>
					<WidthBox width="100%">
						<SystemInput
							value={expression?.name}
							onChange={val => setExpression({...expression, name: val})}
							label="Nome"
						/>
					</WidthBox>
					<WidthBox width="auto" margin="20px 0 0 0">
						<Button
							onClick={add}
							icon2={expression?.ref ? 'edit-3' : 'plus'}
							disabled={isValid()}
							textColor={applicationTheme.brand[600]}
							background={applicationTheme.brand[100]}
							height="41px"
						/>
					</WidthBox>
				</Wrapper>

				<SizedBox height="12px" />
				<SystemDataGrid
					rowsData={expressions.filter(x => x.refField === refSelected)}
					columns={[
						{
							flex: 0.1,
							minWidth: 90,
							type: 'actions',
							filterable: false,
							hideable: false,
							headerAlign: 'left',
							align: 'left',
							sortable: false,
							field: 'actions',
							headerName: '',
							renderCell: ({row}: CellType) => (
								<SystemRowOptions
									childrens={
										<>
											<MenuItem
												onClick={() => setEditItem(row)}
												sx={{'& svg': {mr: 2}}}>
												<FeatherIcon icon="settings" size={16} />
												<Text text="Configurar" />
											</MenuItem>
											<MenuItem
												onClick={() => setExpression(row)}
												sx={{'& svg': {mr: 2}}}>
												<FeatherIcon icon="edit-3" size={16} />
												<Text text="Editar" />
											</MenuItem>
											<Wrapper alignItems="center" justifyContent="center">
												<WidthBox width="90%">
													<Line
														height="0.5px"
														background={applicationTheme.gray[300]}
													/>
												</WidthBox>
											</Wrapper>
											<MenuItem
												onClick={() => {
													setSelectedRow(row);
													setIsOpenDelete(true);
												}} // Abre a modal de exclusão
												sx={{'& svg': {mr: 2}}}>
												<FeatherIcon icon="trash-2" size={16} />
												<Text
													text="Excluir"
													color={applicationTheme.error[900]}
												/>
											</MenuItem>
										</>
									}
								/>
							),
						},
						{
							flex: 0.5,
							type: 'string',
							minWidth: 150,
							filterable: true,
							headerAlign: 'left',
							align: 'left',
							sortable: true,
							field: 'name',
							headerName: `Nome`,
							renderCell: ({row}: CellType) => row.name,
						},
					]}
				/>

				<Wrapper justifyContent="end" margin="20px 0 0 0">
					<Button
						text="Fechar"
						onClick={close}
						fill="auto"
						background={applicationTheme.gray[300]}
						textColor={applicationTheme.gray[700]}
						type="ghost"
					/>
					<Button
						text="Salvar"
						onClick={save}
						fill="auto"
						background={applicationTheme.brand[600]}
						disabled={!isValid}
					/>
				</Wrapper>
			</>
		);
	};

	const saveExp = (data: IExpressionItem[], code: string) => {
		setExpressions(
			[...expressions].map(item => {
				if (item.ref === editItem?.ref) {
					item.items = data;
					item.code = code;
				}
				return item;
			}),
		);
		setEditItem(undefined);
	};

	const save = () => {
		const data = {...application};
		data.resources = data.resources?.map(resource => {
			if (resource.selected) {
				resource.expressions = expressions ?? [];
			}
			return resource;
		});

		setApplication(data);
		close();
	};

	const remove = () => {
		if (selectedRow) {
			setExpressions([...expressions].filter(x => x.ref !== selectedRow.ref));
			setIsOpenDelete(false); // Fecha a modal após excluir
			setSelectedRow(null);
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={close} width="50%" maxWidth="900px">
			<>
				<ModalTitle title="Criar expressões" />
				{editItem ? (
					<ExpressionItem
						currentItem={editItem}
						onClose={() => setEditItem(undefined)}
						save={saveExp}
					/>
				) : (
					render()
				)}
				{/* Modal de Exclusão */}
				<Modal isOpen={isOpenDelete} onClose={() => setIsOpenDelete(false)} width="auto">
					<>
						<ModalTitle
							title="Deletar Item?"
							icon={
								<IconWrapper>
									<FeatherIcon icon="alert-circle" size={20} />
								</IconWrapper>
							}
							centerTitle={true}
						/>
						<div
							style={{
								color: applicationTheme.gray[500],
								textAlign: 'center',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								fontSize: '14px',
								paddingBottom: '24px',
							}}>
							Tem certeza de que deseja excluir o item &apos;{selectedRow?.name}
							&apos;?
							<br />
							Essa ação não pode ser desfeita.
						</div>
						<Wrapper margin="20px 0 0 0" justifyContent="end">
							<Button
								text="Cancelar"
								onClick={() => setIsOpenDelete(false)}
								fill="full"
								background={applicationTheme.gray[300]}
								textColor={applicationTheme.gray[700]}
								type="ghost"
							/>
							<Button
								text="Deletar"
								onClick={remove}
								fill="full"
								background={applicationTheme.error[600]}
							/>
						</Wrapper>
					</>
				</Modal>
			</>
		</Modal>
	);
};

export default Expression;
